import { Box, Button, ButtonProps, HStack, Spacer } from '@chakra-ui/react';

interface QuantitySelectorProps {
  maxQuantity?: number;
  label?: string;
  quantity: number;
  setQuantity: (quantity: number) => void;
}

const QuantityButton = ({ ...rest }: { isDisabled?: boolean; children: React.ReactNode } & ButtonProps) => {
  return (
    <Button
      py="14px"
      px="5"
      rounded="0"
      h="full"
      bg="chakra-body-bg"
      _disabled={{ borderColor: 'chakra-body-text', bg: 'shaded', color: 'gray.500' }}
      borderColor="chakra-body-text"
      {...rest}
    />
  );
};

export const QuantitySelector = ({ maxQuantity, label, quantity, setQuantity }: QuantitySelectorProps) => {
  return (
    <HStack>
      {label && (
        <>
          <Box p="4">{label}</Box>
          <Spacer />
        </>
      )}
      <HStack
        fontWeight="bold"
        borderWidth="2px"
        overflow="hidden"
        borderColor="chakra-body-text"
        rounded="lg"
        spacing="0"
      >
        <QuantityButton
          isDisabled={quantity === 1}
          borderRightWidth="2px"
          onClick={() => {
            if (quantity === 1) return;
            setQuantity(quantity - 1);
          }}
        >
          -
        </QuantityButton>
        <Box px="5">{quantity}</Box>
        <QuantityButton
          isDisabled={quantity === maxQuantity}
          borderLeftWidth="2px"
          onClick={() => {
            if (maxQuantity && quantity === maxQuantity) return;
            setQuantity(quantity + 1);
          }}
        >
          +
        </QuantityButton>
      </HStack>
    </HStack>
  );
};
