'use client';

import { Container } from '@chakra-ui/react';

import { Missing } from 'components/atoms/Missing';
import { BaseLayout } from 'components/templates/Base/base';
import { useTrackPageView } from 'hooks/useTrackPageView';

const NotFoundPage = () => {
  useTrackPageView('Not Found');

  return (
    <BaseLayout>
      <Container textAlign="center" py="32">
        <Missing />
      </Container>
    </BaseLayout>
  );
};

export default NotFoundPage;
