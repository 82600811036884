import { toast } from 'App';

/**
 * Show an error toast.
 * @param message The message of the error
 */
export const showError = (message: string) => {
  toast({
    status: 'error',
    description: message,
  });
};

/**
 * Use this as the catch function for a mutation promise to extract Zod validation
 * errors from the backend and set them as field and global errors appropriately.
 * @param setErrors Formik `setErrors` prop
 */
export const extractErrors = (setErrors: (errors: any) => void) => (error: any) => {
  error.graphQLErrors?.forEach((error: any) => {
    if (error.extensions?.code === 'VALIDATION_FAILURE') {
      const errors = error.extensions.errors;
      errors._errors?.forEach((message: any) => {
        showError(message);
      });
      setErrors(
        Object.fromEntries(
          Object.entries(errors)
            .filter(([key, _]) => key !== '_errors')
            .map(([field, { _errors }]: [string, any]) => {
              return [field, _errors];
            }),
        ),
      );
    } else {
      showError(error.message);
    }
  });
};
