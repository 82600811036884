export const avgColor = async (image: string | HTMLImageElement) => {
  let img = image;
  if (typeof img === 'string') {
    img = new Image();
    img.src = image as string;
    img.crossOrigin = 'Anonymous';
  }

  // Need to await the image load
  await img.decode();

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  canvas.height = img.naturalHeight || img.offsetHeight || img.height;
  canvas.width = img.naturalWidth || img.offsetWidth || img.width;

  ctx?.drawImage(img, 0, 0);

  const imgData = ctx?.getImageData(0, 0, canvas.width, canvas.height);
  if (!imgData) return null;

  // Get the length of image data object
  const length = imgData?.data.length;

  let red = 0;
  let green = 0;
  let blue = 0;
  let count = 0;

  for (var i = 0; i < length; i += 4) {
    red += imgData.data[i];
    green += imgData.data[i + 1];
    blue += imgData.data[i + 2];
    count++;
  }

  return {
    r: Math.floor(red / count),
    g: Math.floor(green / count),
    b: Math.floor(blue / count),
  };
};

export const hsp = (red: number, green: number, blue: number) => {
  // http://alienryderflex.com/hsp.html
  // Brightness
  return Math.sqrt(0.299 * red ** 2 + 0.587 * green ** 2 + 0.114 * blue ** 2);
};

export const rgbToHsl = (red: number, green: number, blue: number) => {
  const r = red / 255,
    g = green / 255,
    b = blue / 255;

  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let hue = 0,
    saturation,
    lightness = (max + min) / 2;

  if (max === min) {
    hue = saturation = 0; // achromatic
  } else {
    var d = max - min;
    saturation = lightness > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        hue = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        hue = (b - r) / d + 2;
        break;
      case b:
        hue = (r - g) / d + 4;
        break;
    }
    hue /= 6;
  }

  const hsl = {
    h: Math.floor(hue * 360),
    s: Math.floor(saturation * 100),
    l: Math.floor(lightness * 100),
  };

  return { ...hsl, str: 'hsl(' + hsl.h + ',' + hsl.s + '%,' + hsl.l + '%)' };
};
