/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AnalyticsMetric {
  REVENUE = 'REVENUE',
  UNITS = 'UNITS',
}

export enum Currency {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
}

export enum Frequency {
  DAILY = 'DAILY',
  MONTHLY = 'MONTHLY',
  WEEKLY = 'WEEKLY',
}

export enum LinkType {
  APPLE_MUSIC = 'APPLE_MUSIC',
  BANDCAMP = 'BANDCAMP',
  DEEZER = 'DEEZER',
  DISCORD = 'DISCORD',
  FACEBOOK = 'FACEBOOK',
  INSTAGRAM = 'INSTAGRAM',
  OTHER = 'OTHER',
  SNAP = 'SNAP',
  SOUNDCLOUD = 'SOUNDCLOUD',
  SPOTIFY = 'SPOTIFY',
  TIDAL = 'TIDAL',
  TIKTOK = 'TIKTOK',
  TWITTER = 'TWITTER',
  WEBSITE = 'WEBSITE',
  YOUTUBE = 'YOUTUBE',
}

export enum MatcherName {
  CITIES = 'CITIES',
  COUNTRIES = 'COUNTRIES',
  MEMBERS_ONLY = 'MEMBERS_ONLY',
  SPOTIFY_PRE_SAVE_ALL_ENABLED = 'SPOTIFY_PRE_SAVE_ALL_ENABLED',
  SPOTIFY_TOP_ARTIST = 'SPOTIFY_TOP_ARTIST',
}

export enum OfferingType {
  ATTENDANCE = 'ATTENDANCE',
  COMPETITION = 'COMPETITION',
  CONTENT = 'CONTENT',
  EVENT = 'EVENT',
  MERCH = 'MERCH',
  PURCHASE = 'PURCHASE',
}

export interface BlockInput {
  groupId?: number | null;
  groupOrder?: number | null;
  order: number;
  title: string;
  url: string;
}

export interface GroupInput {
  name: string;
  order: number;
}

export interface LinkInput {
  name: string;
  order?: number | null;
  url: string;
}

export interface MatcherInput {
  matcherName: MatcherName;
  parameters?: any | null;
}

export interface SocialLinkInput {
  order?: number | null;
  type: LinkType;
  url: string;
}

export interface UploadedFileInput {
  id: string;
  name: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
