import { gql } from '@apollo/client';

import { UserDropdown } from 'components/organisms/Navbar/UserDropdown';

export const ME_QUERY = gql`
  query MagicAuthMe {
    me {
      id
      email
      displayName
      avatar(variant: sm)
      username
      isAdmin
      isStaff
      userSpotifyConnectionEnabled
      ...UserDropdown_user
      managed: communities(roles: [CREATOR, MANAGER]) {
        community {
          id
          displayName
          slug
          logo(variant: md)
        }
      }
      memberships: communities(roles: [MEMBER]) {
        community {
          id
          displayName
          slug
          logo(variant: md)
        }
      }
      tokenCollection {
        id
      }
    }
  }
  ${UserDropdown.fragments.user}
`;

export const SIGN_UP_QUERY = gql`
  mutation SignUpWithMagicLinkQuery($didToken: String!, $username: String, $displayName: String, $distinctId: String) {
    signUpWithMagicLink(didToken: $didToken, username: $username, displayName: $displayName, distinctId: $distinctId)
  }
`;

export const LOGIN_QUERY = gql`
  mutation LoginWithMagicLinkQuery($didToken: String!, $distinctId: String) {
    loginWithMagicLink(didToken: $didToken, distinctId: $distinctId)
  }
`;
