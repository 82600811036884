'use client';

import { Container, Heading } from '@chakra-ui/react';
import { AuthContext } from 'providers/Auth';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import { SignUpForm } from 'components/forms/SignUpForm';
import { PagePanel } from 'components/molecules/PagePanel';
import { BaseLayout } from 'components/templates/Base/base';
import { useTrackPageView } from 'hooks/useTrackPageView';

const SignUpPage = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [params] = useSearchParams();

  if (user) {
    navigate(params.get('then') || ROUTES.dashboard.base.path);
  }

  useTrackPageView('Sign Up');

  return (
    <BaseLayout>
      <Helmet>
        <title>Sign up for Temple</title>
      </Helmet>
      <PagePanel isNarrow>
        <Container variant="form">
          <Heading variant="h1" textAlign="center" mb="6">
            Sign up
          </Heading>
          <SignUpForm />
        </Container>
      </PagePanel>
    </BaseLayout>
  );
};

export default SignUpPage;
