import { Box, HStack, Spacer } from '@chakra-ui/react';

interface DetailRowProps {
  name: string;
  children: React.ReactNode;
}

export const DetailRow = ({ name, children }: DetailRowProps) => (
  <HStack fontSize="sm" data-cy="detailRow">
    <Box fontWeight="bold" flexShrink="0">
      {name}
    </Box>
    <Spacer />
    <Box data-cy="detailRowValue" minW="0" flexShrink="1">
      {children}
    </Box>
  </HStack>
);
