import { FormControlProps, FormControl, Switch, HStack, Stack } from '@chakra-ui/react';
import { useField } from 'formik';

import { Label, LabelProps } from '../Label';

interface SwitchWithLabelProps extends Omit<FormControlProps, 'children'> {
  label: string;
  name: string;
  description?: string;
  isDisabled?: boolean;
  hasSurroundingBox?: boolean;
  labelProps?: Omit<LabelProps, 'label' | 'name' | 'description'>;
  children?: React.ReactNode;
  tooltip?: string;
}

export const SwitchWithLabel = ({
  label,
  name,
  description,
  isDisabled,
  hasSurroundingBox,
  labelProps,
  children,
  tooltip,
  ...props
}: SwitchWithLabelProps) => {
  const [field, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;
  return (
    <FormControl {...props}>
      <Stack>
        <HStack
          border={hasSurroundingBox ? '1px solid' : 0}
          p={hasSurroundingBox ? 4 : 0}
          borderColor="chakra-border-color"
          borderRadius={8}
        >
          <Label
            cursor="pointer"
            fontWeight="bold"
            fontSize="sm"
            flexGrow="1"
            name={name}
            label={label}
            description={description}
            tooltip={tooltip}
            mb="0"
            {...labelProps}
          />
          <Switch
            id={name}
            isDisabled={isDisabled}
            size="sm"
            ml="auto"
            isChecked={value}
            {...field}
            onChange={(e) => {
              setValue(e.target.checked);
            }}
          />
        </HStack>
        {value && children}
      </Stack>
    </FormControl>
  );
};
