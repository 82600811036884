import { AspectRatio, Box, Heading, Stack } from '@chakra-ui/react';

import { ImageOrPlaceholder } from 'components/atoms/ImageOrPlaceholder';

interface LandingPageValuePropV2Props {
  image: string;
  title: string;
  description: string;
  align?: 'left' | 'right';
}

export const LandingPageValuePropV2 = ({ image, title, description, align = 'left' }: LandingPageValuePropV2Props) => {
  return (
    <Stack
      direction={{ base: 'column-reverse', lg: align === 'left' ? 'row' : 'row-reverse' }}
      spacing={{ base: 8, lg: 12 }}
      alignItems={{ lg: 'center' }}
      mx="auto"
      w="full"
      my={{ base: 2, lg: 4 }}
      px="4"
    >
      <AspectRatio ratio={3 / 2} flexGrow={1} flexShrink={0} w={{ base: 'full', lg: 'auto' }}>
        <ImageOrPlaceholder src={image} bg="transparent" />
      </AspectRatio>
      <Stack
        spacing={{ base: 0, lg: 2 }}
        textAlign={{ base: 'center', lg: align === 'left' ? 'right' : 'left' }}
        minW="25%"
        maxW={{ base: 'none', lg: '42%' }}
        flexGrow={0}
        flexShrink={1}
      >
        <Heading variant="h3" textTransform="uppercase">
          {title}
        </Heading>
        <Box dangerouslySetInnerHTML={{ __html: description }} />
      </Stack>
    </Stack>
  );
};
