import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Button, Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { toast } from 'App';
import { LinkType } from '__graphql__/globalTypes';
import { Formik, FieldArray } from 'formik';
import { CommunityContext } from 'providers/Community';
import { useContext } from 'react';

import { SocialIcon } from 'components/atoms/SocialIcon';
import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { Input } from 'components/molecules/Input';
import { extractErrors } from 'utils/errors';
import { regexUrl } from 'utils/regex';

import { linksInBioMutation, linksInBioMutationVariables } from './__graphql__/linksInBioMutation';
import { linksInBioQuery, linksInBioQueryVariables } from './__graphql__/linksInBioQuery';

const appleMatchers = ['apple.c'];
const bandcampMatchers = ['bandcamp.c'];
const deezerMatchers = ['deezer.c'];
const discordMatchers = ['discord.c'];
const facebookMatchers = ['facebook.c'];
const instagramMatchers = ['instagram.c'];
const snapMatchers = ['snap.c'];
const spotifyMatchers = ['spotify.c'];
const soundcloudMatchers = ['soundcloud.c'];
const tidalMatchers = ['tidal.c'];
const tiktokMatchers = ['tiktok.c'];
const twitterMatchers = ['twitter.c'];
const youtubeMatchers = ['youtube.c'];

const checkUrlMatch = (url: string, matchers: string[]) => matchers.some((m) => url?.toLowerCase().includes(m));

const getSocialFromUrl = (url: string): LinkType => {
  if (checkUrlMatch(url, appleMatchers)) return LinkType.APPLE_MUSIC;
  if (checkUrlMatch(url, bandcampMatchers)) return LinkType.BANDCAMP;
  if (checkUrlMatch(url, deezerMatchers)) return LinkType.DEEZER;
  if (checkUrlMatch(url, discordMatchers)) return LinkType.DISCORD;
  if (checkUrlMatch(url, facebookMatchers)) return LinkType.FACEBOOK;
  if (checkUrlMatch(url, instagramMatchers)) return LinkType.INSTAGRAM;
  if (checkUrlMatch(url, snapMatchers)) return LinkType.SNAP;
  if (checkUrlMatch(url, spotifyMatchers)) return LinkType.SPOTIFY;
  if (checkUrlMatch(url, soundcloudMatchers)) return LinkType.SOUNDCLOUD;
  if (checkUrlMatch(url, tidalMatchers)) return LinkType.TIDAL;
  if (checkUrlMatch(url, tiktokMatchers)) return LinkType.TIKTOK;
  if (checkUrlMatch(url, twitterMatchers)) return LinkType.TWITTER;
  if (checkUrlMatch(url, youtubeMatchers)) return LinkType.YOUTUBE;
  return LinkType.OTHER;
};

const QUERY = gql`
  query linksInBioQuery($slug: String!) {
    community(slug: $slug) {
      socialLinks {
        order
        type
        url
      }
      links {
        name
        order
        url
      }
    }
  }
`;

const MUTATION = gql`
  mutation linksInBioMutation($communityId: ID!, $links: [LinkInput!], $socialLinks: [SocialLinkInput!]) {
    updateLinksInBio(communityId: $communityId, links: $links, socialLinks: $socialLinks) {
      links {
        name
        order
        url
      }
      socialLinks {
        order
        type
        url
      }
    }
  }
`;

type socialInput = {
  type: LinkType;
  url: string;
};

type bioInput = {
  name: string;
  url: string;
};

const CommunityLinkPage = () => {
  const { community } = useContext(CommunityContext);
  const { data, loading, refetch } = useQuery<linksInBioQuery, linksInBioQueryVariables>(QUERY, {
    variables: { slug: community?.slug ?? '' },
    skip: !community?.slug,
  });

  const [updateLinks] = useMutation<linksInBioMutation, linksInBioMutationVariables>(MUTATION);

  if (loading) {
    return <WrappedSpinner />;
  }

  return (
    <Stack maxW="48rem">
      <Heading variant="h2" mb={4}>
        Social Links
      </Heading>
      <Formik
        initialValues={{
          social: data?.community?.socialLinks || [],
          bio: data?.community?.links || [],
        }}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            if (!community?.id) return;
            // Need to remove __typename for Gql
            const soc = values.social
              .map(
                (link) =>
                  link.url && {
                    type: link.type || getSocialFromUrl(link.url),
                    url: link.url,
                  },
              )
              .filter((a) => a) as socialInput[];
            const bio = values.bio
              .map(
                (link) =>
                  link.url && {
                    name: link.name,
                    url: link.url,
                  },
              )
              .filter((a) => a) as bioInput[];

            const result = await updateLinks({
              variables: {
                communityId: community?.id,
                socialLinks: soc,
                links: bio,
              },
            });

            if (result) {
              toast({
                status: 'success',
                description: 'Links added',
              });
              await refetch();
            }
          } catch (errors) {
            extractErrors(setErrors)(errors);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, values, submitForm, isSubmitting, resetForm, errors, touched, dirty }) => {
          // Check for duplicate social links
          // const hasDuplicateTypes = values.social
          //   .map((v) => getSocialFromUrl(v.url))
          //   .some((e, i, arr) => arr.indexOf(e) !== i);
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Stack spacing="12" width={{ base: 'auto', md: '620px' }}>
                {/* // Social Links */}
                <Stack gap={4}>
                  <Text variant="description">You can add up to a maximum of 10 links</Text>
                  {/* <Text variant="description">
                    Here's the list of accepted socials: Apple, Bandcamp, Deezer, Discord, Facebook, Instagram,
                    Snapchat, Spotify, Soundcloud, TIdal, Tiktok, Twitter, Youtube + one more of your choice
                  </Text> */}
                  <FieldArray
                    name="social"
                    render={(arrayHelpers) => {
                      return (
                        <Flex flex="1 0" mt={0} direction="column">
                          {values.social.map((link, idx) => (
                            <Flex key={idx} mb={2} alignItems="center">
                              <SocialIcon
                                type={getSocialFromUrl(link.url)}
                                mr={2}
                                transform={
                                  errors.social && errors.social[idx] && touched?.social && touched.social[idx]
                                    ? `translate(0, -0.8rem)`
                                    : ''
                                }
                              />
                              <Input
                                name={`social.${idx}.url`}
                                placeholder="Url"
                                validate={(value: string) => {
                                  if (value && !regexUrl(value)) {
                                    return 'Not a valid url';
                                  }
                                }}
                              />
                              {/* // We could auto populate a name field based on the url */}
                              {/* <Input name={`social.${idx}.name`} mr={2} placeholder="Name" /> */}
                              <Box>
                                <Button
                                  variant="unstyled"
                                  transform={
                                    errors.social && errors.social[idx] && touched?.social && touched.social[idx]
                                      ? `translate(0, -0.8rem)`
                                      : ''
                                  }
                                  onClick={() => arrayHelpers.remove(idx)}
                                >
                                  Remove
                                </Button>
                              </Box>
                            </Flex>
                          ))}
                          {/* {hasDuplicateTypes && (
                            <Text color="red.400">Remember: You cannot add two social links of the same website</Text>
                          )} */}
                          <Button
                            type="button"
                            mr="auto"
                            mt={4}
                            isDisabled={values.social.length >= 10}
                            onClick={() => arrayHelpers.push('')}
                          >
                            Add a link
                          </Button>
                        </Flex>
                      );
                    }}
                  />
                </Stack>
                {/* // Links in Bio */}
                <Stack gap={4} overflow="hidden">
                  <Heading variant="h2" mb={4} mt={8}>
                    Links in Bio
                  </Heading>
                  <Text variant="description">You can add up to a maximum of 10 links</Text>
                  <FieldArray
                    name="bio"
                    render={(arrayHelpers) => {
                      return (
                        <Flex flex="1 0" mt={0} direction="column">
                          {values.bio.map((link, idx) => (
                            <Flex key={idx} mb={2} alignItems="center">
                              <SocialIcon
                                name={link.name}
                                mr={2}
                                transform={
                                  errors.bio && errors.bio[idx] && touched?.bio && touched.bio[idx]
                                    ? `translate(0, -0.8rem)`
                                    : ''
                                }
                              />
                              <Input
                                name={`bio.${idx}.url`}
                                mr={2}
                                placeholder="Url"
                                validate={(value: string) => {
                                  if (value && !regexUrl(value)) {
                                    return 'Not a valid url';
                                  }
                                }}
                              />
                              <Input
                                name={`bio.${idx}.name`}
                                transform={
                                  errors.bio && errors.bio[idx] && touched?.bio && touched.bio[idx]
                                    ? `translate(0, -0.8rem)`
                                    : ''
                                }
                                placeholder="Name"
                              />
                              <Box>
                                <Button
                                  variant="unstyled"
                                  transform={
                                    errors.bio && errors.bio[idx] && touched?.bio && touched.bio[idx]
                                      ? `translate(0, -0.8rem)`
                                      : ''
                                  }
                                  onClick={() => arrayHelpers.remove(idx)}
                                >
                                  Remove
                                </Button>
                              </Box>
                            </Flex>
                          ))}

                          <Button
                            type="button"
                            mr="auto"
                            isDisabled={values.bio.length >= 10}
                            mt={4}
                            onClick={() => arrayHelpers.push('')}
                          >
                            Add a link
                          </Button>
                        </Flex>
                      );
                    }}
                  />
                </Stack>
                <Flex>
                  <Button
                    ml="auto"
                    isDisabled={!dirty}
                    onClick={async () => {
                      await refetch();
                      resetForm({
                        values: {
                          social: data?.community?.socialLinks || [],
                          bio: data?.community?.links || [],
                        },
                      });
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    ml="2"
                    onClick={submitForm}
                    isDisabled={!dirty}
                    isLoading={isSubmitting}
                    variant="secondary"
                  >
                    Save
                  </Button>
                </Flex>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default CommunityLinkPage;
