import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Paragraph } from 'components/atoms/Paragraph';

export const Missing = () => {
  const { t } = useTranslation();
  return (
    <>
      <Heading variant="h1">{t('404_title')}</Heading>
      <Paragraph color="gray.500" fontSize="lg">
        {t('404_subtext')}
      </Paragraph>
    </>
  );
};
