'use client';

import { Heading, Stack } from '@chakra-ui/react';

import { CreateDropForm } from 'components/forms/OfferingForm/dropCreate';

const CreateDropPage = () => {
  return (
    <Stack maxW="36rem" spacing={8}>
      <Heading variant="h2">Create Drop</Heading>
      <CreateDropForm />
    </Stack>
  );
};

export default CreateDropPage;
