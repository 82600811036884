'use client';

import { Box, Container, Heading, Stack } from '@chakra-ui/react';

import { CalendlyWidget } from 'components/molecules/CalendlyWidget';
import { BaseLayout } from 'components/templates/Base/base';
import { useTrackPageView } from 'hooks/useTrackPageView';

const WaitlistPage = () => {
  useTrackPageView('Waitlist');

  return (
    <BaseLayout navbarProps={{ isFullWidth: false }} footerProps={{ isFullWidth: false }}>
      <Container>
        <Stack spacing="2">
          <Stack textAlign="center" spacing="1">
            <Heading variant="h2">Get early access</Heading>
            <Box>Book your Creator onboarding call below.</Box>
          </Stack>
          <CalendlyWidget url="https://calendly.com/d/zz6-vy8-584" />
        </Stack>
      </Container>
    </BaseLayout>
  );
};

export default WaitlistPage;
