import { gql } from '@apollo/client';
import { Box, Link, VStack, SkeletonText, Icon, Image } from '@chakra-ui/react';

import { CommunityLinksPageQuery_community_links } from 'app/[community]/links/__graphql__/CommunityLinksPageQuery';

interface LinkInBioListProps {
  links?: CommunityLinksPageQuery_community_links[];
}

const getUrlDomain = (url: string) => {
  let sinProtocol = url.replace(/^https?:\/\//, '');
  try {
    return new URL('https://' + sinProtocol).hostname;
  } catch (e) {
    return sinProtocol;
  }
};

const LinkInBio = ({ link, isLoaded }: { link: CommunityLinksPageQuery_community_links; isLoaded: boolean }) => {
  const iconUrl = getUrlDomain(link.url);
  return (
    <Link key={link.order} href={link.url} target="_blank" rel="noopener noreferrer" w="100%">
      <Box
        display="flex"
        alignItems="center"
        p={2}
        border="1px solid"
        borderColor={'chakra-border-color'}
        borderRadius={4}
        verticalAlign={'center'}
      >
        <Icon as={Image} boxSize="20px" mr={2} src={`https://icons.duckduckgo.com/ip3/${iconUrl}.ico`} />
        <SkeletonText
          noOfLines={1}
          isLoaded={isLoaded}
          fontWeight="medium"
          color={'black'}
          fontSize={'md'}
          verticalAlign={'center'}
        >
          {link.name}
        </SkeletonText>
      </Box>
    </Link>
  );
};

const LinkInBioList = ({ links }: LinkInBioListProps) => {
  return (
    <VStack align="center" spacing={3} w="100%">
      {links && links.map((link) => <LinkInBio link={link} isLoaded={!!links} />)}
    </VStack>
  );
};

LinkInBioList.fragments = {
  links: gql`
    fragment LinkInBioList_community on Community {
      links {
        name
        url
        order
      }
    }
  `,
};

export default LinkInBioList;
