import { gql } from '@apollo/client';
import { Ticket } from 'phosphor-react';

import { ImageOrPlaceholder, ImageOrPlaceholderProps } from 'components/atoms/ImageOrPlaceholder';

import { CommunityLogo_community } from './__graphql__/CommunityLogo_community';

interface CommunityLogoProps extends ImageOrPlaceholderProps {
  community?: CommunityLogo_community | null;
}

export const CommunityLogo = ({ community, ...rest }: CommunityLogoProps) => {
  const imageSize = { base: '80px', md: '140px' };
  return (
    <ImageOrPlaceholder
      src={community?.logoMd}
      borderRadius="2xl"
      isLoaded={!!community}
      icon={Ticket}
      w={imageSize}
      h={imageSize}
      {...rest}
    />
  );
};

interface CommunityLogoNavProps extends ImageOrPlaceholderProps {
  logo: string | null;
}

export const CommunityLogoNav = ({ logo, ...props }: CommunityLogoNavProps) => {
  return (
    <ImageOrPlaceholder
      src={logo}
      w="9"
      h="9"
      icon={Ticket}
      borderRadius="lg"
      iconProps={{ boxSize: '5' }}
      bg="white"
      borderWidth="2px"
      borderColor="chakra-border-color"
      {...props}
    />
  );
};

CommunityLogo.fragments = {
  community: gql`
    fragment CommunityLogo_community on Community {
      id
      logoMd: logo(variant: md)
    }
  `,
};
