import { Route, Routes } from 'react-router-dom';
import { ROUTES } from 'routes';

import { NotFoundPanel } from 'components/molecules/NotFoundPanel';

import CommunityBlocksPage from './blocks/page';
import DashboardIntegrationPage from './integrations/page';
import ClaimLinkPage from './items/[id]/claim-link/page';
import EditDropPage from './items/[id]/edit/page';
import DropPage from './items/[id]/page';
import CreateDropPage from './items/new/page';
import DashboardDropsPage from './items/page';
import CommunityDashboardLayout from './layout';
import CommunityLinkPage from './links/page';
import EditMessageForm from './message/[messageId]/page';
import NewMessageForm from './message/new/page';
import MessageOverviewPage from './message/page';
import CommunityDashboard from './page';
import CommunitySettingsPage from './settings/page';

export const DashboardCommunityRoutes = () => {
  return (
    <CommunityDashboardLayout>
      <Routes>
        <Route path={ROUTES.dashboard.community.overview.relative} element={<CommunityDashboard />} />
        <Route path={ROUTES.dashboard.community.message.relative} element={<MessageOverviewPage />} />
        <Route path={ROUTES.dashboard.community.messageNew.relative} element={<NewMessageForm />} />
        <Route path={ROUTES.dashboard.community.messageEdit.relative} element={<EditMessageForm />} />
        <Route path={ROUTES.dashboard.community.settings.relative} element={<CommunitySettingsPage />} />
        <Route path={ROUTES.dashboard.community.links.relative} element={<CommunityLinkPage />} />
        <Route path={ROUTES.dashboard.community.blocks.relative} element={<CommunityBlocksPage />} />
        <Route path={ROUTES.dashboard.community.integrations.relative} element={<DashboardIntegrationPage />} />
        <Route path={ROUTES.dashboard.community.items.relative} element={<DashboardDropsPage />} />
        <Route path={ROUTES.dashboard.community.itemsNew.relative} element={<CreateDropPage />} />
        <Route path={ROUTES.dashboard.community.itemsId.relative} element={<DropPage />} />
        <Route path={ROUTES.dashboard.community.itemsEdit.relative} element={<EditDropPage />} />
        <Route path={ROUTES.dashboard.community.itemsClaimLink.relative} element={<ClaimLinkPage />} />
        <Route path="*" element={<NotFoundPanel />} />
      </Routes>
    </CommunityDashboardLayout>
  );
};
