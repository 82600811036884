import { gql, useMutation } from '@apollo/client';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  ButtonProps,
  useDisclosure,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { showError } from 'utils/errors';

import {
  ReleaseTokenButtonMutation,
  ReleaseTokenButtonMutationVariables,
} from './__graphql__/ReleaseTokenButtonMutation';

interface ReleaseTokenButtonProps extends Omit<ButtonProps, 'id'> {
  id: string | null | undefined;
}

const MUTATION = gql`
  mutation ReleaseTokenButtonMutation($id: ID!) {
    releaseTokenContract(id: $id) {
      id
      releasedAt
    }
  }
`;

export const ReleaseTokenButton = ({ id, ...rest }: ReleaseTokenButtonProps) => {
  const { t } = useTranslation('creator', { keyPrefix: 'molecules.ReleaseTokenButton' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [releasing, setReleasing] = useState(false);
  const cancelRef = useRef<HTMLButtonElement | null>(null);
  const [releaseTokenContract] = useMutation<ReleaseTokenButtonMutation, ReleaseTokenButtonMutationVariables>(MUTATION);

  const onRelease = async () => {
    if (!id) return;

    setReleasing(true);
    try {
      await releaseTokenContract({ variables: { id } });
      onClose();
    } catch (error) {
      if (error instanceof Error) {
        showError(error.message);
      }
    }
    setReleasing(false);
  };

  return (
    <>
      <Button onClick={onOpen} {...rest} isLoading={releasing}>
        {t('buttonText')}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        closeOnEsc={!releasing}
        closeOnOverlayClick={!releasing}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('modalHeader')}
            </AlertDialogHeader>

            <AlertDialogBody>{t('modalBody')}</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} isDisabled={releasing}>
                {t('modalCancel')}
              </Button>
              <Button variant="primary" isLoading={releasing} onClick={onRelease} ml={3}>
                {t('modalSubmit')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
