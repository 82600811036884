import { Route, Routes } from 'react-router-dom';
import { ROUTES } from 'routes';

import ItemBuyPage from './buy/page';
import Page from './page';
import ItemReceiptPage from './receipt/page';

export const CommunityItemRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.community.item.base.relative} element={<Page />} />
      <Route path={ROUTES.community.item.key.relative} element={<Page />} />
      <Route path={ROUTES.community.item.buy.base.relative} element={<ItemBuyPage />} />
      <Route path={ROUTES.community.item.buy.key.relative} element={<ItemBuyPage />} />
      <Route path={ROUTES.community.item.receipt.relative} element={<ItemReceiptPage />} />
    </Routes>
  );
};
