import { Box, Icon, InputLeftElement, Link, Stack } from '@chakra-ui/react';
import { useField } from 'formik';
import { ArrowUpRight } from 'phosphor-react';
import { CommunityContext } from 'providers/Community';
import { useContext } from 'react';

import { ReactComponent as StripeWordmark } from 'assets/icons/stripe-wordmark.svg';
import { useStripe } from 'hooks/useStripe';

import { Input, InputProps } from '../Input';
import { IntegrationCard } from '../IntegrationCard';
import { StripeSetUpBtn } from '../StripeSetUpButton';

interface StripePriceProps extends Omit<InputProps, 'name'> {
  name?: string;
}

export const StripePrice = ({ name, validate, ...rest }: StripePriceProps) => {
  const { community } = useContext(CommunityContext);
  const { isConnected, loading, communityId } = useStripe(community?.slug);
  const symbol = '£';
  const [field] = useField<boolean>({ name: name || 'price', validate });

  return (
    <Stack>
      <Input
        {...field}
        name={name || 'price'}
        type="number"
        leftChildren={<InputLeftElement pointerEvents="none" children={symbol} />}
        isDisabled={!isConnected || loading}
        isRequired
        validate={validate}
        {...rest}
      />
      {!isConnected && !loading && (
        <>
          <IntegrationCard
            logo={StripeWordmark}
            iconProps={{ boxSize: '20', height: '40px' }}
            isLoaded={!!community?.slug && !loading}
            bg="shadow"
            rounded="md"
          >
            <Stack spacing="4">
              <Box>
                Temple partners with Stripe to take payments. Before setting a price you must create a Stripe account.{' '}
                <Link href="https://stripe.com/gb/connect/features" isExternal>
                  Learn more about payments <Icon as={ArrowUpRight} mx="2px" />
                </Link>
              </Box>
              <StripeSetUpBtn communityId={communityId}>Connect Stripe</StripeSetUpBtn>
            </Stack>
          </IntegrationCard>
        </>
      )}
    </Stack>
  );
};
