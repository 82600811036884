'use client';

import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Container, Heading, Stack } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { AuthContext } from 'providers/Auth';
import { CommunityContext } from 'providers/Community';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { PagePanel } from 'components/molecules/PagePanel';
import { CommunityHeader } from 'components/organisms/CommunityHeader';
import { useTrackPageView } from 'hooks/useTrackPageView';
import { showError } from 'utils/errors';

const MUTATION = gql`
  mutation CommunityAuthPageMutation($didToken: String!, $context: String!) {
    communitySignUp(didToken: $didToken, context: $context)
  }
`;

const CommunityAuthPage = () => {
  const { community } = useContext(CommunityContext);
  const [params] = useSearchParams();
  const { magic } = useContext(AuthContext);
  const navigate = useNavigate();
  const [communitySignUp] = useMutation(MUTATION);
  const client = useApolloClient();

  useTrackPageView(
    'Community Auth Callback',
    {
      community_id: community?.id,
      community_slug: community?.slug,
      isWhiteLabel: community?.isWhiteLabel,
      isLive: community?.isLive,
      type: 'community',
    },
    !community,
  );

  const handleFailure = () => {
    showError('Login failed, please try that again.');
    navigate(params.get('then') || ROUTES.community.join.set({ communitySlug: community?.slug }));
  };

  useEffect(() => {
    if (!community?.slug) {
      return;
    }

    (async () => {
      await magic.user.logout();

      const credential = params.get('magic_credential');
      const context = params.get('ctx');

      if (!credential || !context) {
        handleFailure();
        return;
      }

      let didToken = await magic.auth.loginWithCredential(credential);

      if (!didToken) {
        handleFailure();
        return;
      }

      try {
        const result = await communitySignUp({
          variables: { didToken, context },
        });

        if (result.data?.communitySignUp) {
          Cookies.set('auth', result.data?.communitySignUp);
          await client.resetStore();
          navigate(params.get('then') || ROUTES.community.join.set({ communitySlug: community?.slug }));
        } else {
          handleFailure();
        }
      } catch (error) {
        handleFailure();
      }
    })();
  }, [community?.slug]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PagePanel isNarrow>
      <Helmet>{community && <title>{community.displayName}</title>}</Helmet>
      <CommunityHeader includeName={false} community={community} align="center" />
      <Container as={Stack} spacing={6} variant="form" textAlign="center">
        <Heading variant="h4">Logging into {community?.displayName}...</Heading>
        <WrappedSpinner />
      </Container>
    </PagePanel>
  );
};

export default CommunityAuthPage;
