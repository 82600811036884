import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import * as common from './locales/en/common.json';
import * as creator from './locales/en/creator.json';
import * as fan from './locales/en/fan.json';

const resources = {
  common,
  creator,
  fan,
};

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n
  .use(resourcesToBackend((language: string, namespace: string) => resources[namespace as keyof typeof resources]))
  .use(initReactI18next)
  .init({
    lng: 'en',
    ns: ['common', 'creator'],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false, // react already handles this
    },
    returnNull: false,
    react: {
      useSuspense: false,
    },
    initImmediate: false,
  });

export default i18n;
