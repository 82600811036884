import { gql, useQuery } from '@apollo/client';
import { Box, Text, Flex, Heading, Icon } from '@chakra-ui/react';
import { MatcherInput, MatcherName } from '__graphql__/globalTypes';
import { UsersThree, MusicNoteSimple, GlobeHemisphereWest, MapPinLine, CurrencyCircleDollar } from 'phosphor-react';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { CheckboxProps } from 'components/forms/Fields/checkbox';
import { SelectProps } from 'components/molecules/Select';

import { GetUsersFromCriteria, GetUsersFromCriteriaVariables } from './__graphql__/GetUsersFromCriteria';

interface CriteriaTagProps extends Pick<CheckboxProps, 'label' | 'icon'> {
  name: MatcherName;
  params?: SelectProps;
}

const CRITERIA: CriteriaTagProps[] = [
  {
    name: MatcherName.MEMBERS_ONLY,
    label: 'All Members',
    icon: UsersThree,
  },
  {
    name: MatcherName.SPOTIFY_PRE_SAVE_ALL_ENABLED,
    label: 'Pre-Save Enabled',
    icon: CurrencyCircleDollar,
  },
  {
    name: MatcherName.SPOTIFY_TOP_ARTIST,
    label: 'Top streamers',
    icon: MusicNoteSimple,
  },
  {
    name: MatcherName.COUNTRIES,
    label: 'Country',
    icon: GlobeHemisphereWest,
  },
  {
    name: MatcherName.CITIES,
    label: 'City',
    icon: MapPinLine,
  },
];

const GET_USER_FROM_CRITERIA = gql`
  query GetUsersFromCriteria($communityId: ID!, $matcherNames: [MatcherName!]) {
    getUsersFromCriteria(communityId: $communityId, matcherNames: $matcherNames) {
      id
    }
  }
`;

const CriteriaTag = ({ label, icon }: CriteriaTagProps) => {
  return (
    <Box
      py={2}
      px={3}
      border="1px"
      borderRadius="lg"
      borderColor="chakra-subtle-bg"
      bgColor="gray.100"
      display="inline-block"
    >
      <Flex alignItems="center" flexWrap="nowrap">
        {icon && <Icon as={icon} boxSize="6" mr="2" />}
        <Text>{label}</Text>
      </Flex>
    </Box>
  );
};

interface CriteriaPreviewProps {
  communityId?: string;
  matchers: MatcherInput[];
  container?: Element | DocumentFragment | null;
}

export const CriteriaPreview = ({ communityId, matchers, container }: CriteriaPreviewProps) => {
  const { data, refetch } = useQuery<GetUsersFromCriteria, GetUsersFromCriteriaVariables>(GET_USER_FROM_CRITERIA, {
    variables: { communityId: communityId ?? '', matcherNames: matchers.map(({ matcherName }) => matcherName) },
    skip: !communityId,
  });

  useEffect(() => {
    refetch();
  }, [matchers.length, refetch]);

  return (
    <>
      {container &&
        createPortal(
          <Flex flex="1 0" direction="column" alignItems="center" justifyContent="center">
            <Heading variant="h4" mt={4}>
              Reach
            </Heading>
            <Text my={2} mr={2}>
              Reaching {data?.getUsersFromCriteria?.length || 0} fans
            </Text>
            <Flex mt={4} flex="1 0" justifyContent="center" wrap="wrap">
              {matchers.map((c) => {
                const criterion = CRITERIA.find((cr) => c.matcherName.includes(cr.name));
                if (!criterion) return null;
                return (
                  <Box m={1} key={criterion.name}>
                    <CriteriaTag {...criterion} />
                  </Box>
                );
              })}
            </Flex>
          </Flex>,
          container,
        )}
    </>
  );
};
