import {
  AspectRatio,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Stack,
  useBreakpointValue,
  LayoutProps,
  AspectRatioProps,
  SystemProps,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';

import { ImageOrPlaceholder, ImageOrPlaceholderProps } from 'components/atoms/ImageOrPlaceholder';

export interface ImageInputProps {
  label: string;
  image?: string | null;
  onChange: (file: File | null) => void;
  helpText?: string;
  specText?: string;
  placeholderProps?: ImageOrPlaceholderProps;
  errorMessage?: string | null | undefined;
  variant?: 'wide' | null;
  outsideBorder?: boolean;
  insideBorder?: boolean;
  width?: LayoutProps['width'];
  height?: LayoutProps['height'];
  ratio?: AspectRatioProps['ratio'];
  fit?: SystemProps['objectFit'];
  isRequired?: boolean;
}

export const ImageInput = ({
  label,
  image = null,
  onChange,
  helpText,
  specText,
  placeholderProps,
  errorMessage,
  variant,
  outsideBorder,
  insideBorder,
  height,
  isRequired,
  fit = 'cover',
  ratio = 1,
  width = '140px',
}: ImageInputProps) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(image);
  const ref = useRef<HTMLInputElement>(null);
  const removeButtonLabel = useBreakpointValue({
    base: 'Remove',
    md: 'Remove image',
  });

  const setFile = (file: File | null) => {
    setImagePreviewUrl(file ? URL.createObjectURL(file) : null);
    onChange(file);
  };

  return (
    <FormControl isInvalid={!!errorMessage} isRequired={isRequired}>
      <Box
        borderWidth={outsideBorder ? '1px' : ''}
        p={outsideBorder ? '4' : ''}
        borderColor="chakra-border-color"
        borderRadius="md"
      >
        <FormLabel fontSize="sm" fontWeight="bold">
          {label}
        </FormLabel>
        <Box
          borderWidth={insideBorder ? '1px' : ''}
          p={insideBorder ? '4' : ''}
          borderColor="chakra-border-color"
          borderRadius="md"
        >
          <Stack spacing="4" direction={variant === 'wide' ? 'column' : 'row'}>
            <Box position="relative" flexShrink="0" width={width} height={height}>
              <AspectRatio ratio={ratio}>
                <ImageOrPlaceholder src={imagePreviewUrl} borderRadius="md" fit={fit} {...placeholderProps} />
              </AspectRatio>
              <input
                type="file"
                ref={ref}
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  opacity: 0,
                }}
                onChange={(event) => {
                  const files = event?.currentTarget?.files;
                  const file = files && files[0];

                  // Don't clear the file if a user cancels file selection (we have a
                  // separate button for that)
                  if (file) {
                    setFile(file);
                  }
                }}
              />
            </Box>
            <Stack spacing="4">
              {!!helpText && <Box fontSize="sm">{helpText}</Box>}
              {!!specText && <Box fontSize="sm">{specText}</Box>}
              <HStack spacing="4">
                <Button
                  onClick={() => {
                    ref.current?.click();
                  }}
                >
                  Upload image
                </Button>
                {!!imagePreviewUrl && (
                  <Button
                    variant="link"
                    onClick={() => {
                      if (ref.current) {
                        ref.current.value = '';
                      }
                      setFile(null);
                    }}
                    size="sm"
                    color="link-color"
                    fontWeight="regular"
                  >
                    {removeButtonLabel}
                  </Button>
                )}
              </HStack>
            </Stack>
          </Stack>
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </Box>
      </Box>
    </FormControl>
  );
};
