import { gql } from '@apollo/client';
import { Heading, Skeleton, SkeletonText, Stack, StackProps } from '@chakra-ui/react';

import { UserMarkdown } from 'components/atoms/UserMarkdown';

import SocialLinksList from '../SocialLinksList';
import { CommunityNameAndBio_community } from './__graphql__/CommunityNameAndBio_community';

interface CommunityNameAndBioProps extends StackProps {
  community?: CommunityNameAndBio_community | null;
  welcomeExplainer?: boolean;
  hideLinkInBio?: boolean;
}

export const CommunityNameAndBio = ({
  community,
  textAlign,
  welcomeExplainer = false,
  hideLinkInBio = false,
  ...rest
}: CommunityNameAndBioProps) => {
  return (
    <Stack flexGrow={1} textAlign={textAlign} spacing={3} {...rest}>
      <Skeleton isLoaded={!!community}>
        <Heading variant="h2" mb="0">
          {community?.displayName}
        </Heading>
      </Skeleton>
      {!hideLinkInBio && community?.socialLinks && (
        <SocialLinksList
          socialLinks={community?.socialLinks}
          justifyContent={textAlign === 'center' ? 'center' : ''}
          gap={4}
        />
      )}
      <SkeletonText noOfLines={4} isLoaded={!!community} maxW={textAlign === 'center' ? undefined : '36rem'}>
        <UserMarkdown>{welcomeExplainer ? community?.welcomeExplainer : community?.description}</UserMarkdown>
      </SkeletonText>
    </Stack>
  );
};

CommunityNameAndBio.fragments = {
  community: gql`
    fragment CommunityNameAndBio_community on Community {
      id
      displayName
      description
      welcomeExplainer
      ...SocialLinksList_community
    }
    ${SocialLinksList.fragments.socialLinks}
  `,
};
