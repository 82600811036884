import { Text, TextProps } from '@chakra-ui/react';
import { ROUTES } from 'routes';

import { Link, LinkProps } from 'components/atoms/Link';

interface UsernameProps extends Omit<LinkProps, 'children' | 'to'> {
  preventLink?: boolean;
  children: string;
  textProps?: TextProps;
}

export const Username = ({ preventLink = false, children, textProps, ...rest }: UsernameProps) => {
  if (preventLink) {
    return (
      <>
        <Text color="gray" {...textProps}>
          @
        </Text>
        {children}
      </>
    );
  }
  return (
    <Link to={ROUTES.user.base.set({ username: children })} {...rest}>
      <Text color="gray" {...textProps}>
        @
      </Text>
      {children}
    </Link>
  );
};
