import { gql, useQuery } from '@apollo/client';
import { Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import { DashboardCard } from 'components/molecules/DashboardCard';
import { ExplainerWithCTA } from 'components/molecules/ExplainerWithCTA';
import { EmptyState } from 'components/organisms/DashboardCollectibleList/empty';
import { DashboardCollectibleListItem } from 'components/organisms/DashboardCollectibleList/item';

import {
  DashboardCollectibleListQuery,
  DashboardCollectibleListQueryVariables,
} from './__graphql__/DashboardCollectibleListQuery';

export const DASHBOARD_COLLECTIBLE_LIST_QUERY = gql`
  query DashboardCollectibleListQuery($slug: String!) {
    community(slug: $slug) {
      offerings(includeHidden: true) {
        ...DashboardCollectibleListItem_offering
      }
    }
  }
  ${DashboardCollectibleListItem.fragments.offerings}
`;

export const DashboardCollectibleList = () => {
  const { communitySlug } = useParams();
  const { data, loading } = useQuery<DashboardCollectibleListQuery, DashboardCollectibleListQueryVariables>(
    DASHBOARD_COLLECTIBLE_LIST_QUERY,
    { variables: { slug: communitySlug ?? '' }, skip: !communitySlug },
  );

  if (loading || !communitySlug) {
    return <DashboardCard title="Loading" isLoaded={false} />;
  }

  if (!data?.community?.offerings.length) {
    return <EmptyState communitySlug={communitySlug} />;
  }

  return (
    <Stack spacing="4">
      <ExplainerWithCTA cta="Create a Drop" to={ROUTES.dashboard.community.itemsNew.set({ communitySlug })}>
        Sell content, tickets and merchandise. Offer discounts or exclusive access if fans perform certain actions such
        as joining your mailing list.
      </ExplainerWithCTA>

      {data?.community?.offerings.map((collection) => (
        <DashboardCollectibleListItem collection={collection} key={collection.id} />
      ))}
    </Stack>
  );
};
