import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Text, Box, Button, Stack, Icon, Link as ChakraLink, Flex } from '@chakra-ui/react';
import { Formik, FormikErrors } from 'formik';
import Cookies from 'js-cookie';
import { AuthContext } from 'providers/Auth';
import { CommunityContext } from 'providers/Community';
import { growthbook } from 'providers/Growthbook';
import { useState, useContext, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import { ReactComponent as MetaMaskFox } from 'assets/icons/metamask-fox.svg';
import { Link, RouterLink } from 'components/atoms/Link';
import { LinkSent } from 'components/atoms/LinkSent';
import { Separator } from 'components/atoms/Separator';
import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { GoogleLoginButton } from 'components/molecules/GoogleLoginButton';
import { Input } from 'components/molecules/Input';
import { MetaMaskLoginButton, WalletAuthSigningInfo } from 'components/molecules/MetaMaskLoginButton';
import { useMagicAuthURLParams } from 'hooks/useMagicAuthURLParams';
import { useMixpanel } from 'hooks/useMixpanel';
import { truncateAddress } from 'utils/address';
import { extractErrors } from 'utils/errors';

import { MetaMaskSignUpMutation, MetaMaskSignUpMutationVariables } from './__graphql__/MetaMaskSignUpMutation';
import { SignUpFormEmailCheckQuery, SignUpFormEmailCheckQueryVariables } from './__graphql__/SignUpFormEmailCheckQuery';

const EMAIL_CHECK_QUERY = gql`
  query SignUpFormEmailCheckQuery($email: String!) {
    emailAuthCheck(email: $email)
  }
`;

const MUTATION = gql`
  mutation MetaMaskSignUpMutation(
    $message: String!
    $address: String!
    $signedMessage: String!
    $email: String!
    $username: String
    $displayName: String
    $distinctId: String
  ) {
    signUpWithSignedMessage(
      message: $message
      address: $address
      signedMessage: $signedMessage
      email: $email
      username: $username
      displayName: $displayName
      distinctId: $distinctId
    )
  }
`;

interface SignUpFormProps {
  then?: string;
  onSent?: () => void;
  emailSignUpRoute?: string;
  inLine?: boolean;
  hideTCandLogin?: boolean;
}

// TODO: refactor this bad boy
export const SignUpForm = ({ then, onSent, emailSignUpRoute, inLine, hideTCandLogin }: SignUpFormProps) => {
  const { userLoading, magicLinkSignUp, sendAuthLink } = useContext(AuthContext);
  const { community } = useContext(CommunityContext);
  const [linkSent, setLinkSent] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [signingInfo, setSigningInfo] = useState<WalletAuthSigningInfo | null>();
  const magicCallbackParams = useMagicAuthURLParams();
  const location = useLocation();
  const [params] = useSearchParams();
  then = encodeURIComponent(then || params.get('then') || '') || undefined;
  const state = location.state as { signingInfo?: WalletAuthSigningInfo } | null;
  const [signUpWithSignedMessage] = useMutation<MetaMaskSignUpMutation, MetaMaskSignUpMutationVariables>(MUTATION);
  const client = useApolloClient();
  const { mixpanel } = useMixpanel();
  const [metamaskIntent, setMetamaskIntent] = useState(params.get('metamask') === 'true');

  useEffect(() => {
    if (state?.signingInfo) {
      setSigningInfo(state?.signingInfo);
    }
    if (magicCallbackParams || state?.signingInfo) {
      setShowFields(true);
    }
  }, [state?.signingInfo, magicCallbackParams]);

  if (linkSent) return <LinkSent />;

  const onWalletLoginCheck = (signingInfo: WalletAuthSigningInfo, loggedIn: boolean) => {
    if (signingInfo.address && !loggedIn) {
      setSigningInfo(signingInfo);
      setShowFields(true);
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        username: '',
        displayName: '',
      }}
      validate={(values) => {
        const errors: FormikErrors<typeof values> = {};
        if (!values.email.length) {
          errors.email = 'Please provide your email address';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
        if (community?.isWhiteLabel) {
          if (values.displayName.length < 3 || values.displayName.length > 30) {
            errors.displayName = 'Name must be between 3 and 30 characters';
          } else if (!values.displayName.length) {
            errors.displayName = 'Your name is required';
          } else if (!/^[\w\d][\w\d._ -]+[\w\d]$/i.test(values.displayName)) {
            errors.displayName = 'Name should only contain letters, numbers, underscores, dashes and dots.';
          }
        } else {
          if (values.username.length < 3 || values.username.length > 30) {
            errors.username = 'Username must be between 3 and 30 characters';
          } else if (!values.username.length) {
            errors.username = 'A username is required';
          } else if (!/^[a-z0-9][a-z0-9._]+[a-z0-9]$/i.test(values.username)) {
            errors.username = 'Username should only contain letters, numbers, underscores and dots.';
          }
        }
        return errors;
      }}
      onSubmit={async (values, { setErrors }) => {
        if (signingInfo) {
          try {
            const result = await signUpWithSignedMessage({
              variables: {
                email: values.email,
                username: values.username || undefined,
                displayName: values.displayName || undefined,
                distinctId: mixpanel?.get_distinct_id(),
                ...signingInfo,
              },
            });
            if (result.data?.signUpWithSignedMessage) {
              Cookies.set('auth', result.data?.signUpWithSignedMessage);
              await client.resetStore();
            }
          } catch (error) {
            extractErrors(setErrors)(error);
          }
        } else if (magicCallbackParams) {
          await magicLinkSignUp({
            username: values.username,
            displayName: values.displayName,
            credential: magicCallbackParams.magicCredential,
            setErrors,
          });
        } else {
          const { data } = await client.query<SignUpFormEmailCheckQuery, SignUpFormEmailCheckQueryVariables>({
            query: EMAIL_CHECK_QUERY,
            variables: { email: values.email },
            fetchPolicy: 'network-only',
          });
          if (data.emailAuthCheck) {
            setErrors({
              email: 'An account is already registered with this email address.',
            });
            return;
          }
          if (
            await sendAuthLink({
              params: { email: values.email, username: values.username, displayName: values.displayName },
            })
          ) {
            setLinkSent(true);
            if (onSent) {
              onSent();
            }
          }
        }
      }}
    >
      {({ handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, values, submitForm }) => {
        if (magicCallbackParams?.email && !values.email) {
          setFieldValue('email', magicCallbackParams?.email);
          setFieldValue('username', magicCallbackParams?.username);
          setFieldValue('displayName', magicCallbackParams?.displayName);
          setTimeout(() => setFieldTouched('email', true), 0);
          setTimeout(() => setFieldTouched('username', true), 0);
          setTimeout(() => setFieldTouched('displayName', true), 0);
          setTimeout(() => submitForm(), 0);
        }

        if (isSubmitting && magicCallbackParams?.email) {
          return <WrappedSpinner />;
        }

        return (
          <form onSubmit={handleSubmit} noValidate>
            <Stack spacing="4" shouldWrapChildren>
              <Stack spacing="4" shouldWrapChildren direction={inLine ? 'row' : 'column'}>
                {signingInfo && (
                  <Box textAlign="center">
                    <Icon boxSize="6" mr="2">
                      <MetaMaskFox />
                    </Icon>
                    You connected MetaMask wallet{' '}
                    <Text fontWeight="bold" title={signingInfo.address}>
                      {truncateAddress(signingInfo.address)}
                    </Text>
                    , set your email address and username to complete Sign Up.
                  </Box>
                )}
                <Stack spacing="4">
                  <Stack spacing="4" direction={inLine ? 'row' : 'column'}>
                    {showFields && (
                      <Input
                        name="email"
                        label="Email"
                        type="email"
                        variant="floating"
                        autoComplete="email"
                        isDisabled={userLoading || isSubmitting || !!magicCallbackParams}
                      />
                    )}
                    {showFields && !community?.isWhiteLabel && (
                      <Input
                        name="username"
                        label="Username"
                        type="text"
                        variant="floating"
                        isDisabled={userLoading || isSubmitting}
                      />
                    )}
                    {showFields && community?.isWhiteLabel && (
                      <Input
                        name="displayName"
                        label="Name"
                        type="text"
                        variant="floating"
                        autoComplete="name"
                        isDisabled={userLoading || isSubmitting}
                      />
                    )}
                  </Stack>
                  {showFields && (
                    <Stack>
                      <Button
                        isLoading={isSubmitting}
                        isDisabled={userLoading || !isValid}
                        colorScheme="primary"
                        color="offBlack"
                        type="submit"
                        w="full"
                      >
                        Continue
                      </Button>
                      <Button
                        isDisabled={isSubmitting}
                        onClick={() => {
                          setShowFields(false);
                        }}
                      >
                        Back
                      </Button>
                    </Stack>
                  )}
                </Stack>
                {!showFields &&
                  !metamaskIntent &&
                  (emailSignUpRoute ? (
                    <Button as={RouterLink} variant="primary" to={emailSignUpRoute} w="full">
                      Sign up with email
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={() => setShowFields(true)} w="full">
                      Sign up with email
                    </Button>
                  ))}
                {!showFields && !metamaskIntent && !inLine && <Separator py="0">or</Separator>}
                {!showFields && !metamaskIntent && (
                  <GoogleLoginButton
                    w="full"
                    then={community ? encodeURIComponent(window.location.pathname + window.location.search) : then}
                  />
                )}
                {!showFields && growthbook.isOn('metamask_login_button') && (
                  <Stack>
                    <MetaMaskLoginButton onLoginResult={onWalletLoginCheck} w="full" />
                    {metamaskIntent && (
                      <Button
                        isDisabled={isSubmitting}
                        onClick={() => {
                          setMetamaskIntent(false);
                        }}
                      >
                        Back
                      </Button>
                    )}
                  </Stack>
                )}
              </Stack>
              {hideTCandLogin && (
                <Flex direction="column">
                  <Box fontSize="xs" textAlign="center">
                    By joining you agree to the{' '}
                    <ChakraLink href={ROUTES.legal.terms.path} target="_blank">
                      Terms of Use
                    </ChakraLink>{' '}
                    and{' '}
                    <ChakraLink href={ROUTES.legal.privacy.path} target="_blank">
                      Privacy Policy
                    </ChakraLink>
                    .
                  </Box>
                  <Box textAlign="center">
                    <Box bg="shadow" p="3" rounded="lg" mt="4">
                      Already have an account?{' '}
                      <Link
                        to={ROUTES.auth.login.set({
                          isWhiteLabel: community?.isWhiteLabel,
                          communitySlug: community?.slug,
                          then,
                        })}
                      >
                        Login
                      </Link>
                    </Box>
                  </Box>
                </Flex>
              )}
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};
