import { gql } from '@apollo/client';
import { Spacer, Stack, StackProps } from '@chakra-ui/react';
import { ROUTES } from 'routes';

import { LinkBox } from 'components/atoms/Link';
import { CommunityLogo } from 'components/molecules/CommunityLogo';
import { CommunityNameAndBio } from 'components/molecules/CommunityNameAndBio';

import { CommunityHeaderControls } from '../CommunityHeaderControls';
import { CommunityHeader_community } from './__graphql__/CommunityHeader_community';

interface CommunityHeaderProps extends Omit<StackProps, 'align'> {
  isLoaded?: boolean;
  community?: CommunityHeader_community | null;
  align?: 'left' | 'center';
  includeControls?: boolean;
  includeBio?: boolean;
  includeName?: boolean;
}

export const CommunityHeader = ({
  align = 'left',
  community,
  includeControls = false,
  includeBio = false,
  includeName = true,
  ...rest
}: CommunityHeaderProps) => {
  return (
    <Stack
      spacing={{ base: 4, md: 8 }}
      alignSelf={align === 'center' ? 'center' : 'flex-start'}
      alignItems={align === 'center' ? 'center' : 'flex-start'}
      mt={{ base: '-56px', md: '-86px', lg: '-118px', xl: '-150px' }}
      w="full"
      {...rest}
    >
      <LinkBox to={ROUTES.community.base.set({ communitySlug: community?.slug })}>
        <CommunityLogo community={community} />
      </LinkBox>
      <Stack
        w="full"
        direction={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'flex-start', md: 'center' }}
        spacing={{ base: 2, md: 4 }}
      >
        {includeName && <CommunityNameAndBio community={community} textAlign={align} />}
        {includeName && includeControls && <Spacer />}
        {includeControls && !!community && <CommunityHeaderControls community={community} showCounts={false} />}
      </Stack>
    </Stack>
  );
};

CommunityHeader.fragments = {
  community: gql`
    fragment CommunityHeader_community on Community {
      id
      displayName
      description
      logo(variant: md)
      background(variant: cover)
      slug
      ...CommunityHeaderControls_community
      ...CommunityLogo_community
      ...CommunityNameAndBio_community
    }
    ${CommunityHeaderControls.fragments.community}
    ${CommunityLogo.fragments.community}
    ${CommunityNameAndBio.fragments.community}
  `,
};
