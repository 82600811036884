import { As, Icon, IconProps, Image, ImageProps, Skeleton, SystemProps } from '@chakra-ui/react';
import { useState } from 'react';

import { Logo } from 'components/atoms/Logo';

export interface ImageOrPlaceholderProps extends Omit<ImageProps, 'src'> {
  src?: string | null;
  icon?: As;
  iconProps?: IconProps;
  fit?: SystemProps['objectFit'];
  isLoaded?: boolean;
}

export const ImageOrPlaceholder = ({
  src,
  iconProps,
  fit = 'contain',
  icon = Logo,
  isLoaded = true,
  ...rest
}: ImageOrPlaceholderProps) => {
  const [isError, setIsError] = useState(false);
  return (
    <Skeleton
      bg="chakra-subtle-bg"
      {...rest}
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="hidden"
      isLoaded={isLoaded}
    >
      {src && !isError ? (
        <Image
          onError={() => {
            setIsError(true);
          }}
          src={src}
          fit={fit}
          w="full"
          h="full"
        />
      ) : (
        <Icon as={icon} boxSize="16" color="chakra-placeholder-color" {...iconProps} />
      )}
    </Skeleton>
  );
};
