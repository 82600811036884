import { Box, HStack, Icon, IconProps, Skeleton, StackProps } from '@chakra-ui/react';

interface IntegrationCardProps extends StackProps {
  logo: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  iconProps?: IconProps;
  isLoaded?: boolean;
}

export const IntegrationCard = ({ children, logo, iconProps, isLoaded = true, ...rest }: IntegrationCardProps) => (
  <HStack rounded="lg" borderWidth="1px" borderColor="chakra-border-color" px="4" py="2" {...rest}>
    <Box p="4" display="flex">
      <Icon as={logo} {...iconProps} />
    </Box>
    <Box
      w="1px"
      marginInlineEnd="2 !important"
      marginInlineStart="4 !important"
      bg="chakra-border-color"
      alignSelf="stretch"
    />
    <Skeleton isLoaded={isLoaded} p="2" w="full">
      {children}
    </Skeleton>
  </HStack>
);
