'use client';

import { Heading, Stack } from '@chakra-ui/react';

import { EditDropForm } from 'components/forms/OfferingForm/dropEdit';

const EditDropPage = () => {
  return (
    <Stack maxW="36rem" spacing={8}>
      <Heading variant="h2">Edit drop</Heading>
      <EditDropForm />
    </Stack>
  );
};

export default EditDropPage;
