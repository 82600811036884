import { gql, useQuery } from '@apollo/client';

import { CommunityStripeQuery, CommunityStripeQueryVariables } from './__graphql__/CommunityStripeQuery';

export const QUERY = gql`
  query CommunityStripeQuery($slug: String!) {
    community(slug: $slug) {
      id
      createdAt
      stripeAccount {
        chargesEnabled
        dashboardUrl
      }
    }
  }
`;

export const useStripe = (slug?: string) => {
  const { data, loading } = useQuery<CommunityStripeQuery, CommunityStripeQueryVariables>(QUERY, {
    skip: !slug,
    variables: {
      slug: slug ?? '',
    },
  });

  return {
    communityId: data?.community?.id,
    isConnected: data?.community?.stripeAccount?.chargesEnabled,
    dashboardUrl: data?.community?.stripeAccount?.dashboardUrl,
    loading,
  };
};
