import { Box, Container, BoxProps, useBreakpointValue } from '@chakra-ui/react';

interface HeroImageSectionProps extends BoxProps {
  children?: React.ReactNode;
  image: string;
  backgroundColor?: string;
}

export const HeroImageSection = ({ children, image, backgroundColor = 'offBlack', ...rest }: HeroImageSectionProps) => {
  const isStacked = useBreakpointValue({ base: true, lg: false });

  return (
    <Box position="relative" {...rest}>
      <Box
        backgroundImage={image}
        backgroundSize="cover"
        backgroundPosition="center"
        minH={{ lg: '100vh' }}
        style={{ aspectRatio: isStacked ? '16/9' : undefined }}
        {...rest}
      />
      <Box {...(isStacked ? {} : { top: 0, right: 0, left: 0, bottom: 0, position: 'absolute', display: 'flex' })}>
        <Box
          mt="auto"
          mb={{ lg: '40px' }}
          maxW={{ lg: 'calc(calc(calc(100vw - 80em - 4rem) / 2) + 700px)' }}
          py="16"
          pl={{ lg: 'calc(calc(calc(100vw - 80em - 4rem) / 2) + 32px)' }}
          pr={{ xl: '16' }}
          background={backgroundColor}
          color="offWhite"
        >
          <Container>{children}</Container>
        </Box>
      </Box>
    </Box>
  );
};
