'use client';

import { gql, useMutation } from '@apollo/client';
import { Container, Heading, Stack } from '@chakra-ui/react';
import { toast } from 'App';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { PagePanel } from 'components/molecules/PagePanel';
import { BaseLayout } from 'components/templates/Base/base';

import {
  SpotifyCallbackPageMutation,
  SpotifyCallbackPageMutationVariables,
} from './__graphql__/SpotifyCallbackPageMutation';

const MUTATION = gql`
  mutation SpotifyCallbackPageMutation($url: String!) {
    loginWithSpotify(url: $url) {
      id
    }
  }
`;

const SpotifyCallbackPage = () => {
  const navigate = useNavigate();
  const [loginWithSpotify] = useMutation<SpotifyCallbackPageMutation, SpotifyCallbackPageMutationVariables>(MUTATION);

  const runCallback = async () => {
    try {
      const result = await loginWithSpotify({ variables: { url: window.location.href } });
      if (result.data?.loginWithSpotify?.id) {
        toast({
          status: 'success',
          description: 'Successfully connected Spotify',
        });
        navigate(localStorage?.getItem('postSpotifyConnectUrl') ?? '/dashboard/settings/spotify');
      }
    } catch (error) {
      if (error instanceof Error) {
        // This callback is run twice due to the Auth provider, so this callback will
        // always be called twice and error the second time around (once the token
        // has been consumed). Hence, chosing not to show the error.
      }
    }
  };

  useEffect(() => {
    runCallback();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseLayout>
      <Helmet>
        <title>Connecting Spotify</title>
      </Helmet>
      <PagePanel isNarrow>
        <Stack as={Container} variant="form" spacing="6">
          <Heading textAlign="center" mb="6">
            Connecting Spotify...
          </Heading>
          <WrappedSpinner />
        </Stack>
      </PagePanel>
    </BaseLayout>
  );
};

export default SpotifyCallbackPage;
