import { Box, Link, ListItem, OrderedList, Stack, UnorderedList } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';

interface UserMarkdownProps extends Omit<ReactMarkdownOptions, 'children'> {
  children: string | null | undefined;
}

export const UserMarkdown = ({ children, ...options }: UserMarkdownProps) =>
  children ? (
    <Stack spacing="0.6rem">
      <ReactMarkdown
        unwrapDisallowed
        skipHtml
        allowedElements={['a', 'p', 'strong', 'em', 'ul', 'ol', 'li']}
        components={{
          a: ({ node, ...props }) => <Link {...props} target="_blank" />,
          p: ({ node, ...props }) => <Box as="p" {...props} />,
          ul: ({ node, ...props }) => <UnorderedList {...props} />,
          ol: ({ node, ...props }) => <OrderedList {...props} />,
          li: ({ node, ...props }) => <ListItem listStylePosition="inside" {...props} />,
        }}
        {...options}
      >
        {children}
      </ReactMarkdown>
    </Stack>
  ) : null;
