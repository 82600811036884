'use client';

import { gql, useQuery } from '@apollo/client';
import { AspectRatio, Container, Heading, Stack } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes';

import { LinkBox } from 'components/atoms/Link';
import { CardGrid } from 'components/molecules/CardGrid';
import { ItemCard } from 'components/molecules/ItemCard';
import { OfferingCard } from 'components/molecules/OfferingCard';
import { useTrackPageView } from 'hooks/useTrackPageView';

import { MyCollectionPageQuery } from './__graphql__/MyCollectionPageQuery';

export const QUERY = gql`
  query MyCollectionPageQuery {
    me {
      id
      tokenCollection {
        id
        offering {
          id
          slug
          community {
            id
            slug
          }
          ...OfferingCard_offering
        }
      }
    }
  }
  ${OfferingCard.fragments.offering}
`;

const MyCollectionPage = () => {
  const { t } = useTranslation(['common', 'fan'], { keyPrefix: 'fan:pages.DashboardCollection' });
  const { data, loading } = useQuery<MyCollectionPageQuery>(QUERY);

  useTrackPageView(
    'My Collection',
    {
      collectionSize: data?.me?.tokenCollection?.length,
    },
    !data?.me,
  );

  return (
    <Container px="0 !important">
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <Stack spacing="4">
        <Heading as="h1">{t('heading')}</Heading>
        <CardGrid
          isLoaded={!loading && !!data?.me}
          loadingCard={({ ...rest }) => <OfferingCard isLoaded={false} {...rest} />}
          emptyCard={({ ...rest }) => (
            <AspectRatio ratio={0.8}>
              <ItemCard isLoaded bg="chakra-border-color" {...rest} />
            </AspectRatio>
          )}
          empty={t('emptyText')}
        >
          {data?.me?.tokenCollection?.map((collectible) => (
            <LinkBox
              key={collectible.id}
              to={ROUTES.community.item.base.set({
                communitySlug: collectible.offering.community.slug,
                managedContractSlug: collectible.offering.slug,
              })}
            >
              <OfferingCard offering={collectible.offering} />
            </LinkBox>
          ))}
        </CardGrid>
      </Stack>
    </Container>
  );
};

export default MyCollectionPage;
