'use client';

import { gql, useQuery } from '@apollo/client';
import { Box, Flex, Heading, Skeleton, Stack, useBreakpointValue } from '@chakra-ui/react';
import { CommunityContext } from 'providers/Community';
import { useContext } from 'react';
import { ROUTES } from 'routes';

import { Link } from 'components/atoms/Link';
import { OfferingChart } from 'components/organisms/Charts/OfferingChart';
import { RevenueBox } from 'components/organisms/Charts/RevenueBox';
import { CommunityTable } from 'components/organisms/Tables';

import { MetaAnalytics, MetaAnalyticsVariables } from './__graphql__/MetaAnalytics';

export const META_QUERY = gql`
  query MetaAnalytics($slug: String!) {
    metaAnalytics(slug: $slug) {
      growthRevenuePerWeek
      growthUserPerWeek
      totalUnits
      totalRevenue
      totalRevenuePerUser
      totalUsers
    }
  }
`;

const CommunityDashboard = () => {
  const { community } = useContext(CommunityContext);
  const { data, loading } = useQuery<MetaAnalytics, MetaAnalyticsVariables>(META_QUERY, {
    variables: {
      slug: community?.slug ?? '',
    },
    skip: !community?.id,
  });

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  return (
    <Stack spacing="12">
      <Stack>
        <Heading variant="h1">
          <Skeleton isLoaded={!loading}>{community?.displayName || 'Community name'}</Skeleton>
        </Heading>
        <Box>
          <Skeleton isLoaded={!loading}>
            <Link to={ROUTES.community.base.set({ communitySlug: community?.slug })}>
              {`${window.location.protocol}//${window.location.host}/${community?.slug}`}
            </Link>
          </Skeleton>
        </Box>
      </Stack>
      <Stack spacing={{ base: 8, lg: 16 }}>
        <Stack>
          <Heading variant="h4">Stats</Heading>
          <Flex maxW="full" direction={isDesktop ? 'row' : 'column'}>
            <Flex flex="3 0">
              <OfferingChart />
            </Flex>
            <Stack direction={isDesktop ? 'column' : 'row'} mt={{ base: 4, lg: 0 }} ml={{ base: 0, lg: 4 }}>
              <RevenueBox label="Total members" value={data?.metaAnalytics?.totalUsers || 0} loading={loading} />
              <RevenueBox label="Total collected" value={data?.metaAnalytics?.totalUnits || 0} loading={loading} />
              <RevenueBox label="Total sales" value={data?.metaAnalytics?.totalRevenue || 0} loading={loading} />
            </Stack>
          </Flex>
        </Stack>
        <Stack>
          <Heading variant="h4">Members</Heading>
          <Flex maxW="full" overflowX="scroll">
            <CommunityTable />
          </Flex>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CommunityDashboard;
