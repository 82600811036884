'use client';

import { gql, useMutation, useQuery } from '@apollo/client';
import { Heading, Stack, Container, Alert, AlertIcon, AlertDescription } from '@chakra-ui/react';
import { AuthContext } from 'providers/Auth';
import { CommunityContext } from 'providers/Community';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import { ErrorMessage } from 'components/atoms/ErrorMessage';
import { Link } from 'components/atoms/Link';
import { PaymentLineItem } from 'components/atoms/PaymentLineItem';
import { PaymentForm } from 'components/forms/PaymentForm/index';
import { SignUpForm } from 'components/forms/SignUpForm';
import { NotFoundPanel } from 'components/molecules/NotFoundPanel';
import { getOfferingTypeName } from 'components/molecules/OfferingCard';
import { OrderItemSummary } from 'components/molecules/OrderItemSummary';
import { PagePanel } from 'components/molecules/PagePanel';
import { CommunityHeader } from 'components/organisms/CommunityHeader';
import { useIdFromSlug } from 'hooks/useIdFromSlug';
import { useTrackPageView } from 'hooks/useTrackPageView';

import { ItemBuyPagePaymentIntentMutation } from './__graphql__/ItemBuyPagePaymentIntentMutation';
import { ItemBuyPageQuery } from './__graphql__/ItemBuyPageQuery';

export const PAYMENT_PAGE_QUERY = gql`
  query ItemBuyPageQuery($id: ID!) {
    offering(id: $id) {
      id
      name
      image(variant: md)
      price
      slug
      isOwned
      numOwned
      isSecret
      maxPurchaseQuantity
      community {
        id
        displayName
        slug
      }
      ...OrderItemSummary_offering
    }
    me {
      id
      stripeCustomer {
        id
        ...PaymentForm_stripeCustomer
      }
    }
  }
  ${OrderItemSummary.fragments.offering}
  ${PaymentForm.fragments.stripeCustomer}
`;

const PAYMENT_INTENT_MUTATION = gql`
  mutation ItemBuyPagePaymentIntentMutation($id: ID!, $code: String, $quantity: Int) {
    offeringPaymentIntent(offeringId: $id, code: $code, quantity: $quantity) {
      id
      baseAmount
      taxAmount
      totalAmount
      currency
      currencySymbol
      clientSecret
      quantity
      ...OrderItemSummary_paymentIntent
      ...PaymentForm_paymentIntent
    }
  }
  ${OrderItemSummary.fragments.paymentIntent}
  ${PaymentForm.fragments.paymentIntent}
`;

const ItemBuyPage = () => {
  const id = useIdFromSlug();
  const { key } = useParams();
  const [params] = useSearchParams();
  const { data, loading, error } = useQuery<ItemBuyPageQuery>(PAYMENT_PAGE_QUERY, {
    variables: { id, code: key },
    skip: !id,
  });
  const quantity = parseInt(params.get('quantity') || '1');
  const [createPaymentIntent, { data: mutationData, loading: mutationLoading, error: mutationError }] =
    useMutation<ItemBuyPagePaymentIntentMutation>(PAYMENT_INTENT_MUTATION, {
      variables: { id, code: key, quantity },
    });
  const { community } = useContext(CommunityContext);
  const { user, userLoading } = useContext(AuthContext);

  const navigate = useNavigate();

  const offering = data?.offering;

  useEffect(() => {
    createPaymentIntent();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useTrackPageView(
    'Item Buy',
    {
      id: offering?.id,
      isOwned: offering?.isOwned,
      community_id: offering?.community.id,
      community_slug: offering?.community.slug,
    },
    !offering,
  );

  if (error || mutationError) {
    if (mutationError?.graphQLErrors?.[0]?.extensions?.code === 'BAD_INVITE_CODE') {
      return (
        <PagePanel isNarrow>
          <CommunityHeader includeName={false} community={community} align="center" />
          <NotFoundPanel />
        </PagePanel>
      );
    }
    return (
      <PagePanel isNarrow>
        <ErrorMessage />
      </PagePanel>
    );
  }

  if (!loading && !mutationLoading && !data?.offering) {
    return (
      <PagePanel isNarrow>
        <CommunityHeader includeName={false} community={community} align="center" />
        <NotFoundPanel />
      </PagePanel>
    );
  }

  const receiptPath = ROUTES.community.item.receipt.set({
    managedContractSlug: data?.offering?.slug,
    communitySlug: data?.offering?.community.slug,
  });

  if (data?.offering && !data?.offering.price) {
    navigate(
      ROUTES.community.item.base.set({
        managedContractSlug: data.offering.slug,
        communitySlug: data.offering.community.slug,
      }),
    );
  }

  return (
    <>
      <Helmet>{data?.offering?.name && <title>Buy {data?.offering?.name} on Temple</title>}</Helmet>
      <PagePanel isNarrow>
        <CommunityHeader includeName={false} community={community} align="center" />
        <Stack as={Container} w="25rem" spacing="8">
          <OrderItemSummary offering={data?.offering} paymentIntent={mutationData?.offeringPaymentIntent} w="full" />
          {!userLoading &&
            (user ? (
              <>
                <Stack>
                  {!!data?.offering?.numOwned && (
                    <Alert status="info">
                      <AlertIcon />
                      <AlertDescription>You own {data.offering.numOwned} currently</AlertDescription>
                    </Alert>
                  )}
                  {mutationData?.offeringPaymentIntent &&
                    (mutationData?.offeringPaymentIntent?.quantity || 1) < quantity && (
                      <Alert status="error">
                        <AlertIcon />
                        <AlertDescription>
                          Maximum {data?.offering?.maxPurchaseQuantity || 1} per customer
                        </AlertDescription>
                      </Alert>
                    )}
                </Stack>
                {!mutationData?.offeringPaymentIntent ||
                  ((mutationData?.offeringPaymentIntent?.quantity || 0) > 0 && (
                    <PaymentForm
                      paymentIntent={mutationData?.offeringPaymentIntent}
                      stripeCustomer={data?.me?.stripeCustomer}
                      onComplete={() => navigate(receiptPath)}
                    >
                      {data?.offering?.type && (
                        <PaymentLineItem
                          label={`${getOfferingTypeName(data.offering)} x${
                            mutationData?.offeringPaymentIntent?.quantity
                          }`}
                        >
                          <Link
                            to={
                              key
                                ? ROUTES.community.item.key.set({
                                    managedContractSlug: data?.offering?.slug,
                                    communitySlug: data?.offering?.community.slug,
                                    key,
                                  })
                                : ROUTES.community.item.base.set({
                                    managedContractSlug: data?.offering?.slug,
                                    communitySlug: data?.offering?.community.slug,
                                  })
                            }
                          >
                            Update
                          </Link>
                        </PaymentLineItem>
                      )}
                    </PaymentForm>
                  ))}
              </>
            ) : (
              <Stack w="full" textAlign="center">
                <Heading variant="h3">
                  Create a free account
                  <br />
                  to checkout
                </Heading>
                <SignUpForm then={window.location.pathname} />
              </Stack>
            ))}
        </Stack>
      </PagePanel>
    </>
  );
};

export default ItemBuyPage;
