import { gql, useMutation } from '@apollo/client';
import { Button, Container, Heading, Stack } from '@chakra-ui/react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { params, ROUTES } from 'routes';

import { Input } from 'components/molecules/Input';
import { SwitchWithLabel } from 'components/molecules/SwitchWithLabel';
import { extractErrors } from 'utils/errors';

import { formatDate } from '../OfferingForm/utils';
import { ClaimLinkFormMutation, ClaimLinkFormMutationVariables } from './__graphql__/ClaimLinkFormMutation';

export const MUTATION = gql`
  mutation ClaimLinkFormMutation($name: String, $maxClaims: Int, $offeringId: ID!, $expiryDate: String) {
    createOfferingInviteCode(name: $name, maxClaims: $maxClaims, offeringId: $offeringId, expiryDate: $expiryDate) {
      key
    }
  }
`;

export const ClaimLinkForm = () => {
  const { communitySlug } = useParams();
  const { t } = useTranslation(['common', 'creator'], { keyPrefix: 'creator:forms.ClaimLinkForm' });
  const { id } = useParams<keyof params>();
  const [createTokenClaimKey] = useMutation<ClaimLinkFormMutation, ClaimLinkFormMutationVariables>(MUTATION);
  const navigate = useNavigate();

  return (
    <Stack>
      <Formik
        initialValues={{
          limitedClaims: false,
          name: '',
          maxClaims: 0,
          canExpire: false,
          namedLink: false,
          expiryDate: new Date().toISOString().replace('Z', ''),
        }}
        onSubmit={async (
          { limitedClaims, maxClaims, canExpire, expiryDate, namedLink, name },
          { setErrors, setSubmitting },
        ) => {
          if (!id) return;

          try {
            await createTokenClaimKey({
              variables: {
                maxClaims: limitedClaims ? maxClaims : null,
                expiryDate: canExpire ? expiryDate : null,
                offeringId: id,
                name: namedLink ? name : null,
              },
            });
            navigate(
              ROUTES.dashboard.community.itemsId.set({
                communitySlug,
                id,
              }),
            );
          } catch (errors) {
            extractErrors(setErrors)(errors);
          }

          setSubmitting(false);
        }}
      >
        {({ handleSubmit, isSubmitting, handleChange, values: { limitedClaims, canExpire } }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Stack spacing="4">
                <SwitchWithLabel
                  label="Named link"
                  name="namedLink"
                  description="Keep track of this link with an optional name"
                >
                  <Input name="name" type="text" />
                </SwitchWithLabel>
                <SwitchWithLabel
                  label={t('fields.maxClaims.label')}
                  name="limitedClaims"
                  description={t('fields.maxClaims.switchLabel')}
                >
                  <Input name="maxClaims" type="number" />
                </SwitchWithLabel>
                <SwitchWithLabel
                  label={t('fields.expiryDate.label')}
                  name="canExpire"
                  description={t('fields.expiryDate.switchLabel')}
                >
                  <Input
                    name="expiryDate"
                    label={t('fields.expiryDate.label')}
                    type="datetime-local"
                    minDate={formatDate()}
                  />
                </SwitchWithLabel>
                <Button isLoading={isSubmitting} variant="primary" type="submit">
                  {t('submit')}
                </Button>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </Stack>
  );
};
