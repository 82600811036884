import { gql } from '@apollo/client';
import { Box, FormControl, FormLabel, Stack, Switch, Input as ChakraInput, FormHelperText } from '@chakra-ui/react';
import { MatcherName, OfferingType } from '__graphql__/globalTypes';
import { useField, useFormikContext } from 'formik';
import { Image } from 'phosphor-react';
import { GrowthbookContext } from 'providers/Growthbook';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { ImageInput } from 'components/molecules/ImageInput';
import { Input } from 'components/molecules/Input';
import { MarkdownEditor } from 'components/molecules/MarkdownEditor';
import { MultiFileUpload, Upload, UploadedFile } from 'components/molecules/MultiFileUpload';
import { Select } from 'components/molecules/Select';
import { StripePrice } from 'components/molecules/StripePrice';
import { SwitchWithLabel } from 'components/molecules/SwitchWithLabel';

import { DropForm_offering } from './__graphql__/DropForm_offering';
import { DropFieldsValues, EventFieldsValues, MatcherFieldValues, OfferingFieldsValues } from './type';
import { formatDate } from './utils';

export const PriceField = () => {
  const { t } = useTranslation('creator', { keyPrefix: 'creator:forms.TokenContractForm.fields.price' });
  return (
    <FormControl>
      <FormLabel fontSize="sm" fontWeight="bold">
        {t('label')}
      </FormLabel>
      <StripePrice />
      <FormHelperText>Use zero if this Drop is free to claim</FormHelperText>
    </FormControl>
  );
};

export const DROP_TYPES = {
  GENERAL: 'GENERAL',
  ...OfferingType,
} as const;

export const CategorySelectionField = ({ isLocked }: { isLocked?: boolean }) => {
  const { t } = useTranslation('creator', { keyPrefix: 'creator:forms.TokenContractForm.fields.type' });
  return (
    <Select
      id="type"
      name="type"
      label={t('label')}
      isDisabled={isLocked}
      options={[
        {
          value: '',
          label: 'General',
        },
        {
          value: DROP_TYPES.COMPETITION,
          label: 'Competition',
        },
        {
          value: DROP_TYPES.CONTENT,
          label: 'Content',
        },
        {
          value: DROP_TYPES.MERCH,
          label: 'Merch',
        },
        {
          value: DROP_TYPES.EVENT,
          label: 'Event',
        },
        {
          value: DROP_TYPES.ATTENDANCE,
          label: 'Attendance',
        },
      ]}
    />
  );
};

export const ImageField = ({ image }: { image?: string | null | undefined }) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [, meta] = useField({
    name: 'image',
    validate: (value) => {
      if (!value) {
        return 'An image is required';
      }
      if ((value as any) instanceof File) {
        const file = value as unknown as File;
        if (file.size && file.size >= 10_000_000) {
          return 'File is too large';
        }
      }
    },
  });
  return (
    <ImageInput
      label="Image"
      onChange={(file: File | null) => {
        setFieldValue('image', file);
        setTimeout(() => setFieldTouched('image', true), 0);
      }}
      outsideBorder
      helpText="We recommend 500 x 500 pixels at a minimum."
      specText=".jpg, .gif, or .png up to 10MB"
      errorMessage={meta.error}
      placeholderProps={{ icon: Image }}
      isRequired
      image={image}
    />
  );
};

export const MaxSupplyField = ({ isDisabled }: { isDisabled?: boolean }) => {
  const { t } = useTranslation('creator', { keyPrefix: 'creator:forms.TokenContractForm.fields.maxSupply' });

  return (
    <SwitchWithLabel label={t('label')} name="limitedSupply" description={t('switchLabel')}>
      <Input
        name="maxSupply"
        type="number"
        validate={(value) => {
          if ((value as number) < 1) {
            return t('forms.TokenContractForm.fields.maxSupply.error', { min: 1 });
          }
        }}
      />
    </SwitchWithLabel>
  );
};

export const PrivateClaimLinkField = () => {
  const { t } = useTranslation('creator', { keyPrefix: 'creator:forms.TokenContractForm.fields.isSecret' });
  const {
    handleChange,
    values: { isSecret },
  } = useFormikContext<{ isSecret: boolean }>();

  return (
    <Stack>
      <Box>
        <FormLabel fontSize="sm" fontWeight="bold">
          {t('label')}
        </FormLabel>
        <FormControl display="flex" alignItems="center" mb="2" mt="-4px">
          <FormLabel cursor="pointer" htmlFor="isSecret" fontSize="sm" mb="0" flexGrow="1">
            {t('switchLabel')}
          </FormLabel>
          <Switch id="isSecret" name="isSecret" onChange={handleChange} isChecked={isSecret} size="sm" />
        </FormControl>
      </Box>
    </Stack>
  );
};

export const BonusContentField = ({
  onChange,
  initialValues,
}: {
  onChange: (files: UploadedFile[]) => void;
  initialValues?: Upload[];
}) => {
  const {
    handleChange,
    values: { hasUnlockableFiles },
  } = useFormikContext<{ hasUnlockableFiles: boolean }>();

  return (
    <Stack>
      <Box>
        <FormLabel fontSize="sm" fontWeight="bold">
          Unlockable content
        </FormLabel>
        <FormControl display="flex" alignItems="center" mb="2" mt="-4px">
          <FormLabel cursor="pointer" htmlFor="hasUnlockableFiles" fontSize="sm" mb="0" flexGrow="1">
            Include files available exclusively to owners of this Drop
          </FormLabel>
          <Switch
            id="hasUnlockableFiles"
            name="hasUnlockableFiles"
            onChange={handleChange}
            isChecked={hasUnlockableFiles}
            size="sm"
          />
        </FormControl>
      </Box>
      <MultiFileUpload
        initialValues={initialValues}
        onChange={onChange}
        display={hasUnlockableFiles ? undefined : 'none'}
      />
    </Stack>
  );
};

export const BlockchainField = () => {
  return (
    <FormControl>
      <FormLabel fontSize="sm" fontWeight="bold">
        Blockchain
      </FormLabel>
      <ChakraInput value="Polygon" isDisabled />
    </FormControl>
  );
};

export const OwnershipMessageField = () => {
  const { t } = useTranslation('creator', { keyPrefix: 'creator:forms.TokenContractForm.fields.ownershipMessage' });

  return (
    <SwitchWithLabel label={t('label')} name="hasOwnershipMessage" description={t('help')}>
      <MarkdownEditor
        name="ownershipMessage"
        validate={(value) => {
          if (value.length > 1000) {
            return t('common:errors.maxLength', { max: 1000 });
          }
        }}
      />
    </SwitchWithLabel>
  );
};

export const DropCriteriaField = () => {
  const { growthbook } = useContext(GrowthbookContext);
  if (!growthbook?.isOn('drop_unlock_criteria')) return null;

  return (
    <Select
      id="matcherNames"
      name="matcherNames"
      label="Drop criteria"
      options={Object.keys(MatcherName).map((matcher) => ({
        value: matcher,
        label: matcher,
      }))}
      isMulti={true}
    />
  );
};

interface DropFieldsProps {
  setUploads: (uploads: UploadedFile[]) => void;
  bonusContentFiles?: Upload[];
  lockFields?: string[];
  image?: string | null;
}

export const DropFields = ({ setUploads, bonusContentFiles, image, lockFields }: DropFieldsProps) => {
  const { t } = useTranslation(['creator', 'common']);
  return (
    <>
      <Input
        name="name"
        label={t('forms.TokenContractForm.fields.name.label')}
        type="text"
        isRequired
        isDisabled={lockFields?.includes('name')}
        validate={(value) => {
          if ((value as string).length < 3 || (value as string).length > 40) {
            return t('common:errors.length', { min: 3, max: 40 });
          }
        }}
      />
      <PriceField />
      <MarkdownEditor
        name="description"
        label={t('forms.TokenContractForm.fields.description.label')}
        validate={(value) => {
          if ((value as string).length > 1000) {
            return t('common:errors.maxLength', { max: 1000 });
          }
        }}
      />
      <ImageField
        image={image}
        // TODO - complete image validation
        // validate={(value) => {
        //   if ((value as any) instanceof File) {
        //     const file = value as unknown as File;
        //     if (file.size && file.size >= 10_000_000) {
        //       return t('forms.TokenContractForm.fields.image.errors.tooLarge', { size: 10 });
        //     }
        //   }
        // }}
      />
      <MaxSupplyField />
      <PrivateClaimLinkField />
      <BonusContentField
        onChange={(uploads) => {
          setUploads(uploads);
        }}
        initialValues={bonusContentFiles}
      />
      <OwnershipMessageField />
      <DropCriteriaField />
    </>
  );
};

type FieldValues = DropFieldsValues & MatcherFieldValues;

export const formatDropFields = (
  {
    image,
    maxSupply,
    limitedSupply,
    hasUnlockableFiles,
    price,
    type,
    hasOwnershipMessage,
    ownershipMessage,
    matcherNames,
    ...values
  }: FieldValues,
  uploads: UploadedFile[] | null,
) => {
  const imageUpdated = typeof image === 'object';
  return {
    ...values,
    image: imageUpdated ? image : undefined,
    maxSupply: limitedSupply ? maxSupply : null,
    type: type || null,
    price: price ? price.toString() : null,
    unlockableFiles: hasUnlockableFiles ? uploads?.map((upload) => ({ name: upload.name, id: upload.id ?? '' })) : null,
    ownershipMessage: hasOwnershipMessage ? ownershipMessage : null,
    matchersWithParameters: matcherNames ? matcherNames.map((matcherName) => ({ matcherName })) : [],
  };
};

export const initialOfferingValues = (
  values?: DropForm_offering | null,
  isCreating?: boolean,
): OfferingFieldsValues => {
  return {
    name: values?.name || '',
    description: values?.description || '',
    image: values?.image || '',
    isPurchasable: !values ? true : !!values?.price,
    price: values?.price || '0',
    isSecret: isCreating ? false : !!values?.isSecret,
  };
};

export const initialMatcherValues = (values?: DropForm_offering | null): MatcherFieldValues => {
  return {
    matcherNames: values?.unlockCriteria.map((c) => c.matcherName) || [],
  };
};

export const initialDropValues = (values?: DropForm_offering | null, isCreating?: boolean): DropFieldsValues => {
  return {
    // Offering Fields
    ...initialOfferingValues(values, isCreating),

    // Drop Fields
    type: values?.type || ('' as OfferingType),
    maxSupply: values?.maxSupply ?? 100,
    limitedSupply: isCreating ? true : !!values?.maxSupply,
    hasUnlockableFiles: isCreating ? false : !!values?.unlockableFiles.length,
    hasOwnershipMessage: false,
    ownershipMessage: '',
  };
};

// An event is a special type of offering (Drop)
export const initialEventValues = (values?: DropForm_offering | null): EventFieldsValues => {
  return {
    // Offering Fields
    ...initialOfferingValues(values, true),
    //Event Fields
    hasAttendeeLimit: !!values?.maxSupply,
    attendeeLimit: values?.maxSupply || 0,
    allowMultiBuy: !!(values?.maxPurchaseQuantity && values?.maxPurchaseQuantity > 1),
    maxPurchaseQuantity: values?.maxPurchaseQuantity || 1,
    startDateTime: formatDate(values?.startDateTime),
    endDateTime: formatDate(values?.endDateTime),
    location: values?.location || '',
  };
};

DropFields.fragments = {
  offering: gql`
    fragment DropForm_offering on Offering {
      id
      name
      description
      image(variant: card)
      maxSupply
      price
      isSecret
      type
      releasedAt
      maxPurchaseQuantity
      startDateTime
      endDateTime
      location
      unlockableFiles {
        id
        title
        fileName
      }
      ownershipMessage
      community {
        id
        slug
      }
      unlockCriteria {
        matcherName
      }
    }
  `,
};
