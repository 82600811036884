import { gql } from '@apollo/client';
import { AspectRatio, Skeleton, Spacer, Stack, StackProps } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { Ticket } from 'phosphor-react';

import { ImageOrPlaceholder } from 'components/atoms/ImageOrPlaceholder';
import { useBrightness } from 'hooks/useBrightness';

import { MembershipCard_membership } from './__graphql__/MembershipCard_membership';

export interface MembershipCardProps extends StackProps {
  isLoaded?: boolean;
  variant?: 'light' | 'dark';
  membership?: MembershipCard_membership | null;
  hideAvatar?: boolean;
  hideLogo?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export const MembershipCard = ({
  membership,
  children,
  isLoaded = true,
  hideAvatar = false,
  hideLogo = false,
  variant = 'dark',
  size = 'md',
  ...rest
}: MembershipCardProps) => {
  const memberSince = membership?.createdAt ? DateTime.fromISO(membership.createdAt) : null;
  const brightness = useBrightness(membership?.community.background || '');
  const computedVariant = membership?.community.background ? (brightness < 50 ? 'light' : 'dark') : variant || 'dark';
  const bgImage = membership?.community.background
    ? computedVariant === 'light'
      ? `linear-gradient(rgba(0, 0, 0, 0.01) 0%, rgba(0, 0, 0, 0.6) 100%),url(${membership?.community.background})`
      : `url(${membership?.community.background})`
    : undefined;

  return (
    <AspectRatio ratio={0.69} minW={size === 'sm' ? '180px' : '220px'}>
      <Stack
        borderRadius="lg"
        p="10"
        background={bgImage}
        backgroundColor="outlineColor"
        backgroundSize="cover"
        backgroundPosition="center"
        textAlign="center"
        spacing="8"
        {...rest}
      >
        <AspectRatio ratio={1} minW="100px" maxW="140px" rounded="lg" data-theme={computedVariant}>
          <ImageOrPlaceholder
            bg="shaded"
            icon={Ticket}
            rounded="lg"
            isLoaded={isLoaded}
            src={membership?.community.logo}
          />
        </AspectRatio>
        <Spacer />
        <Stack flexShrink={0} color="background" flexGrow="1" data-theme={computedVariant}>
          {children}
          <Stack spacing="2" justifyContent="center">
            <Skeleton
              isLoaded={isLoaded}
              fontWeight="semibold"
              fontFamily="heading"
              lineHeight="short"
              fontSize="xl"
              noOfLines={2}
            >
              {membership?.community.displayName}
            </Skeleton>
            {memberSince && (
              <Skeleton isLoaded={isLoaded} fontWeight="semibold" fontSize="sm">
                {memberSince.toFormat('LLLL yyyy')}
              </Skeleton>
            )}
          </Stack>
        </Stack>
      </Stack>
    </AspectRatio>
  );
};

MembershipCard.fragments = {
  membership: gql`
    fragment MembershipCard_membership on CommunityMembership {
      createdAt
      community {
        id
        displayName
        background(variant: card)
        logo(variant: md)
      }
    }
  `,
};
