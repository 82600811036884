import { SimpleGrid, SimpleGridProps, Box, BoxProps, useBreakpointValue } from '@chakra-ui/react';
import { Children } from 'react';

export interface CardGridProps extends SimpleGridProps {
  isLoaded?: boolean;
  loadingCard?: React.ElementType<{ key: number; opacity: number }>;
  emptyCard?: React.ElementType<{ key: number; opacity: number }>;
  size?: string;
  empty?: React.ReactNode;
  minW?: string;
}

const EmptyOverlay = (props: BoxProps) => (
  <Box
    textAlign="center"
    position="absolute"
    top="50%"
    left="50%"
    minW="80%"
    transform="translate(-50%, -50%);"
    color="blackAlpha.700"
    {...props}
  />
);

export const CardGrid = ({
  isLoaded = true,
  loadingCard: LoadingCard,
  emptyCard: EmptyCard,
  children,
  empty,
}: CardGridProps) => {
  const isEmpty = isLoaded && !Children.count(children);
  const columns = useBreakpointValue({ base: 1, xs: 2, md: 3, lg: 4 }) || 2;

  return (
    <>
      <SimpleGrid columns={columns} spacing={{ base: 2, md: 4 }} position="relative">
        {/* Loading/populated state */}
        {!isEmpty &&
          (!LoadingCard || isLoaded
            ? children
            : Array(columns)
                .fill(1)
                .map((_, i) => <LoadingCard key={i} opacity={(columns - i) / columns} />))}

        {/* Empty state */}
        {isEmpty && empty && EmptyCard && (
          <>
            {Array(columns)
              .fill(1)
              .map((_, i) => (
                <EmptyCard key={i} opacity={(columns - i) / columns} />
              ))}
            <EmptyOverlay>{empty}</EmptyOverlay>
          </>
        )}
      </SimpleGrid>
      {isEmpty && !EmptyCard && empty}
    </>
  );
};
