import { gql, useApolloClient } from '@apollo/client';
import { Box, Button, Stack } from '@chakra-ui/react';
import { Formik, FormikErrors } from 'formik';
import { AuthContext } from 'providers/Auth';
import { CommunityContext } from 'providers/Community';
import { useState, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import { Link } from 'components/atoms/Link';
import { LinkSent } from 'components/atoms/LinkSent';
import { Separator } from 'components/atoms/Separator';
import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { GoogleLoginButton } from 'components/molecules/GoogleLoginButton';
import { Input } from 'components/molecules/Input';
import { MetaMaskLoginButton, WalletAuthSigningInfo } from 'components/molecules/MetaMaskLoginButton';
import { useMagicAuthURLParams } from 'hooks/useMagicAuthURLParams';

import { LoginFormEmailCheckQuery, LoginFormEmailCheckQueryVariables } from './__graphql__/LoginFormEmailCheckQuery';

const EMAIL_CHECK_QUERY = gql`
  query LoginFormEmailCheckQuery($email: String!) {
    emailAuthCheck(email: $email)
  }
`;

export const LoginForm = ({ onSent }: { onSent?: () => void }) => {
  const { userLoading, magicLinkLogin, sendAuthLink } = useContext(AuthContext);
  const { community } = useContext(CommunityContext);
  const [linkSent, setLinkSent] = useState(false);
  const magicCallbackParams = useMagicAuthURLParams();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const client = useApolloClient();

  if (linkSent) return <LinkSent login />;

  const onWalletLoginCheck = (signingInfo: WalletAuthSigningInfo, loggedIn: boolean) => {
    if (signingInfo.address && !loggedIn) {
      navigate(
        ROUTES.auth.signUp.set({
          search: window.location.search,
        }),
        { state: { signingInfo } },
      );
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validate={(values) => {
        const errors: FormikErrors<typeof values> = {};
        if (!values.email.length) {
          errors.email = 'Please provide your email address';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={async (values, { setErrors }) => {
        if (magicCallbackParams) {
          await magicLinkLogin({
            credential: magicCallbackParams.magicCredential,
            setErrors,
          });
        } else {
          const { data } = await client.query<LoginFormEmailCheckQuery, LoginFormEmailCheckQueryVariables>({
            query: EMAIL_CHECK_QUERY,
            variables: { email: values.email },
            fetchPolicy: 'network-only',
          });
          if (!data.emailAuthCheck) {
            setErrors({ email: 'No account associated with this email address, please try signing up.' });
            return;
          }
          if (await sendAuthLink({ params: { email: values.email } })) {
            setLinkSent(true);
            if (onSent) {
              onSent();
            }
          }
        }
      }}
    >
      {({ handleSubmit, isSubmitting, isValid, setFieldValue, setFieldTouched, values, submitForm }) => {
        if (magicCallbackParams?.email && !values.email) {
          setFieldValue('email', magicCallbackParams?.email);
          setTimeout(() => setFieldTouched('email', true), 0);
          setTimeout(() => submitForm(), 0);
        }

        if (isSubmitting && magicCallbackParams?.email) {
          return <WrappedSpinner />;
        }

        return (
          <form onSubmit={handleSubmit} noValidate>
            <Stack spacing="4" shouldWrapChildren>
              <Input
                id="email"
                name="email"
                label="Email"
                type="email"
                variant="floating"
                autoFocus
                isDisabled={userLoading || isSubmitting || !!magicCallbackParams}
              />
              <Button
                isLoading={isSubmitting}
                isDisabled={userLoading || !isValid}
                colorScheme="primary"
                color="offBlack"
                type="submit"
                w="full"
              >
                Email login link
              </Button>
              <Separator py="0">or</Separator>
              <GoogleLoginButton w="full" then={params.get('then')} />
              <MetaMaskLoginButton onLoginResult={onWalletLoginCheck} w="full" />
              <Box textAlign="center">
                <Box bg="shadow" p="3" rounded="lg" mt="4">
                  Not registered?{' '}
                  <Link
                    to={
                      community?.isWhiteLabel
                        ? ROUTES.community.join.set({ communitySlug: community.slug, search: window.location.search })
                        : ROUTES.auth.signUp.set({ search: window.location.search })
                    }
                  >
                    Sign up
                  </Link>
                </Box>
              </Box>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};
