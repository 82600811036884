import { gql } from '@apollo/client';
import { Button, MenuItem, Menu, MenuButton, MenuList, Icon, StackProps, Stack } from '@chakra-ui/react';
import { CaretDown, Ticket } from 'phosphor-react';
import { useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import { LabeledNumber } from 'components/atoms/LabeledNumber';
import { RouterLink } from 'components/atoms/Link';
import { GoogleLoginButton } from 'components/molecules/GoogleLoginButton';

import { CommunityHeaderControls_community } from './__graphql__/CommunityHeaderControls_community';

interface CommunityHeaderControlsProps extends StackProps {
  community?: CommunityHeaderControls_community | null;
  showCounts?: boolean;
  withGoogle?: boolean;
}

const titleCase = (str: string) => {
  return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
};

const MemberShipStatus = ({ slug, role }: { slug?: string; role?: string }) => {
  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton isActive={isOpen} as={Button} rightIcon={<CaretDown />}>
            {titleCase(role || '')}
          </MenuButton>
          <MenuList>
            {role === 'MEMBER' ? (
              <MenuItem as={RouterLink} to={ROUTES.community.preferences.set({ communitySlug: slug })}>
                Preferences
              </MenuItem>
            ) : (
              <MenuItem as={RouterLink} to={ROUTES.dashboard.community.base.set({ communitySlug: slug })}>
                Manage
              </MenuItem>
            )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export const CommunityHeaderControls = ({
  community,
  withGoogle,
  showCounts = true,
  ...rest
}: CommunityHeaderControlsProps) => {
  const [params] = useSearchParams();
  return (
    <Stack direction={{ base: 'column', md: 'row' }} flexGrow={0} spacing={2} {...rest}>
      {showCounts && <LabeledNumber name="Members" value={community?.memberCount} isLoaded={!!community} />}
      {community?.currentMembership ? (
        <MemberShipStatus role={community?.currentMembership?.role || ''} slug={community?.slug || ''} />
      ) : (
        <>
          <Button
            w="100%"
            as={RouterLink}
            to={ROUTES.community.join.set({ communitySlug: community?.slug })}
            onClick={() => {
              localStorage?.removeItem('offeringClaimIntent');
            }}
            variant={withGoogle ? 'outline' : 'primary'}
            leftIcon={withGoogle ? undefined : <Icon boxSize="5" as={Ticket} />}
          >
            {withGoogle ? 'Sign up with Email' : 'Become a Member'}
          </Button>
          {withGoogle && (
            <GoogleLoginButton
              w="full"
              then={
                community ? encodeURIComponent(window.location.pathname + window.location.search) : params.get('then')
              }
            />
          )}
        </>
      )}
    </Stack>
  );
};

CommunityHeaderControls.fragments = {
  community: gql`
    fragment CommunityHeaderControls_community on Community {
      id
      slug
      memberCount
      currentMembership {
        role
      }
    }
  `,
};
