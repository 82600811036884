import { Checkbox, FormControl, Link } from '@chakra-ui/react';
import { useField } from 'formik';
import { ROUTES } from 'routes';

import { Input, InputProps } from 'components/molecules/Input';

export const DisplayNameField = (props: Partial<InputProps>) => {
  return (
    <Input
      name="displayName"
      label="Name"
      type="text"
      variant="floating"
      autoComplete="name"
      validate={(value) => {
        if (value.length < 3 || value.length > 30) {
          return 'Please enter a name between 3 and 30 characters long.';
        } else if (!/^[\w\d][\w\d._ -]+[\w\d]$/i.test(value)) {
          return 'Name can only contain letters, numbers, underscores, dashes and dots.';
        }
      }}
      {...props}
    />
  );
};

export const EmailAddressField = (props: Partial<InputProps>) => {
  return (
    <Input
      name="email"
      label="Email"
      type="email"
      variant="floating"
      autoComplete="email"
      validate={(value) => {
        if (!value.length) {
          return 'Please provide your email address.';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
          return "This doesn't look like a valid email address.";
        }
      }}
      {...props}
    />
  );
};

export const DateOfBirthField = (props: Partial<InputProps>) => {
  return (
    <Input
      type="date"
      name="dateOfBirth"
      label="Date of birth"
      autoComplete="bday"
      isRequired
      maxDate={new Date().toISOString().slice(0, 10)}
      {...props}
    />
  );
};

export const CommunityTermsCheckbox = ({ displayName }: { displayName?: string }) => {
  const [{ value }, meta, { setValue }] = useField({
    name: 'agreed',
    validate: (value) => {
      if (!value) {
        return 'You must agree in order to continue';
      }
    },
  });
  return (
    <FormControl isInvalid={!!meta.error}>
      <Checkbox
        alignItems="flex-start"
        onChange={() => {
          setValue(!value);
        }}
      >
        I agree to Temple's{' '}
        <Link href={ROUTES.legal.terms.path} target="_blank">
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link href={ROUTES.legal.privacy.path} target="_blank">
          Privacy Policy
        </Link>{' '}
        and consent to sharing my contact information with {displayName}.
      </Checkbox>
    </FormControl>
  );
};
