'use client';

import { gql, useQuery } from '@apollo/client';
import { Box, Container, Heading } from '@chakra-ui/react';
import { AuthContext } from 'providers/Auth';
import { CommunityContext } from 'providers/Community';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';

import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { PagePanel } from 'components/molecules/PagePanel';
import { CommunityHeader } from 'components/organisms/CommunityHeader';
import { CommunityJoinForm } from 'components/organisms/CommunityJoinForm';
import { CommunityJoinedConfirmation } from 'components/organisms/CommunityJoinedConfirmation';
import { CommunitySignUpForm } from 'components/organisms/CommunitySignUpForm';
import { LatestCommunityOfferings } from 'components/organisms/LatestCommunityOfferings';
import { useTrackPageView } from 'hooks/useTrackPageView';

import { CommunityJoinPageQuery, CommunityJoinPageQueryVariables } from './__graphql__/CommunityJoinPageQuery';

export const QUERY = gql`
  query CommunityJoinPageQuery($slug: String!) {
    community(slug: $slug) {
      id
      ...LatestCommunityOfferings_community
    }
  }
  ${LatestCommunityOfferings.fragments.community}
`;

const CommunityJoinPage = () => {
  const { community } = useContext(CommunityContext);
  const { data, loading } = useQuery<CommunityJoinPageQuery, CommunityJoinPageQueryVariables>(QUERY, {
    variables: { slug: community?.slug ?? '' },
    skip: !community?.id,
  });
  const [params] = useSearchParams();
  const offeringId = localStorage?.getItem('offeringClaimIntent');

  const { user, userLoading } = useContext(AuthContext);

  useTrackPageView(
    'Join Community',
    {
      community_id: community?.id,
      community_slug: community?.slug,
      isWhiteLabel: community?.isWhiteLabel,
      isMember: !!community?.currentMembership?.role,
      isLive: community?.isLive,
      loggedIn: !!user,
    },
    !community,
  );

  return (
    <>
      <Helmet>{community && <title>Join {community.displayName}</title>}</Helmet>
      <PagePanel alignItems="center" isNarrow>
        <CommunityHeader includeName={false} community={community} align="center" />
        {userLoading ? (
          <WrappedSpinner />
        ) : !user ? (
          <CommunitySignUpForm />
        ) : community?.currentMembership?.role ? (
          <CommunityJoinedConfirmation community={community} />
        ) : (
          <CommunityJoinForm community={community} />
        )}
      </PagePanel>
      {!community?.currentMembership?.role && !offeringId && !!community?.offerings.length && !params.get('email') && (
        <Box w="full" bg="chakra-body-bg" py={{ base: 8, xl: 16 }} px={{ xl: 8 }} textAlign="center" mt="auto">
          <Container textAlign="center" mb={4}>
            <Heading variant="h3">Latest Drops from {community?.displayName}</Heading>
          </Container>
          <LatestCommunityOfferings
            px={['1rem', '1rem', '2rem', '2rem']}
            isLoaded={!loading}
            community={data?.community}
          />
        </Box>
      )}
    </>
  );
};

export default CommunityJoinPage;
