'use client';

import { Stack, Heading } from '@chakra-ui/react';

import { DashboardCollectibleList } from 'components/organisms/DashboardCollectibleList';

const DashboardDropsPage = () => {
  return (
    <Stack maxW="36rem" spacing={8}>
      <Heading variant="h2">Drops</Heading>
      <DashboardCollectibleList />
    </Stack>
  );
};

export default DashboardDropsPage;
