import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Button, ButtonProps } from '@chakra-ui/react';
import { OfferingType } from '__graphql__/globalTypes';
import { AuthContext } from 'providers/Auth';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';

import { Link } from 'components/atoms/Link';
import { showError } from 'utils/errors';

import {
  CollectibleClaimButtonMutation,
  CollectibleClaimButtonMutationVariables,
} from './__graphql__/CollectibleClaimButtonMutation';
import { CollectibleClaimButton_offering } from './__graphql__/CollectibleClaimButton_offering';
import { CollectibleClaimButton_offeringInviteCode } from './__graphql__/CollectibleClaimButton_offeringInviteCode';

interface CollectibleClaimButtonProps extends ButtonProps {
  offering: CollectibleClaimButton_offering | null | undefined;
  offeringInviteCode?: CollectibleClaimButton_offeringInviteCode | null | undefined;
  ctaCopy?: string;
}

const MUTATION = gql`
  mutation CollectibleClaimButtonMutation($offeringId: ID!, $key: String) {
    claimToken(offeringId: $offeringId, key: $key) {
      id
    }
  }
`;

export const CollectibleClaimButton = ({
  offering,
  offeringInviteCode,
  ctaCopy = 'Get it for free',
  ...rest
}: CollectibleClaimButtonProps) => {
  const { user, userLoading } = useContext(AuthContext);
  const [claimToken] = useMutation<CollectibleClaimButtonMutation, CollectibleClaimButtonMutationVariables>(MUTATION);
  const [claiming, setClaiming] = useState(false);
  const navigate = useNavigate();
  const client = useApolloClient();

  const props = { variant: 'primary', ...rest };

  const itemParams = useMemo(
    () => ({
      communitySlug: offering?.community.slug,
      managedContractSlug: offering?.slug,
    }),
    [offering?.community.slug, offering?.slug],
  );

  const returnUrl = offeringInviteCode?.key
    ? ROUTES.community.item.key.set({
        ...itemParams,
        key: offeringInviteCode.key,
      })
    : ROUTES.community.item.base.set(itemParams);

  const showCta =
    (offeringInviteCode &&
      (offeringInviteCode.totalClaims < offeringInviteCode.maxClaims || !offeringInviteCode.maxClaims)) ||
    !offering?.isSecret;

  if (offering?.type === OfferingType.COMPETITION) {
    ctaCopy = 'Enter now';
  }

  const handleClick = useCallback(async () => {
    if (claiming || !offering?.id) {
      return;
    }
    setClaiming(true);
    try {
      await claimToken({
        variables: {
          offeringId: offering.id,
          key: offeringInviteCode?.key ? offeringInviteCode?.key : null,
        },
      });
      await client.resetStore();
      navigate(ROUTES.community.item.receipt.set(itemParams));
    } catch (error) {
      if (error instanceof Error) {
        showError(error.message);
      }
    }
    setClaiming(false);
  }, [claiming, claimToken, offering, offeringInviteCode, client, navigate, itemParams]);

  const recordClaimIntent = async () => {
    if (!offering?.id) {
      return;
    }
    localStorage?.setItem('offeringClaimIntent', offering.id);
  };

  useEffect(() => {
    const claimIntent = localStorage?.getItem('offeringClaimIntent');
    if (offering?.community.currentMembership && showCta && claimIntent === offering.id) {
      localStorage?.removeItem('offeringClaimIntent');
      (async () => {
        await handleClick();
      })();
    }
  }, [offering?.community.currentMembership, offering?.id, showCta, handleClick]);

  if (!offering || userLoading || offering.isOwned) {
    return null;
  }

  if (!showCta) {
    return null;
  }

  if (!user) {
    return (
      <Button
        as={Link}
        to={ROUTES.community.join.set({
          then: returnUrl,
          communitySlug: offering.community.slug,
        })}
        onClick={() => {
          recordClaimIntent();
        }}
        {...props}
      >
        {ctaCopy}
      </Button>
    );
  }

  if (user && !offering.community.currentMembership) {
    return (
      <Button
        as={Link}
        to={ROUTES.community.join.set({
          communitySlug: offering.community.slug,
          then: returnUrl,
        })}
        onClick={() => {
          recordClaimIntent();
        }}
        {...props}
      >
        {ctaCopy}
      </Button>
    );
  }

  return (
    <Button isDisabled={claiming} isLoading={claiming} onClick={handleClick} {...props}>
      {ctaCopy}
    </Button>
  );
};

CollectibleClaimButton.fragments = {
  offering: gql`
    fragment CollectibleClaimButton_offering on Offering {
      id
      slug
      isOwned
      isSecret
      type
      community {
        id
        slug
        currentMembership {
          role
        }
      }
    }
  `,
  offeringInviteCode: gql`
    fragment CollectibleClaimButton_offeringInviteCode on OfferingInviteCode {
      key
      name
      maxClaims
      totalClaims
    }
  `,
};
