import { Box, Container, Stack, StackProps } from '@chakra-ui/react';

interface HeroProps extends StackProps {
  children?: React.ReactNode;
  align?: 'center' | 'left' | 'right';
  containerVariant?: string;
}

export const HeroSection = ({ children, align = 'center', containerVariant, ...rest }: HeroProps) => {
  const flexAlign = (
    {
      center: 'center',
      left: 'flex-start',
      right: 'flex-end',
    } as const
  )[align];

  return (
    <Box {...rest}>
      <Container
        as={Stack}
        spacing={{ base: 8, md: 12 }}
        justifyContent="center"
        alignItems={flexAlign}
        textAlign={align}
        minH="100vh"
        variant={containerVariant}
      >
        {children}
      </Container>
    </Box>
  );
};
