import { As, AspectRatio } from '@chakra-ui/react';
import { useContext } from 'react';

import { ImageOrPlaceholder, ImageOrPlaceholderProps } from 'components/atoms/ImageOrPlaceholder';
import { ItemCardContext } from 'components/molecules/ItemCard/context';

interface ItemCardImageProps extends ImageOrPlaceholderProps {
  icon?: As;
  image?: string | null | undefined;
  imageIcon?: As;
}

export const ItemCardImage = ({ image, icon, children, ...rest }: ItemCardImageProps) => {
  const { isLoaded } = useContext(ItemCardContext);
  return (
    <AspectRatio ratio={1}>
      <ImageOrPlaceholder w="9" h="9" src={image} icon={icon} isLoaded={isLoaded} {...rest} />
    </AspectRatio>
  );
};
