import { Route, Routes } from 'react-router-dom';
import { ROUTES } from 'routes';

import { CommunityRoutes } from 'app/[community]/routes';
import { AdminRoutes } from 'app/admin/routes';
import { DashboardRoutes } from 'app/dashboard/routes';
import BaseLayout from 'app/layout';
import LearnPage from 'app/learn/page';
import LoginCallbackPage from 'app/login/callback/page';
import SpotifyCallbackPage from 'app/login/callback/spotify/callback/page';
import LoginPage from 'app/login/page';
import Page from 'app/page';
import SignUpPage from 'app/sign-up/page';
import TermsCookiesPage from 'app/terms/cookies/page';
import DisclaimersPage from 'app/terms/disclaimers/page';
import TermsPage from 'app/terms/page';
import PrivacyPage from 'app/terms/privacy/page';
import TermsOfUsePage from 'app/terms/use/page';
import WaitlistPage from 'app/waitlist/page';
import { MyRoutes } from 'app/you/routes';

import UnsubscribePage from './unsubscribe/page';

export const BaseRoutes = () => {
  return (
    <>
      <BaseLayout>
        <Routes>
          <Route path={ROUTES.landing.path} element={<Page />} />
          <Route path={ROUTES.auth.login.path} element={<LoginPage />} />
          <Route path={ROUTES.auth.callback.path} element={<LoginCallbackPage />} />
          <Route path={ROUTES.spotifyCallback.path} element={<SpotifyCallbackPage />} />
          <Route path={ROUTES.auth.signUp.path} element={<SignUpPage />} />
          <Route path={ROUTES.waitlist.path} element={<WaitlistPage />} />
          <Route path={ROUTES.learn.path} element={<LearnPage />} />
          <Route path={ROUTES.unsubscribe.path} element={<UnsubscribePage />} />

          {/* User route is now handled in CommunityRoutes due to https://github.com/remix-run/react-router/pull/9506 */}

          {/* Legal */}
          <Route path={ROUTES.legal.privacy.path} element={<PrivacyPage />} />
          <Route path={ROUTES.legal.terms.path} element={<TermsPage />} />
          <Route path={ROUTES.legal.disclaimer.path} element={<DisclaimersPage />} />
          <Route path={ROUTES.legal.termsUse.path} element={<TermsOfUsePage />} />
          <Route path={ROUTES.legal.termsCookies.path} element={<TermsCookiesPage />} />

          {/* Sub routers */}
          <Route path={ROUTES.you.global} element={<MyRoutes />} />
          <Route path={ROUTES.dashboard.global} element={<DashboardRoutes />} />
          <Route path={ROUTES.admin.global} element={<AdminRoutes />} />
          <Route path={ROUTES.community.global} element={<CommunityRoutes />} />
        </Routes>
      </BaseLayout>
    </>
  );
};
