import { Tooltip as ChakraTooltip, Box } from '@chakra-ui/react';
import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine, PointTooltipProps, LineProps } from '@nivo/line';
import { Frequency } from '__graphql__/globalTypes';

const Tooltip = ({ point }: PointTooltipProps) => {
  return (
    <ChakraTooltip isOpen fontSize="sm" hasArrow placement="top" label={point.data.y.toString()}>
      <Box />
    </ChakraTooltip>
  );
};

interface Point {
  x: string;
  y: number;
}

interface OfferingChartProps {
  data: Point[];
  frequency: Frequency;
}

export const RevenueLine = ({ data, frequency }: OfferingChartProps) => {
  const xScale: Partial<LineProps['xScale']> = {
    precision: frequency === Frequency.DAILY ? 'hour' : frequency === Frequency.WEEKLY ? 'day' : 'month',
    format: '%Y-%m-%d',
  };
  const axisBottom: Partial<LineProps['axisBottom']> = {
    format: frequency === Frequency.DAILY ? '%d %b' : frequency === Frequency.WEEKLY ? '%d %b' : '%b %Y',
    tickValues:
      frequency === Frequency.DAILY ? 'every day' : frequency === Frequency.WEEKLY ? 'every week' : 'every month',
  };
  return (
    <ResponsiveLine
      data={[
        {
          id: 'revenue',
          data,
        },
      ]}
      curve="monotoneX"
      margin={{ top: 20, right: 30, bottom: 30, left: 30 }}
      xScale={{
        ...xScale,
        type: 'time',
        useUTC: false,
      }}
      xFormat="time:%Y-%m-%d"
      yScale={{
        type: 'linear',
        min: 'auto',
        max: 'auto',
        stacked: true,
        reverse: false,
      }}
      yFormat=" >-.2f"
      axisTop={null}
      axisRight={null}
      axisBottom={{
        ...axisBottom,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 2,
        tickRotation: 0,
        legendPosition: 'end',
      }}
      enableGridX={false}
      enableGridY={false}
      enableArea={true} // Enable area chart
      enableCrosshair={false}
      areaBaselineValue={0}
      colors={['#60BEBF']}
      defs={[
        // Define gradient
        linearGradientDef('gradientA', [
          { offset: 0, color: 'inherit' },
          { offset: 50, color: 'inherit', opacity: 0.85 },
          { offset: 70, color: 'inherit', opacity: 0 },
        ]),
      ]}
      fill={[{ match: '*', id: 'gradientA' }]}
      tooltip={(point) => <Tooltip point={point.point} />}
      pointBorderWidth={1}
      pointBorderColor={{ from: 'color' }}
      pointLabelYOffset={-12}
      useMesh={true}
    />
  );
};
