import { BoxProps, chakra, FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/react';
import MDEditor, { commands } from '@uiw/react-md-editor';
import { useField } from 'formik';
import rehypeSanitize from 'rehype-sanitize';

interface MarkdownEditorProps extends BoxProps {
  name: string;
  label?: string;
  helpText?: string;
  placeholder?: string;
  isRequired?: boolean;
  validate?: (value: string) => string | undefined;
}

const ChakraMdEditor = chakra(MDEditor);

export const MarkdownEditor = ({
  name,
  label,
  placeholder,
  isRequired,
  helpText,
  validate,
  ...props
}: MarkdownEditorProps) => {
  const [field, meta, helpers] = useField({ name, validate });
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <>
      <FormControl data-color-mode="light" isRequired={isRequired}>
        {label && (
          <FormLabel htmlFor={name} fontSize="sm" fontWeight="bold">
            {label}
          </FormLabel>
        )}
        <ChakraMdEditor
          id={name}
          {...field}
          value={value}
          onChange={(e) => setValue(e)}
          previewOptions={{
            rehypePlugins: [[rehypeSanitize]],
          }}
          textareaProps={{ placeholder }}
          commands={[
            commands.bold,
            commands.italic,
            commands.divider,
            commands.orderedListCommand,
            commands.unorderedListCommand,
            commands.divider,
            commands.link,
          ]}
          borderRadius="md"
          extraCommands={[commands.codeLive, commands.fullscreen]}
          boxShadow="none"
          border="1px solid"
          borderColor="chakra-border-color"
          background="chakra-body-bg"
          color="chakra-body-text"
          // fontFamily="inherit"
          sx={{
            '.w-md-editor-toolbar': {
              borderTopRadius: 'md',
              backgroundColor: 'chakra-subtle-bg',
              borderColor: 'chakra-border-color',
              padding: '2',
              svg: {
                color: 'chakra-body-text',
              },
            },
            '.w-md-editor-content': {
              // fontFamily: 'inherit',
            },
            '.w-md-editor-bar': {
              display: 'none',
            },
            '.wmde-markdown': {
              fontFamily: 'inherit',
            },
          }}
          {...props}
        />
        {helpText && <FormHelperText>{helpText}</FormHelperText>}
        {meta.touched && meta.error && <FormErrorMessage>{meta.error}</FormErrorMessage>}
      </FormControl>
    </>
  );
};
