import { gql } from '@apollo/client';
import { Button, ButtonProps, Tooltip } from '@chakra-ui/react';
import { AuthContext } from 'providers/Auth';
import { useContext } from 'react';
import { ROUTES } from 'routes';

import { Link } from 'components/atoms/Link';
import { getCurrencySymbol } from 'utils/currency';

import { CollectibleBuyButton_offering } from './__graphql__/CollectibleBuyButton_offering';
import { CollectibleBuyButton_offeringInviteCode } from './__graphql__/CollectibleBuyButton_offeringInviteCode';

interface CollectibleBuyButtonProps extends ButtonProps {
  offering: CollectibleBuyButton_offering | null | undefined;
  offeringInviteCode: CollectibleBuyButton_offeringInviteCode | null | undefined;
  quantity?: number;
}

export const CollectibleBuyButton = ({
  offering,
  offeringInviteCode,
  quantity = 1,
  ...rest
}: CollectibleBuyButtonProps) => {
  const { user, userLoading } = useContext(AuthContext);

  const props = { variant: 'secondary', ...rest };

  if (!offering || userLoading) {
    return <Button isLoading={true} {...props} />;
  }

  if (!offering.price || !offering.currency || !offering.releasedAt) {
    return null;
  }

  if (offering.isSecret && !offeringInviteCode?.key) {
    return null;
  }

  const canBuyMore = offering.numOwned < offering.maxPurchaseQuantity;
  const displayPrice = `${getCurrencySymbol(offering.currency)}${(parseFloat(offering.price) * quantity).toFixed(2)}`;

  const buyUrl = offering.isSecret
    ? ROUTES.community.item.buy.key.set({
        communitySlug: offering.community.slug,
        managedContractSlug: offering?.slug,
        key: offeringInviteCode?.key,
      })
    : ROUTES.community.item.buy.base.set({
        communitySlug: offering.community.slug,
        managedContractSlug: offering?.slug,
      }) + `?quantity=${quantity}`;

  if (!user) {
    return (
      <Button
        as={Link}
        to={ROUTES.community.join.set({
          then: encodeURIComponent(buyUrl),
          communitySlug: offering.community.slug,
        })}
        {...props}
      >
        Sign up to buy for {displayPrice}
      </Button>
    );
  }

  return (
    <Tooltip
      placement="top"
      isDisabled={canBuyMore}
      label={`You already own the maximum quantity of ${offering.maxPurchaseQuantity}`}
    >
      <Button as={Link} to={canBuyMore ? buyUrl : '#'} isDisabled={!canBuyMore} {...props}>
        Buy for {displayPrice}
      </Button>
    </Tooltip>
  );
};

CollectibleBuyButton.fragments = {
  offering: gql`
    fragment CollectibleBuyButton_offering on Offering {
      id
      slug
      price
      isOwned
      numOwned
      maxPurchaseQuantity
      isSecret
      releasedAt
      currency
      community {
        id
        slug
      }
    }
  `,
  offeringInviteCode: gql`
    fragment CollectibleBuyButton_offeringInviteCode on OfferingInviteCode {
      key
      name
      maxClaims
      totalClaims
    }
  `,
};
