import { Button, Box, Container, HStack, Spacer } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { ROUTES } from 'routes';

import { RouterLink } from 'components/atoms/Link';
import { Logo } from 'components/atoms/Logo';

export const LandingPageNavbar = () => {
  const [pastHero, setPastHero] = useState(false);

  const requestRef = useRef<number | undefined>();
  const elRef = useRef<HTMLDivElement | null>(null);

  const check = () => {
    if (!elRef.current) return;
    setPastHero(window.scrollY > window.outerHeight - elRef.current.clientHeight * 2);
    requestRef.current = requestAnimationFrame(check);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(check);
    return () => {
      if (requestRef.current) cancelAnimationFrame(requestRef.current);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box position="fixed" w="full" zIndex="10" bg={pastHero ? 'whiteAlpha.900' : undefined} ref={elRef}>
      <Container as={HStack} my="5">
        <Box as={RouterLink} to="/" bg={pastHero ? 'offBlack' : 'white'} p="3" borderRadius="md">
          <Logo boxSize="6" textColor={pastHero ? 'offWhite' : 'offBlack'} />
        </Box>
        <Spacer />
        <HStack spacing="9">
          {/* <Link to={ROUTES.auth.login.path} variant={pastHero ? 'header' : 'headerInverse'}>
            Login
          </Link> */}
          <Button as={RouterLink} to={ROUTES.waitlist.path} variant={pastHero ? 'black' : 'white'} p="6 !important">
            Get early access
          </Button>
        </HStack>
      </Container>
    </Box>
  );
};
