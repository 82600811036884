'use client';

import builder, { BuilderComponent } from '@builder.io/react';
import { Container } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import { BaseLayout } from 'components/templates/Base/base';
import { useTrackPageView } from 'hooks/useTrackPageView';
import 'utils/builder';

builder.init(process.env.REACT_APP_BUILDER_API_KEY ?? '');

const LearnPage = () => {
  const location = useLocation();

  useTrackPageView('Learn', { path: location.pathname });

  // Builder.io
  const [content, setContent] = useState<any>(null);
  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get('learn', {
          url: location.pathname,
        })
        .promise();

      setContent(content);
    }
    fetchContent();
  }, [location.pathname]);

  return (
    <>
      <Helmet>{content?.data?.title && <title>{content?.data?.title} on Temple</title>}</Helmet>
      <BaseLayout>
        <Container>
          {/* Headless CMS */}
          {content && <BuilderComponent model="learn" content={content} />}
        </Container>
      </BaseLayout>
    </>
  );
};

export default LearnPage;
