import { Stack } from '@chakra-ui/react';

import { Input } from 'components/molecules/Input';
import { MarkdownEditor } from 'components/molecules/MarkdownEditor';
import { SwitchWithLabel } from 'components/molecules/SwitchWithLabel';

import { PriceField, ImageField } from './dropFields';
import { EventFieldsValues } from './type';

export interface EventFieldsProps {
  image?: string | null | undefined;
  lockFields?: string[];
  isPurchasable?: boolean;
}

export const EventFields = ({ image, lockFields, isPurchasable }: EventFieldsProps) => {
  return (
    <Stack spacing="4">
      <Input
        name="name"
        label="Event name"
        isRequired
        isDisabled={lockFields?.includes('name')}
        validate={(value) => {
          if ((value as string).length < 5) {
            return 'An event name is required';
          }
        }}
      />
      <PriceField />
      <Input
        name="startDateTime"
        label="Event start time"
        type="datetime-local"
        isRequired
        minDate={new Date().toISOString().slice(0, -8)}
        validate={(value) => {
          if (!value) {
            return 'A start time is required';
          }
        }}
      />
      <Input
        name="endDateTime"
        label="Event end time"
        type="datetime-local"
        minDate={new Date().toISOString().slice(0, -8)}
      />
      <ImageField image={image} />
      <MarkdownEditor name="description" label="Description" />
      <Input name="location" label="Venue" />
      <SwitchWithLabel
        label="Attendee limit"
        name="hasAttendeeLimit"
        description="Set the total amount of available tickets"
      >
        <Input
          type="number"
          name="attendeeLimit"
          validate={(value) => {
            if (value && (value as number) < 1) {
              return 'Cannot be less than 1';
            }
          }}
        />
      </SwitchWithLabel>
      <SwitchWithLabel label="Multiple purchase" name="allowMultiBuy" description="Allow fans to buy more than one">
        <Input
          type="number"
          name="maxPurchaseQuantity"
          validate={(value) => {
            if (value && (value as number) < 1) {
              return 'Cannot be less than 1';
            }
          }}
        />
      </SwitchWithLabel>
      <SwitchWithLabel
        label="Exclusive"
        name="isSecret"
        description="This ticket can only be purchased or claimed by those with the link"
      />
    </Stack>
  );
};

export const formatEventFields = (values: EventFieldsValues) => {
  const imageUpdated = typeof values.image === 'object';
  return {
    ...values,
    image: imageUpdated ? values.image : undefined,
    maxSupply: values.attendeeLimit,
    price: values.isPurchasable ? values.price.toString() : undefined,
    maxPurchaseQuantity: values.allowMultiBuy ? values.maxPurchaseQuantity : 1,
  };
};
