import { Box, Skeleton, Stack, StackProps } from '@chakra-ui/react';

interface LabeledNumberProps extends StackProps {
  isLoaded?: boolean;
  name: string;
  value: number | null | undefined;
}

export const LabeledNumber = ({ name, value, isLoaded = true, ...rest }: LabeledNumberProps) => (
  <Stack {...rest} spacing="0">
    <Box fontSize="sm" fontWeight="bold">
      {name}
    </Box>
    <Skeleton isLoaded={isLoaded} fontSize={{ base: 'xl', lg: '2xl' }}>
      {value?.toLocaleString() || '-'}
    </Skeleton>
  </Stack>
);
