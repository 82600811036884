import { gql } from '@apollo/client';
import { AspectRatio } from '@chakra-ui/react';
import { ROUTES } from 'routes';

import { LinkBox } from 'components/atoms/Link';
import { CardGrid, CardGridProps } from 'components/molecules/CardGrid';
import { ItemCard } from 'components/molecules/ItemCard';
import { OfferingCard } from 'components/molecules/OfferingCard';

import { CommunityOfferingGrid_community } from './__graphql__/CommunityOfferingGrid_community';

interface CommunityOfferingGridProps extends CardGridProps {
  community?: CommunityOfferingGrid_community | null;
  isLoaded?: boolean;
}

export const CommunityOfferingGrid = ({ community, isLoaded = true, ...rest }: CommunityOfferingGridProps) => {
  return (
    <CardGrid
      isLoaded={isLoaded && !!community}
      loadingCard={({ ...rest }) => <OfferingCard isLoaded={false} {...rest} />}
      emptyCard={({ ...rest }) => (
        <AspectRatio ratio={0.8}>
          <ItemCard isLoaded bg="chakra-border-color" {...rest} />
        </AspectRatio>
      )}
      empty={`No Drops released by ${community?.displayName}`}
      {...rest}
    >
      {community?.offerings.map((offering) => (
        <LinkBox
          key={offering.id}
          to={ROUTES.community.item.base.set({
            managedContractSlug: offering.slug,
            communitySlug: community?.slug,
          })}
        >
          <OfferingCard h="full" offering={offering} />
        </LinkBox>
      ))}
    </CardGrid>
  );
};

CommunityOfferingGrid.fragments = {
  community: gql`
    fragment CommunityOfferingGrid_community on Community {
      id
      slug
      displayName
      offerings {
        id
        slug
        ...OfferingCard_offering
      }
    }
    ${OfferingCard.fragments.offering}
  `,
};
