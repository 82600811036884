import { gql } from '@apollo/client';
import { Box, Card, CardProps, AspectRatio, Flex, Skeleton } from '@chakra-ui/react';
import { Ticket } from 'phosphor-react';
import { ROUTES } from 'routes';

import { ImageOrPlaceholder } from 'components/atoms/ImageOrPlaceholder';
import { LinkBox } from 'components/atoms/Link';
import { stripeIntegerToPrismaDecimal } from 'utils/currency';

import { getOfferingTypeName } from '../OfferingCard';
import { OrderItemSummary_offering } from './__graphql__/OrderItemSummary_offering';
import { OrderItemSummary_paymentIntent } from './__graphql__/OrderItemSummary_paymentIntent';

interface OrderItemSummaryProps extends CardProps {
  offering: OrderItemSummary_offering | null | undefined;
  paymentIntent?: OrderItemSummary_paymentIntent | null | undefined;
  children?: React.ReactNode;
}

export const OrderItemSummary = ({ offering, paymentIntent, children, ...rest }: OrderItemSummaryProps) => (
  <Card direction="row" size="sm" variant="unstyled" borderRadius="lg" alignItems="center" {...rest}>
    <LinkBox
      to={ROUTES.community.item.base.set({
        communitySlug: offering?.community?.slug,
        managedContractSlug: offering?.slug,
      })}
    >
      <AspectRatio ratio={1} w="20">
        <ImageOrPlaceholder
          icon={Ticket}
          borderRadius="lg"
          src={offering?.image}
          fit="cover"
          iconProps={{ boxSize: '8' }}
        />
      </AspectRatio>
    </LinkBox>
    <Box data-cy="detailRowValue" p="4" flexGrow="1">
      <Flex flexDirection="column" w="full" alignContent="space-evenly">
        {children && <Box>{children}</Box>}
        <Skeleton isLoaded={!!offering} w="full" as="b">
          {offering?.name || ''}
        </Skeleton>
        <Skeleton isLoaded={!!offering}>{getOfferingTypeName(offering)}</Skeleton>
        {paymentIntent && (
          <Skeleton isLoaded={!!offering} minW="10">
            {paymentIntent.currencySymbol}
            {stripeIntegerToPrismaDecimal(paymentIntent?.baseAmount)}
          </Skeleton>
        )}
      </Flex>
    </Box>
  </Card>
);

OrderItemSummary.fragments = {
  offering: gql`
    fragment OrderItemSummary_offering on Offering {
      id
      name
      slug
      image(variant: md)
      type
      price
      community {
        id
        displayName
        slug
      }
    }
  `,
  paymentIntent: gql`
    fragment OrderItemSummary_paymentIntent on StripePaymentIntentWithSecret {
      id
      baseAmount
      currencySymbol
    }
  `,
};
