'use client';

import { Container } from '@chakra-ui/react';
import { AuthContext } from 'providers/Auth';
import { CommunityContext } from 'providers/Community';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import { LoginForm } from 'components/forms/LoginForm';
import { PagePanel } from 'components/molecules/PagePanel';
import { CommunityHeader } from 'components/organisms/CommunityHeader';
import { CommunityWelcomeHeader } from 'components/organisms/CommunityWelcomeHeader';
import { useTrackPageView } from 'hooks/useTrackPageView';

interface CommunityLoginPageProps {
  then?: string;
}

const CommunityLoginPage = ({ then }: CommunityLoginPageProps) => {
  const { community } = useContext(CommunityContext);
  const { user } = useContext(AuthContext);
  const [sent, setSent] = useState(false);
  const navigate = useNavigate();
  const [params] = useSearchParams();

  useTrackPageView(
    'Login',
    {
      community_id: community?.id,
      community_slug: community?.slug,
      isWhiteLabel: community?.isWhiteLabel,
      isLive: community?.isLive,
      type: 'community',
    },
    !community,
  );

  useEffect(() => {
    if (user) {
      navigate(params.get('then') || ROUTES.community.base.set({ communitySlug: community?.slug }));
    }
  }, [params, user, navigate, community?.slug]);

  return (
    <PagePanel isNarrow>
      <CommunityHeader includeName={false} community={community} align="center" />
      <Container variant="form">
        <CommunityWelcomeHeader textAlign="center" community={community} sent={sent} />
        <LoginForm
          onSent={() => {
            setSent(true);
          }}
        />
      </Container>
    </PagePanel>
  );
};

export default CommunityLoginPage;
