import { gql, useQuery } from '@apollo/client';
import { AspectRatio, Heading } from '@chakra-ui/react';
import { ROUTES } from 'routes';

import { LinkBox } from 'components/atoms/Link';
import { CardGrid, CardGridProps } from 'components/molecules/CardGrid';
import { ItemCard } from 'components/molecules/ItemCard';
import { MembershipCard } from 'components/molecules/MembershipCard';

import { UserMembershipsGridQuery, UserMembershipsGridQueryVariables } from './__graphql__/UserMembershipsGridQuery';

interface UserMembershipsGridProps extends CardGridProps {
  username: string | null | undefined;
  empty?: React.ReactNode;
  showHeading?: boolean;
  showCreated?: boolean;
}

export const QUERY = gql`
  query UserMembershipsGridQuery($username: String!) {
    user(username: $username) {
      id
      created: communities(roles: [CREATOR]) {
        community {
          id
          slug
        }
        ...MembershipCard_membership
      }
      memberships: communities(roles: [MEMBER]) {
        community {
          id
          slug
        }
        ...MembershipCard_membership
      }
    }
  }
  ${MembershipCard.fragments.membership}
`;

export const UserMembershipsGrid = ({
  username,
  empty,
  showHeading = false,
  showCreated = false,
  ...rest
}: UserMembershipsGridProps) => {
  const { data, loading } = useQuery<UserMembershipsGridQuery, UserMembershipsGridQueryVariables>(QUERY, {
    variables: { username: username ?? '' },
    skip: !username,
  });

  let heading = 'Memberships';
  let memberships = data?.user?.memberships;

  if (showCreated && data?.user?.created.length) {
    heading = 'Founded';
    memberships = data.user.created;
  }

  return (
    <>
      {showHeading && <Heading variant="h4">{heading}</Heading>}
      <CardGrid
        isLoaded={!loading && !!data}
        loadingCard={({ ...rest }) => <MembershipCard isLoaded={false} {...rest} />}
        emptyCard={({ ...rest }) => (
          <AspectRatio ratio={0.8} opacity={0.8}>
            <ItemCard isLoaded bg="chakra-border-color" {...rest} />
          </AspectRatio>
        )}
        empty={empty}
        {...rest}
      >
        {memberships?.map((membership) => (
          <LinkBox
            key={membership.community.id}
            to={ROUTES.community.base.set({ communitySlug: membership.community.slug })}
          >
            <MembershipCard size="sm" membership={membership} />
          </LinkBox>
        ))}
      </CardGrid>
    </>
  );
};
