'use client';

import { Stack } from '@chakra-ui/react';
import { AuthContext } from 'providers/Auth';
import { CommunityProvider } from 'providers/Community';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { NotFoundPanel } from 'components/molecules/NotFoundPanel';

const CommunityDashboardLayout = ({ children }: { children: React.ReactNode }) => {
  const { communitySlug } = useParams();
  const { user } = useContext(AuthContext);

  return (
    <CommunityProvider communitySlug={communitySlug}>
      {({ community, communityLoading }) => {
        const canManage =
          ['CREATOR', 'MANAGER'].includes(community?.currentMembership?.role || '') || user?.isAdmin || user?.isStaff;
        if (!communityLoading && (!community || !canManage) && communitySlug) {
          return <NotFoundPanel />;
        }
        return (
          <>
            <Helmet>{community && <title>Managing {community.displayName} on Temple</title>}</Helmet>
            <Stack>{children}</Stack>
          </>
        );
      }}
    </CommunityProvider>
  );
};

export default CommunityDashboardLayout;
