'use client';

import { Flex } from '@chakra-ui/react';
import { CommunityProvider } from 'providers/Community';
import { useParams } from 'react-router-dom';

import { NotFoundPanel } from 'components/molecules/NotFoundPanel';
import { Footer } from 'components/organisms/Footer';
import { Navbar } from 'components/organisms/Navbar';

const CommunityLayout = ({ children }: { children: React.ReactNode }) => {
  const params = useParams();
  return (
    <CommunityProvider communitySlug={params.communitySlug}>
      {({ community, communityLoading }) => (
        <>
          <Flex
            direction="column"
            bgPosition="center"
            bgAttachment="fixed"
            bgImage={community?.tile ?? ''}
            w="full"
            flexGrow={1}
          >
            <Navbar />
            <Flex direction="column" alignItems="flex-start" flexGrow="1" mt={{ base: 32, xl: 10 }}>
              {!communityLoading && !community && params.communitySlug ? <NotFoundPanel /> : children}
            </Flex>
          </Flex>
          <Footer isWhiteLabel={community?.isWhiteLabel} />
        </>
      )}
    </CommunityProvider>
  );
};

export default CommunityLayout;
