'use client';

import { Heading, Stack } from '@chakra-ui/react';

import { ClaimLinkForm } from 'components/forms/ClaimLinkForm';

const ClaimLinkPage = () => {
  return (
    <Stack maxW="36rem" spacing={8}>
      <Heading variant="h2">Create invite link</Heading>
      <ClaimLinkForm />
    </Stack>
  );
};

export default ClaimLinkPage;
