import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Container, useToast } from '@chakra-ui/react';
import { Button, HStack, Stack } from '@chakra-ui/react';
import { Formik, FormikErrors } from 'formik';
import { CommunityContext } from 'providers/Community';
import { useContext } from 'react';

import { ErrorMessage } from 'components/atoms/ErrorMessage';
import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { MobileNumberField } from 'components/forms/LocationFields';
import { SwitchWithLabel } from 'components/molecules/SwitchWithLabel';
import { extractErrors } from 'utils/errors';

import {
  CommunityPreferencesFormMutation,
  CommunityPreferencesFormMutationVariables,
} from './__graphql__/CommunityPreferencesFormMutation';
import {
  UserCommunityPreferencesPageQuery,
  UserCommunityPreferencesPageQueryVariables,
} from './__graphql__/UserCommunityPreferencesPageQuery';

export const QUERY = gql`
  query UserCommunityPreferencesPageQuery($communityId: ID!, $slug: String!) {
    community(slug: $slug) {
      id
      displayName
    }
    me {
      id
      country
      city
      dial
      mobileNumber
      communityContactPreferences(communityId: $communityId) {
        shouldWhatsAppUpdates
        shouldSMSUpdates
        shouldEmailUpdates
      }
    }
    countries {
      iso3
      iso2
      name
      dial
    }
    geoIpCountry {
      iso3
      dial
    }
  }
`;

export const COMMUNITY_PREFERENCES_FORM_MUTATION = gql`
  mutation CommunityPreferencesFormMutation(
    $communityId: ID!
    $shouldWhatsAppUpdates: Boolean!
    $shouldSMSUpdates: Boolean!
    $shouldEmailUpdates: Boolean!
    $dial: String
    $mobileNumber: String
  ) {
    updateUserCommunityContactPreferences(
      communityId: $communityId
      shouldWhatsAppUpdates: $shouldWhatsAppUpdates
      shouldSMSUpdates: $shouldSMSUpdates
      shouldEmailUpdates: $shouldEmailUpdates
      dial: $dial
      mobileNumber: $mobileNumber
    ) {
      shouldWhatsAppUpdates
      shouldSMSUpdates
      shouldEmailUpdates
    }
  }
`;

export const CommunityPreferencesForm = () => {
  const { community } = useContext(CommunityContext);

  const client = useApolloClient();
  const toast = useToast();

  const { data, loading, error } = useQuery<
    UserCommunityPreferencesPageQuery,
    UserCommunityPreferencesPageQueryVariables
  >(QUERY, {
    variables: { communityId: community?.id ?? '', slug: community?.slug ?? '' },
    skip: !community?.id || !community.slug,
  });
  const [updateUserCommunityContactPreferences] = useMutation<
    CommunityPreferencesFormMutation,
    CommunityPreferencesFormMutationVariables
  >(COMMUNITY_PREFERENCES_FORM_MUTATION);

  if (error) {
    return <ErrorMessage />;
  }

  if (loading) {
    return <WrappedSpinner />;
  }

  const preferences = data?.me?.communityContactPreferences ? data?.me?.communityContactPreferences[0] : undefined;

  return (
    <Container variant="form" padding="0 !important">
      <Formik
        initialValues={{
          shouldWhatsAppUpdates: preferences?.shouldWhatsAppUpdates,
          shouldSMSUpdates: preferences?.shouldSMSUpdates,
          shouldEmailUpdates: preferences?.shouldEmailUpdates,
          country: data?.me?.country || data?.geoIpCountry?.iso3 || 'USA',
          city: data?.me?.city || '',
          dial: data?.me?.dial || data?.geoIpCountry?.dial || '1',
          mobileNumber: data?.me?.mobileNumber || '',
        }}
        validate={(values) => {
          const errors: FormikErrors<typeof values> = {};
          if (values.shouldWhatsAppUpdates || values.shouldSMSUpdates) {
            if (!values.dial) {
              errors.dial = 'A country code is required';
            }
            if (!values.mobileNumber) {
              errors.mobileNumber = 'A mobile number is required';
            }
          }
          return errors;
        }}
        onSubmit={async (values, { setErrors }) => {
          try {
            await updateUserCommunityContactPreferences({
              variables: {
                communityId: community?.id || '',
                ...values,
                shouldWhatsAppUpdates: !!values.shouldWhatsAppUpdates,
                shouldSMSUpdates: !!values.shouldSMSUpdates,
                shouldEmailUpdates: !!values.shouldEmailUpdates,
                mobileNumber: values.mobileNumber.replace('-', '').replace(' ', ''),
              },
            });
            toast({ status: 'success', description: 'Your preferences have been saved' });
            await client.resetStore();
          } catch (error) {
            extractErrors(setErrors)(error);
          }
        }}
      >
        {({ handleSubmit, isSubmitting, values: { shouldWhatsAppUpdates, shouldSMSUpdates, country } }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Stack spacing="8">
                <Stack spacing="2">
                  <SwitchWithLabel label="Email" name="shouldEmailUpdates" hasSurroundingBox />
                  <SwitchWithLabel label="SMS" name="shouldSMSUpdates" hasSurroundingBox />
                  <SwitchWithLabel label="WhatsApp" name="shouldWhatsAppUpdates" hasSurroundingBox />
                  {!loading &&
                    (!data?.me?.dial || !data?.me?.mobileNumber) &&
                    (shouldWhatsAppUpdates || shouldSMSUpdates) && (
                      <MobileNumberField
                        label="We need a mobile number to send you updates."
                        countries={data?.countries}
                        country={country}
                        dial={data?.me?.dial || ''}
                        mobileNumber={data?.me?.mobileNumber || ''}
                        setFieldValue={undefined}
                        isRequired
                      />
                    )}
                </Stack>
                <HStack justifyContent="flex-end">
                  <Button isLoading={isSubmitting} type="submit" variant="primary">
                    Save
                  </Button>
                </HStack>
              </Stack>
            </form>
          );
        }}
      </Formik>
    </Container>
  );
};
