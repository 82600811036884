import './error.css';

export const ErrorFallback = ({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary: () => void }) => {
  if (process.env.NODE_ENV === 'development') {
    console.error(error);
  }
  return (
    <div className="page">
      <div className="container">
        <div className="img-container">
          <img src="/img/logo.png" alt="Temple logo" className="img" />
        </div>
        <p className="title">Something went wrong</p>
        <button className="btn" onClick={resetErrorBoundary}>
          Back to site
        </button>
      </div>
    </div>
  );
};
