import { gql } from '@apollo/client';
import { Box, BoxProps, Stack } from '@chakra-ui/react';
import { Image } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes';

import { LinkBox } from 'components/atoms/Link';
import { DashboardCard } from 'components/molecules/DashboardCard';

import { DashboardCollectibleListItem_offering } from './__graphql__/DashboardCollectibleListItem_offering';

export const DashboardCollectibleListItem = ({
  collection,
}: {
  collection: DashboardCollectibleListItem_offering;
} & BoxProps) => {
  const { t } = useTranslation('common');
  return (
    <LinkBox
      to={ROUTES.dashboard.community.itemsId.set({ communitySlug: collection.community.slug, id: collection.id })}
      key={collection.id}
    >
      <DashboardCard label={t('token')} title={collection.name} icon={Image} image={collection.image}>
        <Stack>
          <Box fontWeight="bold">
            {collection.maxSupply && collection.maxSupply > 0 ? `Edition of ${collection.maxSupply}` : 'Unlimited'}
          </Box>
          {collection.description && <Box noOfLines={2}>{collection.description}</Box>}
        </Stack>
      </DashboardCard>
    </LinkBox>
  );
};

DashboardCollectibleListItem.fragments = {
  offerings: gql`
    fragment DashboardCollectibleListItem_offering on Offering {
      id
      name
      description
      maxSupply
      image(variant: md)
      slug
      community {
        id
        slug
      }
    }
  `,
};
