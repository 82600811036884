import { StackProps, Stack, Box } from '@chakra-ui/react';

import { Footer, FooterProps } from 'components/organisms/Footer';
import { Navbar, NavbarProps } from 'components/organisms/Navbar';

export interface BaseLayoutProps extends StackProps {
  display?: 'flex' | 'block';
  navbarProps?: NavbarProps;
  footerProps?: FooterProps;
  includeNavbar?: boolean;
  includeFooter?: boolean;
}

export const BaseLayout = ({
  children,
  display = 'block',
  navbarProps,
  footerProps,
  includeNavbar = true,
  includeFooter = true,
  ...rest
}: BaseLayoutProps) => (
  <Stack minH="100vh" w="full" alignItems="center" spacing="0">
    {includeNavbar && <Navbar {...navbarProps} />}
    <Box w="full" h="full" flexGrow="1" display={display} py="10" {...rest}>
      {children}
    </Box>
    {includeFooter && <Footer {...footerProps} />}
  </Stack>
);
