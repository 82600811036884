import { Alert, AlertDescription, AlertIcon, Button, Stack } from '@chakra-ui/react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeSetupIntentProvider } from 'providers/StripeSetupIntent';
import { useState } from 'react';

import { WrappedSpinner } from 'components/atoms/WrappedSpinner';

const NewCardFormInner = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isRendered, setIsRendered] = useState(false);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setIsLoading(false);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack spacing="4">
        {!isRendered && <WrappedSpinner />}
        {errorMessage && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        <PaymentElement
          onReady={() => {
            setIsRendered(true);
          }}
        />
        <Button isDisabled={!stripe || !elements || !isRendered} isLoading={isLoading} variant="primary" type="submit">
          Continue
        </Button>
      </Stack>
    </form>
  );
};

export const NewCardForm = () => (
  <StripeSetupIntentProvider>
    <NewCardFormInner />
  </StripeSetupIntentProvider>
);
