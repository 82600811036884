import { gql, useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Heading,
  Stack,
  Text,
  Container,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { toast } from 'App';
import { AuthContext } from 'providers/Auth';
import { useContext, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';

import { useTrackPageView } from 'hooks/useTrackPageView';
import { showError } from 'utils/errors';

import {
  DeleteUserSpotifyConnectionMutation,
  DeleteUserSpotifyConnectionMutationVariables,
} from './__graphql__/DeleteUserSpotifyConnectionMutation';
import { MySpotifySettingsPageQuery } from './__graphql__/MySpotifySettingsPageQuery';
import {
  RemoveUserSpotifyConnectionMutation,
  RemoveUserSpotifyConnectionMutationVariables,
} from './__graphql__/RemoveUserSpotifyConnectionMutation';

const QUERY = gql`
  query MySpotifySettingsPageQuery {
    me {
      id
      spotifyConnection {
        id
        isActive
      }
    }
  }
`;

type LocationState = {
  from?: string;
};

export const REMOVE_MUTATION = gql`
  mutation RemoveUserSpotifyConnectionMutation($userId: ID!) {
    removeUserSpotifyConnection(userId: $userId) {
      id
      isActive
    }
  }
`;
export const DELETE_MUTATION = gql`
  mutation DeleteUserSpotifyConnectionMutation($userId: String!) {
    deleteUserSpotifyConnection(userId: $userId)
  }
`;

const MySpotifySettingsPage = () => {
  useTrackPageView('Spotify Settings');

  const { data, loading, error } = useQuery<MySpotifySettingsPageQuery>(QUERY);
  const { user } = useContext(AuthContext);

  // Last location
  const location = useLocation();
  const navigate = useNavigate();
  const previousPath = (location.state as LocationState)?.from ?? ROUTES.you.settings.path;

  // For the AlertDialog
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (!loading && !error && !data?.me?.spotifyConnection?.isActive) {
      showError('No Spotify connection found.');
      navigate(previousPath);
    }
  }, [data?.me, navigate, previousPath, loading, error]);

  const [removeUserSpotifyConnection, { loading: disconnectLoading }] = useMutation<
    RemoveUserSpotifyConnectionMutation,
    RemoveUserSpotifyConnectionMutationVariables
  >(REMOVE_MUTATION);
  const [deleteUserSpotifyConnection, { loading: deleteLoading }] = useMutation<
    DeleteUserSpotifyConnectionMutation,
    DeleteUserSpotifyConnectionMutationVariables
  >(DELETE_MUTATION);

  return (
    <Container as={Stack} spacing="6" variant="form" ml="0" px="0 !important">
      <Helmet>
        <title>Spotify Settings</title>
      </Helmet>
      <Stack>
        <Heading as="h1">Manage Spotify</Heading>
        <Text>Manage your connection to Spotify here.</Text>
      </Stack>
      {/* <Heading variant="h4">Data</Heading>
      <SpotifyPanel /> */}
      <Stack spacing="1" alignItems="flex-start">
        <Button
          variant="link"
          color="sizzurp"
          fontWeight="normal"
          m="0"
          px="0 !important"
          isLoading={disconnectLoading}
          isDisabled={deleteLoading || disconnectLoading}
          onClick={async () => {
            try {
              if (!user?.id) return;
              await removeUserSpotifyConnection({
                variables: {
                  userId: user.id,
                },
              });
              toast({
                status: 'success',
                description: 'Successfully removed the connection',
              });
              navigate(previousPath);
            } catch (e) {
              toast({
                status: 'error',
                description: 'Could not remove the connection',
              });
            }
          }}
        >
          Disconnect
        </Button>
        <Button
          variant="link"
          colorScheme="red"
          fontWeight="normal"
          px="0 !important"
          isDisabled={deleteLoading || disconnectLoading}
          onClick={onOpen}
        >
          Disconnect Spotify and delete my data
        </Button>

        <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Remove Spotify Data
              </AlertDialogHeader>

              <AlertDialogBody>
                Are you sure to delete all your Spotify data from Temple? You may lose access to certain rewards.
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose} isDisabled={deleteLoading}>
                  Cancel
                </Button>
                <Button
                  colorScheme="red"
                  ml={3}
                  isDisabled={deleteLoading}
                  isLoading={deleteLoading}
                  onClick={async () => {
                    try {
                      if (!user?.id) return;
                      await deleteUserSpotifyConnection({
                        variables: {
                          userId: user.id,
                        },
                      });
                      toast({
                        status: 'success',
                        description: 'Successfully deleted the connection',
                      });
                      navigate(previousPath);
                    } catch (e) {
                      showError('Could not delete the connection');
                    }
                  }}
                >
                  Remove Spotify data
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Stack>
    </Container>
  );
};

export default MySpotifySettingsPage;
