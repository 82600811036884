import { gql, useMutation } from '@apollo/client';
import { Button, Container, Heading, InputLeftAddon, Stack, Textarea } from '@chakra-ui/react';
import { Form, Formik, FormikErrors } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import { Input } from 'components/molecules/Input';
import { extractErrors, showError } from 'utils/errors';

import { AdminCommunityFormQuery, AdminCommunityFormQueryVariables } from './__graphql__/AdminCommunityFormQuery';

const MUTATION = gql`
  mutation AdminCommunityFormQuery($displayName: String, $slug: String!, $description: String, $username: String!) {
    adminCreateCommunity(displayName: $displayName, slug: $slug, description: $description, username: $username) {
      id
      slug
    }
  }
`;

export const AdminCommunityForm = () => {
  const [params] = useSearchParams();
  const username = params.get('username') ?? '';
  const [mutation] = useMutation<AdminCommunityFormQuery, AdminCommunityFormQueryVariables>(MUTATION);
  const navigate = useNavigate();

  return (
    <Formik
      initialValues={{ displayName: '', slug: '', description: '', username }}
      validate={(values) => {
        const errors: FormikErrors<typeof values> = {};
        if (!values.displayName) {
          errors.displayName = 'Required field';
        }
        if (!values.slug) {
          errors.slug = 'Required field';
        }
        if (!values.username) {
          errors.username = 'Required field';
        }
        return errors;
      }}
      onSubmit={async (variables, { setSubmitting, setErrors }) => {
        try {
          const result = await mutation({ variables });
          if (result.data?.adminCreateCommunity) {
            navigate(
              ROUTES.dashboard.community.settings.set({ communitySlug: result.data?.adminCreateCommunity?.slug }),
              { replace: true },
            );
          } else {
            showError(`User not found: @${variables.username}`);
          }
        } catch (error) {
          extractErrors(setErrors)(error);
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Stack as={Container} variant="form" m="0" spacing="4">
            <Heading>New Community</Heading>
            <Input
              name="username"
              label="Owner account username"
              leftChildren={<InputLeftAddon pointerEvents="none" children="@" />}
              isRequired
            />
            <Input name="displayName" label="Community display name" isRequired />
            <Input
              name="slug"
              label="Community URL"
              leftChildren={<InputLeftAddon pointerEvents="none" children="https://temple.fans/" />}
              isRequired
            />
            <Input name="description" label="Community details" as={Textarea} />
            <Button type="submit" variant="primary" isLoading={isSubmitting}>
              Submit
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
