import { gql, useMutation, useQuery } from '@apollo/client';
import { Flex, HStack, Heading, Stack, Box, Button, Spacer, useBreakpointValue, Icon, Image } from '@chakra-ui/react';
import { toast } from 'App';
import { Formik, FieldArray } from 'formik';
import { Question } from 'phosphor-react';
import { CommunityContext } from 'providers/Community';
import { useContext } from 'react';

import { Paragraph } from 'components/atoms/Paragraph';
import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { Input } from 'components/molecules/Input';
import { extractErrors } from 'utils/errors';
import { regexUrl } from 'utils/regex';

import { blocksMutation, blocksMutationVariables } from './__graphql__/blocksMutation';
import { blocksQuery, blocksQueryVariables } from './__graphql__/blocksQuery';

const QUERY = gql`
  query blocksQuery($communityId: ID!) {
    blocks(communityId: $communityId) {
      title
      url
      order
    }
  }
`;
const MUTATION = gql`
  mutation blocksMutation($communityId: ID!, $blocks: [BlockInput!], $groups: [GroupInput!]) {
    updateBlocksAndGroups(communityId: $communityId, blocks: $blocks, groups: $groups) {
      blocks {
        title
        url
        order
      }
      groups {
        name
        order
      }
    }
  }
`;

type Block = {
  title: string;
  url: string;
  order: number;
};

const CommunityBlocksPage = () => {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const { community } = useContext(CommunityContext);
  const { data, loading, refetch } = useQuery<blocksQuery, blocksQueryVariables>(QUERY, {
    variables: { communityId: community?.id ?? '' },
    skip: !community?.slug,
  });

  const [updateBlocks] = useMutation<blocksMutation, blocksMutationVariables>(MUTATION);

  if (loading) return <WrappedSpinner />;
  return (
    <Stack maxW="36rem" spacing={8}>
      <Heading variant="h2">What is a Block?</Heading>
      <Stack gap={4} alignItems="flex-start">
        <HStack alignItems="stretch" gap={4}>
          <Box>
            <Question size={25} style={{ color: 'offGray' }} />
          </Box>
          <Paragraph>
            You can use blocks to display your music or videos, just add the share URL from Bandcamp, Spotify,
            SoundCloud, Vimeo or YouTube. You can add up to 10 blocks.
          </Paragraph>
        </HStack>
      </Stack>
      <Formik
        initialValues={{
          blocks: data?.blocks || [],
        }}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          try {
            if (!community?.id) return;

            const blocks = values.blocks
              .map((block, index) => block.url && { title: block.title, url: block.url, order: index })
              .filter((a) => a) as Block[];
            const result = await updateBlocks({
              variables: {
                communityId: community?.id,
                blocks: blocks,
              },
            });

            if (result) {
              toast({
                status: 'success',
                description: 'Blocks added',
              });
              await refetch();
            }
          } catch (e) {
            extractErrors(setErrors)(e);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, values, submitForm, isSubmitting, resetForm, errors, touched, dirty }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldArray
                name="blocks"
                render={(arrayHelpers) => {
                  return (
                    <Flex direction="column" flex="1 0" mt={0}>
                      {isMobile && (
                        <HStack spacing={4} mb={12}>
                          <Button
                            type="button"
                            isDisabled={values.blocks.length >= 10}
                            onClick={() => arrayHelpers.push('')}
                          >
                            Add a block
                          </Button>
                          {/* TODO: add grouping */}
                          <Button type="button" isLoading={isSubmitting} isDisabled>
                            Group blocks
                          </Button>
                        </HStack>
                      )}
                      {values.blocks.map((block: Block, index: number) => (
                        <Stack key={index} direction="column" spacing={4} mb={5}>
                          <HStack justifyContent={'space-between'} width="full">
                            <HStack>
                              {/* TODO: add reordering */}
                              <Icon as={Image} boxSize={'24px'} src={`${window.location.origin}/icons/reorder.svg`} />
                              <Heading variant="h4">{`${block.title ? block.title : `Block ${index + 1}`}`}</Heading>
                            </HStack>
                            <Box>
                              <Button
                                variant="unstyled"
                                transform={
                                  errors.blocks && errors.blocks[index] && touched?.blocks && touched.blocks[index]
                                    ? `translate(0, -0.8rem)`
                                    : ''
                                }
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                Remove
                              </Button>
                            </Box>
                          </HStack>
                          <Stack width="full">
                            <Input
                              name={`blocks.${index}.title`}
                              placeholder="Title"
                              isRequired={true}
                              validate={(value: string) => {
                                if (!value) return 'Title is Required';
                              }}
                            />
                            <Input
                              name={`blocks.${index}.url`}
                              placeholder="Paste your URL here"
                              validate={(value: string) => {
                                if (!value) return 'URL is Required';
                                if (value && !regexUrl(value)) {
                                  return 'Not a valid url';
                                }
                              }}
                            />
                          </Stack>
                        </Stack>
                      ))}
                      <HStack justifyContent="space-between">
                        {!isMobile && (
                          <HStack spacing={4}>
                            <Button
                              type="button"
                              isDisabled={values.blocks.length >= 10}
                              onClick={() => arrayHelpers.push('')}
                            >
                              Add a block
                            </Button>
                            {/* TODO: add grouping */}
                            <Button type="button" isLoading={isSubmitting} isDisabled>
                              Group blocks
                            </Button>
                          </HStack>
                        )}
                        <Spacer />
                        <Button
                          isDisabled={!dirty}
                          onClick={async () => {
                            await refetch();
                            resetForm({
                              values: {
                                blocks: data?.blocks || [],
                              },
                            });
                          }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          onClick={submitForm}
                          isDisabled={!dirty}
                          isLoading={isSubmitting}
                          variant="secondary"
                        >
                          Save
                        </Button>
                      </HStack>
                    </Flex>
                  );
                }}
              />
            </form>
          );
        }}
      </Formik>
    </Stack>
  );
};

export default CommunityBlocksPage;
