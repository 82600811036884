import { gql, useMutation } from '@apollo/client';
import { Divider, useToast } from '@chakra-ui/react';
import { Heading, Flex, Text } from '@chakra-ui/react';
import { CommunityContext } from 'providers/Community';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';

import { CommunityPreferencesForm } from 'components/forms/CommunityPreferencesForm';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { PagePanel } from 'components/molecules/PagePanel';
import { CommunityHeader } from 'components/organisms/CommunityHeader';
import { useTrackPageView } from 'hooks/useTrackPageView';
import { showError } from 'utils/errors';

import { LeaveCommunityMutation } from './__graphql__/LeaveCommunityMutation';

export const LEAVE_COMMUNITY_MUTATION = gql`
  mutation LeaveCommunityMutation($communityId: ID!) {
    leaveCommunity(communityId: $communityId)
  }
`;

const UserCommunityPreferencesPage = () => {
  const { community } = useContext(CommunityContext);

  const toast = useToast();
  const navigate = useNavigate();

  const [leaveCommunity] = useMutation<LeaveCommunityMutation>(LEAVE_COMMUNITY_MUTATION);

  useTrackPageView(
    'Community Preferences',
    {
      community_id: community?.id,
      community_slug: community?.slug,
      isWhiteLabel: community?.isWhiteLabel,
    },
    !community,
  );

  return (
    <PagePanel isNarrow spacing={8} mb={12}>
      <CommunityHeader includeName={false} community={community} align="center" />
      <Heading variant="h3">Settings</Heading>
      <Flex direction="column" alignItems="flex-start">
        <Text variant="subtitle" fontWeight="bold" mb={2}>
          Communication preferences
        </Text>
        <Text variant="helper" textAlign="start" mb={4}>
          Choose how you receive updates, content and news from {community?.displayName}.
        </Text>
        <CommunityPreferencesForm />
        <Divider my={8} />
        <Text variant="subtitle" fontWeight="bold" mb={2}>
          Danger Zone
        </Text>
        <Text variant="helper" textAlign="start" mb={4}>
          By leaving {community?.displayName}, you will lose access to certain rewards.
        </Text>
        <Flex flex="1 0" w="100%" justifyContent="flex-end" mt={4}>
          <DeleteModal
            title="Leave Community"
            body="Are you sure you wish to leave this community? You may lose access to certain rewards."
            cta="Leave Community"
            showDeleteBtn={true}
            onDelete={async () => {
              try {
                if (!community?.id) return;
                await leaveCommunity({
                  variables: {
                    communityId: community.id,
                  },
                });
                toast({
                  status: 'success',
                  description: 'Successfully left community',
                });
                navigate(ROUTES.you.memberships.path);
              } catch (e) {
                showError('Could not leave community');
              }
            }}
          />
        </Flex>
      </Flex>
    </PagePanel>
  );
};

export default UserCommunityPreferencesPage;
