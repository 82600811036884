import { gql, useMutation, useQuery } from '@apollo/client';
import { Stack, Button, Box, Heading, FormLabel, HStack, FormControl, FormHelperText, Flex } from '@chakra-ui/react';
import { toast } from 'App';
import { FieldArray, Formik, FormikErrors } from 'formik';
import { useEffect } from 'react';

import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { Input } from 'components/molecules/Input';
import { Select, SelectProps } from 'components/molecules/Select';
import { extractErrors } from 'utils/errors';

import { CityQuery } from './__graphql__/CityQuery';
import { CountryFormMutation, CountryFormMutationVariables } from './__graphql__/CountryFormMutation';
import { CountryFormQuery } from './__graphql__/CountryFormQuery';
import { CountryQuery, CountryQuery_countries } from './__graphql__/CountryQuery';

const getFlagEmoji = (countryCode: string) => {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
};

export const COUNTRY_FORM_USER_FRAGMENT = gql`
  fragment CountryFormQuery_user on User {
    id
    country
    city
    dial
    mobileNumber
  }
`;

export const COUNTRY_FORM_QUERY = gql`
  query CountryFormQuery {
    me {
      id
      ...CountryFormQuery_user
    }
  }
  ${COUNTRY_FORM_USER_FRAGMENT}
`;

export const COUNTRY_FORM_MUTATION = gql`
  mutation CountryFormMutation($country: String!, $city: String!, $mobileNumber: String!, $dial: String!) {
    updateUser(country: $country, city: $city, mobileNumber: $mobileNumber, dial: $dial) {
      ...CountryFormQuery_user
    }
  }
  ${COUNTRY_FORM_USER_FRAGMENT}
`;

export const COUNTRY_QUERY = gql`
  query CountryQuery {
    countries {
      iso3
      iso2
      name
      dial
    }
  }
`;

export const CITY_QUERY = gql`
  query CityQuery($country: String!) {
    cities(country: $country) {
      id
      name
      country {
        iso3
      }
    }
  }
`;

export const MobileNumberField = ({
  countries,
  country,
  dial,
  mobileNumber,
  label,
  setFieldValue,
  isRequired,
  helpText,
}: {
  countries: CountryQuery_countries[] | null | undefined;
  country: string;
  dial: string;
  mobileNumber: string;
  label?: string;
  setFieldValue?: (field: string, value: string | undefined) => void;
  isRequired?: boolean;
  helpText?: string;
}) => {
  useEffect(() => {
    if (countries && !dial && setFieldValue) {
      const d = countries.find((c) => c.iso3 === country);
      setFieldValue('dial', d?.dial);
    }
  }, [countries, country, setFieldValue, dial]);
  const t: { [value: string]: boolean } = {};
  return (
    <FormControl isRequired={isRequired}>
      <FormLabel fontSize="sm" fontWeight="bold">
        {label}
      </FormLabel>
      <HStack spacing="2" alignItems="flex-start">
        <Box w="180px">
          <Select
            id="dial"
            name="dial"
            placeholder="Prefix"
            defaultValue={dial || ''}
            options={
              countries
                ?.map((c) => {
                  const sanitised = c.dial.split('-')[0];
                  let code = c.iso2;
                  if (sanitised === '44') {
                    code = 'gb';
                  } else if (sanitised === '1') {
                    code = 'us';
                  }
                  return { value: sanitised, label: `${getFlagEmoji(code)} +${sanitised}` };
                })
                .filter((c) => !(t[c.value] = c.value in t))
                .sort((a, b) => parseInt(a.value) - parseInt(b.value)) || []
            }
          />
        </Box>
        <Input
          name="mobileNumber"
          type="tel"
          placeholder={dial === '44' ? 'e.g. 07700 900 000' : 'e.g. 555-555-5555'}
          defaultValue={mobileNumber}
          autoComplete="tel-national"
          validate={(value: string) => {
            if (value && !/^\d+$/.test(value.trim())) {
              return 'Please enter a valid phone number.';
            }
          }}
        />
      </HStack>
      {helpText && <FormHelperText>{helpText}</FormHelperText>}
    </FormControl>
  );
};

interface CityFieldProps extends Pick<SelectProps, 'label' | 'isMulti'> {
  countryIso3?: string;
  defaultCity?: string;
  name?: string;
}

export const CityField = ({ name, countryIso3, label, isMulti, defaultCity }: CityFieldProps) => {
  const { data } = useQuery<CityQuery>(CITY_QUERY, {
    variables: { country: countryIso3 },
    skip: !countryIso3,
  });
  return (
    <Select
      name={name || 'city'}
      label={label || 'Closest city'}
      placeholder=""
      isDisabled={!countryIso3}
      isMulti={isMulti}
      options={
        data?.cities
          ?.map((c) => {
            return { value: c.id, label: c.name };
          })
          .sort((a, b) => a.label.localeCompare(b.label)) || []
      }
      defaultValue={defaultCity || ''}
    />
  );
};

interface CountryFieldProps extends Pick<SelectProps, 'label' | 'isMulti'> {
  countries: CountryQuery_countries[] | null | undefined;
  defaultCountry?: string;
  name?: string;
}

export const CountryField = ({ name, defaultCountry, countries, label, isMulti }: CountryFieldProps) => {
  return (
    <Select
      name={name || 'country'}
      placeholder=""
      label={label}
      defaultValue={defaultCountry || ''}
      isMulti={isMulti}
      options={
        countries
          ?.map((c) => {
            return { value: c.iso3, label: c.name };
          })
          .sort((a, b) => a.label.localeCompare(b.label)) || []
      }
    />
  );
};

// TODO: Remove this
export const CountryForm = () => {
  const { data: meData, loading: meLoading } = useQuery<CountryFormQuery>(COUNTRY_FORM_QUERY);
  const { data, loading } = useQuery<CountryQuery>(COUNTRY_QUERY);
  const [updateUser] = useMutation<CountryFormMutation, CountryFormMutationVariables>(COUNTRY_FORM_MUTATION);

  if (meLoading || loading) {
    return <WrappedSpinner />;
  }

  if (!data) {
    return <Box>Something went wrong</Box>;
  }

  return (
    <Formik
      initialValues={{
        country: meData?.me?.country || '',
        city: meData?.me?.city || '',
        dial: meData?.me?.dial || '',
        mobileNumber: meData?.me?.mobileNumber || '',
      }}
      validate={(values) => {
        const errors: FormikErrors<typeof values> = {};
        if (!values.country) {
          errors.country = 'A country is required';
        }
        if (!values.city) {
          errors.city = 'A city is required';
        }
        if (!values.dial) {
          errors.dial = 'A country code is required';
        }
        if (!values.mobileNumber) {
          errors.mobileNumber = 'A mobile number is required';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        const variables: CountryFormMutationVariables = { ...values };
        updateUser({ variables })
          .then(() => {
            toast({ status: 'success', description: 'Profile saved' });
          })
          .catch(extractErrors(setErrors))
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({ handleSubmit, isSubmitting, errors, setFieldValue, values: { country } }) => {
        return (
          <form onSubmit={handleSubmit} noValidate>
            <Stack spacing="12">
              <Stack spacing="4">
                <Heading variant="h4">Account set-up</Heading>
                <CountryField defaultCountry={country} countries={data.countries} label="Where are you in the world?" />
                <CityField
                  countryIso3={data.countries?.find((c) => c.iso3 === country)?.iso3}
                  defaultCity={meData?.me?.city || ''}
                />
                <MobileNumberField
                  label={'Mobile Number'}
                  countries={data.countries}
                  country={country}
                  dial={meData?.me?.dial || ''}
                  mobileNumber={meData?.me?.mobileNumber || ''}
                  setFieldValue={setFieldValue}
                />
              </Stack>

              <Button isLoading={isSubmitting} type="submit" variant="primary">
                Save
              </Button>
            </Stack>
          </form>
        );
      }}
    </Formik>
  );
};

interface LocationFieldProps extends Omit<CountryFieldProps, 'countries'>, CityFieldProps {
  labelCountry?: string;
  labelCity?: string;
  multiCountriesIso3?: string[];
  displayCity?: boolean;
  displayCountry?: boolean;
}

export const LocationField = ({
  defaultCountry,
  defaultCity,
  labelCountry,
  labelCity,
  isMulti,
  displayCity,
  displayCountry,
  countryIso3, // current country
  multiCountriesIso3, // multi selection of current countries
}: LocationFieldProps) => {
  const { data } = useQuery<CountryQuery>(COUNTRY_QUERY);
  if (!data) return null;

  if (!isMulti)
    return (
      <>
        {displayCountry && (
          <CountryField
            name="countries"
            defaultCountry={defaultCountry}
            countries={data.countries}
            label={labelCountry}
          />
        )}
        {displayCountry && displayCity && (
          <CityField
            name="cities"
            label={labelCity}
            countryIso3={data.countries?.find((c) => c.iso3 === countryIso3)?.iso3}
            defaultCity={defaultCity}
          />
        )}
      </>
    );

  return (
    <>
      {displayCountry && <CountryField isMulti name="countries" label={'Countries'} countries={data?.countries} />}
      {multiCountriesIso3 && displayCountry && displayCity && (
        <Flex flex="1 0" mt={2}>
          <FieldArray
            name="cities"
            render={() => {
              return (
                <Flex flex="1 0" direction="column">
                  {multiCountriesIso3?.map((cIso3, index) => (
                    <Flex key={cIso3} my={2}>
                      <CityField
                        isMulti
                        name={`cities.${index}`}
                        countryIso3={cIso3}
                        label={data?.countries?.find((country) => country.iso3 === cIso3)?.name}
                      />
                    </Flex>
                  ))}
                </Flex>
              );
            }}
          />
        </Flex>
      )}
    </>
  );
};
