export interface slugs {
  id: string | undefined; // offeringId
  managedContractSlug: string | undefined;
  communitySlug: string | undefined;
  key: string | undefined;
  username: string | undefined;
  campaignId: string | undefined;
  audienceId: string | undefined;
  messageId: string | undefined;
}
export interface extensions {
  then: string;
  search: string;
}

export type params = Partial<slugs & extensions>;

type setParams<S extends keyof slugs, E extends keyof extensions> = Required<Pick<slugs, S>> &
  Partial<Required<Pick<extensions, E>>>;

const setter = (route: string, params?: params) => {
  let r = route;
  if (params) {
    for (let key in params) {
      let k = key as keyof typeof params;
      let v = params[k];
      r = v ? r.replace(`:${key}`, v) : r;
      // TODO - this is a hack to remove the slug from the path if it's undefined
      // .split('/')
      // .filter((i) => i !== `:${key}`)
      // .join('/');
    }
  }
  r = params?.then ? `${r}?then=${params.then}` : r;
  r = params?.search ? `${r}${params.search}` : r;
  return r;
};

// T is generic to specify which parameters(slugs) are acceptable for this route
const routeSet =
  <T extends keyof slugs | undefined, E extends keyof extensions>(route: string) =>
  (params: setParams<Exclude<T, undefined>, E>) =>
    setter(route, params);

// This adds a /:community slug before the path -> should disappear when we use subdomains
// For routes that have both a white-label and a non-white label versions
const whiteLabelRouteSet =
  <T extends keyof slugs | 'isWhiteLabel', E extends keyof extensions>(route: string) =>
  ({
    isWhiteLabel,
    ...params
  }: setParams<Exclude<T, 'isWhiteLabel' | 'communitySlug'>, E> & {
    isWhiteLabel: boolean | undefined;
    communitySlug: string | undefined;
  }) => {
    let wlRoute = isWhiteLabel && params.communitySlug ? addWhiteLabelSlug(route) : route;
    return setter(wlRoute, params);
  };

export const addWhiteLabelSlug = (route: string) => `/:communitySlug${route}`;

export const ROUTES = {
  landing: {
    path: '/',
    set: routeSet<undefined, 'then'>('/'),
  },
  learn: {
    path: '/learn/*',
    set: routeSet('/learn/*'),
  },
  unsubscribe: {
    path: '/unsubscribe',
    set: routeSet('/unsubscribe'),
  },
  waitlist: {
    path: '/waitlist',
  },
  legal: {
    privacy: {
      path: '/terms/privacy',
      set: whiteLabelRouteSet<'isWhiteLabel' | 'communitySlug', 'then'>('/legal/privacy'),
    },
    disclaimer: {
      path: '/terms/disclaimer',
      set: whiteLabelRouteSet<'isWhiteLabel' | 'communitySlug', 'then'>('/legal/disclaimer'),
    },
    terms: {
      path: '/terms',
      set: whiteLabelRouteSet<'isWhiteLabel' | 'communitySlug', 'then'>('/legal/terms'),
    },
    termsUse: {
      path: '/terms/use',
      set: whiteLabelRouteSet<'isWhiteLabel' | 'communitySlug', 'then'>('/legal/terms/use'),
    },
    termsCookies: {
      path: '/terms/cookies',
      set: whiteLabelRouteSet<'isWhiteLabel' | 'communitySlug', 'then'>('/legal/terms/cookies'),
    },
  },
  error: {
    path: '*',
  },
  admin: {
    global: '/admin/*',
    base: {
      path: '/admin',
      relative: '',
      set: routeSet<undefined, 'then'>('/admin'),
    },
    createCommunity: {
      path: '/admin/create-community',
      relative: '/create-community',
      set: routeSet<undefined, 'then'>('/admin/create-community'),
    },
    spotifyPreSave: {
      path: '/admin/spotify/pre-save',
      relative: '/spotify/pre-save',
      set: routeSet<undefined, 'then'>('/admin/spotify/pre-save'),
    },
  },
  auth: {
    login: {
      path: '/login',
      set: whiteLabelRouteSet<'isWhiteLabel' | 'communitySlug', 'then' | 'search'>('/login'),
    },
    callback: {
      path: '/login/callback',
      set: whiteLabelRouteSet<'isWhiteLabel' | 'communitySlug', 'then'>('/login/callback'),
    },
    signUp: {
      path: '/sign-up',
      set: routeSet<undefined, 'then' | 'search'>('/sign-up'),
    },
  },
  user: {
    global: '/@:username/*',
    base: {
      path: '/@:username',
      relative: '',
      set: routeSet<'username', 'then'>('/@:username'),
    },
    membership: {
      path: '/@:username/',
      relative: '/',
      set: routeSet<'username', 'then'>('/@:username/'),
    },
  },
  spotifyCallback: {
    path: '/login/spotify/callback',
    set: routeSet('/login/spotify/callback'),
  },
  dashboard: {
    global: '/dashboard/*',
    // TODO remove index or base
    base: {
      path: '/dashboard',
      relative: '',
      set: routeSet<undefined, 'then'>('/dashboard'),
    },
    index: {
      path: '/dashboard',
      relative: '/',
      set: routeSet<undefined, 'then'>('/dashboard/'),
    },
    community: {
      global: '/:communitySlug/*',
      base: {
        path: '/dashboard/:communitySlug',
        relative: '/',
        set: routeSet<'communitySlug', 'then'>('/dashboard/:communitySlug'),
      },
      overview: {
        path: '/dashboard/:communitySlug',
        relative: '/',
        set: routeSet<'communitySlug', 'then'>('/dashboard/:communitySlug'),
      },
      message: {
        path: '/dashboard/:communitySlug/message',
        relative: '/message',
        set: routeSet<'communitySlug', 'then'>('/dashboard/:communitySlug/message'),
      },
      messageEdit: {
        path: '/dashboard/:communitySlug/message/:messageId/edit',
        relative: '/message/:messageId/edit',
        set: routeSet<'communitySlug' | 'messageId', 'then'>('/dashboard/:communitySlug/message/:messageId/edit'),
      },
      messageNew: {
        path: '/dashboard/:communitySlug/message/new',
        relative: '/message/new',
        set: routeSet<'communitySlug', 'then'>('/dashboard/:communitySlug/message/new'),
      },
      campaign: {
        global: '/campaign/*',
        overview: {
          path: '/dashboard/:communitySlug/campaigns',
          relative: '/campaigns',
          set: routeSet<'communitySlug', 'then'>('/dashboard/:communitySlug/campaigns'),
        },
        new: {
          path: '/dashboard/:communitySlug/campaign/new',
          relative: '/new',
          set: routeSet<'communitySlug', 'then'>('/dashboard/:communitySlug/campaign/new'),
        },
        edit: {
          path: '/dashboard/:communitySlug/campaign/:campaignId/edit',
          relative: '/:campaignId/edit',
          set: routeSet<'communitySlug' | 'campaignId', 'then'>('/dashboard/:communitySlug/campaign/:campaignId/edit'),
        },
        view: {
          path: '/dashboard/:communitySlug/campaign/:campaignId',
          relative: '/:campaignId',
          set: routeSet<'communitySlug' | 'campaignId', 'then'>('/dashboard/:communitySlug/campaign/:campaignId'),
        },
        audienceNew: {
          path: '/dashboard/:communitySlug/campaign/:campaignId/audience',
          relative: '/:campaignId/audience',
          set: routeSet<'communitySlug' | 'campaignId', 'then'>(
            '/dashboard/:communitySlug/campaign/:campaignId/audience',
          ),
        },
        audienceEdit: {
          path: '/dashboard/:communitySlug/campaign/:campaignId/audience/:audienceId',
          relative: '/:campaignId/audience/:audienceId',
          set: routeSet<'communitySlug' | 'campaignId' | 'audienceId', 'then'>(
            '/dashboard/:communitySlug/campaign/:campaignId/audience/:audienceId',
          ),
        },
        messageNew: {
          path: '/dashboard/:communitySlug/campaign/:campaignId/message',
          relative: '/:campaignId/message',
          set: routeSet<'communitySlug' | 'campaignId', 'then'>(
            '/dashboard/:communitySlug/campaign/:campaignId/message',
          ),
        },
        messageEdit: {
          path: '/dashboard/:communitySlug/campaign/:campaignId/message/:messageId',
          relative: '/:campaignId/message/:messageId',
          set: routeSet<'communitySlug' | 'campaignId' | 'messageId', 'then'>(
            '/dashboard/:communitySlug/campaign/:campaignId/message/:messageId',
          ),
        },
      },
      campaignEdit: {
        path: '/dashboard/:communitySlug/campaign/:campaignId',
        relative: '/campaign/:campaignId',
        set: routeSet<'communitySlug' | 'campaignId', 'then'>('/dashboard/:communitySlug/campaign/:campaignId'),
      },
      settings: {
        path: '/dashboard/:communitySlug/settings',
        relative: '/settings',
        set: routeSet<'communitySlug', 'then'>('/dashboard/:communitySlug/settings'),
      },
      links: {
        path: '/dashboard/:communitySlug/links',
        relative: '/links',
        set: routeSet<'communitySlug', 'then'>('/dashboard/:communitySlug/links'),
      },
      blocks: {
        path: '/dashboard/:communitySlug/blocks',
        relative: '/blocks',
        set: routeSet<'communitySlug', 'then'>('/dashboard/:communitySlug/blocks'),
      },
      integrations: {
        path: '/dashboard/:communitySlug/integrations',
        relative: '/integrations',
        set: routeSet<'communitySlug', 'then'>('/dashboard/:communitySlug/integrations'),
      },
      items: {
        path: '/dashboard/:communitySlug/items',
        relative: '/items',
        set: routeSet<'communitySlug', 'then'>('/dashboard/:communitySlug/items'),
      },
      itemsNew: {
        path: '/dashboard/:communitySlug/items/new',
        relative: '/items/new',
        set: routeSet<'communitySlug', 'then'>('/dashboard/:communitySlug/items/new'),
      },
      itemsId: {
        path: '/dashboard/:communitySlug/items/:id',
        relative: '/items/:id',
        set: routeSet<'communitySlug' | 'id', 'then'>('/dashboard/:communitySlug/items/:id'),
      },
      itemsEdit: {
        path: '/dashboard/:communitySlug/items/:id/edit',
        relative: '/items/:id/edit',
        set: routeSet<'communitySlug' | 'id', 'then'>('/dashboard/:communitySlug/items/:id/edit'),
      },
      itemsClaimLink: {
        path: '/dashboard/:communitySlug/items/:id/claim-link',
        relative: '/items/:id/claim-link',
        set: routeSet<'communitySlug' | 'id', 'then'>('/dashboard/:communitySlug/items/:id/claim-link'),
      },
    },
  },
  you: {
    global: '/you/*',
    memberships: {
      path: '/you/memberships',
      relative: '/memberships',
      set: routeSet<undefined, 'then'>('/you/memberships'),
    },
    collection: {
      path: '/you/collection',
      relative: '/collection',
      set: routeSet<undefined, 'then'>('/you/collection'),
    },
    settings: {
      path: '/you/settings',
      relative: '/settings',
      set: routeSet<undefined, 'then'>('/you/settings'),
      spotify: {
        path: '/you/settings/spotify',
        relative: '/settings/spotify',
        set: routeSet<undefined, 'then'>('/you/settings/spotify'),
      },
    },
  },
  community: {
    global: '/:communitySlug/*',
    base: {
      path: '/:communitySlug',
      relative: '',
      set: routeSet<'communitySlug', 'then'>('/:communitySlug'),
    },
    join: {
      path: '/:communitySlug/join',
      relative: '/join',
      set: routeSet<'communitySlug', 'search' | 'then'>('/:communitySlug/join'),
    },
    signUp: {
      path: '/:communitySlug/sign-up',
      relative: '/sign-up',
      set: routeSet<'communitySlug', 'search' | 'then'>('/:communitySlug/sign-up'),
    },
    auth: {
      path: '/:communitySlug/auth',
      relative: '/auth',
      set: routeSet<'communitySlug', 'search' | 'then'>('/:communitySlug/auth'),
    },
    preferences: {
      path: '/:communitySlug/preferences',
      relative: '/preferences',
      set: routeSet<'communitySlug', 'then'>('/:communitySlug/preferences'),
    },
    drops: {
      path: '/:communitySlug/drops',
      relative: '/drops',
      set: routeSet<'communitySlug', 'then'>('/:communitySlug/drops'),
    },
    collection: {
      path: '/:communitySlug/collection',
      relative: '/collection',
      set: routeSet<'communitySlug', 'then'>('/:communitySlug/collection'),
    },
    settings: {
      path: '/:communitySlug/settings',
      relative: '/settings',
      set: routeSet<'communitySlug', 'then'>('/:communitySlug/settings'),
    },
    item: {
      base: {
        path: '/:communitySlug/item/:managedContractSlug',
        relative: '',
        global: '/item/:managedContractSlug/*',
        set: routeSet<'managedContractSlug' | 'communitySlug', 'then'>('/:communitySlug/item/:managedContractSlug'),
      },
      key: {
        path: '/:communitySlug/item/:managedContractSlug/:key',
        relative: '/:key',
        set: routeSet<'key' | 'managedContractSlug' | 'communitySlug', 'then'>(
          '/:communitySlug/item/:managedContractSlug/:key',
        ),
      },
      buy: {
        base: {
          path: '/:communitySlug/item/:managedContractSlug/buy',
          relative: '/buy',
          set: routeSet<'managedContractSlug' | 'communitySlug', 'then'>(
            '/:communitySlug/item/:managedContractSlug/buy',
          ),
        },
        key: {
          path: '/:communitySlug/item/:managedContractSlug/buy/:key',
          relative: '/buy/:key',
          set: routeSet<'managedContractSlug' | 'communitySlug' | 'key', 'then'>(
            '/:communitySlug/item/:managedContractSlug/buy/:key',
          ),
        },
      },
      receipt: {
        path: '/:communitySlug/item/:managedContractSlug/receipt',
        relative: '/receipt',
        set: routeSet<'managedContractSlug' | 'communitySlug', 'then'>(
          '/:communitySlug/item/:managedContractSlug/receipt',
        ),
      },
    },
  },
};
