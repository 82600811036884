import { gql, useMutation, useQuery } from '@apollo/client';
import { Stack, Button, Box, Heading, HStack, InputLeftAddon, Spacer } from '@chakra-ui/react';
import { toast } from 'App';
import { Formik, FormikErrors } from 'formik';
import { User } from 'phosphor-react';
import { GrowthbookContext } from 'providers/Growthbook';
import { useContext, useState } from 'react';
import { ROUTES } from 'routes';

import { ErrorMessage } from 'components/atoms/ErrorMessage';
import { Link } from 'components/atoms/Link';
// import { ReactComponent as MetaMaskFox } from 'assets/icons/metamask-fox.svg';
import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { ImageInput } from 'components/molecules/ImageInput';
import { Input } from 'components/molecules/Input';
import { SpotifyConnectButton } from 'components/molecules/SpotifyConnectButton';
import { extractErrors } from 'utils/errors';

import { CityField, CountryField, COUNTRY_QUERY, MobileNumberField } from '../LocationFields';
import { CountryQuery } from '../LocationFields/__graphql__/CountryQuery';
import { SettingsFormMutation, SettingsFormMutationVariables } from './__graphql__/SettingsFormMutation';
import { SettingsFormQuery } from './__graphql__/SettingsFormQuery';

export const SETTINGS_FORM_USER_FRAGMENT = gql`
  fragment SettingsFormQuery_user on User {
    id
    username
    avatar(variant: sm)
    avatarMd: avatar(variant: md)
    email
    displayName
    shouldEmailTempleUpdates
    country
    city
    dial
    mobileNumber
    communities {
      community {
        displayName
        slug
      }
    }
  }
`;

export const SETTINGS_FORM_QUERY = gql`
  query SettingsFormQuery {
    me {
      id
      ...SettingsFormQuery_user
    }
  }
  ${SETTINGS_FORM_USER_FRAGMENT}
`;

export const SETTINGS_FORM_MUTATION = gql`
  mutation SettingsFormMutation(
    $username: String!
    $displayName: String!
    $avatar: Upload
    $shouldEmailTempleUpdates: Boolean!
    $country: String
    $city: String
    $dial: String
    $mobileNumber: String
  ) {
    updateUser(
      username: $username
      displayName: $displayName
      avatar: $avatar
      shouldEmailTempleUpdates: $shouldEmailTempleUpdates
      country: $country
      city: $city
      dial: $dial
      mobileNumber: $mobileNumber
    ) {
      ...SettingsFormQuery_user
    }
  }
  ${SETTINGS_FORM_USER_FRAGMENT}
`;

export const SettingsForm = () => {
  const { data, loading, error } = useQuery<SettingsFormQuery>(SETTINGS_FORM_QUERY);
  const { data: countryData } = useQuery<CountryQuery>(COUNTRY_QUERY);
  const [updateUser] = useMutation<SettingsFormMutation, SettingsFormMutationVariables>(SETTINGS_FORM_MUTATION);
  const [avatarChanged, setAvatarChanged] = useState(false);
  const { growthbook } = useContext(GrowthbookContext);

  if (loading) {
    return <WrappedSpinner />;
  }

  if (!data) {
    return <Box>Something went wrong</Box>;
  }

  if (!data?.me) {
    return null;
  }

  if (error) {
    return <ErrorMessage />;
  }

  return (
    <Formik
      initialValues={{
        username: data.me.username || '',
        displayName: data.me.displayName,
        avatar: '',
        shouldEmailTempleUpdates: data.me.shouldEmailTempleUpdates || false,
        country: data?.me?.country || '',
        city: data?.me?.city || '',
        dial: data?.me?.dial || '',
        mobileNumber: data?.me?.mobileNumber || '',
      }}
      validate={(values) => {
        const errors: FormikErrors<typeof values> = {};
        if (values.username.length < 3 || values.username.length > 30) {
          errors.username = 'Username must be between 3 and 30 characters';
        } else if (!values.username.length) {
          errors.username = 'A username is required';
        } else if (!/^[a-z0-9][a-z0-9._]+[a-z0-9]$/i.test(values.username)) {
          errors.username = 'Username should contain letters, numbers, underscores and dots only.';
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        const variables: any = { ...values };
        if (!avatarChanged) {
          delete variables['avatar'];
        }
        updateUser({ variables })
          .then(() => {
            toast({ status: 'success', description: 'Profile saved' });
            setAvatarChanged(false);
          })
          .catch(extractErrors(setErrors))
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      {({
        handleSubmit,
        isSubmitting,
        errors,
        setFieldValue,
        handleChange,
        values: { shouldEmailTempleUpdates, country },
      }) => (
        <form onSubmit={handleSubmit} noValidate>
          <Stack spacing="12">
            {/* // TODO Or extract this one into a Profile Form component  */}
            <ImageInput
              label="Profile image"
              image={data.me?.avatarMd}
              outsideBorder
              onChange={(file: File | null) => {
                setFieldValue('avatar', file);
                setAvatarChanged(true);
              }}
              helpText="We recommend 500 x 500 pixels at a minimum."
              specText=".jpg, .gif, or .png up to 5MB"
              errorMessage={errors.avatar}
              placeholderProps={{ icon: User, borderRadius: 'full' }}
            />

            <Stack spacing="4">
              <Heading variant="h4">Profile information</Heading>
              <Input name="displayName" label="Name" type="text" />
              <Input
                name="username"
                label="Username"
                type="text"
                leftChildren={<InputLeftAddon pointerEvents="none" children="@" />}
              />
              {countryData && (
                <>
                  <CountryField defaultCountry={country} countries={countryData.countries} label="Country" />
                  <CityField
                    countryIso3={countryData.countries?.find((c) => c.iso3 === country)?.iso3}
                    defaultCity={data?.me?.city || ''}
                  />
                  <MobileNumberField
                    label="Mobile number"
                    countries={countryData.countries}
                    country={country}
                    dial={data?.me?.dial || ''}
                    mobileNumber={data?.me?.mobileNumber || ''}
                    setFieldValue={setFieldValue}
                  />
                </>
              )}
            </Stack>

            <Stack spacing="4">
              <Heading variant="h4">Email</Heading>
              <HStack>
                <Box textStyle="email">{data.me?.email}</Box>
              </HStack>
            </Stack>
            {/* // TODO Extract this into own form  */}
            {/* <Box>
              <Stack>
                <Heading variant="h4">Email communications</Heading>
                <FormControl display="flex" alignItems="center" mb="2" mt="-4px">
                  <FormLabel cursor="pointer" htmlFor="shouldEmailTempleUpdates" fontSize="sm" mb="0" flexGrow="1">
                    When Temple has important updates
                  </FormLabel>
                  <Switch
                    id="shouldEmailTempleUpdates"
                    name="shouldEmailTempleUpdates"
                    onChange={handleChange}
                    isChecked={shouldEmailTempleUpdates}
                    size="sm"
                  />
                </FormControl>
              </Stack>
            </Box> */}

            {/* // TODO Extract this into own form + refactor by removing Table and unnecessary divs  */}

            <Stack spacing="4">
              <Heading variant="h4">Communication settings</Heading>
              <Stack>
                {data.me?.communities.map((result) => (
                  <HStack key={result.community.slug}>
                    <Box fontWeight="bold">{result.community.displayName}</Box>
                    <Spacer />
                    <Link to={ROUTES.community.preferences.set({ communitySlug: result.community.slug })}>Change</Link>
                  </HStack>
                ))}
              </Stack>
            </Stack>

            {/* // TODO Extract this to up components  */}
            {!growthbook.isOn('hide_music_connections') && (
              <Stack>
                <Heading variant="h4">Connections</Heading>
                <SpotifyConnectButton />
              </Stack>
            )}

            <Button isLoading={isSubmitting} type="submit" variant="primary">
              Save profile
            </Button>
          </Stack>
        </form>
      )}
    </Formik>
  );
};
