import { AuthContext } from 'providers/Auth';
import { CommunityContext } from 'providers/Community';
import { ReactNode, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'routes';

import { WrappedSpinner } from 'components/atoms/WrappedSpinner';

export const RequireAuth = ({ children }: { children: ReactNode }) => {
  const { user, userLoading, logoutLoading } = useContext(AuthContext);
  const { community } = useContext(CommunityContext);
  let location = useLocation();

  if (logoutLoading || userLoading) {
    return <WrappedSpinner />;
  }

  if (user) {
    return <>{children}</>;
  }

  return (
    <Navigate
      to={ROUTES.auth.login.set({
        then: location.pathname,
        isWhiteLabel: community?.isWhiteLabel,
        communitySlug: community?.slug,
      })}
      replace
    />
  );
};
