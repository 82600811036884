import { gql, useMutation, useQuery } from '@apollo/client';
import { Alert, AlertDescription, AlertIcon, Box, Button, Heading, Link, Stack } from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, PaymentIntent, Stripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

import { PaymentLineItem } from 'components/atoms/PaymentLineItem';
import PaymentMethod from 'components/atoms/PaymentMethod';
import { WrappedSpinner } from 'components/atoms/WrappedSpinner';

import CardSelectionForm from '../CardSelectionForm';
import { PaymentFormCheckIssuedQuery } from './__graphql__/PaymentFormCheckIssuedQuery';
import {
  PaymentFormUpdatePaymentIntentMutation,
  PaymentFormUpdatePaymentIntentMutationVariables,
} from './__graphql__/PaymentFormUpdatePaymentIntentMutation';
import { PaymentForm_paymentIntent } from './__graphql__/PaymentForm_paymentIntent';
import { PaymentForm_stripeCustomer } from './__graphql__/PaymentForm_stripeCustomer';

interface PaymentFormProps {
  paymentIntent?: PaymentForm_paymentIntent | null;
  stripeCustomer?: PaymentForm_stripeCustomer | null;
  onComplete: () => void;
  onPaymentMethodChange?: (paymentMethod: string) => void;
  children?: React.ReactNode;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

const PAYMENT_INTENT_FRAGMENT = gql`
  fragment PaymentForm_paymentIntent on StripePaymentIntentWithSecret {
    id
    clientSecret
    country
    currencySymbol
    baseAmount
    taxAmount
    totalAmount
    quantity
  }
`;

const UPDATE_PAYMENT_INTENT = gql`
  mutation PaymentFormUpdatePaymentIntentMutation($id: ID!, $country: String!) {
    updatePaymentIntent(id: $id, country: $country) {
      id
      ...PaymentForm_paymentIntent
    }
  }
  ${PAYMENT_INTENT_FRAGMENT}
`;

const CHECK_ISSUED_QUERY = gql`
  query PaymentFormCheckIssuedQuery($paymentProviderId: String!) {
    receipt(paymentProviderId: $paymentProviderId) {
      id
    }
  }
`;

export const PaymentForm = ({ paymentIntent, stripeCustomer, onComplete, children }: PaymentFormProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [stripePaymentMethodId, setStripePaymentMethodId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [updatePaymentIntent, { loading }] = useMutation<
    PaymentFormUpdatePaymentIntentMutation,
    PaymentFormUpdatePaymentIntentMutationVariables
  >(UPDATE_PAYMENT_INTENT);
  const clientSecret = paymentIntent?.clientSecret;
  const [paymentIntentStatus, setPaymentIntentStatus] = useState<PaymentIntent.Status | undefined>();
  const { data, startPolling } = useQuery<PaymentFormCheckIssuedQuery>(CHECK_ISSUED_QUERY, {
    skip: !paymentIntent?.id,
    variables: { paymentProviderId: paymentIntent?.id },
  });

  const selectedPaymentMethod = stripeCustomer?.paymentMethods?.find(
    (paymentMethod) => paymentMethod.id === stripePaymentMethodId,
  );

  useEffect(() => {
    stripePromise.then(setStripe);
  }, []);

  useEffect(() => {
    (async () => {
      if (clientSecret) {
        const response = await stripe?.retrievePaymentIntent(clientSecret);
        setPaymentIntentStatus(response?.paymentIntent?.status);
      }
    })();
  }, [clientSecret, stripe]);

  useEffect(() => {
    (async () => {
      if (paymentIntent && selectedPaymentMethod?.country && selectedPaymentMethod.country !== paymentIntent.country) {
        await updatePaymentIntent({
          variables: {
            id: paymentIntent.id,
            country: selectedPaymentMethod.country,
          },
        });
      }
    })();
  }, [selectedPaymentMethod, paymentIntent, updatePaymentIntent]);

  useEffect(() => {
    if (paymentIntentStatus === 'succeeded') {
      // Payment succeeded so poll for receipt
      startPolling(1000);
    }
  }, [paymentIntentStatus, startPolling]);

  useEffect(() => {
    if (data?.receipt?.id) {
      onComplete();
    }
  }, [data, onComplete]);

  if (!paymentIntent?.clientSecret || !stripe) {
    return <WrappedSpinner />;
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!stripe || !selectedPaymentMethod || !clientSecret) {
      return;
    }

    setSubmitting(true);
    setErrorMessage(null);

    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      // Make sure to change this to your payment completion page
      return_url: window.location.href,
      payment_method: selectedPaymentMethod.id,
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (
      error?.type === 'card_error' ||
      error?.type === 'validation_error' ||
      error?.code === 'payment_intent_authentication_failure'
    ) {
      setErrorMessage(error?.message || null);
      setSubmitting(false);
    } else if (error) {
      setErrorMessage('An unexpected error occured.');
      setSubmitting(false);
    } else if (paymentIntent?.status && ['requires_capture', 'succeeded'].includes(paymentIntent.status)) {
      setPaymentIntentStatus(paymentIntent?.status);
    }
  };

  if (!paymentIntentStatus) {
    return <WrappedSpinner />;
  }

  if (paymentIntentStatus === 'succeeded') {
    return (
      <Stack alignItems="center">
        <Heading variant="h4">Processing payment</Heading>
        <WrappedSpinner />
      </Stack>
    );
  }

  return (
    <Elements stripe={stripe} options={{ clientSecret: clientSecret ?? '' }}>
      <>
        {paymentIntent &&
          (loading ? (
            <Box textAlign="center" color="darkGrey">
              <WrappedSpinner size="sm" />
              Calculating price&hellip;
            </Box>
          ) : (
            <Stack spacing="2">
              {children}
              <PaymentLineItem label="Subtotal">
                {paymentIntent.currencySymbol}
                {(paymentIntent.baseAmount / 100).toFixed(2)}
              </PaymentLineItem>
              {paymentIntent.taxAmount && (
                <PaymentLineItem label={`Sales tax (${(paymentIntent.taxAmount / paymentIntent.baseAmount) * 100}%)`}>
                  {paymentIntent.currencySymbol}
                  {(paymentIntent.taxAmount / 100).toFixed(2)}
                </PaymentLineItem>
              )}
              <PaymentLineItem label="Total due">
                {paymentIntent.currencySymbol}
                {(paymentIntent.totalAmount / 100).toFixed(2)}
              </PaymentLineItem>
            </Stack>
          ))}
      </>
      <Stack spacing="4">
        {errorMessage && (
          <Alert status="error">
            <AlertIcon />
            <AlertDescription>{errorMessage}</AlertDescription>
          </Alert>
        )}
        {selectedPaymentMethod ? (
          !loading && (
            <PaymentMethod stripePaymentMethod={selectedPaymentMethod}>
              {!submitting && (
                <Link fontSize="sm" onClick={() => setStripePaymentMethodId(null)}>
                  Change
                </Link>
              )}
            </PaymentMethod>
          )
        ) : (
          <Stack>
            <CardSelectionForm
              stripeCustomer={stripeCustomer}
              onSelect={(stripePaymentMethodId) => setStripePaymentMethodId(stripePaymentMethodId)}
            />
          </Stack>
        )}
        {selectedPaymentMethod && !loading && (
          <Button variant="primary" isDisabled={!selectedPaymentMethod} isLoading={submitting} onClick={handleSubmit}>
            Confirm purchase
          </Button>
        )}
      </Stack>
    </Elements>
  );
};

PaymentForm.fragments = {
  stripeCustomer: gql`
    fragment PaymentForm_stripeCustomer on StripeCustomer {
      paymentMethods {
        id
        country
        ...PaymentMethod_stripePaymentMethod
      }
      ...CardSelectionForm_stripeCustomer
    }
    ${PaymentMethod.fragments.stripePaymentMethod}
    ${CardSelectionForm.fragments.stripeCustomer}
  `,
  paymentIntent: PAYMENT_INTENT_FRAGMENT,
};
