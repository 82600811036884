import { Box, Button, HStack, StackProps } from '@chakra-ui/react';

import { Link } from 'components/atoms/Link';

interface ExplainerWithCTAProps extends StackProps {
  children: React.ReactNode;
  cta?: string;
  to?: string;
}

export const ExplainerWithCTA = ({ children, cta, to, ...rest }: ExplainerWithCTAProps) => (
  <HStack
    bg="brandYellow"
    p={6}
    color="black"
    spacing="8"
    direction={{ base: 'column', md: 'row' }}
    alignItems={{ base: 'flex-start', md: 'center' }}
  >
    <Box flexGrow={1}>{children}</Box>
    {to && cta && (
      <Button as={Link} flexShrink={0} variant="secondary" to={to}>
        {cta}
      </Button>
    )}
  </HStack>
);
