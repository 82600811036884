import { Route, Routes, useParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import UserPage from 'app/user';
import { NotFoundPanel } from 'components/molecules/NotFoundPanel';

import CommunityAuthPage from './auth/page';
import { CommunityItemRoutes } from './item/[id]/routes';
import CommunityJoinPage from './join/page';
import CommunityLayout from './layout';
import CommunityLinksPage from './links/page';
import CommunityLoginPage from './login/page';
import CommunityDropsPage from './page';
import UserCommunityPreferencesPage from './preferences/page';

export const CommunityRoutes = () => {
  const params = useParams();

  if (params.communitySlug?.startsWith('@')) {
    return <UserPage />;
  }

  return (
    <CommunityLayout>
      <Routes>
        <Route path={ROUTES.community.item.base.global} element={<CommunityItemRoutes />} />
        <Route path={ROUTES.community.join.relative} element={<CommunityJoinPage />} />
        <Route path={ROUTES.community.auth.relative} element={<CommunityAuthPage />} />
        <Route path={ROUTES.community.drops.relative} element={<CommunityDropsPage />} />
        <Route path={ROUTES.community.preferences.relative} element={<UserCommunityPreferencesPage />} />
        <Route path={ROUTES.auth.login.path} element={<CommunityLoginPage />} />
        <Route path="" element={<CommunityLinksPage />} />
        <Route path="*" element={<NotFoundPanel />} />
      </Routes>
    </CommunityLayout>
  );
};
