import { gql } from '@apollo/client';
import { StackProps, HStack, Spacer, Box } from '@chakra-ui/react';
import { FaCcAmex, FaCcMastercard, FaCcVisa, FaCreditCard } from 'react-icons/fa';

import { PaymentMethod_stripePaymentMethod } from './__graphql__/PaymentMethod_stripePaymentMethod';

interface PaymentMethodProps extends StackProps {
  stripePaymentMethod: PaymentMethod_stripePaymentMethod;
}

const PaymentMethod = ({ stripePaymentMethod, children, ...rest }: PaymentMethodProps) => {
  return (
    <HStack spacing="4" w="full" rounded="lg" py="3" px="4" bg="chakra-subtle-bg" {...rest}>
      <Box color="gray.500">
        {(stripePaymentMethod.brand &&
          {
            visa: <FaCcVisa size={24} />,
            mastercard: <FaCcMastercard size={24} />,
            amex: <FaCcAmex size={24} />,
          }[stripePaymentMethod.brand]) || <FaCreditCard size={24} />}
      </Box>
      <Box fontSize="sm">••• {stripePaymentMethod.last4}</Box>
      <Spacer />
      <Box fontSize="sm">
        {stripePaymentMethod.expMonth}/{stripePaymentMethod.expYear?.toString().slice(-2)}
      </Box>
      {children}
    </HStack>
  );
};

PaymentMethod.fragments = {
  stripePaymentMethod: gql`
    fragment PaymentMethod_stripePaymentMethod on StripePaymentMethod {
      id
      country
      brand
      last4
      expMonth
      expYear
    }
  `,
};

export default PaymentMethod;
