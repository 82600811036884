'use client';

import { gql, useQuery } from '@apollo/client';
import { Box, Flex, Heading, Icon, Stack, useBreakpointValue } from '@chakra-ui/react';
import { CaretRight } from 'phosphor-react';
import { AuthContext } from 'providers/Auth';
import { CommunityContext } from 'providers/Community';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { ROUTES } from 'routes';

import { Link } from 'components/atoms/Link';
import { CommunityPanel } from 'components/molecules/CommunityPanel';
import EmbedBlocksList from 'components/molecules/LinkInBioEmbedsList';
import LinkInBioList from 'components/molecules/LinkInBioList';
import { OfferingCard } from 'components/molecules/OfferingCard';
import { PagePanel } from 'components/molecules/PagePanel';
import { CommunityHeader } from 'components/organisms/CommunityHeader';
import { CommunityHeaderControls } from 'components/organisms/CommunityHeaderControls';
import { LatestCommunityOfferings } from 'components/organisms/LatestCommunityOfferings';
import { useTrackPageView } from 'hooks/useTrackPageView';

import {
  CommunityLinksPageQuery,
  CommunityLinksPageQueryVariables,
  CommunityLinksPageQuery_community_links,
} from './__graphql__/CommunityLinksPageQuery';

export const QUERY = gql`
  query CommunityLinksPageQuery($slug: String!) {
    community(slug: $slug) {
      id
      displayName
      slug
      offerings {
        id
        slug
        ...OfferingCard_offering
      }
      ...CommunityPanel_community
      ...CommunityHeader_community
      ...LinkInBioList_community
      ...LinkInBioEmbeds_community
    }
  }
  ${OfferingCard.fragments.offering}
  ${CommunityPanel.fragments.community}
  ${CommunityHeader.fragments.community}
  ${LinkInBioList.fragments.links}
  ${EmbedBlocksList.fragments.blocks}
`;

const LinkInBioContainer = ({
  hideHeader,
  links,
}: {
  hideHeader?: boolean;
  links: CommunityLinksPageQuery_community_links[] | undefined;
}) => {
  return (
    <>
      {!!links && links.length > 0 && (
        <Stack spacing={4} w="full">
          {!hideHeader && (
            <Heading variant="h4" color="inherit">
              Featured Links
            </Heading>
          )}
          <LinkInBioList links={links} />
        </Stack>
      )}
    </>
  );
};

const CommunityLinksPage = () => {
  const { community, communityLoading } = useContext(CommunityContext);
  const { data, loading } = useQuery<CommunityLinksPageQuery, CommunityLinksPageQueryVariables>(QUERY, {
    variables: { slug: community?.slug ?? '' },
    skip: !community?.id,
  });

  const { user, userLoading } = useContext(AuthContext);

  const isMobile = useBreakpointValue({ base: true, lg: false });

  useTrackPageView(
    'Community Links',
    {
      community_id: community?.id,
      community_slug: community?.slug,
      isWhiteLabel: community?.isWhiteLabel,
      isMember: !!community?.currentMembership?.role,
      isLive: community?.isLive,
      loggedIn: !!user,
    },
    !community || userLoading,
  );

  return (
    <>
      <Helmet>{community && <title>{community.displayName}</title>}</Helmet>
      {isMobile && !user && (
        <Box position="absolute" top={6} right={4} pointerEvents="all" zIndex={99999}>
          <CommunityHeaderControls community={community} showCounts={false} />
        </Box>
      )}
      <PagePanel alignItems={isMobile ? 'center' : ''} spacing="8">
        <Stack spacing={4}>
          <CommunityHeader
            community={data?.community}
            align={isMobile ? 'center' : undefined}
            includeControls={!isMobile}
          />
        </Stack>
        {isMobile && (
          <Flex flex="1 0" minW="320px">
            <LinkInBioContainer hideHeader links={data?.community?.links} />
          </Flex>
        )}
        <Flex flex="1 0">
          <Stack>
            {!!community?.offerings.length && (
              <Stack>
                <Heading
                  mb={4}
                  as={Link}
                  to={ROUTES.community.base.set({ communitySlug: community.slug })}
                  variant="h4"
                  color="inherit"
                  textAlign={isMobile ? 'center' : undefined}
                >
                  Drops from {community?.displayName} <Icon as={CaretRight} boxSize={4} />
                </Heading>
                <LatestCommunityOfferings
                  isLoaded={!loading}
                  numberOfOfferings={3}
                  community={data?.community}
                  w={{ base: `${2.5 * 150}px`, sm: `${3.2 * 200}px`, lg: '100%' }}
                />
                <EmbedBlocksList blocks={data?.community?.blocks} />
              </Stack>
            )}
          </Stack>
          {!isMobile && (
            <Flex flex="1 0" ml={16}>
              <LinkInBioContainer links={data?.community?.links} />
            </Flex>
          )}
        </Flex>
      </PagePanel>
      {!communityLoading && !community?.currentMembership && <CommunityPanel mt="auto" community={community} />}
    </>
  );
};

export default CommunityLinksPage;
