import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Container, Heading, Stack } from '@chakra-ui/react';
import { toast } from 'App';
import { Formik, FormikProps } from 'formik';
import { Ref, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import { ErrorMessage } from 'components/atoms/ErrorMessage';
import { UploadedFile } from 'components/molecules/MultiFileUpload';
import { extractErrors } from 'utils/errors';

import {
  CreateOfferingFormMutation,
  CreateOfferingFormMutationVariables,
} from './__graphql__/CreateOfferingFormMutation';
import { CreateOfferingFormQuery } from './__graphql__/CreateOfferingFormQuery';
import {
  CategorySelectionField,
  DROP_TYPES,
  DropFields,
  formatDropFields,
  initialDropValues,
  initialEventValues,
  initialMatcherValues,
} from './dropFields';
import { EventFields } from './eventFields';

export const QUERY = gql`
  query CreateOfferingFormQuery($slug: String!) {
    community(slug: $slug) {
      id
      slug
      logo(variant: md)
    }
    me {
      id
      avatar(variant: md)
    }
  }
`;

export const CREATE_DROP_MUTATION = gql`
  mutation CreateOfferingFormMutation(
    $communityId: ID!
    $name: String!
    $description: String
    $image: Upload!
    $maxSupply: Int
    $price: String
    $isSecret: Boolean!
    $type: OfferingType
    $unlockableFiles: [UploadedFileInput!]
    $ownershipMessage: String
    $startDateTime: String
    $endDateTime: String
    $location: String
    $matchersWithParameters: [MatcherInput!]
  ) {
    createOffering(
      communityId: $communityId
      name: $name
      description: $description
      image: $image
      maxSupply: $maxSupply
      price: $price
      isSecret: $isSecret
      type: $type
      unlockableFiles: $unlockableFiles
      ownershipMessage: $ownershipMessage
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      location: $location
      matchersWithParameters: $matchersWithParameters
    ) {
      id
    }
  }
`;

interface CreateDropFormProps {
  hideSubmitBtn?: boolean;
}

export const CreateDropForm = forwardRef(({ hideSubmitBtn }: CreateDropFormProps, ref: Ref<FormikProps<any>>) => {
  const { communitySlug } = useParams();
  const { t } = useTranslation(['creator', 'common']);
  const { data, loading, error } = useQuery<CreateOfferingFormQuery>(QUERY, {
    variables: { slug: communitySlug },
    skip: !communitySlug,
  });
  const [createOffering] = useMutation<CreateOfferingFormMutation, CreateOfferingFormMutationVariables>(
    CREATE_DROP_MUTATION,
  );
  const [uploads, setUploads] = useState<UploadedFile[]>([]);
  const navigate = useNavigate();
  const uploading = !!uploads.filter((upload) => !upload.id).length;

  if ((!loading && !data && communitySlug) || error) {
    return <ErrorMessage />;
  }

  return (
    <>
      <Formik
        innerRef={ref}
        initialValues={{ ...initialDropValues(null, true), ...initialEventValues(null), ...initialMatcherValues(null) }}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          if (!data?.community) return;
          try {
            const result = await createOffering({
              variables: {
                communityId: data?.community?.id,
                ...formatDropFields(values, uploads),
              },
            });
            const id = result.data?.createOffering?.id;
            if (!hideSubmitBtn) {
              toast({
                status: 'success',
                description: t('forms.CreateTokenContractForm.successToast'),
              });
              navigate(
                ROUTES.dashboard.community.itemsId.set({
                  communitySlug,
                  id,
                }),
                { replace: true },
              );
            }
            return id;
          } catch (errors) {
            extractErrors(setErrors)(errors);
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ handleSubmit, isValid, isSubmitting, values: { hasUnlockableFiles, type } }) => {
          return (
            <form onSubmit={handleSubmit} noValidate>
              <Stack spacing="12">
                <Stack spacing="4">
                  <CategorySelectionField />
                  {type === DROP_TYPES.EVENT ? <EventFields /> : <DropFields setUploads={setUploads} />}
                </Stack>
                {!hideSubmitBtn && (
                  <Button
                    isDisabled={loading || !isValid || (hasUnlockableFiles && uploading)}
                    isLoading={isSubmitting}
                    variant="primary"
                    type="submit"
                  >
                    {t('forms.CreateTokenContractForm.submit')}
                  </Button>
                )}
              </Stack>
            </form>
          );
        }}
      </Formik>
    </>
  );
});
