import { gql, useQuery } from '@apollo/client';
import {
  Flex,
  Text,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Avatar,
  Skeleton,
  HStack,
} from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { CommunityContext } from 'providers/Community';
import { useContext, useState } from 'react';
import { ROUTES } from 'routes';

import { RouterLink } from 'components/atoms/Link';
import { COUNTRY_QUERY } from 'components/forms/LocationFields';
import { CountryQuery } from 'components/forms/LocationFields/__graphql__/CountryQuery';

import { UserCommunityEngagement, UserCommunityEngagementVariables } from './__graphql__/UserCommunityEngagement';

export const USER_COMMUNITY_ENGAGEMENT = gql`
  query UserCommunityEngagement($slug: String!) {
    community(slug: $slug) {
      userEngagement {
        messageReceived
        totalOwned
        totalSpend
        location
        memberSince
        user {
          id
          displayName
          username
          avatar(variant: md)
        }
      }
    }
  }
`;

const LoadingRow = () => (
  <Tr>
    <Td>
      <Skeleton height="20px" />
    </Td>
    <Td>
      <Skeleton height="20px" />
    </Td>
    <Td>
      <Skeleton height="20px" />
    </Td>
    <Td>
      <Skeleton height="20px" />
    </Td>
  </Tr>
);

interface CommunityTableProps {
  enableFilter?: boolean;
}

export const CommunityTable = ({ enableFilter }: CommunityTableProps) => {
  const { community } = useContext(CommunityContext);
  const { data, loading } = useQuery<UserCommunityEngagement, UserCommunityEngagementVariables>(
    USER_COMMUNITY_ENGAGEMENT,
    {
      variables: { slug: community?.slug ?? '' },
      skip: !community?.slug,
    },
  );

  const { data: countryData } = useQuery<CountryQuery>(COUNTRY_QUERY);

  const [country, setCountry] = useState('');

  return (
    <Flex flex="1 0" direction="column">
      {enableFilter && (
        <Flex flex="1 0">
          <Select
            width={200}
            placeholder="Country"
            size="sm"
            onChange={async (e) => {
              setCountry(e.target.value);
            }}
            value={country}
          >
            {countryData?.countries?.map((country: any) => {
              return (
                <option key={country.id} value={country.iso3}>
                  {country.name}
                </option>
              );
            })}
          </Select>
        </Flex>
      )}
      <TableContainer w="100%">
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>User</Th>
              <Th>Spent</Th>
              <Th>Location</Th>
              <Th>Member since</Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading && (
              <>
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
                <LoadingRow />
              </>
            )}
            {!loading &&
              data?.community?.userEngagement &&
              data.community.userEngagement.length > 0 &&
              data.community.userEngagement.map((userEngagement: any) => {
                return (
                  <Tr key={userEngagement.user.id}>
                    <Td>
                      <HStack
                        as={RouterLink}
                        to={ROUTES.user.base.set({ username: userEngagement.user.username })}
                        spacing={3}
                      >
                        <Avatar
                          name={userEngagement.user.displayName}
                          src={userEngagement.user.avatar || ''}
                          size="sm"
                        />
                        <Text>{userEngagement.user.displayName}</Text>
                      </HStack>
                    </Td>
                    <Td>{Math.round(userEngagement.totalSpend) / 100}</Td>
                    <Td>{userEngagement.location}</Td>
                    <Td>{DateTime.fromISO(userEngagement.memberSince).toLocaleString(DateTime.DATE_SHORT)}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
      {!loading && (!data?.community?.userEngagement || data.community.userEngagement.length === 0) && (
        <Flex flex="1 0" alignItems="center" justifyContent="center">
          <Text>No users found just yet</Text>
        </Flex>
      )}
    </Flex>
  );
};
