import { gql } from '@apollo/client';
import { Box, Button, Link, Stack } from '@chakra-ui/react';
import { useStripe } from '@stripe/react-stripe-js';
import { Plus } from 'phosphor-react';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { NewCardForm } from 'components/forms/NewCardForm';
import PaymentMethodSelector from 'components/molecules/PaymentMethodSelector';

import { CardSelectionForm_stripeCustomer } from './__graphql__/CardSelectionForm_stripeCustomer';

interface CardSelectionFormProps {
  stripeCustomer?: CardSelectionForm_stripeCustomer | null;
  onSelect: (stripePaymentMethodId: string) => void;
}

const CardSelectionForm = ({ stripeCustomer, onSelect }: CardSelectionFormProps) => {
  const stripe = useStripe();
  const [params, setParams] = useSearchParams();
  const [showNewCardForm, setShowNewCardForm] = useState(!stripeCustomer?.paymentMethods.length);

  useEffect(() => {
    const clientSecret = params.get('setup_intent_client_secret');
    if (stripe && clientSecret) {
      stripe.retrieveSetupIntent(clientSecret).then((response) => {
        const pm = response.setupIntent?.payment_method;
        if (pm) {
          onSelect(typeof pm === 'string' ? pm : pm?.id);
        }
      });
      setParams({});
    }
  }, [stripe, params, onSelect, setParams]);

  return (
    <Stack spacing="6">
      <Stack spacing="4" display={showNewCardForm ? 'flex' : 'none'} textAlign="center">
        <NewCardForm />
        {stripeCustomer?.paymentMethods.length && (
          <Box>
            or{' '}
            <Link
              onClick={() => {
                setShowNewCardForm(false);
              }}
            >
              go back to saved cards
            </Link>
          </Box>
        )}
      </Stack>
      {!showNewCardForm && (
        <Stack spacing="2" mt={showNewCardForm ? undefined : '0 !important'}>
          <PaymentMethodSelector
            stripeCustomer={stripeCustomer}
            onSelect={(id) => {
              onSelect(id);
            }}
          />
          <Button
            onClick={() => {
              setShowNewCardForm(true);
            }}
            w="full"
            leftIcon={<Plus />}
          >
            Use new card
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

CardSelectionForm.fragments = {
  stripeCustomer: gql`
    fragment CardSelectionForm_stripeCustomer on StripeCustomer {
      paymentMethods {
        id
      }
      ...PaymentMethodSelector_stripeCustomer
    }
    ${PaymentMethodSelector.fragments.stripeCustomer}
  `,
};

export default CardSelectionForm;
