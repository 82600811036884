import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Button, Container, Heading, Stack } from '@chakra-ui/react';
import { toast } from 'App';
import { Formik, FormikProps } from 'formik';
import { Ref, forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { params, ROUTES } from 'routes';

import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { UploadedFile } from 'components/molecules/MultiFileUpload';
import { extractErrors } from 'utils/errors';

import { EditOfferingFormMutation, EditOfferingFormMutationVariables } from './__graphql__/EditOfferingFormMutation';
import { EditOfferingFormQuery } from './__graphql__/EditOfferingFormQuery';
import {
  CategorySelectionField,
  DROP_TYPES,
  DropFields,
  formatDropFields,
  initialDropValues,
  initialEventValues,
  initialMatcherValues,
} from './dropFields';
import { EventFields } from './eventFields';

export const QUERY = gql`
  query EditOfferingFormQuery($id: ID!) {
    offering(id: $id) {
      ...DropForm_offering
    }
  }
  ${DropFields.fragments.offering}
`;

export const UPDATE_DROP_MUTATION = gql`
  mutation EditOfferingFormMutation(
    $id: ID!
    $name: String!
    $description: String!
    $image: Upload
    $maxSupply: Int
    $price: String
    $isSecret: Boolean!
    $type: OfferingType
    $unlockableFiles: [UploadedFileInput!]
    $ownershipMessage: String
    $startDateTime: String
    $endDateTime: String
    $location: String
    $matchersWithParameters: [MatcherInput!]
  ) {
    updateOffering(
      id: $id
      name: $name
      description: $description
      image: $image
      maxSupply: $maxSupply
      price: $price
      isSecret: $isSecret
      type: $type
      unlockableFiles: $unlockableFiles
      ownershipMessage: $ownershipMessage
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      location: $location
      matchersWithParameters: $matchersWithParameters
    ) {
      id
    }
  }
`;

interface EditDropFormProps {
  dropId?: string;
  hideSubmitBtn?: boolean;
  lockFields?: string[];
}

export const EditDropForm = forwardRef(
  ({ dropId, hideSubmitBtn, lockFields }: EditDropFormProps, ref: Ref<FormikProps<any>>) => {
    const { t } = useTranslation(['creator', 'common']);
    const { id } = useParams<keyof params>();
    const { data, loading, error } = useQuery<EditOfferingFormQuery>(QUERY, {
      variables: { id: id ?? dropId ?? '' },
    });

    const [updateOffering] = useMutation<EditOfferingFormMutation, EditOfferingFormMutationVariables>(
      UPDATE_DROP_MUTATION,
    );
    const [uploads, setUploads] = useState<UploadedFile[] | null>(null);
    const navigate = useNavigate();
    const uploading = !!uploads?.filter((upload) => !upload.id).length;

    const bonusContentFiles = data?.offering?.unlockableFiles.map((file, index) => ({
      file: { name: file.fileName },
      id: file.id,
      name: file.title,
      index,
    }));

    useEffect(() => {
      if (bonusContentFiles !== undefined && uploads === null) {
        setUploads(bonusContentFiles);
      }
    }, [bonusContentFiles, uploads, setUploads]);

    if ((!loading && !data && id) || error) {
      return <Box>Something went wrong</Box>;
    }

    if (loading || !data?.offering) {
      return <WrappedSpinner />;
    }

    return (
      <Container variant="form" padding="0 !important">
        <Formik
          innerRef={ref}
          initialValues={{
            ...initialDropValues(data?.offering),
            ...initialEventValues(data?.offering),
            ...initialMatcherValues(data?.offering),
          }}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            if (!data?.offering) return;
            try {
              const result = await updateOffering({
                variables: {
                  id: data.offering.id,
                  ...formatDropFields(values, uploads),
                },
              });

              const id = result.data?.updateOffering?.id;
              if (!hideSubmitBtn) {
                toast({
                  status: 'success',
                  description: t('forms.EditTokenContractForm.successToast'),
                });
                navigate(
                  ROUTES.dashboard.community.itemsId.set({
                    communitySlug: data.offering?.community.slug,
                    id,
                  }),
                  { replace: true },
                );
              }
              return id;
            } catch (errors) {
              extractErrors(setErrors)(errors);
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ handleSubmit, isValid, isSubmitting, values: { type, hasUnlockableFiles, image, isPurchasable } }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Stack spacing="12">
                  <Stack spacing="4">
                    <CategorySelectionField isLocked={lockFields?.includes('type')} />
                    {type === DROP_TYPES.EVENT ? (
                      <EventFields image={image} lockFields={lockFields} isPurchasable={isPurchasable} />
                    ) : (
                      <DropFields setUploads={setUploads} image={image} lockFields={lockFields} />
                    )}
                  </Stack>
                  {!hideSubmitBtn && (
                    <Button
                      isDisabled={loading || !isValid || (hasUnlockableFiles && uploading)}
                      isLoading={isSubmitting}
                      variant="primary"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit();
                      }}
                    >
                      {t('forms.CreateTokenContractForm.submit')}
                    </Button>
                  )}
                </Stack>
              </form>
            );
          }}
        </Formik>
      </Container>
    );
  },
);
