import { As, HStack, Icon, Skeleton, Text, IconProps as ChakraIconProps, TextProps } from '@chakra-ui/react';
import { IconProps, Ticket } from 'phosphor-react';
import { useMatch, useResolvedPath } from 'react-router-dom';

import { ImageOrPlaceholder } from 'components/atoms/ImageOrPlaceholder';
import { Link, type LinkProps } from 'components/atoms/Link';

import { CommunityLogoNav } from '../CommunityLogo';

interface SidebarLinkProps extends LinkProps {
  label: string;
  icon?: As;
  imageUrl?: string | null | undefined;
  nested?: boolean;
  isLoaded?: boolean;
  _active?: IconProps & ChakraIconProps;
  textProps?: TextProps;
}

export const SidebarLink = ({
  icon,
  imageUrl,
  label,
  to,
  textProps,
  nested = false,
  isLoaded = true,
  _active = { weight: 'fill' },
  children,
  ...rest
}: SidebarLinkProps) => {
  let resolved = useResolvedPath(to);
  let isActive = useMatch({ path: resolved.pathname, end: !nested });

  return (
    <>
      <Link
        to={to}
        display="block"
        px="4"
        fontWeight="bold"
        size="sm"
        aria-current={isActive ? 'page' : undefined}
        textColor="chakra-body-text"
        opacity={isActive ? 1 : 0.75}
        textDecoration="none"
        flexShrink={0}
        w="full"
        _hover={{
          opacity: 1,
        }}
        role="group"
        {...rest}
      >
        <HStack spacing="4">
          <Skeleton isLoaded={isLoaded} display="flex" py="4">
            {icon ? (
              <Icon as={icon} weight={isActive ? 'fill' : undefined} boxSize="24px" {...(isActive ? _active : {})} />
            ) : (
              imageUrl && <CommunityLogoNav logo={imageUrl} mx={{ base: '-6px', lg: undefined }} />
            )}
          </Skeleton>
          <Skeleton isLoaded={isLoaded}>
            <Text {...textProps}>{label}</Text>
          </Skeleton>
        </HStack>
      </Link>
      {children}
    </>
  );
};
