import { Flex, FormLabel, Text, Tooltip, FormLabelProps, TextProps, Icon } from '@chakra-ui/react';
import { Info } from 'phosphor-react';

export interface LabelProps extends FormLabelProps {
  label: string;
  name: string;
  description?: string;
  descriptionProps?: TextProps;
  tooltip?: string;
}

export const Label = ({ label, name, description, tooltip, descriptionProps, ...props }: LabelProps) => {
  return (
    <FormLabel cursor="pointer" htmlFor={name} {...props}>
      <Flex flex="1 0" alignItems="center">
        <Text fontSize="sm" mr={1} fontWeight="bold">
          {label}
        </Text>
        {!!tooltip && (
          <Tooltip placement="top" hasArrow label={tooltip}>
            <Icon weight="fill" boxSize={4} as={Info} color="gray.400" />
          </Tooltip>
        )}
      </Flex>
      {description && (
        <Text fontSize="sm" fontWeight="normal" {...descriptionProps}>
          {description}
        </Text>
      )}
    </FormLabel>
  );
};
