import { Route, Routes } from 'react-router-dom';
import { ROUTES } from 'routes';

import { RequireAuth } from 'app/decorators';
import { NotFoundPanel } from 'components/molecules/NotFoundPanel';
import { DashboardSidebar } from 'components/organisms/DashboardSidebar';
import { BaseSideBarLayout } from 'components/templates/BaseSideBar';

import { DashboardCommunityRoutes } from './[community]/routes';
import DashboardIndexPage from './page';

export const DashboardRoutes = () => {
  return (
    <BaseSideBarLayout sidebar={<DashboardSidebar />} navbarProps={{ isDashboard: true }}>
      <RequireAuth>
        <Routes>
          <Route path={ROUTES.dashboard.community.global} element={<DashboardCommunityRoutes />} />
          <Route path="/" element={<DashboardIndexPage />} />
          <Route path="*" element={<NotFoundPanel />} />
        </Routes>
      </RequireAuth>
    </BaseSideBarLayout>
  );
};
