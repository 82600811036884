import { gql } from '@apollo/client';
import { Skeleton, Stack, Text, SkeletonText } from '@chakra-ui/react';
import { OfferingType } from '__graphql__/globalTypes';
import { DateTime } from 'luxon';

import { UserMarkdown } from 'components/atoms/UserMarkdown';
import { DetailRow } from 'components/molecules/DetailRow';
import { OwnershipMessagePanel } from 'components/molecules/OwnershipMessagePanel';
import { UnlockableFilesPanel } from 'components/molecules/UnlockableFilesPanel';

import { ItemDetails_offering } from './__graphql__/ItemDetails_offering';

interface ItemDetailsProps {
  offering: ItemDetails_offering | null | undefined;
  includeDescription?: boolean;
  includeOwnershipMessage?: boolean;
}

export const ItemDetails = ({
  offering,
  includeDescription = true,
  includeOwnershipMessage = true,
}: ItemDetailsProps) => {
  return (
    <Stack spacing="8">
      {includeDescription && (!offering || offering?.description) && (
        <>
          {!offering && <SkeletonText noOfLines={3} isLoaded={false} />}
          <UserMarkdown>{offering?.description}</UserMarkdown>
        </>
      )}
      {includeOwnershipMessage && <OwnershipMessagePanel offering={offering} />}
      <UnlockableFilesPanel offering={offering} />
      <Stack p="4" borderRadius="10px" bg="chakra-subtle-bg">
        <Text variant="upper" fontWeight="bold" fontSize="sm">
          Details
        </Text>
        {offering?.startDateTime && (
          <DetailRow name="Start time">
            {DateTime.fromISO(offering.startDateTime).toFormat('ccc, d LLL y, t')}
          </DetailRow>
        )}
        {offering?.location && <DetailRow name="Location">{offering.location}</DetailRow>}
        <DetailRow name={offering?.type === OfferingType.COMPETITION ? 'Maximum entries' : 'Maximum available'}>
          <Skeleton isLoaded={!!offering}>{offering?.maxSupply ? offering?.maxSupply : 'Unlimited'}</Skeleton>
        </DetailRow>
      </Stack>
    </Stack>
  );
};

ItemDetails.fragments = {
  offering: gql`
    fragment ItemDetails_offering on Offering {
      id
      description
      maxSupply
      releasedAt
      type
      startDateTime
      endDateTime
      location
      unlockableFiles {
        title # not used directly but there is no ID
      }
      ...UnlockableFilesPanel_offering
      ...OwnershipMessagePanel_offering
    }
    ${UnlockableFilesPanel.fragments.offering}
    ${OwnershipMessagePanel.fragments.offering}
  `,
};
