'use client';

import { Heading, Stack } from '@chakra-ui/react';

import { DropDetails } from 'components/organisms/DropDetails';

const DropPage = () => {
  return (
    <Stack maxW="56rem">
      <DropDetails />
    </Stack>
  );
};

export default DropPage;
