import { Box, Text, Icon, Skeleton, Stack, StackProps } from '@chakra-ui/react';
import { ArrowFatUp, ArrowFatDown } from 'phosphor-react';

interface RevenueBoxProps extends StackProps {
  label: string;
  value: string | number;
  upwards?: boolean;
  loading?: boolean;
}

export const RevenueBox = ({ label, value, upwards, loading, ...props }: RevenueBoxProps) => {
  const isUpwards = typeof value === 'string' ? parseInt(value) >= 0 : value >= 0;
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      width={{ base: '100%', lg: '180px' }}
      height={{ base: '120px', lg: '100%' }}
      border="1px solid"
      borderColor="chakra-border-color"
      borderRadius="lg"
      {...props}
    >
      <Text variant="subtitle" fontSize={{ base: '16px', md: '20px' }} fontWeight="600">
        {label}
      </Text>
      <Box position="relative">
        {!loading && upwards && (
          <Box position="absolute" right={-4} top={-1}>
            <Icon
              as={isUpwards ? ArrowFatUp : ArrowFatDown}
              color={isUpwards ? '#60BEBF' : 'red.300'}
              weight="fill"
              boxSize="6"
              w="4"
              h="4"
              flexShrink="0"
            />
          </Box>
        )}
        <Skeleton isLoaded={!loading}>
          <Text fontSize="1.2em">{value}</Text>
        </Skeleton>
      </Box>
    </Stack>
  );
};
