import { Button, ButtonProps, useClipboard } from '@chakra-ui/react';

export const CopyShareLinkButton = (props: ButtonProps) => {
  const params = new URLSearchParams(window.location.search);
  ['username', 'displayName', 'email', 'magic_credential'].forEach((param) => {
    params.delete(param);
  });
  const qs = params.toString();
  const { onCopy, hasCopied } = useClipboard(window.location.href + (qs ? `?${qs}` : ''));

  return (
    <Button variant="primary" onClick={onCopy} {...props}>
      {hasCopied ? 'Copied' : 'Copy share link'}
    </Button>
  );
};
