import { Icon, IconProps } from '@chakra-ui/react';

import { ReactComponent as LogoBlackSvg } from 'assets/icons/logo-black.svg';
import { ReactComponent as LogoWhiteSvg } from 'assets/icons/logo-white.svg';
import { ReactComponent as LogoSvg } from 'assets/icons/logo.svg';

interface LogoProps extends IconProps {
  white?: Boolean;
  black?: Boolean;
}

export const Logo = ({ white, black, ...props }: LogoProps) => {
  if (white) return <LogoWhiteSvg />;
  if (black) return <LogoBlackSvg />;

  return (
    <Icon viewBox="0 0 24 30" boxSize="6" {...props}>
      <LogoSvg />
    </Icon>
  );
};
