import { Box, Heading, HStack, Icon, Link, Stack, StackProps } from '@chakra-ui/react';
import { ArrowUpRight } from 'phosphor-react';
import { useParams } from 'react-router-dom';

import { ReactComponent as StripeWordmark } from 'assets/icons/stripe-wordmark.svg';
import { IntegrationCard } from 'components/molecules/IntegrationCard';
import { StripeSetUpBtn } from 'components/molecules/StripeSetUpButton';
import { useStripe } from 'hooks/useStripe';

interface CommunityIntegrationsListProps extends StackProps {}

export const CommunityIntegrationsList = ({ ...rest }: CommunityIntegrationsListProps) => {
  const { communitySlug: slug } = useParams();
  const { isConnected, loading, dashboardUrl, communityId } = useStripe(slug);
  return (
    <Stack spacing="4" {...rest}>
      <IntegrationCard
        logo={StripeWordmark}
        iconProps={{ boxSize: '20', height: '40px' }}
        isLoaded={!!slug && !loading}
      >
        <HStack>
          <Stack mr="auto" spacing="1">
            <Heading variant="h4">Stripe</Heading>
            {isConnected ? (
              <>
                <Box>Connected</Box>
                <Link href={dashboardUrl || ''} isExternal>
                  Go to Stripe.com dashboard <Icon as={ArrowUpRight} mx="2px" />
                </Link>
              </>
            ) : (
              <Box>Temple uses Stripe to collect payments on your behalf.</Box>
            )}
          </Stack>
          {!isConnected && <StripeSetUpBtn communityId={communityId}>Set-up</StripeSetUpBtn>}
        </HStack>
      </IntegrationCard>
    </Stack>
  );
};
