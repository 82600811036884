import { createContext, useEffect, useState } from 'react';

interface CookieConsentContextState {
  categories: string[] | null;
}

export const CookieConsentContext = createContext<CookieConsentContextState>({ categories: null });

export const CookieConsentProvider = ({ children }: { children: React.ReactNode }) => {
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed.min.js';
    script.setAttribute('data-auto-block', 'off');
    script.setAttribute('data-website-uuid', 'ccce54e1-4fe2-4eba-8715-9a977f63e4e9');
    const el = document.body.appendChild(script);

    (window as any).getUpdatedCookieWhitelistByTermly = (data: any) => {
      setCategories(data.categories);
    };

    return () => {
      el.remove();
      delete (window as any).getUpdatedCookieWhitelistByTermly;
    };
  }, []);

  return <CookieConsentContext.Provider value={{ categories }}>{children}</CookieConsentContext.Provider>;
};
