import { gql } from '@apollo/client';
import { Box, HStack, Heading, Stack } from '@chakra-ui/react';
import { GrowthbookContext } from 'providers/Growthbook';
import { useContext, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import { CopyShareLinkButton } from 'components/atoms/CopyShareLinkButton';
import { Link } from 'components/atoms/Link';
import { CommunityShareButton } from 'components/molecules/CommunityShareButton';
import { MembershipCard } from 'components/molecules/MembershipCard';
import { SpotifyConnectButton } from 'components/molecules/SpotifyConnectButton';

import { CommunityJoinedConfirmation_community } from './__graphql__/CommunityJoinedConfirmation_community';

interface CommunityJoinedConfirmationProps {
  community: CommunityJoinedConfirmation_community;
}

export const CommunityJoinedConfirmation = ({ community }: CommunityJoinedConfirmationProps) => {
  const { t } = useTranslation(['fan', 'common'], { keyPrefix: 'organisms.CommunityJoinedConfirmation' });
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const then = params.get('then');
  const { growthbook } = useContext(GrowthbookContext);

  useEffect(() => {
    if (then) {
      navigate(then);
    }
  }, [then, navigate]);

  return (
    <Stack spacing="12" maxW="440px" textAlign="center" alignItems="center">
      <Stack spacing="2" alignItems="center" w="full">
        <Heading mb="0" variant="outline">
          {t('heading')}
        </Heading>
        <Box fontSize="lg">
          <Trans t={t} i18nKey="subheading" values={{ community: community.displayName }}>
            <Link to={ROUTES.community.base.set({ communitySlug: community.slug })} />
          </Trans>
        </Box>
      </Stack>

      <MembershipCard flexShrink="1" membership={community.currentMembership} />

      {!growthbook.isOn('hide_music_connections') && (
        <Stack spacing="4" alignItems="center">
          <Heading as="h4" fontSize="lg">
            {community.currentMembership?.user.spotifyConnection?.isActive
              ? 'Spotify Connected'
              : 'Connect your Spotify'}
          </Heading>
          <Box>Connect and stream {community.displayName} on Spotify to create more perk and unlock opportunities.</Box>
          <SpotifyConnectButton />
        </Stack>
      )}
      <Stack spacing="4" alignItems="center">
        <Heading as="h4" fontSize="lg">
          Spread the love
        </Heading>
        <Box>Share {community.displayName} with friends.</Box>
        <HStack>
          <CommunityShareButton community={community} variant="outline" borderWidth={1} />
          <CopyShareLinkButton variant="outline" borderWidth={1} />
        </HStack>
      </Stack>
    </Stack>
  );
};

CommunityJoinedConfirmation.fragments = {
  community: gql`
    fragment CommunityJoinedConfirmation_community on Community {
      id
      slug
      displayName
      isLive
      currentMembership {
        ...MembershipCard_membership
        user {
          id
          spotifyConnection {
            id
            isActive
            enableTopArtists
          }
        }
      }
      ...CommunityShareButton_community
      # creators {
      #   user {
      #     id
      #     username
      #     avatar(variant: md)
      #   }
      # }
    }
    ${CommunityShareButton.fragments.community}
    ${MembershipCard.fragments.membership}
  `,
};
