'use client';

import { Box, Container, Heading, Stack } from '@chakra-ui/react';
import { AuthContext } from 'providers/Auth';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';

import { UserMembershipsGrid } from 'components/organisms/UserMembershipsGrid';
import { useTrackPageView } from 'hooks/useTrackPageView';

const MyMembershipsPage = () => {
  const { user } = useContext(AuthContext);

  useTrackPageView('My Memberships');

  return (
    <Container px="0 !important">
      <Helmet>
        <title>Temple - Memberships</title>
      </Helmet>
      <Stack spacing="4">
        <Heading as="h1">Memberships</Heading>
        <UserMembershipsGrid
          username={user?.username}
          role="MEMBER"
          empty={<Box>You have not joined any Communities yet.</Box>}
        />
      </Stack>
    </Container>
  );
};

export default MyMembershipsPage;
