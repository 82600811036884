import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Button, Container, Heading, HStack, Stack, useBreakpointValue, useClipboard } from '@chakra-ui/react';
import { toast } from 'App';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { params, ROUTES } from 'routes';

import { RouterLink } from 'components/atoms/Link';
import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { OfferingCard } from 'components/molecules/OfferingCard';
import { ReleaseTokenButton } from 'components/molecules/ReleaseTokenButton';

import { OfferingChart } from '../Charts/OfferingChart';
import { ItemDetails } from '../ItemDetails';
import {
  CommunityDashboardTokenDetailsQuery,
  CommunityDashboardTokenDetailsQueryVariables,
} from './__graphql__/CommunityDashboardTokenDetailsQuery';
import { DeleteOffering, DeleteOfferingVariables } from './__graphql__/DeleteOffering';

export const QUERY = gql`
  query CommunityDashboardTokenDetailsQuery($id: ID!) {
    offering(id: $id) {
      id
      name
      releasedAt
      slug
      isSecret
      price
      community {
        id
        slug
      }
      claimKeys {
        key
      }
      ...OfferingCard_offering
      ...ItemDetails_offering
    }
  }
  ${OfferingCard.fragments.offering}
  ${ItemDetails.fragments.offering}
`;

const DELETE_DROP = gql`
  mutation DeleteOffering($id: ID!) {
    deleteOffering(id: $id)
  }
`;

// Can't we merge this with Offering Manager Card ????
export const DropDetails = () => {
  const { id } = useParams<keyof params>();
  const { data, loading } = useQuery<CommunityDashboardTokenDetailsQuery, CommunityDashboardTokenDetailsQueryVariables>(
    QUERY,
    {
      variables: { id: id ?? '' },
      skip: !id,
      // Can't explain why this is necessary but it does not seem to inherit the global
      // fetch policy as defined on the client
      fetchPolicy: 'network-only',
    },
  );
  const [deleteOffering] = useMutation<DeleteOffering, DeleteOfferingVariables>(DELETE_DROP);
  const navigate = useNavigate();

  const key = data?.offering?.claimKeys?.[0]?.key;
  const { onCopy, hasCopied, setValue } = useClipboard('');

  const isDesktop = useBreakpointValue({ base: false, lg: true });

  useEffect(() => {
    if (key) {
      setValue(
        window.location.origin +
          ROUTES.community.item.key.set({
            key,
            communitySlug: data?.offering?.community.slug,
            managedContractSlug: data?.offering?.slug,
          }),
      );
    }
  }, [data?.offering?.slug, data?.offering?.community.slug, key, setValue]);

  if (!id || loading) {
    return <WrappedSpinner />;
  }

  return (
    <Stack spacing={8}>
      <Heading variant="h2">Details for {data?.offering?.name || 'this drop.'}</Heading>
      <Stack spacing={8}>
        <HStack mx="auto" justifyContent="center" alignItems="flex-start" w="full">
          {isDesktop && (
            <Stack w="full">
              <OfferingCard
                offering={data?.offering}
                minW={{ base: '12rem', md: '20rem' }}
                w="full"
                flexGrow={{ base: undefined, lg: '0.4' }}
              />
            </Stack>
          )}
          <Stack spacing={isDesktop ? 10 : 8} w="48rem">
            <Heading variant="h3" textAlign="center">
              {data?.offering?.name}
            </Heading>
            {!isDesktop && (
              <Stack w="full">
                <OfferingCard
                  offering={data?.offering}
                  minW={{ base: '12rem', md: '20rem' }}
                  w="full"
                  flexGrow={{ base: undefined, lg: '0.4' }}
                />
              </Stack>
            )}
            <Stack spacing="4">
              <Box textAlign="center">
                <ItemDetails offering={data?.offering} includeOwnershipMessage={false} />
              </Box>
              <Stack spacing="2">
                {data?.offering?.releasedAt ? (
                  <Button
                    variant="primary"
                    as={RouterLink}
                    to={ROUTES.community.item.base.set({
                      communitySlug: data.offering.community.slug,
                      managedContractSlug: data.offering.slug,
                    })}
                  >
                    View Drop
                  </Button>
                ) : (
                  <ReleaseTokenButton id={data?.offering?.id} variant="primary" />
                )}
                <HStack>
                  {data?.offering?.isSecret &&
                    (key ? (
                      <Button w="50%" flexGrow="1" onClick={onCopy}>
                        {hasCopied ? 'Copied' : 'Copy invite link'}
                      </Button>
                    ) : (
                      <Button
                        as={RouterLink}
                        to={ROUTES.dashboard.community.itemsClaimLink.set({
                          communitySlug: data?.offering?.community.slug,
                          id: data?.offering?.id,
                        })}
                        w="50%"
                        flexGrow="1"
                      >
                        Create invite link
                      </Button>
                    ))}
                  <Button
                    as={RouterLink}
                    to={ROUTES.dashboard.community.itemsEdit.set({
                      communitySlug: data?.offering?.community.slug,
                      id: data?.offering?.id,
                    })}
                    w="50%"
                    flexGrow="1"
                  >
                    Edit
                  </Button>
                  <DeleteModal
                    showDeleteBtn={
                      !data?.offering?.releasedAt ||
                      (data?.offering?.releasedAt && new Date(data.offering.releasedAt).getTime() > Date.now())
                    }
                    onDelete={async () => {
                      if (!data?.offering?.id) return;
                      try {
                        const resp = await deleteOffering({
                          variables: {
                            id: data.offering.id,
                          },
                        });
                        if (resp.data?.deleteOffering === 'ok') {
                          navigate(
                            ROUTES.dashboard.community.items.set({
                              communitySlug: data?.offering?.community.slug,
                            }),
                          );
                        }
                      } catch (error) {
                        toast({
                          status: 'error',
                          description: 'Could not delete the drop',
                        });
                      }
                    }}
                  />
                </HStack>
              </Stack>
            </Stack>
          </Stack>
        </HStack>
        <Stack>
          <Heading variant="h4">Stats</Heading>
          <OfferingChart offeringId={data?.offering?.id} title={data?.offering?.price ? 'Purchases' : 'Claims'} />
        </Stack>
      </Stack>
    </Stack>
  );
};
