'use client';

import { gql, useQuery } from '@apollo/client';
import { Button, Heading, Stack, Skeleton } from '@chakra-ui/react';
import { OfferingType } from '__graphql__/globalTypes';
import { LockKeyOpen } from 'phosphor-react';
import { CommunityContext } from 'providers/Community';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';

import { ErrorMessage } from 'components/atoms/ErrorMessage';
import { LinkBox, RouterLink } from 'components/atoms/Link';
import { NotFoundPanel } from 'components/molecules/NotFoundPanel';
import { OfferingCard } from 'components/molecules/OfferingCard';
import { OwnershipMessagePanel } from 'components/molecules/OwnershipMessagePanel';
import { PagePanel } from 'components/molecules/PagePanel';
import { UnlockableFilesPanel } from 'components/molecules/UnlockableFilesPanel';
import { CommunityHeader } from 'components/organisms/CommunityHeader';
import { useIdFromSlug } from 'hooks/useIdFromSlug';
import { useTrackPageView } from 'hooks/useTrackPageView';

import { ItemReceiptPageQuery } from './__graphql__/ItemReceiptPageQuery';

export const QUERY = gql`
  query ItemReceiptPageQuery($id: ID!) {
    offering(id: $id) {
      id
      name
      isOwned
      slug
      type
      ...OfferingCard_offering
      ...UnlockableFilesPanel_offering
      ...OwnershipMessagePanel_offering
      community {
        id
        slug
      }
    }
  }
  ${OfferingCard.fragments.offering}
  ${UnlockableFilesPanel.fragments.offering}
  ${OwnershipMessagePanel.fragments.offering}
`;

const ItemReceiptPage = () => {
  const id = useIdFromSlug();
  const { community } = useContext(CommunityContext);

  const { data, loading, error } = useQuery<ItemReceiptPageQuery>(QUERY, {
    variables: { id },
    skip: !id,
  });
  const navigate = useNavigate();
  const token = data?.offering;

  useEffect(() => {
    if (data?.offering && !data.offering.isOwned) {
      navigate(
        ROUTES.community.item.base.set({
          managedContractSlug: data.offering.slug,
          communitySlug: data.offering.community.slug,
        }),
      );
    }
  }, [data?.offering, data?.offering?.isOwned, navigate]);

  useTrackPageView(
    'Item Receipt',
    {
      id: token?.id,
      isOwned: token?.isOwned,
      community_id: token?.community.id,
      community_slug: token?.community.slug,
    },
    !token,
  );

  if (!loading && !token) {
    return (
      <PagePanel isNarrow>
        <NotFoundPanel />
      </PagePanel>
    );
  }

  if (error) {
    return (
      <PagePanel isNarrow>
        <ErrorMessage />
      </PagePanel>
    );
  }

  return (
    <>
      <Helmet>
        {data?.offering && (
          <title>
            You {data?.offering?.type === OfferingType.COMPETITION ? 'entered' : 'got'} {data.offering.name}
          </title>
        )}
      </Helmet>
      <PagePanel isNarrow spacing={4}>
        <CommunityHeader includeName={false} community={community} align="center" />
        <Skeleton isLoaded={!!id && !loading} textAlign="center">
          <Heading variant="h1">
            You&rsquo;ve {data?.offering?.type === OfferingType.COMPETITION ? 'entered' : 'got it'}!
          </Heading>
        </Skeleton>
        <LinkBox
          to={ROUTES.community.item.base.set({
            communitySlug: community?.slug,
            managedContractSlug: data?.offering?.slug,
          })}
        >
          <OfferingCard minW="340px" icon={LockKeyOpen} isLoaded={!!id && !loading} offering={data?.offering} />
        </LinkBox>
        <OwnershipMessagePanel w="340px" offering={data?.offering} />
        <UnlockableFilesPanel hasSubtitle={false} w="340px" offering={data?.offering} />
        <Stack>
          <Button
            w="340px"
            as={RouterLink}
            isLoading={!id || loading}
            to={ROUTES.community.item.base.set({
              communitySlug: community?.slug,
              managedContractSlug: data?.offering?.slug,
            })}
            variant="primary"
          >
            Back to Drop
          </Button>
          <Button w="340px" as={RouterLink} isLoading={!id || loading} to={ROUTES.you.collection.path} variant="solid">
            View your Collection
          </Button>
        </Stack>
      </PagePanel>
    </>
  );
};

export default ItemReceiptPage;
