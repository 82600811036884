import { gql } from '@apollo/client';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useState } from 'react';
import { ROUTES } from 'routes';

import { CommunityShareButton_community } from './__graphql__/CommunityShareButton_community';

interface CommunityShareButtonProps extends ButtonProps {
  community?: CommunityShareButton_community | null;
}

export const CommunityShareButton = ({ community, ...rest }: CommunityShareButtonProps) => {
  const [loading, setLoading] = useState(false);
  const data: ShareData = {
    title: community?.displayName,
    text: `Check out ${community?.displayName} on Temple!`,
    url: window.location.origin + ROUTES.community.base.set({ communitySlug: community?.slug }),
  };
  const canShare = navigator.canShare && navigator.canShare(data);

  if (!canShare) {
    return null;
  }

  return (
    <Button
      variant="primary"
      flexGrow={1}
      isLoading={loading}
      onClick={async () => {
        setLoading(true);
        const navigator = window.navigator;
        if (canShare) {
          let blob: Blob | null = null;
          if (community?.logo) {
            try {
              blob = await fetch(community?.logo).then((r) => r.blob());
            } catch (e) {}
          }
          try {
            await navigator.share({
              ...data,
              files: blob
                ? [
                    new File([blob], `${community?.slug}.png`, {
                      type: blob.type,
                    }),
                  ]
                : [],
            });
          } catch (e) {}
        }
        setLoading(false);
      }}
      {...rest}
    >
      Share
    </Button>
  );
};

CommunityShareButton.fragments = {
  community: gql`
    fragment CommunityShareButton_community on Community {
      id
      slug
      displayName
      logo(variant: md)
    }
  `,
};
