import { gql, useQuery } from '@apollo/client';
import { Badge, Heading, Skeleton, SkeletonText, Stack, StackProps } from '@chakra-ui/react';
import { OfferingType } from '__graphql__/globalTypes';
import { useSearchParams } from 'react-router-dom';

import { UserMarkdown } from 'components/atoms/UserMarkdown';
import { OrderItemSummary } from 'components/molecules/OrderItemSummary';

import {
  CommunityWelcomeHeaderOfferingQuery,
  CommunityWelcomeHeaderOfferingQueryVariables,
} from './__graphql__/CommunityWelcomeHeaderOfferingQuery';
import { CommunityWelcomeHeader_community } from './__graphql__/CommunityWelcomeHeader_community';

interface CommunityWelcomeHeaderProps extends StackProps {
  community?: CommunityWelcomeHeader_community | null;
  sent?: boolean;
}

const OFFERING_QUERY = gql`
  query CommunityWelcomeHeaderOfferingQuery($id: ID!) {
    offering(id: $id) {
      id
      type
      community {
        id
      }
      ...OrderItemSummary_offering
    }
  }
  ${OrderItemSummary.fragments.offering}
`;

export const CommunityWelcomeHeader = ({ community, sent = false, ...rest }: CommunityWelcomeHeaderProps) => {
  const [params] = useSearchParams();
  let offeringId =
    localStorage?.getItem('offeringClaimIntent') || (params.get('then') || '').match(/\/item\/[^/]+-(\d+)/)?.[1];
  const { data } = useQuery<CommunityWelcomeHeaderOfferingQuery, CommunityWelcomeHeaderOfferingQueryVariables>(
    OFFERING_QUERY,
    { variables: { id: offeringId ?? '' }, skip: !offeringId },
  );

  return (
    <Stack spacing="6" alignItems="center" mb={8} {...rest}>
      <Heading>
        <Skeleton isLoaded={!!community}>{community?.displayName || 'Loading'}</Skeleton>
      </Heading>
      {!sent && (
        <>
          <SkeletonText noOfLines={2} isLoaded={!!community}>
            <UserMarkdown>{community ? community?.welcomeExplainer : 'Loading'}</UserMarkdown>
          </SkeletonText>
          {offeringId && (!data?.offering?.community.id || data?.offering?.community.id === community?.id) && (
            <OrderItemSummary
              w="full"
              textAlign="left"
              bg="chakra-subtle-bg"
              px={4}
              offering={data?.offering}
              mx="auto"
            >
              <Badge colorScheme="primary" color="offBlack" size="sm" mb={1}>
                Become a member to {data?.offering?.type === OfferingType.COMPETITION ? 'enter' : 'unlock'}
              </Badge>
            </OrderItemSummary>
          )}
        </>
      )}
    </Stack>
  );
};

CommunityWelcomeHeader.fragments = {
  community: gql`
    fragment CommunityWelcomeHeader_community on Community {
      id
      welcomeExplainer
      displayName
    }
  `,
};
