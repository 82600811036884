import { gql } from '@apollo/client';
import { Flex, FlexProps, Skeleton } from '@chakra-ui/react';

import { SocialLinkIcon } from 'components/atoms/SocialIcon';

import { CommunityNameAndBio_community_socialLinks } from '../CommunityNameAndBio/__graphql__/CommunityNameAndBio_community';
import { SocialLinksList_community_socialLinks } from './__graphql__/SocialLinksList_community';

interface SocialLinkListProps extends FlexProps {
  socialLinks?: SocialLinksList_community_socialLinks[] | CommunityNameAndBio_community_socialLinks[];
}

const SocialLinksList = ({ socialLinks, ...props }: SocialLinkListProps) => {
  return (
    <Skeleton isLoaded={!!socialLinks}>
      <Flex gap={2} {...props}>
        {socialLinks ? (
          socialLinks
            .slice()
            .sort((a, b) => a.order - b.order)
            .map((link) => <SocialLinkIcon key={link.order} linkType={link.type} url={link.url} />)
        ) : (
          <></>
        )}
      </Flex>
    </Skeleton>
  );
};

SocialLinksList.fragments = {
  socialLinks: gql`
    fragment SocialLinksList_community on Community {
      socialLinks {
        type
        url
        order
      }
    }
  `,
};

export default SocialLinksList;
