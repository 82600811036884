'use client';

import { gql, useQuery } from '@apollo/client';
import { Avatar, Box, Container, Heading, Skeleton, Stack, Tab, TabList, Tabs } from '@chakra-ui/react';
import { AuthContext } from 'providers/Auth';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes, useParams } from 'react-router-dom';
import { params, ROUTES } from 'routes';

import ErrorPage from 'app/error';
import NotFoundPage from 'app/not-found';
import { RouterLink } from 'components/atoms/Link';
import { Username } from 'components/atoms/Username';
import { UserMembershipsGrid } from 'components/organisms/UserMembershipsGrid';
import { BaseLayout } from 'components/templates/Base/base';
import { useTrackPageView } from 'hooks/useTrackPageView';

import { UserDetailPageQuery } from './__graphql__/UserDetailPageQuery';

export const QUERY = gql`
  query UserDetailPageQuery($username: String!) {
    user(username: $username) {
      id
      username
      displayName
      avatar(variant: md)
      communities(roles: [CREATOR]) {
        community {
          id
        }
      }
    }
  }
`;

const TAB_MAP = { memberships: 0 } as const;

const UserPage = () => {
  const { user, userLoading } = useContext(AuthContext);
  const { communitySlug, '*': splat } = useParams<keyof params | '*'>();
  const username = communitySlug?.substring(1);
  const { data, loading, error } = useQuery<UserDetailPageQuery>(QUERY, {
    variables: { username },
  });

  const tab = splat?.split('/')[0];

  useTrackPageView(
    'User',
    { user_username: data?.user?.username, user_isFounder: !!data?.user?.communities.length },
    !data?.user,
  );

  if (error) {
    return <ErrorPage />;
  }

  if (!loading && !data) {
    return (
      <Box textAlign="center" py="10">
        Sorry, something went wrong when loading this page.
      </Box>
    );
  }

  if ((!loading && !data?.user) || (tab && !(tab in TAB_MAP))) {
    return <NotFoundPage />;
  }

  return (
    <BaseLayout>
      <Container>
        <Helmet>{data?.user && <title>Temple - {data.user.displayName}</title>}</Helmet>
        <Stack spacing="12">
          <Stack
            alignItems={{ base: 'flex-start', lg: 'center' }}
            direction={{ base: 'column', lg: 'row' }}
            spacing={{ base: 4, lg: 8 }}
          >
            <Avatar size="2xl" src={data?.user?.avatar || undefined} />
            <Stack spacing="1">
              <Skeleton isLoaded={!loading}>
                <Heading as="h1">{data?.user?.displayName || 'Loading'}</Heading>
              </Skeleton>
              <Skeleton isLoaded={!loading}>
                <Username>{data?.user?.username || 'loading'}</Username>
              </Skeleton>
            </Stack>
          </Stack>
          <Stack spacing="4">
            {!userLoading && !loading && user?.username === username && (
              <Tabs isManual index={tab ?? '' in TAB_MAP ? TAB_MAP[tab as keyof typeof TAB_MAP] : 0}>
                <TabList>
                  <Tab as={RouterLink} to={ROUTES.user.base.set({ username: data?.user?.username })}>
                    {data?.user?.communities.length ? 'Founded' : 'Memberships'}
                  </Tab>
                </TabList>
              </Tabs>
            )}
            <Routes>
              <Route
                path={ROUTES.user.membership.relative}
                element={
                  <UserMembershipsGrid
                    showHeading={user?.username !== username}
                    showCreated
                    username={data?.user?.username}
                    empty={`${data?.user?.displayName} hasn't joined any Communities yet`}
                  />
                }
              />
            </Routes>
          </Stack>
        </Stack>
      </Container>
    </BaseLayout>
  );
};

export default UserPage;
