import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
  LinkBox as ChakraLinkBox,
  LinkBoxProps as ChakraLinkBoxProps,
  MenuItem,
  MenuItemProps,
} from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

export type LinkProps = ChakraLinkProps & RouterLinkProps;

export const Link: React.FC<LinkProps> = React.forwardRef((props: LinkProps, ref: React.Ref<any>) => {
  return <ChakraLink ref={ref} as={RouterLink} {...props} />;
});

export type LinkBoxProps = ChakraLinkBoxProps & RouterLinkProps;

export const LinkBox: React.FC<LinkBoxProps> = React.forwardRef((props: LinkBoxProps, ref: React.Ref<any>) => {
  return <ChakraLinkBox ref={ref} as={RouterLink} {...props} />;
});

export type MenuLinkProps = MenuItemProps & RouterLinkProps;

export const MenuLink: React.FC<MenuLinkProps> = React.forwardRef((props: MenuLinkProps, ref: React.Ref<any>) => {
  return <MenuItem ref={ref} as={RouterLink} {...props} />;
});

export { RouterLink, type RouterLinkProps };
