import { gql, useQuery } from '@apollo/client';
import { Icon, Stack } from '@chakra-ui/react';
import { Gear, Star, Ticket } from 'phosphor-react';
import { AuthContext } from 'providers/Auth';
import { CommunityContext } from 'providers/Community';
import { GrowthbookContext } from 'providers/Growthbook';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes';

import { Sidebar } from 'components/molecules/Sidebar';
import { SidebarLink } from 'components/molecules/SidebarLink';
import { SidebarToggle } from 'components/molecules/SidebarToggle';

import { DashboardSidebarQuery } from './__graphql__/DashboardSidebarQuery';

export const DASHBOARD_SIDEBAR_QUERY = gql`
  query DashboardSidebarQuery {
    me {
      id
      communities(roles: [CREATOR, MANAGER]) {
        community {
          id
          displayName
          slug
          logo(variant: md)
        }
      }
      memberships: communities(roles: [MEMBER]) {
        community {
          id
          displayName
          slug
          logo(variant: md)
        }
      }
      tokenCollection {
        id
      }
    }
  }
`;

export const DashboardSidebar = React.memo(() => {
  const { t } = useTranslation(['common', 'fan'], { keyPrefix: 'fan:sidebars.dashboard', useSuspense: false });
  const { data, loading } = useQuery<DashboardSidebarQuery>(DASHBOARD_SIDEBAR_QUERY);
  const isCreator = !loading && !!data?.me?.communities.length;

  const { currentCommunityId } = useContext(AuthContext);

  const { growthbook } = useContext(GrowthbookContext);

  if (loading) return <></>;

  const community = currentCommunityId
    ? data?.me?.communities.find((c) => c.community.id === currentCommunityId)
    : data?.me?.communities[0];

  return (
    <Sidebar>
      {isCreator && community && (
        <>
          <SidebarLink
            label={community.community.displayName}
            imageUrl={community.community.logo}
            to={ROUTES.dashboard.community.overview.set({ communitySlug: community.community.slug })}
            textProps={{ noOfLines: 2 }}
            nested
            key={community.community.id}
          >
            <Stack spacing="1">
              {growthbook?.isOn('message_criteria') && (
                <SidebarLink
                  label="Campaigns"
                  to={ROUTES.dashboard.community.message.set({
                    communitySlug: community.community.slug,
                  })}
                  pl="10"
                  key={community.community.id}
                  nested
                  fontSize="sm"
                />
              )}
              <SidebarLink
                label="Drops"
                to={ROUTES.dashboard.community.items.set({ communitySlug: community.community.slug })}
                pl="10"
                key={community.community.id}
                nested
                fontSize="sm"
              />
              <SidebarLink
                label="Links"
                to={ROUTES.dashboard.community.links.set({ communitySlug: community.community.slug })}
                pl="10"
                key={community.community.id}
                nested
                fontSize="sm"
              />
              <SidebarLink
                label="Blocks"
                to={ROUTES.dashboard.community.blocks.set({ communitySlug: community.community.slug })}
                pl="10"
                key={community.community.id}
                nested
                fontSize="sm"
              />
              <SidebarLink
                label="Integrations"
                to={ROUTES.dashboard.community.integrations.set({ communitySlug: community.community.slug })}
                pl="10"
                key={community.community.id}
                nested
                fontSize="sm"
              />
              <SidebarLink
                label="Settings"
                to={ROUTES.dashboard.community.settings.set({ communitySlug: community.community.slug })}
                pl="10"
                key={community.community.id}
                nested
                fontSize="sm"
                mb="4 !important"
              />
            </Stack>
          </SidebarLink>
        </>
      )}
      {data?.me?.tokenCollection?.length && (
        <SidebarLink label={t('collection')} to={ROUTES.you.collection.path} icon={Star} />
      )}
      {data?.me?.memberships?.length && (
        <SidebarLink label={t('memberships')} to={ROUTES.you.memberships.path} icon={Ticket} />
      )}
      {/* <SidebarLink label={t('settings')} nested to={ROUTES.you.settings.path} icon={Gear} /> */}
    </Sidebar>
  );
});
