import { gql, useMutation } from '@apollo/client';
import { Button, Container, Heading, Stack } from '@chakra-ui/react';
import { toast } from 'App';
import { Form, Formik, FormikErrors } from 'formik';

import { Input } from 'components/molecules/Input';
import { extractErrors, showError } from 'utils/errors';

import { Checkbox } from '../Fields';
import {
  AdminSpotifyPreSaveFormQuery,
  AdminSpotifyPreSaveFormQueryVariables,
} from './__graphql__/AdminSpotifyPreSaveFormQuery';

const MUTATION = gql`
  mutation AdminSpotifyPreSaveFormQuery($communityId: ID!, $spotifyId: String!, $album: Boolean!) {
    adminExecutePresave(communityId: $communityId, spotifyId: $spotifyId, album: $album)
  }
`;

export const AdminSpotifyPreSaveForm = () => {
  const [mutation] = useMutation<AdminSpotifyPreSaveFormQuery, AdminSpotifyPreSaveFormQueryVariables>(MUTATION);

  return (
    <Formik
      initialValues={{ communityId: '', spotifyId: '', album: true }}
      validate={(values) => {
        const errors: FormikErrors<typeof values> = {};
        if (!values.communityId) {
          errors.communityId = 'Required field';
        }
        if (!values.spotifyId) {
          errors.spotifyId = 'Required field';
        }
        return errors;
      }}
      onSubmit={async (variables, { setSubmitting, setErrors, resetForm }) => {
        try {
          const result = await mutation({ variables });
          if (result.data?.adminExecutePresave) {
            resetForm();
            toast({
              status: 'success',
              description: `Triggered pre save for ${result.data?.adminExecutePresave} user(s)`,
            });
          } else {
            showError(`Pre save failed, check the community ID and spotify ID`);
          }
        } catch (error) {
          extractErrors(setErrors)(error);
        }
        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Stack as={Container} variant="form" m="0" spacing="4">
            <Heading>Spotify Pre-Save</Heading>
            <Input name="communityId" label="Community ID (numeric)" isRequired />
            <Input name="spotifyId" label="Spotify Release ID" isRequired />
            <Checkbox label="Album" name="album" />
            <Button type="submit" variant="primary" isLoading={isSubmitting}>
              Submit
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
