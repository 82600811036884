'use client';

import { gql, useMutation } from '@apollo/client';
import { Container, Heading, Box, Stack } from '@chakra-ui/react';
import { toast } from 'App';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BaseLayout } from 'components/templates/Base/base';

import { UnsubscribeMutation } from './__graphql__/UnsubscribeMutation';

const UNSUBSCRIBE_MUTATION = gql`
  mutation UnsubscribeMutation($jwt: String!) {
    unsubscribe(jwt: $jwt)
  }
`;

const UnsubscribePage = () => {
  const params = new URLSearchParams(useLocation().search);
  const [unsubscribe] = useMutation<UnsubscribeMutation>(UNSUBSCRIBE_MUTATION);

  useEffect(() => {
    const runUnsubscribe = async () => {
      const token = params.get('token');
      if (!token) {
        toast({
          status: 'error',
          description: 'No unsubscribe token found',
        });
        return;
      }
      try {
        await unsubscribe({ variables: { jwt: token } });
        toast({
          status: 'success',
          description: 'Successfully unsubscribed',
        });
      } catch (e) {
        toast({
          status: 'error',
          description: 'Could not unsubscribe',
        });
      }
    };
    runUnsubscribe();
  }, [unsubscribe]);

  return (
    <BaseLayout navbarProps={{ isFullWidth: false }} footerProps={{ isFullWidth: false }}>
      <Container>
        <Stack spacing="2">
          <Stack textAlign="center" spacing="4">
            <Heading variant="h2">Unsubscribed</Heading>
            <Box>You will stop receiving emails from this community</Box>
            <Box>This will not affect any other communication settings you have set up</Box>
          </Stack>
        </Stack>
      </Container>
    </BaseLayout>
  );
};

export default UnsubscribePage;
