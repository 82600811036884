import {
  Flex,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableContainer,
  Checkbox as ChakraCheckbox,
  FlexProps,
} from '@chakra-ui/react';
import { Dispatch, SetStateAction } from 'react';

import { getOfferingTypeName } from 'components/molecules/OfferingCard';

import { CommunityOfferingsQuery_community_offerings } from './__graphql__/CommunityOfferingsQuery';

interface OfferingSelectionProps extends FlexProps {
  offerings: CommunityOfferingsQuery_community_offerings[];
  setOfferingId: Dispatch<SetStateAction<string | null>> | Dispatch<SetStateAction<string[]>>;
  offeringId: string | string[] | null;
}

export const ControlledOfferingSelection = ({
  offerings,
  setOfferingId,
  offeringId,
  ...props
}: OfferingSelectionProps) => {
  return (
    <Flex direction="column" {...props}>
      <TableContainer overflowY="auto" maxH="220px">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Select</Th>
              <Th>Name</Th>
              <Th>Type</Th>
            </Tr>
          </Thead>
          <Tbody>
            {offerings.map((offering: any) => (
              <Tr key={offering.id}>
                <Td>
                  <ChakraCheckbox
                    onChange={(e) => {
                      if (typeof offeringId === 'string') {
                        setOfferingId(e.currentTarget.checked ? offering.id : null);
                      }
                      if (Array.isArray(offeringId)) {
                        let newOfferingId = e.currentTarget.checked
                          ? [...offeringId, offering.id]
                          : ([...offeringId.filter((id) => id !== offering.id)] as string[]);
                        (setOfferingId as Dispatch<SetStateAction<string[]>>)(newOfferingId);
                      }
                    }}
                    isChecked={offeringId?.includes(offering.id) || offeringId === offering.id}
                  />
                </Td>
                <Td>{offering.name}</Td>
                <Td>{getOfferingTypeName(offering)}</Td>
              </Tr>
            ))}
          </Tbody>
          <Tfoot>
            <Tr></Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      {/* {!offeringId && <Button onClick={() => setOfferingId('new')}>New drop</Button>} */}
    </Flex>
  );
};
