'use client';

import { Heading, Stack } from '@chakra-ui/react';

import { CommunitySettingsForm } from 'components/forms/CommunitySettingsForm';

const CommunitySettingsPage = () => {
  return (
    <Stack maxW="36rem" spacing={8}>
      <Heading variant="h2">Settings</Heading>
      <CommunitySettingsForm />
    </Stack>
  );
};

export default CommunitySettingsPage;
