'use client';

import { Stack, Heading } from '@chakra-ui/react';

import { CommunityIntegrationsList } from 'components/organisms/CommunityIntegrationsList';

const DashboardIntegrationPage = () => {
  return (
    <Stack maxW="48rem" spacing={8}>
      <Heading variant="h2">Integrations</Heading>
      <CommunityIntegrationsList />
    </Stack>
  );
};

export default DashboardIntegrationPage;
