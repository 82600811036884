'use client';

import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';

import { DashboardIndexPageQuery } from './__graphql__/DashboardIndexPageQuery';

const QUERY = gql`
  query DashboardIndexPageQuery {
    me {
      id
      management: communities(roles: [CREATOR, MANAGER]) {
        community {
          id
          slug
        }
      }
    }
  }
`;

const DashboardIndexPage = () => {
  const { data, loading } = useQuery<DashboardIndexPageQuery>(QUERY);
  const navigate = useNavigate();

  const dashboardUrl = data?.me?.management.length
    ? ROUTES.dashboard.community.base.set({ communitySlug: data?.me?.management[0].community.slug })
    : ROUTES.you.memberships.path;

  useEffect(() => {
    if (data && !loading) {
      navigate(dashboardUrl, { replace: true });
    }
  }, [data, loading, dashboardUrl, navigate]);

  return null;
};

export default DashboardIndexPage;
