import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { useState } from 'react';

export interface DeleteModalProps {
  showDeleteBtn?: boolean;
  title?: string;
  body?: string;
  cta?: string;
  onDelete?: () => void;
}

export const DeleteModal = ({ title, body, cta, showDeleteBtn, onDelete }: DeleteModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  if (!showDeleteBtn || !onDelete) return null;
  return (
    <>
      <Button variant="danger" onClick={onOpen} isLoading={isLoading}>
        {cta || 'Delete'}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title || 'Delete item'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{body || 'You are about to definitively remove this item.'}</ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isLoading}>
              Close
            </Button>
            <Button
              variant="danger"
              isLoading={isLoading}
              onClick={async () => {
                setIsLoading(true);
                await onDelete();
                setIsLoading(false);
                onClose();
              }}
            >
              {cta || 'Delete'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
