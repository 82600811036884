import { gql } from '@apollo/client';
import {
  MenuItem,
  MenuDivider,
  Menu,
  MenuButton,
  MenuList,
  HStack,
  MenuGroup,
  MenuButtonProps,
  useColorMode,
  Icon,
  Image,
  Text,
} from '@chakra-ui/react';
import { Gear, House, Moon, PlusCircle, SignOut, Sun } from 'phosphor-react';
import { AuthContext } from 'providers/Auth';
import { ReactNode, useContext } from 'react';
import { ROUTES } from 'routes';

import { MenuLink } from 'components/atoms/Link';
import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { CommunityLogoNav } from 'components/molecules/CommunityLogo';

import { UserDropdown_user } from './__graphql__/UserDropdown_user';

interface UserDropdownProps extends MenuButtonProps {
  user: UserDropdown_user | null | undefined;
  children: ReactNode;
}

export const UserDropdown = ({ children, ...rest }: UserDropdownProps) => {
  const { logout, userLoading, user, logoutLoading, currentCommunityId, setCurrentCommunityId } =
    useContext(AuthContext);
  const { colorMode, toggleColorMode } = useColorMode();

  if (userLoading || !user) return null;

  if (logoutLoading) {
    return <WrappedSpinner />;
  }

  return (
    <Menu autoSelect={false}>
      <MenuButton {...rest}>
        <HStack spacing="3">{children}</HStack>
      </MenuButton>
      <MenuList>
        <MenuGroup title={user.displayName}>
          {!!user.managed.length &&
            user.managed.map((community) => (
              <MenuLink
                alignItems="center"
                justifyContent="center"
                maxWidth="420px"
                // whiteSpace="nowrap"
                // wordBreak="keep-all"
                textOverflow="ellipsis"
                overflow="hidden"
                icon={<CommunityLogoNav logo={community.community.logo} />}
                onClick={() => {
                  setCurrentCommunityId(community.community.id);
                }}
                to={ROUTES.dashboard.community.base.set({ communitySlug: community.community.slug })}
              >
                <Text
                  fontSize="lg"
                  fontWeight="500"
                  m={0}
                  textOverflow="ellipsis"
                  color={currentCommunityId === community.community.id ? 'gray.500' : ''}
                >
                  {community.community.displayName}
                </Text>
              </MenuLink>
            ))}
          <MenuLink to={ROUTES.dashboard.index.path}>Home</MenuLink>
          <MenuLink to={ROUTES.you.settings.path}>Settings</MenuLink>
          <MenuItem onClick={logout}>Log out</MenuItem>
        </MenuGroup>
        {user.isStaff && (
          <>
            <MenuDivider />
            <MenuGroup title="Labs (staff only)">
              <MenuItem icon={colorMode === 'light' ? <Moon /> : <Sun />} onClick={toggleColorMode}>
                Toggle {colorMode === 'light' ? 'Dark' : 'Light'} Mode
              </MenuItem>
            </MenuGroup>
            <MenuDivider />
            <MenuGroup title="Admin">
              <MenuLink icon={<PlusCircle />} to={`/admin/create-community?username=${user.username}`}>
                New Community
              </MenuLink>
            </MenuGroup>
          </>
        )}
      </MenuList>
    </Menu>
  );
};

UserDropdown.fragments = {
  user: gql`
    fragment UserDropdown_user on User {
      id
      isStaff
      managed: communities(roles: [CREATOR, MANAGER]) {
        community {
          id
          displayName
          slug
        }
      }
    }
  `,
};
