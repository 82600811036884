import { gql, useMutation, useQuery } from '@apollo/client';
import { Stack, Button, Textarea, Heading, InputLeftAddon, Container } from '@chakra-ui/react';
import { toast } from 'App';
import { Formik, FormikErrors } from 'formik';
import { Ticket } from 'phosphor-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import { ErrorMessage } from 'components/atoms/ErrorMessage';
import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { ImageInput } from 'components/molecules/ImageInput';
import { Input } from 'components/molecules/Input';
import { extractErrors } from 'utils/errors';

import {
  CommunitySettingsFormMutation,
  CommunitySettingsFormMutationVariables,
} from './__graphql__/CommunitySettingsFormMutation';
import { CommunitySettingsFormQuery } from './__graphql__/CommunitySettingsFormQuery';

export const COMMUNITY_SETTINGS_FORM_COMMUNITY_FRAGMENT = gql`
  fragment CommunitySettingsForm_community on Community {
    id
    displayName
    slug
    description
    welcomeExplainer
    logo(variant: md)
    background(variant: cover)
    isLive
  }
`;

export const SETTINGS_FORM_QUERY = gql`
  query CommunitySettingsFormQuery($slug: String!) {
    community(slug: $slug) {
      id
      ...CommunitySettingsForm_community
    }
  }
  ${COMMUNITY_SETTINGS_FORM_COMMUNITY_FRAGMENT}
`;

export const COMMUNITY_SETTINGS_FORM_MUTATION = gql`
  mutation CommunitySettingsFormMutation(
    $id: ID!
    $slug: String!
    $displayName: String!
    $description: String
    $welcomeExplainer: String
    $logoImage: Upload
    $backgroundImage: Upload
    $isLive: Boolean
  ) {
    updateCommunity(
      id: $id
      slug: $slug
      displayName: $displayName
      description: $description
      welcomeExplainer: $welcomeExplainer
      logoImage: $logoImage
      backgroundImage: $backgroundImage
      isLive: $isLive
    ) {
      ...CommunitySettingsForm_community
    }
  }
  ${COMMUNITY_SETTINGS_FORM_COMMUNITY_FRAGMENT}
`;

export const CommunitySettingsForm = () => {
  const { communitySlug: slug } = useParams();
  const { t } = useTranslation(['creator', 'common']);
  const { data, loading, error } = useQuery<CommunitySettingsFormQuery>(SETTINGS_FORM_QUERY, {
    variables: { slug },
    skip: !slug,
  });
  const [updateCommunity] = useMutation<CommunitySettingsFormMutation, CommunitySettingsFormMutationVariables>(
    COMMUNITY_SETTINGS_FORM_MUTATION,
  );
  const [logoImageChanged, setLogoChanged] = useState(false);
  const [backgroundImageChanged, setBackgroundImageChanged] = useState(false);
  const navigate = useNavigate();

  if (loading) {
    return <WrappedSpinner />;
  }

  if (error) {
    return <ErrorMessage />;
  }

  if (!data?.community) {
    return null;
  }

  return (
    <>
      <Formik
        initialValues={{
          slug: data.community.slug,
          displayName: data.community.displayName,
          description: data.community.description || '',
          welcomeExplainer: data.community.welcomeExplainer || '',
          logoImage: '',
          backgroundImage: '',
        }}
        validate={(values) => {
          const errors: FormikErrors<typeof values> = {};
          if (values.displayName.length < 3 || values.displayName.length > 30) {
            errors.displayName = t('common:errors.length', { min: 3, max: 30 });
          }
          if (values.slug.length < 3 || values.slug.length > 30) {
            errors.slug = t('common:errors.length', { min: 3, max: 30 });
          } else if (values.slug && !/^[0-9a-z]+[a-z0-9-]*[0-9a-z]+$/i.test(values.slug)) {
            errors.slug = t('common:errors.format', {
              rule: 'letters, numbers and dashes (and not start or end with a dash)',
            });
          }
          if (values.description.length > 1000) {
            errors.description = t('common:errors.maxLength', { max: 1000 });
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, setErrors }) => {
          const variables: any = { ...values };
          if (!logoImageChanged) {
            delete variables['logoImage'];
          }
          if (!backgroundImageChanged) {
            delete variables['backgroundImage'];
          }
          updateCommunity({
            variables: { ...variables, id: data.community?.id },
          })
            .then((result) => {
              toast({
                status: 'success',
                description: t('forms.CommunitySettingsForm.successToast'),
              });
              if (result.data?.updateCommunity?.slug) {
                setLogoChanged(false);
                setBackgroundImageChanged(false);
                navigate(
                  ROUTES.dashboard.community.settings.set({
                    communitySlug: result.data?.updateCommunity?.slug,
                  }),
                  { replace: true },
                );
              }
            })
            .catch(extractErrors(setErrors))
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ handleSubmit, isSubmitting, errors, setFieldValue }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Stack spacing="12">
              <Stack spacing="4">
                <ImageInput
                  label={t('forms.CommunitySettingsForm.fields.image.label')}
                  image={data.community?.logo}
                  outsideBorder
                  onChange={(file: File | null) => {
                    setFieldValue('logoImage', file);
                    setLogoChanged(true);
                  }}
                  helpText={t('forms.CommunitySettingsForm.fields.image.helpText')}
                  specText={t('forms.CommunitySettingsForm.fields.image.specText')}
                  errorMessage={errors.logoImage}
                  placeholderProps={{ icon: Ticket }}
                />
                <ImageInput
                  label="Background image"
                  image={data.community?.background}
                  onChange={(file: File | null) => {
                    setFieldValue('backgroundImage', file);
                    setBackgroundImageChanged(true);
                  }}
                  helpText={t('forms.CommunitySettingsForm.fields.backgroundImage.helpText')}
                  specText={t('forms.CommunitySettingsForm.fields.backgroundImage.specText')}
                  errorMessage={errors.backgroundImage}
                  placeholderProps={{ icon: Ticket }}
                  variant="wide"
                  width="100%"
                  ratio={2}
                  fit="cover"
                />
              </Stack>

              <Stack spacing="4">
                <Heading variant="h4">{t('forms.CommunitySettingsForm.heading')}</Heading>
                <Input
                  name="displayName"
                  label={t('forms.CommunitySettingsForm.fields.displayName.label')}
                  type="text"
                />
                <Input
                  name="slug"
                  label={t('forms.CommunitySettingsForm.fields.url.label')}
                  type="text"
                  leftChildren={<InputLeftAddon pointerEvents="none" children={`${window.location.origin}/`} />}
                />
                <Input
                  name="description"
                  label={t('forms.CommunitySettingsForm.fields.description.label')}
                  as={Textarea}
                />
                <Input
                  name="welcomeExplainer"
                  label={t('forms.CommunitySettingsForm.fields.welcomeExplainer.label')}
                  as={Textarea}
                />
              </Stack>

              <Button isLoading={isSubmitting} variant="primary" type="submit">
                {t('forms.CommunitySettingsForm.save')}
              </Button>
            </Stack>
          </form>
        )}
      </Formik>
    </>
  );
};
