import { gql } from '@apollo/client';
import { As, Box, Skeleton, Stack, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { ArchiveBox, DiceFive, Drop, Package, Ticket } from 'phosphor-react';

import { ReactComponent as Purchase } from 'assets/icons/purchase.svg';
import { ItemCard, ItemCardBody, ItemCardHeader, ItemCardImage, ItemCardProps } from 'components/molecules/ItemCard';

import { OfferingType } from '../../../__graphql__/globalTypes';
import { OfferingCard_offering } from './__graphql__/OfferingCard_offering';

export interface OfferingCardProps extends ItemCardProps {
  offering?: OfferingCard_offering | null;
  isLoaded?: boolean;
  icon?: As;
}

const getOfferingTypeIcon = (offering: OfferingCard_offering | null | undefined) => {
  if (offering?.type === OfferingType.EVENT) {
    return Ticket;
  }
  if (offering?.type === OfferingType.ATTENDANCE) {
    return Ticket;
  }
  if (offering?.type === OfferingType.PURCHASE) {
    return Purchase;
  }
  if (offering?.type === OfferingType.MERCH) {
    return Package;
  }
  if (offering?.type === OfferingType.COMPETITION) {
    return DiceFive;
  }
  if (offering?.type === OfferingType.CONTENT) {
    return ArchiveBox;
  }
  return Drop;
};

export const getOfferingTypeName = (offering: Pick<OfferingCard_offering, 'type' | 'price'> | null | undefined) => {
  if (offering?.type === OfferingType.EVENT) {
    if (!offering?.price) {
      return 'Guestlist';
    }
    return 'Ticket Pre-Sale';
  }
  if (offering?.type === OfferingType.ATTENDANCE) {
    return 'Attendance';
  }
  if (offering?.type === OfferingType.PURCHASE) {
    return 'Purchase';
  }
  if (offering?.type === OfferingType.MERCH) {
    return 'Merch';
  }
  if (offering?.type === OfferingType.COMPETITION) {
    return 'Competition';
  }
  if (offering?.type === OfferingType.CONTENT) {
    return 'Content Download';
  }
  return 'Drop';
};

export const OfferingCard = ({ offering, isLoaded = true, icon, ...rest }: OfferingCardProps) => {
  return (
    <ItemCard isLoaded={isLoaded} maxW="400px" {...rest}>
      <ItemCardHeader
        image={offering?.community.logo}
        imageIcon={Ticket}
        icon={icon || getOfferingTypeIcon(offering)}
        textAlign="right"
      >
        {offering?.startDateTime ? (
          <Box noOfLines={1}>{DateTime.fromISO(offering?.startDateTime).toFormat('ccc, d LLL, t')}</Box>
        ) : offering?.maxSupply ? (
          <Box fontWeight="bold">1 of {offering?.maxSupply}</Box>
        ) : null}
      </ItemCardHeader>
      <ItemCardImage image={offering?.image} />
      <ItemCardBody textAlign="center">
        <Stack spacing="1px">
          <Skeleton
            data-cy="collectibleCard-name"
            isLoaded={isLoaded}
            noOfLines={1}
            fontWeight="bold"
            fontFamily="heading"
          >
            {offering?.name || '-'}
          </Skeleton>
          <Skeleton data-cy="collectibleCard-supply" isLoaded={isLoaded} fontSize={{ base: 'xs', md: 'xs' }}>
            <Text variant="upper" fontWeight="medium">
              {getOfferingTypeName(offering)}
            </Text>
          </Skeleton>
        </Stack>
      </ItemCardBody>
    </ItemCard>
  );
};

OfferingCard.fragments = {
  offering: gql`
    fragment OfferingCard_offering on Offering {
      id
      maxSupply
      image(variant: card)
      name
      type
      price
      startDateTime
      community {
        id
        logo(variant: md)
      }
    }
  `,
};
