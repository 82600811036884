import { gql, useMutation } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { ReactNode, useState } from 'react';

import {
  CommunityStripeAccountMutation,
  CommunityStripeAccountMutationVariables,
} from './__graphql__/CommunityStripeAccountMutation';

export const STRIPE_ACCOUNT_MUTATION = gql`
  mutation CommunityStripeAccountMutation($communityId: ID!, $returnUrl: String!) {
    createStripeCommunityAccount(communityId: $communityId) {
      accountLinkUrl(returnUrl: $returnUrl)
    }
  }
`;

interface StripeSetUpBtnProps {
  communityId: string | undefined;
  children: ReactNode;
}

export const StripeSetUpBtn = ({ communityId, children }: StripeSetUpBtnProps) => {
  const [createStripeCommunityAccount] = useMutation<
    CommunityStripeAccountMutation,
    CommunityStripeAccountMutationVariables
  >(STRIPE_ACCOUNT_MUTATION);

  const [stripeLoading, setStripeLoading] = useState(false);

  const returnUrl = window.location.href;

  const setUp = async () => {
    if (!communityId) {
      return;
    }
    setStripeLoading(true);
    const response = await createStripeCommunityAccount({
      variables: { communityId, returnUrl },
    });
    if (response.data?.createStripeCommunityAccount?.accountLinkUrl) {
      window.location.href = response.data?.createStripeCommunityAccount?.accountLinkUrl;
    } else {
      setStripeLoading(false);
    }
  };

  return (
    <Button flexShrink="0" variant="outline" borderWidth="1px" isLoading={stripeLoading} onClick={setUp}>
      {children}
    </Button>
  );
};
