import { AspectRatio, Box, Heading, Stack } from '@chakra-ui/react';

import { ImageOrPlaceholder } from 'components/atoms/ImageOrPlaceholder';

interface LandingPageValuePropProps {
  image: string;
  title: string;
  description: string;
  align?: 'left' | 'right';
}

export const LandingPageValueProp = ({ image, title, description, align = 'left' }: LandingPageValuePropProps) => {
  return (
    <Stack
      direction={{ base: 'column', lg: align === 'left' ? 'row' : 'row-reverse' }}
      spacing={{ base: 8, lg: 8 }}
      alignItems={{ lg: 'center' }}
      mx="auto"
      w="full"
      my={{ base: 2, lg: 4 }}
      px="4"
    >
      <AspectRatio ratio={1} width={{ base: '300px' }} mx="auto">
        <ImageOrPlaceholder src={image} />
      </AspectRatio>
      <Stack spacing={{ base: 0, lg: 2 }} textAlign={{ base: 'center', lg: 'left' }} flexGrow="1">
        <Heading variant="h3" textTransform="uppercase">
          {title}
        </Heading>
        <Box dangerouslySetInnerHTML={{ __html: description }} />
      </Stack>
    </Stack>
  );
};
