import { As, HStack, Icon, Skeleton, Spacer, StackProps } from '@chakra-ui/react';
import { useContext } from 'react';

import { ImageOrPlaceholder } from 'components/atoms/ImageOrPlaceholder';
import { ItemCardContext } from 'components/molecules/ItemCard/context';

interface ItemCardHeaderProps extends StackProps {
  icon?: As;
  image?: string | null | undefined;
  imageIcon?: As;
}

export const ItemCardHeader = ({ image, imageIcon, textAlign, icon, children, ...rest }: ItemCardHeaderProps) => {
  const { isLoaded } = useContext(ItemCardContext);
  return (
    <HStack p="3" spacing="4" {...rest}>
      <ImageOrPlaceholder
        w="9"
        h="9"
        src={image}
        icon={imageIcon}
        iconProps={{ boxSize: '4' }}
        my="-2px"
        borderRadius="10px"
        flexShrink="0"
        fit="cover"
        isLoaded={isLoaded}
      />
      <Spacer />
      <Skeleton
        isLoaded={isLoaded}
        fontSize="xs"
        flexGrow="1"
        display={{ base: 'none', sm: 'block' }}
        textAlign={textAlign}
      >
        {children}
      </Skeleton>
      {icon && (
        <Skeleton isLoaded={isLoaded} w="6" h="6">
          <Icon as={icon} boxSize="6" w="6" h="6" flexShrink="0" />
        </Skeleton>
      )}
    </HStack>
  );
};
