import { Box, Container, Flex, useBreakpointValue } from '@chakra-ui/react';

import { BaseLayout, BaseLayoutProps } from 'components/templates/Base/base';

interface SidebarLayoutProps extends BaseLayoutProps {
  display?: 'flex' | 'block';
  sidebar: React.ReactNode;
}

export const BaseSideBarLayout = ({ sidebar, children, navbarProps, ...rest }: SidebarLayoutProps) => {
  const isSidebarVisible = useBreakpointValue({ base: false, lg: true });

  return (
    <BaseLayout navbarProps={{ dropdownChildren: !isSidebarVisible && sidebar, ...navbarProps }} {...rest}>
      <Container variant="wide">
        <Flex h="full">
          <Box
            display={{ base: 'none', lg: 'block' }}
            w={{ base: '220px', lg: '260px', xl: '300px' }}
            flexGrow={0}
            flexShrink={0}
          >
            {sidebar}
          </Box>
          <Container flexGrow="1" px={isSidebarVisible ? undefined : 0}>
            <Box w="full" h="full">
              {children}
            </Box>
          </Container>
        </Flex>
      </Container>
    </BaseLayout>
  );
};
