import { gql } from '@apollo/client';
import { Stack, StackProps, Text } from '@chakra-ui/react';

import { UserMarkdown } from 'components/atoms/UserMarkdown';

import { OwnershipMessagePanel_offering } from './__graphql__/OwnershipMessagePanel_offering';

interface OwnershipMessagePanelProps extends StackProps {
  offering: OwnershipMessagePanel_offering | null | undefined;
}

export const OwnershipMessagePanel = ({ offering, ...rest }: OwnershipMessagePanelProps) => {
  if (!offering?.isOwned || !offering?.ownershipMessage) {
    return null;
  }

  return (
    <Stack
      p="4"
      borderRadius="lg"
      borderColor="chakra-border-color"
      borderWidth="1px"
      width="full"
      textAlign="left"
      {...rest}
    >
      <Text variant="upper" fontWeight="bold" fontSize="sm">
        You&rsquo;ve Got It
      </Text>
      <UserMarkdown>{offering.ownershipMessage}</UserMarkdown>
    </Stack>
  );
};

OwnershipMessagePanel.fragments = {
  offering: gql`
    fragment OwnershipMessagePanel_offering on Offering {
      id
      isOwned
      ownershipMessage
    }
  `,
};
