import { gql } from '@apollo/client';
import { Box, Heading, Skeleton, SkeletonProps, Stack } from '@chakra-ui/react';
import { ROUTES } from 'routes';

import { Link } from 'components/atoms/Link';

import { ItemPageHeader_offering } from './__graphql__/ItemPageHeader_offering';

interface ItemPageHeaderProps extends SkeletonProps {
  offering: ItemPageHeader_offering | null | undefined;
}

export const ItemPageHeader = ({ offering, ...rest }: ItemPageHeaderProps) => {
  return (
    <Skeleton as={Stack} isLoaded={!!offering} {...rest}>
      <Heading variant="upper">{offering?.name || '&nbsp;'}</Heading>
      <Box>
        by{' '}
        <Link to={ROUTES.community.base.set({ communitySlug: offering?.community.slug })}>
          {offering?.community.displayName}
        </Link>
      </Box>
    </Skeleton>
  );
};

ItemPageHeader.fragments = {
  offering: gql`
    fragment ItemPageHeader_offering on Offering {
      id
      name
      community {
        id
        slug
        displayName
      }
    }
  `,
};
