import { gql, useQuery, useMutation } from '@apollo/client';
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Stack,
  useBreakpointValue,
} from '@chakra-ui/react';
import { toast } from 'App';
import { MatcherInput, MatcherName } from '__graphql__/globalTypes';
import { Formik, FormikErrors } from 'formik';
import { UsersThree, MusicNoteSimple, GlobeHemisphereWest, MapPinLine, CurrencyCircleDollar } from 'phosphor-react';
import { CommunityContext } from 'providers/Community';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { CriteriaPreview, CriteriaField } from 'components/forms/Criteria';
import { ControlledOfferingSelection } from 'components/forms/Criteria/offeringSelection';
import { Checkbox } from 'components/forms/Fields';
import { LocationField } from 'components/forms/LocationFields';
import { formatDate } from 'components/forms/OfferingForm/utils';
import { DeleteModal } from 'components/molecules/DeleteModal';
import { Input } from 'components/molecules/Input';
import { MarkdownEditor } from 'components/molecules/MarkdownEditor';
import { SwitchWithLabel } from 'components/molecules/SwitchWithLabel';
import { CommunityOfferingGrid } from 'components/organisms/CommunityOfferingGrid';
import { extractErrors } from 'utils/errors';

import { CommunityOfferingsQuery, CommunityOfferingsQueryVariables } from './__graphql__/CommunityOfferingsQuery';
import { CreateMessage, CreateMessageVariables } from './__graphql__/CreateMessage';
import { DeleteMessage, DeleteMessageVariables } from './__graphql__/DeleteMessage';
import { Message, MessageVariables } from './__graphql__/Message';
import { UpdateMessage, UpdateMessageVariables } from './__graphql__/UpdateMessage';

// https://github.com/microsoft/TypeScript/issues/16069#issuecomment-1516315173
function isPresent<T>(input: false | undefined | T): input is T {
  return !!input;
}

export const OFFERINGS_LIST = gql`
  query CommunityOfferingsQuery($slug: String!) {
    community(slug: $slug) {
      id
      ...CommunityOfferingGrid_community
    }
  }
  ${CommunityOfferingGrid.fragments.community}
`;

const CREATE_MESSAGE = gql`
  mutation CreateMessage(
    $communityId: ID!
    $title: String!
    $messageBody: String!
    $messageReleaseDate: String
    $isDraft: Boolean!
    $releaseNow: Boolean
    $messageOfferingLinks: [ID!]
    $matchersWithParameters: [MatcherInput!]
  ) {
    createMessage(
      communityId: $communityId
      title: $title
      messageBody: $messageBody
      messageReleaseDate: $messageReleaseDate
      isDraft: $isDraft
      releaseNow: $releaseNow
      messageOfferingLinks: $messageOfferingLinks
      matchersWithParameters: $matchersWithParameters
    ) {
      id
    }
  }
`;

const UPDATE_MESSAGE = gql`
  mutation UpdateMessage(
    $id: ID!
    $title: String!
    $messageBody: String!
    $messageReleaseDate: String
    $isDraft: Boolean!
    $releaseNow: Boolean
    $messageOfferingLinks: [ID!]
    $matchersWithParameters: [MatcherInput!]
  ) {
    updateMessage(
      id: $id
      title: $title
      messageBody: $messageBody
      messageReleaseDate: $messageReleaseDate
      isDraft: $isDraft
      releaseNow: $releaseNow
      messageOfferingLinks: $messageOfferingLinks
      matchersWithParameters: $matchersWithParameters
    ) {
      id
    }
  }
`;

const GET_MESSAGE = gql`
  query Message($id: ID!) {
    message(id: $id) {
      title
      messageBody
      messageReleaseDate
      messageOfferingLinks {
        offeringId
      }
      messageUserCriteria {
        id
        matcherName
        parameters
      }
    }
  }
`;

const DELETE_MESSAGE = gql`
  mutation DeleteMessage($id: ID!) {
    deleteMessage(id: $id) {
      id
    }
  }
`;

export const MessageForm = () => {
  const { community } = useContext(CommunityContext);

  const { data, loading } = useQuery<CommunityOfferingsQuery, CommunityOfferingsQueryVariables>(OFFERINGS_LIST, {
    variables: { slug: community?.slug ?? '' },
    skip: !community?.id,
  });

  const [createMessage] = useMutation<CreateMessage, CreateMessageVariables>(CREATE_MESSAGE, {
    refetchQueries: ['MessageListQuery'],
  });
  const [updateMessage] = useMutation<UpdateMessage, UpdateMessageVariables>(UPDATE_MESSAGE, {
    refetchQueries: ['MessageListQuery'],
  });
  const [deleteMessage] = useMutation<DeleteMessage, DeleteMessageVariables>(DELETE_MESSAGE, {
    refetchQueries: ['MessageListQuery'],
  });

  const { messageId } = useParams();

  const { data: messageData, loading: messageLoading } = useQuery<Message, MessageVariables>(GET_MESSAGE, {
    variables: { id: messageId ?? '' },
    skip: !messageId,
  });

  const ref = useRef<HTMLDivElement>(null);
  const mobileRef = useRef<HTMLDivElement>(null);

  const isMobile = useBreakpointValue({ base: true, lg: false });

  const initialOfferings = useMemo(
    () => messageData?.message?.messageOfferingLinks?.map((m) => m.offeringId) || [],
    [messageData],
  );
  const [offeringIds, setOfferingIds] = useState<string[]>(initialOfferings);

  useEffect(() => {
    setOfferingIds(initialOfferings);
  }, [messageData?.message?.messageOfferingLinks.length, initialOfferings]);

  const navigate = useNavigate();

  const matchers: { [name in MatcherName]?: MatcherInput } = Object.fromEntries(
    messageData?.message?.messageUserCriteria.map((c) => [
      c.matcherName,
      { matcherName: c.matcherName, parameters: c.parameters },
    ]) || [],
  );

  if (messageLoading) return <WrappedSpinner />;

  return (
    <Flex>
      <Stack as={Container} variant="form" px="0 !important" marginLeft={0}>
        <Heading variant="h2">Send a new message to your fans.</Heading>
        <Formik
          initialValues={{
            matchers,
            COUNTRY: matchers.COUNTRIES?.parameters?.length > 0,
            // TODO: city implies country
            CITY: matchers.CITIES?.parameters?.length > 0,
            countries: (matchers.COUNTRIES?.parameters || []) as string[],
            cities: (matchers.CITIES?.parameters || []) as string[],
            title: messageData?.message?.title || '',
            messageBody: messageData?.message?.messageBody || '',
            hasReleaseDate: !!messageData?.message?.messageReleaseDate,
            messageReleaseDate: messageData?.message?.messageReleaseDate
              ? formatDate(messageData.message.messageReleaseDate)
              : formatDate(Date()),
            offerings: messageData?.message?.messageOfferingLinks?.map((m) => m.offeringId) || [],
          }}
          validate={(values) => {
            const errors: FormikErrors<typeof values> = {};
            if (!values.messageBody) {
              errors.messageBody = 'A message is required';
            }
            if (values.hasReleaseDate && new Date(values.messageReleaseDate).getTime() < Date.now()) {
              errors.messageReleaseDate = 'A release date in the future is required';
            }
            return errors;
          }}
          onSubmit={async (values, { setErrors, setSubmitting }) => {
            try {
              const msg = {
                ...values,
                isDraft: false,
                releaseNow: !values.hasReleaseDate,
                messageReleaseDate: values.hasReleaseDate ? values.messageReleaseDate : null,
                messageOfferingLinks: offeringIds,
                matchersWithParameters: [
                  values.matchers.MEMBERS_ONLY && { matcherName: MatcherName.MEMBERS_ONLY },
                  values.matchers.SPOTIFY_PRE_SAVE_ALL_ENABLED && {
                    matcherName: MatcherName.SPOTIFY_PRE_SAVE_ALL_ENABLED,
                  },
                  values.matchers.SPOTIFY_TOP_ARTIST && { matcherName: MatcherName.SPOTIFY_TOP_ARTIST },
                  values.COUNTRY && { matcherName: MatcherName.COUNTRIES, parameters: values.countries },
                  values.CITY && { matcherName: MatcherName.CITIES, parameters: values.cities },
                ].filter(isPresent),
              } as const;
              if (messageId) {
                await updateMessage({
                  variables: {
                    ...msg,
                    id: messageId,
                  },
                });
                toast({
                  status: 'success',
                  description: 'Successfully updated',
                });
              } else {
                await createMessage({
                  variables: {
                    ...msg,
                    communityId: community?.id || '',
                  },
                });
                toast({
                  status: 'success',
                  description: 'Successfully saved',
                });
              }
              navigate(ROUTES.dashboard.community.message.set({ communitySlug: community?.slug }));
            } catch (errors) {
              extractErrors(setErrors)(errors);
              setSubmitting(false);
            }
          }}
        >
          {({ handleSubmit, submitForm, isSubmitting, values }) => {
            return (
              <form onSubmit={handleSubmit}>
                <CriteriaPreview
                  communityId={community?.id}
                  matchers={Object.entries(values.matchers)
                    .map(([name, value]) => ({
                      matcherName: name as MatcherName,
                      parameters: value,
                    }))
                    .filter((m) => !!m?.parameters)}
                  container={isMobile ? mobileRef.current : ref.current}
                />
                <FormControl isRequired>
                  <FormLabel fontSize="lg" fontWeight="bold">
                    Message Name
                  </FormLabel>
                  <Input name="title" />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel fontSize="lg" fontWeight="bold" mt={4}>
                    Send to
                  </FormLabel>
                  <Flex flex="1 0" flexWrap="wrap">
                    <CriteriaField
                      criteriaProps={{
                        name: 'matchers.MEMBERS_ONLY',
                        label: 'Members Only',
                        icon: UsersThree,
                      }}
                    />
                    <CriteriaField
                      criteriaProps={{
                        name: 'matchers.SPOTIFY_PRE_SAVE_ALL_ENABLED',
                        label: 'Pre-Save Enabled',
                        icon: CurrencyCircleDollar,
                      }}
                    />
                    <CriteriaField
                      criteriaProps={{
                        name: 'matchers.SPOTIFY_TOP_ARTIST',
                        label: 'Top streamers',
                        icon: MusicNoteSimple,
                      }}
                    />
                  </Flex>
                </FormControl>
                <FormControl my={4}>
                  <FormLabel>Location</FormLabel>
                  <FormHelperText mb={4}>
                    We'll only send this message to people located in the selected areas.
                  </FormHelperText>
                  <Flex justifyContent="flex-start" flexWrap="nowrap">
                    <Checkbox label="Country" name="COUNTRY" icon={GlobeHemisphereWest} />
                    <Checkbox label="City" name="CITY" icon={MapPinLine} />
                  </Flex>
                  <LocationField
                    isMulti
                    displayCity={values.CITY}
                    displayCountry={values.COUNTRY}
                    multiCountriesIso3={values.countries}
                  />
                </FormControl>

                <FormControl isRequired mt={4}>
                  <FormLabel fontSize="lg" fontWeight="bold">
                    Your Message
                  </FormLabel>
                  <MarkdownEditor name="messageBody" minH={300} />
                </FormControl>

                <FormControl mt={4}>
                  <SwitchWithLabel
                    label="Schedule message for later?"
                    name="hasReleaseDate"
                    description="You can schedule your message to be sent at a later date."
                    labelProps={{
                      fontSize: 'lg',
                      fontWeight: 'bold',
                    }}
                  >
                    <Input name="messageReleaseDate" type="datetime-local" />
                  </SwitchWithLabel>
                </FormControl>

                <FormControl mt={4}>
                  <FormLabel fontSize="lg" fontWeight="bold" mt={4}>
                    Link an additional drop to your message
                  </FormLabel>
                  <FormHelperText mb={4}>Link one or more additional drop to your message as a gift</FormHelperText>
                  <ControlledOfferingSelection
                    mt={4}
                    offerings={data?.community?.offerings || []}
                    name="offeringsControlled"
                    setOfferingId={setOfferingIds}
                    offeringId={offeringIds}
                  />
                </FormControl>
                {isMobile && (
                  <Flex flex="1 0" my={6}>
                    <Box ref={mobileRef} />
                  </Flex>
                )}
                <Flex flex="1 0" mt={4}>
                  <DeleteModal
                    cta="Delete Message"
                    showDeleteBtn={!!messageId}
                    onDelete={async () => {
                      if (messageId) {
                        await deleteMessage({ variables: { id: messageId } });
                        navigate(ROUTES.dashboard.community.message.set({ communitySlug: community?.slug }));
                      }
                    }}
                  />
                  <Button
                    type="submit"
                    ml="auto"
                    onClick={submitForm}
                    isDisabled={loading}
                    isLoading={isSubmitting}
                    variant="primary"
                  >
                    Send Message
                  </Button>
                </Flex>
              </form>
            );
          }}
        </Formik>
      </Stack>
      {!isMobile && (
        <Flex flex="1 0">
          <Box
            ml={10}
            border="1px solid"
            borderColor="gray.200"
            borderRadius="lg"
            h="300"
            minW="400"
            position="sticky"
            top={100}
          >
            <Box ref={ref} />
          </Box>
        </Flex>
      )}
    </Flex>
  );
};
