import { Container } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';

import { BaseLayout } from 'components/templates/Base/base';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    name?: string;
  }
}

interface PolicyPageProps {
  id: string;
}

export const PolicyPage = ({ id }: PolicyPageProps) => {
  const reload = () => {
    const script = document.createElement('script');
    script.src = 'https://app.termly.io/embed-policy.min.js';
    script.async = true;
    document.body.appendChild(script);
  };
  const ref = useRef(null);
  useEffect(() => {
    if (!ref.current) {
      reload();
    }
  });
  return (
    <BaseLayout>
      <Helmet>
        <script src="https://app.termly.io/embed-policy.min.js" async></script>
      </Helmet>
      <Container textAlign="center">
        <div name="termly-embed" ref={ref} data-id={id} data-type="iframe"></div>
      </Container>
    </BaseLayout>
  );
};
