import { Flex, FlexProps } from '@chakra-ui/react';

export const Separator = (props: FlexProps) => {
  const beforeAndAfter = {
    content: '""',
    height: '1px',
    flexGrow: 1,
    bg: 'chakra-border-color',
  };
  return (
    <Flex
      py={{ base: '1', lg: '2' }}
      alignItems="center"
      _before={{ marginRight: '4', ...beforeAndAfter }}
      _after={{ marginLeft: '4', ...beforeAndAfter }}
      fontSize="sm"
      textAlign="center"
      {...props}
    />
  );
};
