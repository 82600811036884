import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { AuthContext } from 'providers/Auth';
import { CommunityContext } from 'providers/Community';
import { useContext, useState } from 'react';

import { ReactComponent as GoogleLogo } from 'assets/icons/google.svg';

interface GoogleLoginButtonProps extends ButtonProps {
  then?: string | null;
  onClick?: () => void;
}

export const GoogleLoginButton = ({ then, onClick, ...rest }: GoogleLoginButtonProps) => {
  const [loading, setLoading] = useState(false);
  const { ssoProviderRedirect } = useContext(AuthContext);
  const { community } = useContext(CommunityContext);

  return (
    <Button
      variant="outline"
      borderWidth="1"
      isLoading={loading}
      isDisabled={loading}
      leftIcon={
        <Icon boxSize="5" viewBox="0 0 18 18">
          <GoogleLogo />
        </Icon>
      }
      onClick={async () => {
        if (onClick) {
          onClick();
        }
        setLoading(true);
        if (community) {
          localStorage?.setItem('community', community.slug);
        } else {
          localStorage?.removeItem('community');
        }
        await ssoProviderRedirect('google', then ? `?then=${then}` : '');
      }}
      {...rest}
    >
      Continue with Google
    </Button>
  );
};
