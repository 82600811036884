'use client';

import { gql, useQuery } from '@apollo/client';
import { Heading, Stack } from '@chakra-ui/react';
import { AuthContext } from 'providers/Auth';
import { CommunityContext } from 'providers/Community';
import { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from 'components/atoms/ErrorMessage';
import { PagePanel } from 'components/molecules/PagePanel';
import { CommunityHeader } from 'components/organisms/CommunityHeader';
import { CommunityOfferingGrid } from 'components/organisms/CommunityOfferingGrid';
import { useTrackPageView } from 'hooks/useTrackPageView';

import { CommunityOverviewQuery, CommunityOverviewQueryVariables } from './__graphql__/CommunityOverviewQuery';

export const QUERY = gql`
  query CommunityOverviewQuery($slug: String!) {
    community(slug: $slug) {
      id
      displayName
      isWhiteLabel
      ...CommunityOfferingGrid_community
      currentMembership {
        role
      }
    }
  }
  ${CommunityOfferingGrid.fragments.community}
`;

const CommunityDropsPage = () => {
  const { t } = useTranslation('common');
  const { community } = useContext(CommunityContext);

  const { data, error } = useQuery<CommunityOverviewQuery, CommunityOverviewQueryVariables>(QUERY, {
    variables: { slug: community?.slug ?? '' },
    skip: !community?.id,
  });
  const { user } = useContext(AuthContext);

  useTrackPageView(
    'Community Overview',
    {
      community_id: data?.community?.id,
      community_slug: community?.slug,
      isWhiteLabel: data?.community?.isWhiteLabel,
      isMember: !!data?.community?.currentMembership?.role,
      loggedIn: !!user,
    },
    !data?.community,
  );

  if (error) {
    return (
      <PagePanel isNarrow>
        <ErrorMessage />
      </PagePanel>
    );
  }

  return (
    <>
      <Helmet>{community && <title>{community.displayName} on Temple</title>}</Helmet>
      <PagePanel spacing="8">
        <CommunityHeader community={community} includeControls includeBio />
        <Stack spacing="4">
          <Heading variant="h4">{t('token_many')}</Heading>
          <CommunityOfferingGrid community={data?.community} />
        </Stack>
      </PagePanel>
    </>
  );
};

export default CommunityDropsPage;
