import { gql } from '@apollo/client';
import { HStack, StackProps } from '@chakra-ui/react';
import { ROUTES } from 'routes';

import { LinkBox } from 'components/atoms/Link';
import { OfferingCard } from 'components/molecules/OfferingCard';

import { LatestCommunityOfferings_community } from './__graphql__/LatestCommunityOfferings_community';

interface LatestCommunityOfferingsProps extends StackProps {
  community?: LatestCommunityOfferings_community | null;
  isLoaded?: boolean;
  numberOfOfferings?: number;
}

// TODO replace this
export const LatestCommunityOfferings = ({
  community,
  isLoaded = true,
  numberOfOfferings = 5,
  ...rest
}: LatestCommunityOfferingsProps) => {
  if (isLoaded && !community?.offerings) {
    return null;
  }

  const offerings = community?.offerings.slice(0, numberOfOfferings);

  return (
    <HStack
      overflowX="auto"
      _before={{ content: '""', margin: 'auto' }}
      _after={{ content: '""', margin: 'auto' }}
      spacing={{ base: 2, lg: 4 }}
      alignItems="stretch"
      {...rest}
    >
      {offerings?.map((offering) => (
        <LinkBox
          key={offering.id}
          to={ROUTES.community.item.base.set({
            managedContractSlug: offering.slug,
            communitySlug: community?.slug,
          })}
        >
          <OfferingCard w={{ base: '150px', sm: '200px', lg: '250px' }} h="full" offering={offering} />
        </LinkBox>
      ))}
    </HStack>
  );
};

LatestCommunityOfferings.fragments = {
  community: gql`
    fragment LatestCommunityOfferings_community on Community {
      id
      slug
      displayName
      offerings {
        id
        slug
        ...OfferingCard_offering
      }
    }
    ${OfferingCard.fragments.offering}
  `,
};
