export const COLORS = {
  brandYellow: '#F7FF8B',
  hoverYellow: '#e4f74a',
  offBlack: '#1D1D1F', // rgb(29, 29, 31)
  offGray: '#C7CBCC',
  offRed: '#FFCFCF',
  offWhite: '#F1F4F6',
  sizzurp: '#4C518C',
  lineGray: '#DCE2E5',
  darkGray: '#666666',
  grayAlbum: '#494949',
  dolphin: '#60BEBF',
};

export const themeColors = {
  secondary: {
    '50': '#EEEFF6',
    '100': '#D1D2E6',
    '200': '#B3B6D6',
    '300': '#9599C6',
    '400': '#777CB5',
    '500': '#5A60A5',
    '600': '#484C84',
    '700': '#363963',
    '800': '#242642',
    '900': '#121321',
  },
  // TODO remove this
  primary: {
    '50': COLORS.brandYellow,
    '100': COLORS.brandYellow,
    '200': COLORS.brandYellow,
    '300': COLORS.brandYellow,
    '400': COLORS.brandYellow,
    '500': COLORS.brandYellow,
    '600': COLORS.brandYellow,
    '700': COLORS.brandYellow,
    '800': COLORS.brandYellow,
    '900': COLORS.brandYellow,
  },
  brandYellow: COLORS.brandYellow,
  offBlack: COLORS.offBlack,
  offGray: COLORS.offGray,
  offRed: COLORS.offRed,
  offWhite: COLORS.offWhite,
  sizzurp: COLORS.sizzurp,
  lineGray: COLORS.lineGray,
  grayAlbum: COLORS.grayAlbum,
  dolphin: COLORS.dolphin,
};
