import { gql } from '@apollo/client';
import { Box, Flex, List, ListIcon, ListItem, Stack, Tooltip } from '@chakra-ui/react';
import { MatcherName } from '__graphql__/globalTypes';
import { XCircle, CheckCircle, Question } from 'phosphor-react';
import { AuthContext } from 'providers/Auth';
import { useContext } from 'react';

import { CollectibleClaimButton } from 'components/organisms/CollectibleClaimButton';

import { SpotifyConnectButton } from '../SpotifyConnectButton';
import { ItemCriteria_offering } from './__graphql__/ItemCriteria_offering';
import { ItemCriteria_offeringInviteCode } from './__graphql__/ItemCriteria_offeringInviteCode';

type ItemCriteriaProps = {
  offering?: ItemCriteria_offering | null;
  offeringInviteCode?: ItemCriteria_offeringInviteCode | null;
};

export const ItemCriteria = ({ offering, offeringInviteCode }: ItemCriteriaProps) => {
  const { user } = useContext(AuthContext);

  if (!offering?.unlockCriteria?.length) {
    return null;
  }

  if (
    offering?.unlockCriteria?.length === 1 &&
    offering.unlockCriteria[0].matcherName === MatcherName.SPOTIFY_PRE_SAVE_ALL_ENABLED
  ) {
    if (offering.unlockCriteria[0].isMet) {
      return null;
    }

    return user ? (
      <SpotifyConnectButton preSave={offering.community.id} />
    ) : (
      <CollectibleClaimButton
        offering={offering}
        offeringInviteCode={offeringInviteCode}
        ctaCopy="Sign up to Pre-Save and unlock"
      />
    );
  }

  return (
    <Stack>
      <Box textTransform="uppercase" fontWeight="bold" fontSize="sm">
        Unlock requirements
      </Box>
      <List spacing={2}>
        {offering.unlockCriteria.map((criteria) => (
          <ListItem>
            <Flex alignItems="center" mb={1}>
              <ListIcon
                as={criteria.isMet ? CheckCircle : XCircle}
                boxSize={6}
                mb="-2px"
                color={criteria.isMet ? 'green.500' : 'red.500'}
              />
              <Box mr={0.5} color="chakra-body-text">
                {criteria.friendlyName}
              </Box>
              {!criteria.isMet && (
                <Tooltip label={criteria.description} placement="right">
                  <Question size={16} style={{ color: 'offGray' }} />
                </Tooltip>
              )}
            </Flex>
          </ListItem>
        ))}
      </List>
    </Stack>
  );
};

ItemCriteria.fragments = {
  offering: gql`
    fragment ItemCriteria_offering on Offering {
      id
      community {
        id
      }
      unlockCriteria {
        matcherName
        isMet
        friendlyName
        description
      }
      ...CollectibleClaimButton_offering
    }
    ${CollectibleClaimButton.fragments.offering}
  `,
  offeringInviteCode: gql`
    fragment ItemCriteria_offeringInviteCode on OfferingInviteCode {
      ...CollectibleClaimButton_offeringInviteCode
    }
    ${CollectibleClaimButton.fragments.offeringInviteCode}
  `,
};
