import { gql, useApolloClient } from '@apollo/client';
import { Box, Button, Container, Heading, Stack } from '@chakra-ui/react';
import { useSearchParams } from 'react-router-dom';

import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { SpotifyConnectButton } from 'components/molecules/SpotifyConnectButton';

import { CommunitySpotifyInterstitial_community } from './__graphql__/CommunitySpotifyInterstitial_community';

interface CommunitySpotifyInterstitialProps {
  community: CommunitySpotifyInterstitial_community | null | undefined;
}

export const CommunitySpotifyInterstitial = ({ community }: CommunitySpotifyInterstitialProps) => {
  const client = useApolloClient();
  const [params] = useSearchParams();

  if (!community) {
    return <WrappedSpinner />;
  }

  return (
    <Container variant="form">
      <Stack spacing="6" textAlign="center" alignItems="center">
        <Heading>Connect your Spotify</Heading>
        <Box>Link your Spotify to stand out from the crowd if you're a big listener of {community.displayName}.</Box>
        <Stack>
          <SpotifyConnectButton then={params.get('then') || undefined} />
          <Button
            variant="unstyled"
            onClick={() => {
              client.resetStore();
            }}
          >
            Skip, I'll do it later
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
};

CommunitySpotifyInterstitial.fragments = {
  community: gql`
    fragment CommunitySpotifyInterstitial_community on Community {
      id
      displayName
    }
  `,
};
