import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

interface CallbackParams {
  username: string | null | undefined;
  displayName: string | null | undefined;
  email: string | null | undefined;
  magicCredential: string;
}

export const useMagicAuthURLParams = () => {
  const [params, setParams] = useSearchParams();
  const [callbackParams, setCallbackParams] = useState<CallbackParams | null>(null);

  const magicCredential = params.get('magic_credential');
  const formEmail = params.get('email');
  const formUsername = params.get('username');
  const formDisplayName = params.get('displayName');

  useEffect(() => {
    const credential = magicCredential || callbackParams?.magicCredential;
    setCallbackParams(
      credential
        ? {
            username: formUsername || callbackParams?.username,
            email: formEmail || callbackParams?.email,
            displayName: formDisplayName || callbackParams?.displayName,
            magicCredential: credential,
          }
        : null,
    );
    // const strippedParams = Object.fromEntries(
    //   Array.from(params.entries()).filter(
    //     ([param]) => !['username', 'displayName', 'email', 'magic_credential'].includes(param),
    //   ),
    // );
    // setParams(strippedParams, { replace: true });
  }, [
    formUsername,
    formEmail,
    formDisplayName,
    magicCredential,
    setCallbackParams,
    callbackParams?.username,
    callbackParams?.email,
    callbackParams?.displayName,
    callbackParams?.magicCredential,
    setParams,
    params,
  ]);

  return callbackParams;
};
