import { gql } from '@apollo/client';
import { Box, HStack, Icon, Link, Spacer, Stack, StackProps, Text } from '@chakra-ui/react';
import { DownloadSimple, Lock } from 'phosphor-react';
import { useTranslation } from 'react-i18next';

import { UnlockableFilesPanel_offering } from './__graphql__/UnlockableFilesPanel_offering';

interface UnlockableFilesPanelProps extends StackProps {
  offering: UnlockableFilesPanel_offering | null | undefined;
  hasSubtitle?: boolean;
}

export const UnlockableFilesPanel = ({ offering, hasSubtitle = true, ...rest }: UnlockableFilesPanelProps) => {
  const { t } = useTranslation('common', { keyPrefix: 'molecules.UnlockableFilesPanel' });

  if (!offering?.unlockableFiles.length) {
    return null;
  }

  return (
    <Stack
      p="4"
      borderRadius="lg"
      borderColor="chakra-border-color"
      borderWidth="1px"
      width="full"
      textAlign="left"
      {...rest}
    >
      <Text variant="upper" fontWeight="bold" fontSize="sm">
        {t('title')}
      </Text>
      {hasSubtitle && <Text fontSize="sm">{t('subtitle')}</Text>}
      {offering?.unlockableFiles.map((file, idx) => (
        <HStack key={idx}>
          <Stack spacing="0">
            <Box overflowWrap="anywhere">{file.title}</Box>
            {file.downloadUrl && (
              <Box fontSize="sm" color="ghost">
                {t('downloadLink', { fileType: file.fileType })}
              </Box>
            )}
          </Stack>
          <Spacer />
          {file.downloadUrl ? (
            <Link href={file.downloadUrl} download color="inherit">
              <Icon weight="fill" as={DownloadSimple} boxSize="5" />
            </Link>
          ) : (
            <>
              <Box>{t('lockedLink')}</Box>
              <Icon weight="fill" as={Lock} />
            </>
          )}
        </HStack>
      ))}
    </Stack>
  );
};

UnlockableFilesPanel.fragments = {
  offering: gql`
    fragment UnlockableFilesPanel_offering on Offering {
      id
      community {
        id
      }
      unlockableFiles {
        title
        downloadUrl
        fileSize
        fileType
      }
    }
  `,
};
