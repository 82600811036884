import equal from 'fast-deep-equal';
import { AuthContext } from 'providers/Auth';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { useMixpanel } from './useMixpanel';

export const useTrackPageView = (page: string, data?: object | undefined, skip: boolean = false) => {
  const { user, userLoading } = useContext(AuthContext);
  const params = useParams();
  const { mixpanel, initialised } = useMixpanel();
  const location = useLocation();
  const [lastData, setLastData] = useState<any>(false);

  useEffect(() => {
    if (!userLoading && !skip && !equal(data, lastData) && initialised) {
      mixpanel?.track('Page View', { page, params, path: location.pathname, distinct_id: user?.id, ...data });
      setLastData(data);
    }
  }, [user, userLoading, data, mixpanel, initialised, page, params, location.pathname, skip, lastData]);
};
