import { gql, useQuery } from '@apollo/client';
import { useColorModeValue, useTheme } from '@chakra-ui/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useEffect, useState } from 'react';

import { WrappedSpinner } from 'components/atoms/WrappedSpinner';

import { StripeSetupIntentProviderQuery } from './__graphql__/StripeSetupIntentProviderQuery';

interface StripeSetupIntentProviderProps {
  children: React.ReactNode;
}

// This is called outside of a component to avoid recreating the Stripe object
// on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || '');

const QUERY = gql`
  query StripeSetupIntentProviderQuery {
    stripeSetupIntentClientSecret
  }
`;

export const StripeSetupIntentProvider = ({ children }: StripeSetupIntentProviderProps) => {
  const { data, loading } = useQuery<StripeSetupIntentProviderQuery>(QUERY);
  const [stripe, setStripe] = useState<Stripe | null>(null);

  const theme = useTheme();
  const textColor = useColorModeValue('#1A202C', theme.colors.offWhite);
  const bgColor = useColorModeValue('white', theme.colors.offBlack);

  const options = {
    clientSecret: data?.stripeSetupIntentClientSecret || undefined,
    appearance: {
      variables: {
        fontFamily: 'Inter, system-ui, sans-serif',
        colorPrimary: theme.colors.primary[500],
        colorText: textColor,
        colorBackground: bgColor,
      },
    },
  };

  useEffect(() => {
    stripePromise.then(setStripe);
  }, []);

  if (loading || !stripe) {
    return <WrappedSpinner />;
  }

  if (!data?.stripeSetupIntentClientSecret) {
    return null;
  }

  return (
    <Elements options={options} stripe={stripe}>
      {children}
    </Elements>
  );
};
