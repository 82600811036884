'use client';

import { Box, Button, Container, Heading, Stack } from '@chakra-ui/react';

import { Paragraph } from 'components/atoms/Paragraph';
import { BaseLayout } from 'components/templates/Base/base';

const ErrorPage = () => (
  <BaseLayout>
    <Container textAlign="center" py="32">
      <Heading variant="h1">Oops</Heading>
      <Stack>
        <Paragraph color="gray.500" fontSize="lg">
          Sorry but something went wrong while loading this page.
        </Paragraph>
        <Box>
          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Reload page
          </Button>
        </Box>
      </Stack>
    </Container>
  </BaseLayout>
);

export default ErrorPage;
