import mixpanel from 'mixpanel-browser';
import { CookieConsentContext } from 'providers/CookieConsent';
import { useContext, useEffect, useState } from 'react';

export const useMixpanel = () => {
  const { categories } = useContext(CookieConsentContext);
  const [callQueue, setCallQueue] = useState<(() => void)[]>([]);
  const [initialised, setInitialised] = useState(false);

  useEffect(() => {
    if (categories !== null && !initialised) {
      if (process.env.REACT_APP_MIXPANEL_TOKEN) {
        // Initialise Mixpanel only when we know the user's cookie consent preferences
        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
          debug: process.env.NODE_ENV === 'development',
          api_host: process.env.REACT_APP_API_URL + '/events',
          disable_cookie: !categories.includes('analytics'),
        });

        // Run any calls that were queued for post-initialisation
        callQueue.forEach((call) => {
          call();
        });
      }
      setInitialised(true);
    }
  }, [categories, callQueue, initialised]);

  // Proxy Mixpanel instance to queue calls until we know the user's cookie consent preferences
  const proxy = new Proxy(mixpanel, {
    get: (target: any, prop) => {
      const value = target[prop];
      if (value instanceof Function && !initialised) {
        return function (...args: unknown[]) {
          setCallQueue([
            ...callQueue,
            () => {
              // Queue a function to call the intended Mixpanel function
              value.apply(target, args);
            },
          ]);
        };
      }
      return value;
    },
  });

  return {
    mixpanel: process.env.REACT_APP_MIXPANEL_TOKEN ? (initialised ? mixpanel : proxy) : null,
    initialised,
  };
};
