import { Flex, FormControl, FormControlProps, FormLabel, Icon, Input, Text } from '@chakra-ui/react';
import { useField } from 'formik';
import { IconProps } from 'phosphor-react';

export interface CheckboxProps extends FormControlProps {
  label: string;
  name: string;
  icon?: React.ForwardRefExoticComponent<IconProps & React.RefAttributes<SVGSVGElement>>;
  isLocked?: boolean;
  children?: React.ReactNode;
}

export const Checkbox = ({ label, name, icon, isLocked, children, onChange, ...props }: CheckboxProps) => {
  const [field, meta, helpers] = useField<boolean>(name);
  const { setValue } = helpers;

  return (
    <FormControl width="auto" {...props}>
      <FormLabel
        name={name}
        py={4}
        px={6}
        border="1px"
        borderRadius="lg"
        borderColor="chakra-border-color"
        bgColor={isLocked || meta.value ? 'chakra-subtle-bg' : 'chakra-background-color'}
        display="inline-block"
        cursor={isLocked ? '' : 'pointer'}
      >
        <Flex alignItems="center" flexWrap="nowrap">
          {icon && <Icon as={icon} boxSize="6" mr="2" />}
          <Text>{label}</Text>
        </Flex>
      </FormLabel>
      <Input
        type="checkbox"
        {...field}
        name={name}
        value={meta.value ? 1 : 0}
        hidden
        onChange={(e) => {
          if (!isLocked) {
            onChange && onChange(e);
            setValue(!meta.value);
          }
        }}
      />
      {children}
    </FormControl>
  );
};
