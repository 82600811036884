import { gql } from '@apollo/client';
import { Box, VStack, Skeleton, Text, Divider } from '@chakra-ui/react';

import { LinkInBioEmbeds_community_blocks } from './__graphql__/LinkInBioEmbeds_community';

interface EmbedBlockCardProps {
  block: LinkInBioEmbeds_community_blocks;
  isLoaded: boolean;
}

const EmbedBlockCard = ({ block, isLoaded }: EmbedBlockCardProps) => {
  return isLoaded && block.html ? (
    // override iframe styling
    <Box borderWidth="1px" borderRadius={10} p={1} w="100%">
      <Box dangerouslySetInnerHTML={{ __html: block.html }} />
      <Divider mt={2} />
      <Box p={4} textAlign="center">
        <Text fontWeight="bold" textAlign="center">
          {block.title}
        </Text>
      </Box>
    </Box>
  ) : (
    <Skeleton height="100%" width="100%" />
  );
};

const EmbedBlocksList = ({ blocks }: { blocks?: LinkInBioEmbeds_community_blocks[] }) => {
  return (
    <>
      {!!blocks && (
        <VStack align="center" spacing={3} w="100%">
          {blocks.map((block) => (
            <EmbedBlockCard block={block} isLoaded={!!blocks} />
          ))}
        </VStack>
      )}
    </>
  );
};

EmbedBlocksList.fragments = {
  blocks: gql`
    fragment LinkInBioEmbeds_community on Community {
      blocks {
        title
        url
        html
      }
    }
  `,
};

export default EmbedBlocksList;
