import { Avatar, extendTheme, Toast } from '@chakra-ui/react';
import { User } from 'phosphor-react';

import { themeColors, COLORS } from './color';
import { switchTheme } from './switch';

export default extendTheme({
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
    cssVarPrefix: 'temple',
  },
  breakpoints: {
    xs: '22em' /* 352px */,
    sm: '30em' /* 480px */,
    md: '48em' /* 768px */,
    lg: '62em' /* 992px */,
    xl: '80em' /* 1280px */,
    '2xl': '88em' /* 1408px */,
    '3xl': '96em' /* 1536px */,
    '4xl': '108em' /* 1728px */,
  },
  fonts: {
    body: 'Inter, system-ui, sans-serif',
    heading: 'SuisseIntl, Inter, system-ui, sans-serif',
    marketing: 'SuisseIntl, Inter, system-ui, sans-serif',
  },
  colors: themeColors,
  textStyles: {
    address: {
      fontFamily: 'mono',
      // fontSize: "sm",
      // color: "muted",
    },
    cardHeader: {
      fontWeight: '400',
    },
  },
  radii: {
    lg: '10px',
  },
  semanticTokens: {
    colors: {
      'chakra-body-text': { _light: 'gray.800', _dark: 'whiteAlpha.900' },
      'chakra-body-bg': { _light: 'white', _dark: 'offBlack' },
      'chakra-border-color': { _light: 'lineGray', _dark: 'whiteAlpha.300' },
      'chakra-subtle-bg': { _light: 'gray.100', _dark: 'grayAlbum' },
      'chakra-placeholder-color': { _light: 'gray.500', _dark: 'whiteAlpha.400' },
      'primary-inverse': { _light: 'offBlack', _dark: 'white' },
      'primary-inverse-fg': { _light: 'white', _dark: 'offBlack' },
      'link-color': { _light: 'sizzurp', _dark: 'dolphin' },
      error: {
        default: 'red.500',
        _dark: 'red.300',
      },
      muted: 'gray.500',
      whiteBackdrop: {
        default: 'white',
        _dark: 'gray.900',
      },
      accentedBackdrop: {
        default: 'white',
        _dark: 'gray.800',
      },
      logo: {
        default: '#1d1d1d',
        _dark: 'white',
      },
      shaded: {
        default: 'gray.100',
        _dark: 'gray.900',
      },
      shadow: {
        default: 'blackAlpha.50',
        _dark: 'whiteAlpha.50',
      },
      ghost: {
        default: 'gray.400',
        _dark: 'gray.600',
      },
      outlineColor: {
        default: 'gray.200',
        _dark: 'gray.700',
      },
      foreground: {
        default: 'offBlack',
        _dark: 'offWhite',
      },
      background: {
        default: 'white',
        _dark: 'offBlack',
      },
    },
  },
  components: {
    Avatar: {
      baseStyle: {
        container: { borderWidth: `2px`, borderColor: 'primary-inverse-fg', background: 'chakra-subtle-bg' },
      },
    },
    Alert: {
      baseStyle: {
        container: {
          borderRadius: 'md',
        },
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 'bold',
        borderRadius: 'lg',
      },
      defaultProps: {
        size: { base: 'md', lg: 'lg' },
      },
      sizes: {
        md: {
          fontSize: 'sm',
        },
        lg: {
          fontSize: 'sm',
        },
      },
      variants: {
        primary: {
          bg: 'brandYellow',
          _hover: {
            bg: 'primary-inverse',
            color: 'primary-inverse-fg',
            _disabled: { bg: 'brandYellow', color: 'offBlack' },
          },
          color: 'offBlack',
        },
        secondary: {
          backgroundColor: 'offBlack',
          _hover: {
            backgroundColor: 'black',
          },
          color: 'offWhite',
        },
        outline: {
          backgroundColor: 'chakra-body-bg',
          borderWidth: 2,
          borderColor: 'chakra-border-color',
        },
        link: {
          margin: 8,
        },
        cta: {
          background: COLORS.brandYellow,
          borderRadius: '30px',
          fontSize: 'lg !important',
          fontWeight: 400,
          color: COLORS.offBlack,
          px: '8 !important',
          py: '6 !important',
          _hover: {
            backgroundColor: COLORS.offBlack,
            color: COLORS.brandYellow,
          },
        },
        black: {
          background: COLORS.offBlack,
          color: COLORS.offWhite,
          borderRadius: '30px',
          fontSize: 'lg !important',
          fontWeight: 400,
          _hover: {
            backgroundColor: 'white',
            color: COLORS.offBlack,
          },
        },
        white: {
          background: 'white',
          color: COLORS.offBlack,
          borderRadius: '30px',
          fontSize: 'lg !important',
          fontWeight: 400,
          _hover: {
            backgroundColor: COLORS.offBlack,
            color: COLORS.offWhite,
          },
        },
        danger: {
          background: 'red.400',
          color: COLORS.offWhite,
          fontSize: 'md',
          fontWeight: 600,
          _hover: {
            backgroundColor: COLORS.offGray,
            color: COLORS.offBlack,
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          my: '5px',
        },
      },
    },
    Container: {
      baseStyle: {
        maxW: '80em',
        px: ['1rem', '1rem', '2rem', '2rem'],
      },
      variants: {
        wide: {
          maxW: '1720px',
        },
        unconstrained: {
          maxW: undefined,
          px: 0,
        },
        form: {
          maxW: '36rem',
        },
        text: {
          maxW: '80ch',
        },
        narrow: {
          maxW: '320px',
        },
      },
    },
    Form: {
      variants: {
        floating: {
          container: {
            input: {
              p: 4,
              height: 'auto',
              transition: 'padding 0.15s',
            },
            _focusWithin: {
              input: {
                pt: '26px',
                pb: '6px',
                height: 'auto',
              },
              label: { top: 0, fontSize: 'xs', color: 'gray.500' },
            },
            'input:not(:placeholder-shown)': {
              pt: '26px',
              pb: '6px',
              height: 'auto',
            },
            'input:not(:placeholder-shown) + label': {
              top: 0,
              fontSize: 'xs',
              color: 'gray.500',
            },
            label: {
              top: '9px',
              left: 0,
              zIndex: 2,
              position: 'absolute',
              color: 'gray.500',
              mx: 3,
              px: 1,
              my: 2,
              pointerEvents: 'none',
              transitionbb: 'all 0.15s',
            },
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        fontWeight: 'bold',
        letterSpacing: '-1px',
      },
      variants: {
        outline: {
          marginBottom: 2,
          fontSize: ['4xl', '4xl', '5xl', '6xl'],
          // textShadow: '0 0 1.5px black, 0 0 1.5px black, 0 0 1.5px black, 0 0 1.5px black;',
          // color: 'white',
        },
        h1: {
          marginBottom: 2,
          fontSize: ['3xl', '3xl', '4xl', '5xl'],
        },
        h2: {
          marginBottom: 2,
          fontSize: ['2xl', '2xl', '3xl', '4xl'],
        },
        h3: {
          marginBottom: 2,
          fontSize: ['xl', 'xl', '2xl', '3xl'],
        },
        h4: {
          marginBottom: 1,
          fontSize: ['lg', 'lg', 'xl', '2xl'],
        },
        h5: {
          marginBottom: 1,
          fontSize: ['md', 'md', 'lg', 'xl'],
        },
        h6: {
          marginBottom: 1,
          fontSize: ['md', 'md', 'md', 'lg'],
        },
        title: {
          fontStyle: 'normal',
          fontWeight: '700',
          textTransform: 'uppercase',
          fontSize: ['36px', '42px', '48px'],
          lineHeight: '111%',
          letterSpacing: '-0.04em',
        },
        section: {
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: ['24px', '36px', '60px'],
          lineHeight: ' 113%',
          textAlign: 'center',
          letterSpacing: '-0.02em',
          color: COLORS.brandYellow,
        },
        subtitle: {
          fontFamily: 'Inter, sans-serif',
          fontStyle: 'normal',
          fontWeight: '700',
          fontSize: ['16px', '20px', '24px'],
          lineHeight: '133%',
          textAlign: 'center',
          textTransform: 'uppercase',
        },
      },
    },
    Input: {
      baseStyle: {
        background: 'white',
      },
    },
    Link: {
      baseStyle: {
        textDecoration: 'none',
        color: 'link-color',
        transition: 'none',
        borderBottom: '1px solid transparent',
        _hover: {
          textDecoration: 'none',
        },
      },
      variants: {
        header: {
          textDecoration: 'none',
          color: 'offBlack',
          _hover: {
            textDecoration: 'none',
            borderBottom: `1px solid offBlack`,
          },
        },
        headerInverse: {
          textDecoration: 'none',
          color: 'white',
          _hover: {
            textDecoration: 'none',
            borderBottom: `1px solid white`,
          },
        },
        footer: {
          color: 'offWhite',
          _hover: { color: 'white', borderBottom: '1px solid #F7FF8B' },
        },
      },
    },
    Switch: switchTheme,
    Tabs: {
      defaultProps: {
        variant: 'line',
      },
      variants: {
        line: {
          tablist: {
            borderBottomColor: 'transparent',
          },
          tab: {
            fontWeight: 'bold',
            color: 'gray',
            _selected: {
              borderBottomColor: 'primary-inverse',
              color: 'primary-inverse',
            },
            _active: {
              bg: 'none',
            },
          },
          tabpanel: {
            p: 0,
          },
        },
      },
    },
    Text: {
      baseStyle: {
        display: 'inline',
      },
      variants: {
        paragraph: {
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '20px',
          lineHeight: '140% ',
          textAlign: 'center',
          color: COLORS.offBlack,
        },
        subtitle: {
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '20px',
          lineHeight: '140% ',
          textAlign: 'center',
          color: COLORS.offBlack,
        },
        helper: {
          fontStyle: 'normal',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '110% ',
          textAlign: 'center',
          color: COLORS.offBlack,
        },
        upper: {
          textTransform: 'uppercase',
        },
      },
    },
  },
});

// This is hacky, see https://github.com/chakra-ui/chakra-ui/issues/1424 for more info
Toast.defaultProps = {
  ...Toast.defaultProps,
  variant: 'subtle',
  isClosable: true,
};

Avatar.defaultProps = {
  icon: <User color={COLORS.offGray} />,
};
