import { gql } from '@apollo/client';
import { Stack } from '@chakra-ui/react';

import PaymentMethod from 'components/atoms/PaymentMethod';

import { PaymentMethodSelector_stripeCustomer } from './__graphql__/PaymentMethodSelector_stripeCustomer';

interface PaymentMethodSelectorProps {
  stripeCustomer: PaymentMethodSelector_stripeCustomer | null | undefined;
  onSelect: (stripePaymentId: string) => void;
}

const PaymentMethodSelector = ({ stripeCustomer, onSelect }: PaymentMethodSelectorProps) => {
  const handleClick = (stripePaymentId: string) => {
    onSelect(stripePaymentId);
  };

  return (
    <Stack>
      {stripeCustomer?.paymentMethods?.map((stripePaymentMethod, index) => (
        <PaymentMethod
          key={index}
          onClick={() => {
            handleClick(stripePaymentMethod.id);
          }}
          cursor="pointer"
          stripePaymentMethod={stripePaymentMethod}
        />
      ))}
    </Stack>
  );
};

PaymentMethodSelector.fragments = {
  stripeCustomer: gql`
    fragment PaymentMethodSelector_stripeCustomer on StripeCustomer {
      paymentMethods {
        id
        ...PaymentMethod_stripePaymentMethod
      }
    }
    ${PaymentMethod.fragments.stripePaymentMethod}
  `,
};

export default PaymentMethodSelector;
