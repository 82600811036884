import {
  FormControl,
  FormLabel,
  Input as ChakraInput,
  FormErrorMessage,
  FormHelperText,
  InputGroup,
  FormControlProps,
} from '@chakra-ui/react';
import { FieldValidator, useField } from 'formik';

export interface InputProps extends FormControlProps {
  label?: string;
  name: string;
  validate?: FieldValidator;
  type?: 'text' | 'email' | 'number' | 'password' | 'datetime-local' | 'tel' | 'date';
  children?: React.ReactNode;
  leftChildren?: React.ReactNode;
  helper?: React.ReactNode;
  underLabel?: React.ReactNode;
  maxDate?: string;
  minDate?: string;
  autoComplete?: string;
  autoFocus?: boolean;
}

export const Input = ({
  label,
  name,
  type = 'text',
  placeholder,
  as,
  helper,
  children,
  variant,
  leftChildren,
  underLabel,
  isDisabled,
  maxDate,
  minDate,
  autoComplete,
  validate,
  autoFocus,
  ...rest
}: InputProps) => {
  const [field, meta] = useField({ name, type, validate });

  return (
    <FormControl isInvalid={!!meta.error && meta.touched} variant={variant} {...rest}>
      {!!label && variant !== 'floating' && (
        <FormLabel htmlFor={name} fontSize="sm" fontWeight="bold">
          {label}
        </FormLabel>
      )}
      {underLabel}
      <InputGroup>
        {leftChildren}
        <ChakraInput
          type={type}
          as={as}
          placeholder={variant === 'floating' ? ' ' : placeholder}
          isDisabled={isDisabled}
          max={maxDate}
          min={minDate}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
          {...field}
        />
        {!!label && variant === 'floating' && <FormLabel htmlFor={name}>{label}</FormLabel>}
        {children}
      </InputGroup>
      {meta.error &&
        (typeof meta.error === 'string' ? (
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        ) : (
          //@ts-ignore
          meta.error.map((error: string, idx: number) => <FormErrorMessage key={idx}>{error}</FormErrorMessage>)
        ))}
      {!!helper && <FormHelperText lineHeight="tall">{helper}</FormHelperText>}
    </FormControl>
  );
};
