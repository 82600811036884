import {
  Container,
  Stack,
  ButtonGroup,
  IconButton,
  ContainerProps,
  HStack,
  Text,
  Box,
  StackProps,
  Flex,
} from '@chakra-ui/react';
import React from 'react';
import { FaInstagram, FaLinkedin } from 'react-icons/fa';
import { ROUTES } from 'routes';

import { Link, RouterLink } from 'components/atoms/Link';
import { Logo } from 'components/atoms/Logo';

export interface FooterProps extends ContainerProps {
  isFullWidth?: boolean;
  isWhiteLabel?: boolean;
}

const InnerStack = (props: StackProps) => (
  <Stack
    direction={{ base: 'column', md: 'row' }}
    alignItems={{ base: 'flex-start', md: 'center' }}
    spacing="8"
    w="full"
    {...props}
  />
);

const IconLink = (props: { href: string; icon: React.ReactElement; 'aria-label': string }) => (
  <IconButton as="a" target="_blank" size="sm" textColor="offWhite" _hover={{ textColor: 'white' }} {...props} />
);

export const Footer = ({ isFullWidth = true, isWhiteLabel, ...rest }: FooterProps) => {
  return (
    <Box as="footer" py="8" bg="offBlack" color="white" w="full" {...rest}>
      <Container variant={isFullWidth ? 'wide' : undefined}>
        <InnerStack direction={{ base: 'column', md: 'row-reverse' }} pr={{ base: 0, md: '60px' }}>
          {isWhiteLabel ? (
            <Flex
              as={RouterLink}
              to={ROUTES.landing.path}
              justifyContent="center"
              alignItems="center"
              mx={{ base: 'auto' }}
            >
              <Box mr={2}>
                <Logo boxSize="8" white={true} />
              </Box>
              <Text>Powered by Temple</Text>
            </Flex>
          ) : (
            <ButtonGroup variant="ghost" mx={{ base: 'auto' }}>
              {/* <IconLink
                href="https://discord.gg/sjJc23UKgE"
                aria-label="Discord"
                icon={<FaDiscord fontSize="20px" />}
              /> */}
              <IconLink
                href="https://instagram.com/temple.fans"
                aria-label="Instagram"
                icon={<FaInstagram fontSize="20px" />}
              />
              {/* <IconLink
                href="https://twitter.com/usetemple"
                aria-label="Twitter"
                icon={<FaTwitter fontSize="20px" />}
              /> */}
              {/* <IconLink href="https://github.com/usetemple" aria-label="GitHub" icon={<FaGithub fontSize="20px" />} /> */}
              <IconLink
                href="https://www.linkedin.com/company/templefans/"
                aria-label="LinkedIn"
                icon={<FaLinkedin fontSize="20px" />}
              />
            </ButtonGroup>
          )}
          <HStack
            spacing="8"
            fontSize="xs"
            textColor="gray"
            flexGrow="1"
            width={{ base: '100%', md: 'auto' }}
            justifyContent={{ base: 'center', md: 'flex-start' }}
          >
            <Text>&copy; {new Date().getFullYear()} Temple</Text>
            {/* <ChakraLink href="https://apply.workable.com/usetemple/" variant="footer">
            We&rsquo;re hiring
          </ChakraLink> */}
            <Link to={ROUTES.legal.termsCookies.path} target="_blank" variant="footer">
              Cookie Policy
            </Link>
            <Link to={ROUTES.legal.privacy.path} target="_blank" variant="footer">
              Privacy
            </Link>
            <Link to={ROUTES.legal.terms.path} target="_blank" variant="footer">
              Terms
            </Link>
            {/* <ChakraLink
            href="https://usetemple.notion.site/How-Temple-Works-78a86eed1226416da5be8186a3766692"
            variant="footer"
          >
            How it works
          </ChakraLink> */}
          </HStack>
        </InnerStack>
      </Container>
    </Box>
  );
};
