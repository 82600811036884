'use client';

import builder, { BuilderComponent } from '@builder.io/react';
import { Box } from '@chakra-ui/react';
import { AuthContext } from 'providers/Auth';
import { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';

import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { HeroSection } from 'components/molecules/HeroSection';
import { Footer } from 'components/organisms/Footer';
import { LandingPageNavbar } from 'components/organisms/LandingPageNavbar';
import { useTrackPageView } from 'hooks/useTrackPageView';
import 'utils/builder';

builder.init(process.env.REACT_APP_BUILDER_API_KEY ?? '');

const Page = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  useTrackPageView('Landing');

  // Builder.io
  const [content, setContent] = useState<any>(null);
  useEffect(() => {
    async function fetchContent() {
      const content = await builder
        .get('page', {
          url: window.location.pathname,
        })
        .promise();

      setContent(content);
    }
    fetchContent();
  }, []);

  useEffect(() => {
    if (user?.id) {
      navigate(ROUTES.dashboard.base.path);
    }
  }, [user?.id, navigate]);

  return (
    <>
      <Helmet>
        <title>Fans get perks. Artists get heard.</title>
      </Helmet>

      <Box position="relative" w="full" bg="offBlack" textColor="white">
        <LandingPageNavbar />
        {content ? (
          <Box sx={{ p: { fontFamily: 'marketing' } }}>
            <BuilderComponent model="page" content={content} />
          </Box>
        ) : (
          <HeroSection>
            <WrappedSpinner color="offBlack" />
          </HeroSection>
        )}
        <Footer isFullWidth={false} />
      </Box>
    </>
  );
};

export default Page;
