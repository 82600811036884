import { Flex, Divider, Text, Box } from '@chakra-ui/react';

import { Logo } from '../Logo';

export const LinkSent = ({ login, children }: { login?: boolean; children?: React.ReactNode }) => {
  return (
    <Flex flex="1 0" alignItems="center" justifyContent="center" direction="column">
      <Text textAlign="center" fontWeight="bold">
        {children ? children : `Please check your inbox for a link to complete ${login ? 'login' : 'sign up'}.`}
      </Text>
      <Divider my={4} w="80%" />
      <Flex justifyContent="center">
        <Box mr={2}>
          <Logo boxSize="8" black={true} />
        </Box>
        <Text>Powered by Temple</Text>
      </Flex>
    </Flex>
  );
};
