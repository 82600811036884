import { Button, Stack } from '@chakra-ui/react';
import { Star } from 'phosphor-react';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'routes';

import { Link } from 'components/atoms/Link';
import { Paragraph } from 'components/atoms/Paragraph';
import { DashboardCard } from 'components/molecules/DashboardCard';

export const EmptyState = ({ communitySlug }: { communitySlug: string }) => {
  const { t } = useTranslation(['creator', 'common']);
  return (
    <Stack spacing="4">
      <Paragraph>{t('pages.DashboardCollectibleList.emptyExplainer')}</Paragraph>
      <DashboardCard label={t('common:token')} icon={Star} title={t('pages.DashboardCollectibleList.emptyCreateTitle')}>
        <Button
          as={Link}
          colorScheme="primary"
          color="offBlack"
          to={ROUTES.dashboard.community.itemsNew.set({ communitySlug })}
        >
          {t('pages.DashboardCollectibleList.createCta')}
        </Button>
      </DashboardCard>
    </Stack>
  );
};
