import App from 'App';
import { ErrorFallback } from 'ErrorBoundary';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { reportWebVitals } from 'reportWebVitals';

import './i18n';

const container = document.getElementById('root');

if (!container) {
  throw Error('Failed to find the root element');
}

const root = createRoot(container);

root.render(
  <ErrorBoundary
    FallbackComponent={ErrorFallback}
    onReset={(details) => {
      // Reset the state of your app so the error doesn't happen again
      window.location.replace('/');
    }}
  >
    <App />
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
