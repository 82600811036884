import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { OAuthExtension, OAuthProvider } from '@magic-ext/oauth';
import Cookies from 'js-cookie';
import { Magic } from 'magic-sdk';
import { createContext, Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { ROUTES } from 'routes';

import { useMixpanel } from 'hooks/useMixpanel';
import { extractErrors, showError } from 'utils/errors';

import { LoginWithMagicLinkQuery, LoginWithMagicLinkQueryVariables } from './__graphql__/LoginWithMagicLinkQuery';
import { MagicAuthMe, MagicAuthMe_me } from './__graphql__/MagicAuthMe';
import { SignUpWithMagicLinkQuery, SignUpWithMagicLinkQueryVariables } from './__graphql__/SignUpWithMagicLinkQuery';
import { ME_QUERY, SIGN_UP_QUERY, LOGIN_QUERY } from './query';

type SendAuthLinkParams = { params: Record<string, string> & { email: string } };

type MagicLinkSignUpParams = {
  username: string;
  displayName: string;
  setErrors: (errors: {
    username?: string | undefined;
    displayName?: string | undefined;
    email?: string | undefined;
  }) => void;
  credential: string;
};

type MagicLinkLoginParams = {
  setErrors: (errors: { email?: string | undefined }) => void;
  credential: string;
};

const polygon = {
  rpcUrl: 'https://rpc-mainnet.maticvigil.com/',
  chainId: 137,
};

const mumbai = {
  rpcUrl: 'https://rpc-mumbai.maticvigil.com',
  chainId: 80001,
};

const customNodeOptions = {
  ...polygon,
  ...mumbai,
};

const magic = new Magic(process.env.REACT_APP_MAGIC_AUTH_PUBLISHABLE_KEY || '', {
  network: customNodeOptions,
  extensions: [new OAuthExtension()],
});

interface AuthContextState {
  user?: MagicAuthMe_me | null;
  userLoading: boolean;
  logoutLoading: boolean;
  logout: () => Promise<void>;
  sendAuthLink: (params: SendAuthLinkParams) => Promise<boolean>;
  ssoProviderRedirect: (provider: OAuthProvider, qs?: string) => Promise<boolean>;
  magicLinkSignUp: (params: MagicLinkSignUpParams) => Promise<void>;
  magicLinkLogin: (params: MagicLinkLoginParams) => Promise<void>;
  magic: typeof magic;
  currentCommunityId: string | null;
  setCurrentCommunityId: Dispatch<SetStateAction<string | null>>;
}

export const initialContextState = {
  user: undefined,
  userLoading: false,
  logoutLoading: false,
  logout: () => Promise.resolve(),
  sendAuthLink: (params: SendAuthLinkParams) => Promise.resolve(false),
  ssoProviderRedirect: (provider: OAuthProvider, qs?: string) => Promise.resolve(false),
  magicLinkSignUp: (params: MagicLinkSignUpParams) => Promise.resolve(),
  magicLinkLogin: (params: MagicLinkLoginParams) => Promise.resolve(),
  magic,
  currentCommunityId: null,
  setCurrentCommunityId: () => {},
};

const AuthContext = createContext<AuthContextState>(initialContextState);

const MagicAuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [logoutLoading, setLogoutLoading] = useState(false);
  const client = useApolloClient();
  const { mixpanel } = useMixpanel();

  // Get User
  const { data, loading } = useQuery<MagicAuthMe>(ME_QUERY);

  // Store the selected managed community in navigation
  // We'll move this once on Next
  const [currentCommunityId, setCurrentCommunityId] = useState<string | null>(null);

  // Mutations
  const [signUpWithMagicLink] = useMutation<SignUpWithMagicLinkQuery, SignUpWithMagicLinkQueryVariables>(SIGN_UP_QUERY);
  const [loginWithMagicLink] = useMutation<LoginWithMagicLinkQuery, LoginWithMagicLinkQueryVariables>(LOGIN_QUERY);

  const handleLogout = async () => {
    try {
      setLogoutLoading(true);
      await magic.user.logout();
      Cookies.remove('auth');
      mixpanel?.track('Logout');
      await client.resetStore();
    } catch (error) {
      showError('Issue logging out, please try again');
      console.error('Issue during logout', error);
    } finally {
      setLogoutLoading(false);
    }
  };

  const sendAuthLink = async ({ params }: SendAuthLinkParams) => {
    // Ensure user is logged out of magic, or else login attempts will not work as expected
    await magic.user.logout();

    const url = new URL(window.location.href);

    Object.entries(params).forEach(([key, value]) => url.searchParams.set(key, value));

    magic.auth
      .loginWithMagicLink({
        email: params.email,
        redirectURI: url.toString(),
        showUI: false,
      })
      .catch(() => {
        // This request listens for the login and will eventually time out, just
        // swallow that error to prevent issues
      });

    mixpanel?.track('Send Magic Link email');

    return true;
  };

  const ssoProviderRedirect = async (provider: OAuthProvider, qs: string = '') => {
    // Ensure user is logged out of magic, or else login attempts will not work as expected
    await magic.user.logout();

    const url = new URL(window.location.href).origin + ROUTES.auth.callback.path + qs;

    mixpanel?.track('Initial Magic Link SSO', { provider });

    magic.oauth
      .loginWithRedirect({
        provider,
        redirectURI: url.toString(),
      })
      .catch(() => {
        // This request listens for the login and will eventually time out, just
        // swallow that error to prevent issues
      });

    return true;
  };

  const magicLinkSignUp = async ({ setErrors, username, displayName, credential }: MagicLinkSignUpParams) => {
    await magic.user.logout();

    let didToken = await magic.auth.loginWithCredential(credential);
    if (didToken) {
      try {
        const result = await signUpWithMagicLink({
          variables: { didToken, username, displayName, distinctId: mixpanel?.get_distinct_id() },
        });
        if (result.data?.signUpWithMagicLink) {
          Cookies.set('auth', result.data?.signUpWithMagicLink);
          await client.resetStore();
        }
      } catch (error) {
        extractErrors(setErrors)(error);
      }
    } else {
      showError('This sign up link has expired, please refresh and try again.');
    }
  };

  const magicLinkLogin = async ({ setErrors, credential }: MagicLinkLoginParams) => {
    await magic.user.logout();

    let didToken = await magic.auth.loginWithCredential(credential);
    if (didToken) {
      try {
        const result = await loginWithMagicLink({
          variables: { didToken, distinctId: mixpanel?.get_distinct_id() },
        });
        if (result.data?.loginWithMagicLink) {
          Cookies.set('auth', result.data?.loginWithMagicLink);
          await client.resetStore();
        }
      } catch (error) {
        extractErrors(setErrors)(error);
      }
    } else {
      showError('This login link has expired, please refresh and try again.');
    }
  };

  return (
    <AuthContext.Provider
      value={{
        user: data?.me,
        userLoading: loading,
        logoutLoading,
        logout: handleLogout,
        sendAuthLink,
        ssoProviderRedirect,
        magicLinkSignUp,
        magicLinkLogin,
        magic,
        currentCommunityId,
        setCurrentCommunityId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { MagicAuthProvider, AuthContext };
