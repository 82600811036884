import { gql, useMutation, useQuery } from '@apollo/client';
import { Box, Link as ChakraLink, Button, Container, Stack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { AuthContext } from 'providers/Auth';
import { CommunityContext } from 'providers/Community';
import { GrowthbookContext } from 'providers/Growthbook';
import { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import { Link } from 'components/atoms/Link';
import { LinkSent } from 'components/atoms/LinkSent';
import { Separator } from 'components/atoms/Separator';
import { CityField, CountryField, MobileNumberField } from 'components/forms/LocationFields';
import {
  CommunityTermsCheckbox,
  DateOfBirthField,
  DisplayNameField,
  EmailAddressField,
} from 'components/forms/SignUpForm/fields';
import { GoogleLoginButton } from 'components/molecules/GoogleLoginButton';
import { CommunityWelcomeHeader } from 'components/organisms/CommunityWelcomeHeader';
import { useMixpanel } from 'hooks/useMixpanel';
import { extractErrors } from 'utils/errors';

import {
  CommunitySignUpFormMutation,
  CommunitySignUpFormMutationVariables,
} from './__graphql__/CommunitySignUpFormMutation';
import { CommunitySignUpFormQuery } from './__graphql__/CommunitySignUpFormQuery';

const QUERY = gql`
  query CommunitySignUpFormQuery {
    countries {
      iso3
      iso2
      name
      dial
    }
    geoIpCountry {
      iso3
      dial
    }
  }
`;

const MUTATION = gql`
  mutation CommunitySignUpFormMutation(
    $communityId: ID!
    $offeringId: ID
    $displayName: String!
    $email: String!
    $dial: String
    $mobileNumber: String
    $country: String
    $city: String
    $dateOfBirth: String
    $distinctId: String
  ) {
    communitySignUpContext(
      communityId: $communityId
      offeringId: $offeringId
      displayName: $displayName
      email: $email
      dial: $dial
      mobileNumber: $mobileNumber
      country: $country
      city: $city
      dateOfBirth: $dateOfBirth
      distinctId: $distinctId
    )
  }
`;

export const CommunitySignUpForm = () => {
  const { community } = useContext(CommunityContext);
  const { magic } = useContext(AuthContext);
  const [sent, setSent] = useState(false);
  const { data, loading } = useQuery<CommunitySignUpFormQuery>(QUERY);
  const { mixpanel } = useMixpanel();
  const [communitySignUpContext] = useMutation<CommunitySignUpFormMutation, CommunitySignUpFormMutationVariables>(
    MUTATION,
  );
  const [params] = useSearchParams();
  const { growthbook } = useContext(GrowthbookContext);
  const moreData = growthbook.isOn('collect_more_data_on_join');
  const then = params.get('then') || undefined;

  let offeringId = localStorage?.getItem('offeringClaimIntent') || (then || '').match(/\/item\/[^/]+-(\d+)/)?.[1];

  return (
    <Container variant="form">
      <CommunityWelcomeHeader textAlign="center" community={community} sent={sent} />
      {sent ? (
        <LinkSent>Please check your inbox for a link to finish signing up for {community?.displayName}.</LinkSent>
      ) : (
        <Formik
          initialValues={{
            displayName: '',
            email: '',
            dial: data?.geoIpCountry?.dial || '1',
            mobileNumber: '',
            country: moreData ? data?.geoIpCountry?.iso3 || 'GBR' : '',
            city: '',
            dateOfBirth: '',
            agreed: false,
          }}
          onSubmit={async ({ email, agreed: _agreed, ...values }, { setErrors, setSubmitting }) => {
            if (!community?.id) {
              return;
            }
            if (offeringId) {
              localStorage?.setItem('offeringClaimIntent', offeringId);
            }
            const variables = {
              communityId: community?.id,
              offeringId: localStorage?.getItem('offeringClaimIntent'),
              email,
              distinctId: mixpanel?.get_distinct_id(),
              ...values,
            };

            let result: Awaited<ReturnType<typeof communitySignUpContext>>;
            try {
              result = await communitySignUpContext({ variables });
            } catch (error) {
              extractErrors(setErrors)(error);
              setSubmitting(false);
              return;
            }

            const url =
              window.location.origin +
              ROUTES.community.auth.set({ communitySlug: community.slug }) +
              '?ctx=' +
              result.data?.communitySignUpContext +
              '&then=' +
              (then || '');

            await magic.user.logout();

            magic.auth
              .loginWithMagicLink({
                email,
                redirectURI: url,
                showUI: false,
              })
              .catch(() => {
                // This request listens for the login and will eventually time out, just
                // swallow that error to prevent issues
              });

            mixpanel?.track('Send Magic Link email');
            setSent(true);
          }}
        >
          {({ isValid, isSubmitting, setFieldValue, values: { country, city, dial, mobileNumber } }) => {
            return (
              <Form noValidate>
                <Stack spacing="4" shouldWrapChildren>
                  <DisplayNameField isRequired />
                  <EmailAddressField isRequired />
                  {moreData && (
                    <MobileNumberField
                      label="Mobile number"
                      countries={data?.countries}
                      country={country}
                      dial={dial}
                      mobileNumber={mobileNumber}
                      setFieldValue={setFieldValue}
                      helpText={`Add your number to receive content from ${community?.displayName} via SMS.`}
                    />
                  )}
                  {moreData && <CountryField label="Country" defaultCountry={country} countries={data?.countries} />}
                  {moreData && (
                    <CityField
                      label="Closest city"
                      countryIso3={data?.countries?.find((c) => c.iso3 === country)?.iso3}
                      defaultCity={city}
                    />
                  )}
                  {community?.minimumAgeToJoin && <DateOfBirthField />}
                  <CommunityTermsCheckbox displayName={community?.displayName} />

                  <Button
                    variant="primary"
                    type="submit"
                    w="full"
                    isLoading={isSubmitting}
                    isDisabled={!isValid || loading}
                  >
                    Send confirmation email
                  </Button>
                  <Separator py="0">or</Separator>
                  <GoogleLoginButton
                    w="full"
                    then={encodeURIComponent(window.location.pathname + window.location.search)}
                    onClick={() => {
                      if (offeringId) {
                        localStorage?.setItem('offeringClaimIntent', offeringId);
                      }
                    }}
                  />
                  <Box fontSize="xs" textAlign="center">
                    By joining you agree to the{' '}
                    <ChakraLink href={ROUTES.legal.terms.path} target="_blank">
                      Terms of Use
                    </ChakraLink>{' '}
                    and{' '}
                    <ChakraLink href={ROUTES.legal.privacy.path} target="_blank">
                      Privacy Policy
                    </ChakraLink>
                    .
                  </Box>
                  <Box bg="shadow" p="3" rounded="lg" mt="4" textAlign="center">
                    Already have an account?{' '}
                    <Link
                      to={ROUTES.auth.login.set({
                        isWhiteLabel: community?.isWhiteLabel,
                        communitySlug: community?.slug,
                        then,
                      })}
                    >
                      Login
                    </Link>
                  </Box>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      )}
    </Container>
  );
};
