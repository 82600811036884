import { gql } from '@apollo/client';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useState } from 'react';
import { ROUTES } from 'routes';

import { OfferingShareButton_offering } from './__graphql__/OfferingShareButton_offering';

interface OfferingShareButtonProps extends ButtonProps {
  offering?: OfferingShareButton_offering | null;
}

export const OfferingShareButton = ({ offering, ...rest }: OfferingShareButtonProps) => {
  const [loading, setLoading] = useState(false);

  const data: ShareData = {
    title: offering?.name,
    text: `Check out this Drop from ${offering?.community.displayName} on Temple!`,
    url:
      window.location.origin +
      ROUTES.community.item.base.set({ communitySlug: offering?.community.slug, managedContractSlug: offering?.slug }),
  };
  const canShare = navigator.canShare && navigator.canShare(data);

  if (!canShare) {
    return null;
  }

  return (
    <Button
      variant="primary"
      flexGrow={1}
      isLoading={loading}
      onClick={async () => {
        setLoading(true);
        const navigator = window.navigator;
        if (canShare) {
          let blob: Blob | null = null;
          if (offering?.image) {
            try {
              blob = await fetch(offering?.image).then((r) => r.blob());
            } catch (e) {}
          }
          try {
            await navigator.share({
              ...data,
              files: blob
                ? [
                    new File([blob], `${offering?.slug}.png`, {
                      type: blob.type,
                    }),
                  ]
                : [],
            });
          } catch (e) {}
        }
        setLoading(false);
      }}
      {...rest}
    >
      Share
    </Button>
  );
};

OfferingShareButton.fragments = {
  offering: gql`
    fragment OfferingShareButton_offering on Offering {
      id
      name
      image(variant: card)
      slug
      community {
        id
        slug
        displayName
      }
    }
  `,
};
