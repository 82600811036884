import { createPortal } from 'react-dom';

import { Checkbox } from 'components/forms/Fields';
import { Select, SelectProps } from 'components/molecules/Select';

import { CheckboxProps } from '../Fields/checkbox';

interface CriteriaFieldProps {
  container?: Element | DocumentFragment | null;
  displayParams?: boolean;
  criteriaProps: CheckboxProps;
  paramsProps?: SelectProps;
}

export const CriteriaField = ({ container, criteriaProps, paramsProps, displayParams = true }: CriteriaFieldProps) => {
  return (
    <>
      <Checkbox {...criteriaProps} />
      {container &&
        createPortal(
          <>
            {paramsProps && displayParams && (
              <Select {...paramsProps} name={paramsProps?.name || 'params'} options={paramsProps?.options || []} />
            )}
          </>,
          container,
        )}
    </>
  );
};
