import { useState, useEffect } from 'react';

import { avgColor, rgbToHsl } from 'utils/colors';

export const useBrightness = (image?: string | HTMLImageElement) => {
  // Brightness between 0 - Dark and 100 - Light
  const [brightness, setBrightness] = useState(50);

  useEffect(() => {
    (async () => {
      if (image) {
        const avg = await avgColor(image);
        if (avg) {
          const hsl = rgbToHsl(avg.r, avg.g, avg.b);
          setBrightness(hsl.l);
        }
      }
    })();
  }, [image]);

  return brightness;
};
