import { gql, useQuery } from '@apollo/client';
import { Flex, Text, Select, Skeleton, Stack, Box, HStack, Spacer, StackProps } from '@chakra-ui/react';
import { AnalyticsMetric, Frequency } from '__graphql__/globalTypes';
import { CommunityContext } from 'providers/Community';
import { useContext, useState } from 'react';

import { RevenueLine } from './RevenueLine';
import { RevenueAnalytics, RevenueAnalyticsVariables } from './__graphql__/RevenueAnalytics';

interface OfferingChartProps extends StackProps {
  offeringId?: string | null;
  title?: string;
}

export const REVENUE_QUERY = gql`
  query RevenueAnalytics($slug: String!, $frequency: Frequency!, $metric: AnalyticsMetric!, $offeringId: ID) {
    community(slug: $slug) {
      offeringAnalytics(frequency: $frequency, metric: $metric, offeringId: $offeringId) {
        date
        value
      }
    }
  }
`;

export const OfferingChart = ({ offeringId, title, ...rest }: OfferingChartProps) => {
  const { community } = useContext(CommunityContext);
  const [frequency, setFrequency] = useState<Frequency>(Frequency.WEEKLY);

  const { data, loading } = useQuery<RevenueAnalytics, RevenueAnalyticsVariables>(REVENUE_QUERY, {
    variables: {
      slug: community?.slug ?? '',
      frequency,
      metric: AnalyticsMetric.UNITS,
      offeringId,
    },
    skip: !community?.id,
  });

  const datapoints = data?.community?.offeringAnalytics;

  return (
    <Stack
      height="400px"
      p={4}
      w="full"
      border="1px solid"
      borderColor="chakra-border-color"
      borderRadius="lg"
      {...rest}
    >
      <HStack px="2">
        <Skeleton isLoaded={!loading}>
          <Text variant="subtitle" fontWeight="600">
            {title || 'Drops Collected'}
          </Text>
        </Skeleton>
        <Spacer />
        <Flex alignItems="center">
          <Select
            defaultValue={Frequency.WEEKLY}
            size="sm"
            onChange={async (e) => {
              setFrequency(e.target.value as Frequency);
            }}
            value={frequency}
          >
            <option value={Frequency.DAILY}>Daily</option>
            <option value={Frequency.WEEKLY}>Weekly</option>
            <option value={Frequency.MONTHLY}>Monthly</option>
          </Select>
        </Flex>
      </HStack>
      <Box flexGrow={1}>
        {loading && <Skeleton isLoaded={!loading} h="full" borderRadius={24} />}
        {!loading && datapoints && datapoints.length > 0 && (
          <Box
            h="full"
            w="full"
            __css={{
              '& svg:first-of-type': {
                width: '100%',
              },
            }}
          >
            <RevenueLine
              data={datapoints.map(({ date, value }) => ({ x: date, y: value })) || []}
              frequency={frequency}
            />
          </Box>
        )}
        {!loading && datapoints?.length === 0 && <Box>There is no data to display</Box>}
      </Box>
    </Stack>
  );
};
