import { Builder, withChildren } from '@builder.io/react';
import { Box, Button, Container, Heading, Stack } from '@chakra-ui/react';
import Balancer from 'react-wrap-balancer';

import { RouterLink } from 'components/atoms/Link';
import { HeroImageSection } from 'components/molecules/HeroImageSection';
import { HeroSection } from 'components/molecules/HeroSection';
import { LandingPageValueProp } from 'components/molecules/LandingPageValueProp';
import { LandingPageValuePropV2 } from 'components/molecules/LandingPageValuePropV2';

Builder.registerComponent(withChildren(Container), {
  name: 'Container',
  inputs: [
    {
      name: 'variant',
      type: 'string',
      defaultValue: 'default',
      enum: ['default', 'wide', 'form', 'text', 'narrow', 'unconstrained'],
    },
  ],
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: { name: 'Text', options: { text: 'I am child text block!' } },
    },
  ],
});

Builder.registerComponent(Heading, {
  name: 'Heading',
  inputs: [
    { name: 'children', type: 'text', friendlyName: 'Text', required: true },
    { name: 'variant', type: 'text', defaultValue: 'h1', enum: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'title'] },
    { name: 'align', type: 'text', defaultValue: 'left', enum: ['left', 'center', 'right'] },
  ],
});

Builder.registerComponent(() => <Box py={{ base: '3', lg: '5' }} />, {
  name: 'Spacer',
});

Builder.registerComponent((args: any) => <Button as={RouterLink} to="/" {...args} />, {
  name: 'Button',
  inputs: [
    { name: 'children', type: 'text', friendlyName: 'Text', required: true },
    { name: 'to', type: 'text', friendlyName: 'URL Path', required: true },
    { name: 'size', type: 'text', defaultValue: 'md', enum: ['sm', 'md', 'lg'] },
    {
      name: 'variant',
      type: 'text',
      defaultValue: 'primary',
      enum: ['primary', 'secondary', 'outline', 'cta', 'black'],
    },
  ],
});

Builder.registerComponent(withChildren(Stack), {
  name: 'Stack',
  inputs: [{ name: 'spacing', type: 'number', friendlyName: 'Spacing', required: false }],
});

Builder.registerComponent(withChildren(Balancer), {
  name: 'Balancer',
  inputs: [{ name: 'ratio', type: 'number', friendlyName: 'Ratio', required: false }],
});

Builder.registerComponent(withChildren(HeroSection), {
  name: 'HeroSection',
  inputs: [
    {
      name: 'containerVariant',
      type: 'string',
      defaultValue: 'default',
      enum: ['default', 'wide', 'form', 'text', 'narrow', 'unconstrained'],
    },
    { name: 'align', type: 'text', defaultValue: 'left', enum: ['left', 'center', 'right'] },
  ],
});

Builder.registerComponent(withChildren(HeroImageSection), {
  name: 'HeroImageSection',
  inputs: [
    {
      name: 'image',
      type: 'file',
    },
  ],
});

Builder.registerComponent(LandingPageValueProp, {
  name: 'LandingPageValueProp',
  inputs: [
    {
      name: 'image',
      type: 'file',
    },
    {
      name: 'title',
      type: 'text',
    },
    {
      name: 'description',
      type: 'richText',
    },
    { name: 'align', type: 'text', defaultValue: 'left', enum: ['left', 'right'] },
  ],
});

Builder.registerComponent(LandingPageValuePropV2, {
  name: 'LandingPageValuePropV2',
  inputs: [
    {
      name: 'image',
      type: 'file',
    },
    {
      name: 'title',
      type: 'text',
    },
    {
      name: 'description',
      type: 'richText',
    },
    { name: 'align', type: 'text', defaultValue: 'left', enum: ['left', 'right'] },
  ],
});
