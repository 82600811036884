'use client';

import { gql, useQuery } from '@apollo/client';
import {
  Text,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Avatar,
  AvatarGroup,
  Stack,
  Heading,
} from '@chakra-ui/react';
import { CommunityContext } from 'providers/Community';
import { useContext } from 'react';
import { ROUTES } from 'routes';

import { RouterLink } from 'components/atoms/Link';
import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { ExplainerWithCTA } from 'components/molecules/ExplainerWithCTA';

import { MessageListQuery, MessageListQueryVariables } from './__graphql__/MessageListQuery';
import { UsersByMessageQuery, UsersByMessageQueryVariables } from './__graphql__/UsersByMessageQuery';

export const MESSAGE_LIST_QUERY = gql`
  query MessageListQuery($communityId: ID!, $complete: Boolean) {
    messageList(communityId: $communityId, complete: $complete) {
      id
      title
      messageDeliveries {
        id
      }
      messageOfferingLinks {
        offeringId
      }
      messageReleaseDate
      messageUserCriteria {
        id
      }
      numberOfSentMessagesTotal
    }
  }
`;

export const USER_BY_MESSAGE_QUERY = gql`
  query UsersByMessageQuery($messageId: ID!) {
    usersByMessage(messageId: $messageId) {
      avatar(variant: md)
      id
      email
      displayName
    }
  }
`;

const MessageEngagedAvatar = ({ communityId, messageId }: { communityId: string; messageId: string }) => {
  const { data, loading } = useQuery<UsersByMessageQuery, UsersByMessageQueryVariables>(USER_BY_MESSAGE_QUERY, {
    variables: { messageId: messageId ?? '' },
    skip: !messageId || !communityId,
  });
  const users = data?.usersByMessage;
  if (loading) return <WrappedSpinner />;
  return (
    <AvatarGroup size="sm" max={2}>
      {users && users.length > 0 ? (
        users.map((user) => <Avatar key={user.id} name={user.displayName} src={user.avatar || ''} />)
      ) : (
        <Text>0</Text>
      )}
    </AvatarGroup>
  );
};

const MessageOverviewPage = () => {
  const { community } = useContext(CommunityContext);
  const { data, loading } = useQuery<MessageListQuery, MessageListQueryVariables>(MESSAGE_LIST_QUERY, {
    variables: { communityId: community?.id ?? '', complete: true },
    skip: !community?.id,
    fetchPolicy: 'cache-and-network',
  });

  if (loading || !community?.slug) return <WrappedSpinner />;

  return (
    <Stack maxW="64rem" spacing={8}>
      <Heading variant="h2">Campaigns</Heading>
      <ExplainerWithCTA
        cta="Create a Campaign"
        to={ROUTES.dashboard.community.messageNew.set({ communitySlug: community?.slug })}
      >
        Send targeted messages to your fanbase
      </ExplainerWithCTA>
      {data?.messageList && data?.messageList?.length !== 0 && (
        <TableContainer my={6}>
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th>Message name</Th>
                <Th>Reached</Th>
                <Th>To</Th>
                <Th>Release Date</Th>
                <Th>Drop Attached</Th>
                <Th>Status</Th>
                <Th>&nbsp;</Th>
              </Tr>
            </Thead>

            <Tbody>
              {data.messageList.map((message, idx: number) => {
                return (
                  <Tr>
                    <Td>{message.title}</Td>
                    <Td>{message.messageDeliveries.length || '0'}</Td>
                    <Td>
                      <MessageEngagedAvatar communityId={community.id} messageId={message.id} />
                    </Td>
                    <Td>{message?.messageReleaseDate ? new Date(message.messageReleaseDate).toLocaleString() : '/'}</Td>
                    <Td>{message?.messageOfferingLinks.length || '0'}</Td>
                    <Td>
                      {message?.messageReleaseDate
                        ? new Date(message.messageReleaseDate) < new Date()
                          ? 'Sent'
                          : 'Scheduled'
                        : '/'}
                    </Td>
                    <Td textAlign="right">
                      {message?.messageReleaseDate && new Date(message.messageReleaseDate) > new Date() && (
                        <Button
                          variant="primary"
                          as={RouterLink}
                          isDisabled={new Date(message.messageReleaseDate) < new Date()}
                          size="sm"
                          to={ROUTES.dashboard.community.messageEdit.set({
                            communitySlug: community?.slug,
                            messageId: message.id,
                          })}
                        >
                          Edit
                        </Button>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
};

export default MessageOverviewPage;
