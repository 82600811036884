import { GrowthBook, Attributes } from '@growthbook/growthbook-react';
import { AuthContext } from 'providers/Auth';
import { createContext, useContext, useEffect } from 'react';

interface GrowthbookContextState {
  growthbook: GrowthBook;
  setAttributes: (attrs?: Attributes) => void;
}

export const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  enableDevMode: process.env.NODE_ENV === 'development',
});

export const GrowthbookContext = createContext<GrowthbookContextState>({ growthbook, setAttributes: () => {} });

export const GrowthBookProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useContext(AuthContext);

  useEffect(() => {
    setAttributes();
  }, [user?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  const setAttributes = (attrs: Attributes = {}) => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(), // This is dirty but it prevents issues with race conditions
      id: user?.id,
      loggedIn: !!user?.id,
      isAdmin: user?.isAdmin ?? false,
      isStaff: user?.isStaff ?? false,
      ...attrs,
    });
  };

  useEffect(() => {
    growthbook.loadFeatures({ autoRefresh: true });
  }, []);

  return <GrowthbookContext.Provider value={{ growthbook, setAttributes }}>{children}</GrowthbookContext.Provider>;
};
