import { ApolloProvider } from '@apollo/client';
import { ChakraProvider, ColorModeScript, createStandaloneToast } from '@chakra-ui/react';
import { client } from 'apollo';
import 'inter-ui/inter.css';
import { MagicAuthProvider } from 'providers/Auth';
import { CookieConsentProvider } from 'providers/CookieConsent';
import { GrowthBookProvider } from 'providers/Growthbook';
import { BrowserRouter } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import theme from 'theme';

import { BaseRoutes } from 'app/routes';
import { ScrollToTop } from 'components/molecules/ScrollToTop';

export const { ToastContainer, toast } = createStandaloneToast({
  theme,
  defaultOptions: {
    position: 'top',
    duration: 5000,
  },
});

const App = () => (
  <ApolloProvider client={client}>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <ChakraProvider theme={theme}>
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID ?? ''} autoBoot>
        <BrowserRouter>
          <ScrollToTop />
          <CookieConsentProvider>
            <MagicAuthProvider>
              <GrowthBookProvider>
                <BaseRoutes />
              </GrowthBookProvider>
            </MagicAuthProvider>
          </CookieConsentProvider>
        </BrowserRouter>
        <ToastContainer />
      </IntercomProvider>
    </ChakraProvider>
  </ApolloProvider>
);

export default App;
