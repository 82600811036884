import { Container, Heading } from '@chakra-ui/react';
import { Helmet } from 'react-helmet';

import { SettingsForm } from 'components/forms/SettingsForm';
import { useTrackPageView } from 'hooks/useTrackPageView';

const MySettingsPage = () => {
  useTrackPageView('Settings');

  return (
    <Container variant="form" ml="0" px="0 !important">
      <Helmet>
        <title>Temple - Settings</title>
      </Helmet>
      <Heading as="h1" mb="8">
        Settings
      </Heading>
      <SettingsForm />
    </Container>
  );
};

export default MySettingsPage;
