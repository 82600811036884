import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';

import { WrappedSpinner } from 'components/atoms/WrappedSpinner';

interface CalendlyWidgetProps {
  url: string;
}

export const CalendlyWidget = ({ url }: CalendlyWidgetProps) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    const el = document.body.appendChild(script);

    return () => {
      el.remove();
    };
  }, []);

  return (
    <Box position="relative">
      <Box position="absolute" top="200px" w="full" textAlign="center">
        <WrappedSpinner />
      </Box>
      <div className="calendly-inline-widget" data-url={url} style={{ minWidth: '320px', height: '750px' }} />
    </Box>
  );
};
