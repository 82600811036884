import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Button, Checkbox, Container, FormControl, FormErrorMessage, FormLabel, Link, Stack } from '@chakra-ui/react';
import { Formik, FormikErrors } from 'formik';
import { GrowthbookContext } from 'providers/Growthbook';
import { useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ROUTES } from 'routes';

import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { CityField, CountryField, MobileNumberField } from 'components/forms/LocationFields';
import { Input } from 'components/molecules/Input';
import { SwitchWithLabel } from 'components/molecules/SwitchWithLabel';
import { extractErrors } from 'utils/errors';

import { CommunitySpotifyInterstitial } from '../CommunitySpotifyInterstitial';
import { CommunityWelcomeHeader } from '../CommunityWelcomeHeader';
import {
  CommunityJoinFormJoinMutation,
  CommunityJoinFormJoinMutationVariables,
} from './__graphql__/CommunityJoinFormJoinMutation';
import { CommunityJoinFormQuery } from './__graphql__/CommunityJoinFormQuery';
import { CommunityJoinForm_community } from './__graphql__/CommunityJoinForm_community';

interface CommunityJoinFormProps {
  community: CommunityJoinForm_community | null | undefined;
}

export const QUERY = gql`
  query CommunityJoinFormQuery {
    me {
      id
      country
      city
      dial
      mobileNumber
      dateOfBirth
      spotifyConnection {
        id
        isActive
      }
    }
    countries {
      iso3
      iso2
      name
      dial
    }
    geoIpCountry {
      iso3
      dial
    }
  }
`;

export const JOIN_MUTATION = gql`
  mutation CommunityJoinFormJoinMutation(
    $communityId: ID!
    $shouldWhatsAppUpdates: Boolean!
    $shouldSMSUpdates: Boolean!
    $shouldEmailUpdates: Boolean!
    $country: String
    $city: String
    $dial: String
    $mobileNumber: String
    $dateOfBirth: String
  ) {
    joinCommunity(
      communityId: $communityId
      shouldWhatsAppUpdates: $shouldWhatsAppUpdates
      shouldSMSUpdates: $shouldSMSUpdates
      shouldEmailUpdates: $shouldEmailUpdates
      country: $country
      city: $city
      dial: $dial
      mobileNumber: $mobileNumber
      dateOfBirth: $dateOfBirth
    ) {
      role
    }
  }
`;

export const CommunityJoinForm = ({ community }: CommunityJoinFormProps) => {
  const { t } = useTranslation('fan', { keyPrefix: 'organisms.CommunityJoinForm' });
  const { data, loading } = useQuery<CommunityJoinFormQuery, CommunityJoinFormQuery>(QUERY);
  const [joining, setJoining] = useState(false);
  const [joinCommunity] = useMutation<CommunityJoinFormJoinMutation, CommunityJoinFormJoinMutationVariables>(
    JOIN_MUTATION,
  );
  const client = useApolloClient();

  // Spotify interstitial
  const { growthbook } = useContext(GrowthbookContext);
  const showSpotify = false; // !growthbook.isOn('hide_music_connections');
  const moreData = growthbook.isOn('collect_more_data_on_join');
  const [justJoined, setJustJoined] = useState(false);

  if (loading) {
    return <WrappedSpinner />;
  }

  if (showSpotify && justJoined) {
    return <CommunitySpotifyInterstitial community={community} />;
  }

  const today = new Date().toISOString().slice(0, 10); // get current date in ISO format

  return (
    <Container variant="form">
      <CommunityWelcomeHeader textAlign="center" community={community} />
      <Stack spacing="6" alignItems="center">
        <Formik
          initialValues={{
            country: data?.me?.country || (moreData ? data?.geoIpCountry?.iso3 || 'GBR' : '') || '',
            city: data?.me?.city || '',
            dial: data?.me?.dial || data?.geoIpCountry?.dial || '1',
            mobileNumber: data?.me?.mobileNumber || '',
            shouldWhatsAppUpdates: false,
            shouldSMSUpdates: false,
            shouldEmailUpdates: true,
            agreed: false,
            dateOfBirth: data?.me?.dateOfBirth?.substring(0, 10) || undefined,
          }}
          validate={(values) => {
            const errors: FormikErrors<typeof values> = {};
            if (community?.minimumAgeToJoin && !values.dateOfBirth) {
              errors.dateOfBirth = 'Please enter your date of birth';
            }
            if (values.shouldWhatsAppUpdates || values.shouldSMSUpdates) {
              if (!values.dial) {
                errors.dial = 'A country code is required';
              }
              if (!values.mobileNumber) {
                errors.mobileNumber = 'A mobile number is required';
              }
            }
            if (!values.shouldEmailUpdates && !values.shouldWhatsAppUpdates && !values.shouldSMSUpdates) {
              errors.shouldEmailUpdates = 'You must select at least one contact method';
            }
            if (!values.agreed) {
              errors.agreed = 'You must agree in order to continue';
            }
            return errors;
          }}
          onSubmit={async ({ agreed, mobileNumber, ...values }, { setErrors }) => {
            if (!community?.id || joining) {
              return null;
            }
            setJoining(true);
            try {
              await joinCommunity({
                variables: {
                  communityId: community.id,
                  mobileNumber: mobileNumber.replace('-', '').replace(' ', ''),
                  ...values,
                  shouldWhatsAppUpdates: !!values.shouldWhatsAppUpdates,
                  shouldSMSUpdates: !!values.shouldSMSUpdates,
                  shouldEmailUpdates: !!values.shouldEmailUpdates,
                },
              });
              if (showSpotify && !data?.me?.spotifyConnection?.isActive) {
                setJustJoined(true);
              } else {
                await client.resetStore();
              }
            } catch (error: any) {
              extractErrors(setErrors)(error);
            }
            setJoining(false);
          }}
        >
          {({
            setFieldValue,
            handleSubmit,
            isValid,
            errors,
            values: { country, shouldWhatsAppUpdates, shouldSMSUpdates, agreed },
          }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Stack spacing="4" textAlign="left">
                {moreData && (
                  <Stack>
                    <FormLabel fontWeight="bold" fontSize="sm">
                      How would you like to receive content from {community?.displayName}?
                    </FormLabel>
                    <SwitchWithLabel label="Email" name="shouldEmailUpdates" hasSurroundingBox />
                    <SwitchWithLabel label="SMS" name="shouldSMSUpdates" hasSurroundingBox />
                    {/* <SwitchWithLabel label="WhatsApp" name="shouldWhatsAppUpdates" hasSurroundingBox /> */}
                    <FormControl isInvalid={!!errors.shouldEmailUpdates}>
                      {errors.shouldEmailUpdates && <FormErrorMessage>{errors.shouldEmailUpdates}</FormErrorMessage>}
                    </FormControl>
                  </Stack>
                )}
                {(shouldWhatsAppUpdates || shouldSMSUpdates) && (
                  <MobileNumberField
                    label="Mobile number"
                    countries={data?.countries}
                    country={country}
                    dial={data?.me?.dial || ''}
                    mobileNumber={data?.me?.mobileNumber || ''}
                    setFieldValue={setFieldValue}
                    isRequired
                  />
                )}
                {moreData && (
                  <CountryField
                    defaultCountry={country}
                    countries={data?.countries}
                    label="Where are you in the world?"
                  />
                )}
                {moreData && (
                  <CityField
                    label="What's your nearest city?"
                    countryIso3={data?.countries?.find((c) => c.iso3 === country)?.iso3}
                    defaultCity={data?.me?.city || ''}
                  />
                )}
                {community?.minimumAgeToJoin && (
                  <Input
                    type="date"
                    name="dateOfBirth"
                    label="Date of birth"
                    autoComplete="bday"
                    isRequired
                    maxDate={today}
                  />
                )}
                <FormControl isInvalid={!!errors.agreed}>
                  <Checkbox
                    alignItems="flex-start"
                    onChange={() => {
                      setFieldValue('agreed', !agreed);
                    }}
                  >
                    <Trans t={t} i18nKey="updatesCheckboxLabel" values={{ community: community?.displayName }}>
                      <Link href={ROUTES.legal.terms.path} target="_blank">
                        Terms of Service
                      </Link>
                      <Link href={ROUTES.legal.privacy.path} target="_blank">
                        Privacy Policy
                      </Link>
                    </Trans>
                  </Checkbox>
                </FormControl>
                <Button variant="primary" type="submit" w="full" isLoading={joining} isDisabled={!isValid}>
                  {t('continueButton')}
                </Button>
              </Stack>
            </form>
          )}
        </Formik>
      </Stack>
    </Container>
  );
};

CommunityJoinForm.fragments = {
  community: gql`
    fragment CommunityJoinForm_community on Community {
      id
      displayName
      slug
      welcomeExplainer
      minimumAgeToJoin
      ...CommunitySpotifyInterstitial_community
    }
    ${CommunitySpotifyInterstitial.fragments.community}
  `,
};
