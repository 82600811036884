import { Container, Stack, StackProps } from '@chakra-ui/react';

interface PagePanelProps extends StackProps {
  isNarrow?: boolean;
}

export const PagePanel = ({ children, isNarrow = false, ...rest }: PagePanelProps) => {
  return (
    <Container
      as={Stack}
      bg="chakra-body-bg"
      my={{ xl: 10 }}
      borderRadius={{ xl: '20px' }}
      py={{ base: 4, lg: 12, xl: 20 }}
      flexGrow={{ base: 1, xl: 0 }}
      width={isNarrow ? { base: 'full', xl: '40rem' } : undefined}
      alignItems={isNarrow ? 'center' : undefined}
      {...rest}
    >
      {children}
    </Container>
  );
};
