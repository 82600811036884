'use client';

import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Container, Heading, Stack } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { AuthContext } from 'providers/Auth';
import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES } from 'routes';

import CommunityLayout from 'app/[community]/layout';
import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { PagePanel } from 'components/molecules/PagePanel';
import { BaseLayout } from 'components/templates/Base/base';
import { useMixpanel } from 'hooks/useMixpanel';
import { showError } from 'utils/errors';

import { LoginCallbackPageMutation, LoginCallbackPageMutationVariables } from './__graphql__/LoginCallbackPageMutation';

const MUTATION = gql`
  mutation LoginCallbackPageMutation($name: String!, $didToken: String!, $distinctId: String) {
    loginWithMagicLinkProvider(name: $name, didToken: $didToken, distinctId: $distinctId)
  }
`;

const LoginCallbackPage = () => {
  const { user, magic } = useContext(AuthContext);

  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [loginWithProvider] = useMutation<LoginCallbackPageMutation, LoginCallbackPageMutationVariables>(MUTATION);
  const client = useApolloClient();
  const { mixpanel } = useMixpanel();
  const communitySlug = localStorage?.getItem('community');

  useEffect(() => {
    if (user) {
      // useNavigate doesn't support query strings so doing a full page redirect
      window.location.href = window.origin + (params.get('then') || ROUTES.dashboard.base.path);
    }
  }, [user, navigate, params]);

  useEffect(() => {
    (async () => {
      const magicResult = await magic.oauth.getRedirectResult();
      const name = magicResult.oauth.userInfo.name;

      if (!name) {
        throw new Error('No name provided');
      }

      try {
        const result = await loginWithProvider({
          variables: {
            name,
            didToken: magicResult.magic.idToken,
            distinctId: mixpanel?.get_distinct_id(),
          },
        });
        if (result.data?.loginWithMagicLinkProvider) {
          Cookies.set('auth', result.data?.loginWithMagicLinkProvider);
          await client.resetStore();
        }
      } catch (error) {
        if (error instanceof Error) {
          showError(error.message);
          navigate(params.get('then') || ROUTES.dashboard.base.path);
        }
      }
    })();
  });

  if (communitySlug) {
    return (
      <CommunityLayout>
        <Helmet>
          <title>Signing into Temple</title>
        </Helmet>
        <PagePanel textAlign="center" isNarrow>
          <Stack as={Container} variant="form" spacing={6}>
            <Heading variant="h4">Logging in...</Heading>
            <WrappedSpinner />
          </Stack>
        </PagePanel>
      </CommunityLayout>
    );
  } else {
    return (
      <BaseLayout>
        <Helmet>
          <title>Signing into Temple</title>
        </Helmet>
        <PagePanel isNarrow>
          <Stack as={Container} variant="form" spacing="6">
            <Heading textAlign="center" mb="6">
              Signing you in&hellip;
            </Heading>
            <WrappedSpinner />
          </Stack>
        </PagePanel>
      </BaseLayout>
    );
  }
};

export default LoginCallbackPage;
