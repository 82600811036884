import { HStack, IconProps, Skeleton, Stack, SkeletonProps, Heading } from '@chakra-ui/react';
import { IconProps as PhosphorIconProps } from 'phosphor-react';

import { ImageOrPlaceholder } from 'components/atoms/ImageOrPlaceholder';

export interface DashboardCardProps extends SkeletonProps {
  label?: string;
  title?: string;
  icon?: React.ForwardRefExoticComponent<PhosphorIconProps & React.RefAttributes<SVGSVGElement>>;
  iconProps?: IconProps;
  image?: string | null;
  isLoaded?: boolean;
}

export const DashboardCard = ({
  label,
  title,
  children,
  icon,
  iconProps,
  image,
  isLoaded = true,
  ...rest
}: DashboardCardProps) => (
  <Stack borderColor="chakra-border-color" borderWidth="1px" borderRadius="md" padding="4" spacing="4">
    {label && (
      <Skeleton fontWeight="bold" fontSize="sm" isLoaded={isLoaded}>
        {label}
      </Skeleton>
    )}
    <HStack spacing="4" alignItems="stretch">
      <ImageOrPlaceholder
        w="36"
        h="36"
        borderRadius="md"
        icon={icon}
        src={image}
        iconProps={iconProps}
        flexShrink={0}
        isLoaded={isLoaded}
      />
      <Stack spacing="4" flexGrow="1">
        {title && (
          <Skeleton isLoaded={isLoaded}>
            <Heading variant="h4">{title}</Heading>
          </Skeleton>
        )}
        <Skeleton isLoaded={isLoaded} flexGrow="1" {...rest}>
          {children}
        </Skeleton>
      </Stack>
    </HStack>
  </Stack>
);
