import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Button, ButtonProps, Icon } from '@chakra-ui/react';
import { ethers } from 'ethers';
import Cookies from 'js-cookie';
import { useState } from 'react';

import { ReactComponent as MetaMaskFox } from 'assets/icons/metamask-fox.svg';
import { extractErrors, showError } from 'utils/errors';

import {
  MetaMaskLoginButtonMutation,
  MetaMaskLoginButtonMutationVariables,
} from './__graphql__/MetaMaskLoginButtonMutation';
import { MetaMaskLoginButtonQuery } from './__graphql__/MetaMaskLoginButtonQuery';

export interface WalletAuthSigningInfo {
  address: string;
  message: string;
  signedMessage: string;
}

interface MetaMaskLoginButtonProps extends ButtonProps {
  onLoginResult?: (signingInfo: WalletAuthSigningInfo, loggedIn: boolean) => void;
}

const QUERY = gql`
  query MetaMaskLoginButtonQuery {
    walletAuthMessage
  }
`;

const MUTATION = gql`
  mutation MetaMaskLoginButtonMutation($message: String!, $address: String!, $signedMessage: String!) {
    loginWithSignedMessage(message: $message, address: $address, signedMessage: $signedMessage)
  }
`;

export const MetaMaskLoginButton = ({ onLoginResult, ...rest }: MetaMaskLoginButtonProps) => {
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const [loginWithSignedMessage] = useMutation<MetaMaskLoginButtonMutation, MetaMaskLoginButtonMutationVariables>(
    MUTATION,
  );

  const handleClick = async () => {
    if (!window?.ethereum) {
      showError("MetaMask plugin not detected in this browser. Please check that it's installed to continue.");
      return;
    }

    setLoading(true);

    const provider = new ethers.providers.Web3Provider(window?.ethereum);
    try {
      await provider.send('eth_requestAccounts', []);
    } catch (error) {
      showError('Could not load wallet from MetaMask, please check it is unlocked');
      setLoading(false);
      return;
    }
    const signer = provider.getSigner();
    const { data } = await client.query<MetaMaskLoginButtonQuery>({ query: QUERY, fetchPolicy: 'network-only' });

    try {
      const signedMessage = await signer.signMessage(data.walletAuthMessage);
      const address = await signer.getAddress();
      const result = await loginWithSignedMessage({
        variables: { message: data.walletAuthMessage, address, signedMessage },
      });
      const success = !!result.data?.loginWithSignedMessage;
      if (result.data?.loginWithSignedMessage) {
        Cookies.set('auth', result.data?.loginWithSignedMessage);
        await client.resetStore();
      }
      if (onLoginResult) {
        onLoginResult({ address, message: data.walletAuthMessage, signedMessage }, success);
      }
    } catch (error) {
      extractErrors(() => {})(error);
    } finally {
      setLoading(false);
    }
  };

  if (!window?.ethereum && ('ontouchstart' in window || 'onmsgesturechange' in window)) {
    return (
      <Button
        as="a"
        variant="outline"
        borderWidth="1"
        isLoading={loading}
        isDisabled={loading}
        leftIcon={
          <Icon boxSize="6">
            <MetaMaskFox />
          </Icon>
        }
        href={`https://metamask.app.link/dapp/${window.location.hostname}${window.location.pathname}${
          window.location.search
        }${window.location.search ? '&metamask=true' : '?metamask=true'}`}
        {...rest}
      >
        Continue with MetaMask
      </Button>
    );
  }

  return (
    <Button
      variant="outline"
      borderWidth="1"
      isLoading={loading}
      isDisabled={loading}
      leftIcon={
        <Icon boxSize="6">
          <MetaMaskFox />
        </Icon>
      }
      onClick={handleClick}
      {...rest}
    >
      Continue with MetaMask
    </Button>
  );
};
