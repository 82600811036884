import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client';
import { Box, Button, ButtonProps, Flex, Icon, Spacer, Text } from '@chakra-ui/react';
import { toast } from 'App';
import { useEffect } from 'react';
import { ROUTES } from 'routes';

import { ReactComponent as SpotifyIcon } from 'assets/icons/spotify.svg';
import { Link } from 'components/atoms/Link';
import { WrappedSpinner } from 'components/atoms/WrappedSpinner';

import {
  SpotifyConnectButtonPreSaveMutation,
  SpotifyConnectButtonPreSaveMutationVariables,
} from './__graphql__/SpotifyConnectButtonPreSaveMutation';
import { SpotifyConnectButtonQuery } from './__graphql__/SpotifyConnectButtonQuery';

interface SpotifyLoginButtonProps extends ButtonProps {
  then?: string;
  preSave?: string;
}

const QUERY = gql`
  query SpotifyConnectButtonQuery {
    me {
      id
      spotifyConnection {
        id
        isActive
      }
    }
    spotifyAuthorizationUrl
  }
`;

const PRE_SAVE_MUTATION = gql`
  mutation SpotifyConnectButtonPreSaveMutation($communityId: ID!) {
    updateUserCommunitySpotifyPreferences(communityId: $communityId, allowPresave: true) {
      id
      allowPresave
    }
  }
`;

export const SpotifyConnectButton = ({ then, preSave, ...props }: SpotifyLoginButtonProps) => {
  const { data, loading } = useQuery<SpotifyConnectButtonQuery>(QUERY);
  const [updateCommunitySpotifyPreferences, { loading: preSaving }] = useMutation<
    SpotifyConnectButtonPreSaveMutation,
    SpotifyConnectButtonPreSaveMutationVariables
  >(PRE_SAVE_MUTATION);
  const client = useApolloClient();

  localStorage?.setItem('postSpotifyConnectUrl', then ?? window.location.pathname);
  const isConnected = data?.me?.spotifyConnection?.isActive;

  const enablePreSave = async () => {
    if (!preSave) return;
    localStorage?.removeItem('spotify:presave');
    try {
      await updateCommunitySpotifyPreferences({ variables: { communityId: preSave } });
    } catch (e) {
      console.error(e);
      toast({
        status: 'error',
        description: 'Could not enable Pre-Save',
      });
      return;
    }
    await client.resetStore();
  };

  useEffect(() => {
    if (isConnected && preSave && localStorage?.getItem('spotify:presave') === preSave) {
      enablePreSave();
    }
  });

  if (loading) {
    return <WrappedSpinner />;
  }

  if (isConnected && preSave) {
    return (
      <Button
        variant="outline"
        borderWidth="1"
        leftIcon={
          <Icon boxSize="6">
            <SpotifyIcon />
          </Icon>
        }
        onClick={enablePreSave}
        isLoading={preSaving}
        {...props}
      >
        Enable Pre-Save to unlock
      </Button>
    );
  }

  return isConnected ? (
    <Flex
      flex="1 0"
      px="6"
      py="3"
      justifyContent="space-between"
      alignItems="center"
      bg="chakra-subtle-bg"
      border="1px solid"
      borderColor="chakra-border-color"
      borderRadius="lg"
    >
      <Box>
        <Icon boxSize="6" mr={4}>
          <SpotifyIcon />
        </Icon>
        <Text fontWeight="bold">Connected</Text>
      </Box>
      <Spacer />
      <Link ml="12" to={ROUTES.you.settings.spotify.path} state={{ from: window.location.pathname }}>
        Manage
      </Link>
    </Flex>
  ) : (
    <Button
      as="a"
      href={data?.spotifyAuthorizationUrl}
      variant="outline"
      borderWidth="1"
      leftIcon={
        <Icon boxSize="6">
          <SpotifyIcon />
        </Icon>
      }
      onClick={() => {
        if (preSave) {
          localStorage?.setItem('spotify:presave', preSave);
        }
      }}
      {...props}
    >
      {preSave ? 'Pre-Save to unlock' : 'Connect your Spotify'}
    </Button>
  );
};
