import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  InputGroup,
  Spinner,
  Stack,
  StackProps,
} from '@chakra-ui/react';
import { ComputableProgressInfo, UploadClient } from '@uploadcare/upload-client';
import { X } from 'phosphor-react';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

export interface UploadedFile {
  id?: string | null | undefined;
  name: string;
  progress?: number | null | undefined;
}

export interface Upload extends UploadedFile {
  index: number | string;
  file: File | { name: string };
}

interface MultiFileUploadProps extends Omit<StackProps, 'onChange'> {
  onChange: (files: UploadedFile[]) => void;
  initialValues?: Upload[];
}

const client = new UploadClient({ publicKey: process.env.REACT_APP_UPLOADCARE_PUBLIC_KEY ?? '' });

export const MultiFileUpload = ({ onChange, initialValues = [], ...rest }: MultiFileUploadProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploads, _setUploads] = useState<Upload[]>(initialValues);
  const uploadsRef = useRef<Upload[]>([]);
  const uploadsCounter = useRef<number>(initialValues.length);

  useEffect(() => {
    uploadsRef.current = uploads;
  }, [uploads]);

  const setUploads = (uploads: Upload[]) => {
    _setUploads(uploads);
    onChange(uploads.map((upload) => ({ name: upload.name, id: upload.id, progress: upload.progress })));
  };

  const updateUpload = (index: number | string, fields: Partial<Upload>) => {
    setUploads(
      uploadsRef.current.map((upload) => {
        if (upload.index === index) {
          return { ...upload, ...fields };
        } else {
          return upload;
        }
      }),
    );
  };

  const removeUpload = (index: string | number) => {
    setUploads(uploadsRef.current.filter((upload) => upload.index !== index));
  };

  const onFileSelection = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event?.currentTarget?.files;

    if (files) {
      const newUploads = [...uploadsRef.current];
      let addedToState = false;
      Array.from(files).forEach((file) => {
        const index = uploadsCounter.current++;
        const name = file.name.split('.').slice(0, -1).join('.');
        newUploads.push({ index, name, file });
        client
          .uploadFile(file, {
            onProgress: (progressInfo) => {
              if (addedToState) {
                updateUpload(index, { progress: (progressInfo as ComputableProgressInfo).value });
              }
            },
          })
          .then((file) => {
            if (addedToState) {
              updateUpload(index, { id: file.uuid });
            }
          });
      });
      setUploads(newUploads);
      addedToState = true;
    }
  };

  return (
    <Stack spacing="4" {...rest}>
      <HStack spacing="4">
        <Box>
          <Button
            onClick={() => {
              inputRef.current?.click();
            }}
          >
            Upload files
          </Button>
          <input
            type="file"
            multiple
            ref={inputRef}
            style={{
              display: 'none',
            }}
            onChange={onFileSelection}
          />
        </Box>
        <Box fontSize="sm">The maximum size per file is 100MB.</Box>
      </HStack>
      <Stack spacing="3">
        {uploads.map((upload) => (
          <Stack key={upload.index || upload.id}>
            <HStack spacing="4">
              <FormControl variant="floating">
                <InputGroup>
                  <Input
                    type="text"
                    placeholder=" "
                    onChange={(e) => {
                      updateUpload(upload.index, { name: e.target.value });
                    }}
                    value={upload.name}
                  />
                  <FormLabel htmlFor="">Short description</FormLabel>
                </InputGroup>
              </FormControl>
              {upload.id ? (
                <Icon cursor="pointer" onClick={() => removeUpload(upload.index)} as={X} weight="bold" />
              ) : (
                <Spinner size="sm" />
              )}
            </HStack>
            <HStack>
              <Box fontSize="sm" flexGrow="1" ml="4">
                {upload.file.name}
              </Box>
              {upload.id ? (
                <Box textTransform="uppercase" fontSize="sm" fontWeight="bold" color="green.500">
                  Uploaded
                </Box>
              ) : (
                <Box textTransform="uppercase" fontSize="sm" fontWeight="bold" color="red.500">
                  Uploading &mdash; {Math.round((upload.progress || 0) * 100)}%
                </Box>
              )}
            </HStack>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
