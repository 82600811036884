import { Box, HStack, Spacer } from '@chakra-ui/react';

interface PaymentLineItemProps {
  label: string;
  children: React.ReactNode;
}

export const PaymentLineItem = ({ label, children }: PaymentLineItemProps) => (
  <HStack color="muted" fontSize="sm">
    <Box>{label}</Box>
    <Spacer />
    <Box fontWeight="semibold">{children}</Box>
  </HStack>
);
