import { Stack, StackProps } from '@chakra-ui/react';

import { ItemCardContext } from './context';

export { ItemCardBody } from './body';
export { ItemCardImage } from './image';
export { ItemCardHeader } from './header';

export interface ItemCardProps extends StackProps {
  isLoaded?: boolean;
}

export const ItemCard = ({ isLoaded = true, ...rest }: ItemCardProps) => (
  <ItemCardContext.Provider value={{ isLoaded }}>
    <Stack
      spacing="0"
      borderColor="chakra-border-color"
      bg="chakra-body-bg"
      borderWidth="1px"
      borderRadius="lg"
      {...rest}
    />
  </ItemCardContext.Provider>
);
