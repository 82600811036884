import { Icon, IconProps, Image, Link } from '@chakra-ui/react';
import { LinkType } from '__graphql__/globalTypes';

interface SocialIconProps extends IconProps {
  type?: LinkType;
}

export const SocialIcon = ({ type, ...props }: SocialIconProps) => {
  const iconProps = { as: Image, boxSize: 6, viewBox: '0 0 24 30', alt: 'icon', ...props };
  const getSrc = (iconName: string) => `${window.location.origin}/icons/social-logos/${iconName}`;
  switch (type) {
    case 'APPLE_MUSIC':
      return <Icon src={`${getSrc('APPLE_MUSIC.svg')}`} {...iconProps} />;
    case 'BANDCAMP':
      return <Icon src={`${getSrc('BANDCAMP.svg')}`} {...iconProps} />;
    case 'DEEZER':
      return <Icon src={`${getSrc('DEEZER.svg')}`} {...iconProps} />;
    case 'DISCORD':
      return <Icon src={`${getSrc('DISCORD.svg')}`} {...iconProps} />;
    case 'FACEBOOK':
      return <Icon src={`${getSrc('FACEBOOK.svg')}`} {...iconProps} />;
    case 'INSTAGRAM':
      return <Icon src={`${getSrc('INSTAGRAM.svg')}`} {...iconProps} />;
    case 'SNAP':
      return <Icon src={`${getSrc('SNAP.svg')}`} {...iconProps} />;
    case 'SPOTIFY':
      return <Icon src={`${getSrc('SPOTIFY.svg')}`} {...iconProps} />;
    case 'SOUNDCLOUD':
      return <Icon src={`${getSrc('SOUNDCLOUD.svg')}`} {...iconProps} />;
    case 'TIDAL':
      return <Icon src={`${getSrc('TIDAL.svg')}`} {...iconProps} />;
    case 'TIKTOK':
      return <Icon src={`${getSrc('TIKTOK.svg')}`} {...iconProps} />;
    case 'TWITTER':
      return <Icon src={`${getSrc('TWITTER.svg')}`} {...iconProps} />;
    case 'YOUTUBE':
      return <Icon src={`${getSrc('YOUTUBE.svg')}`} {...iconProps} />;
    default:
      return <Icon src={`${getSrc('WEBSITE.svg')}`} {...iconProps} />;
  }
};

interface SocialLinkIconProps {
  linkType: LinkType;
  url: string;
}

export const SocialLinkIcon = ({ linkType, url }: SocialLinkIconProps) => {
  const formatUrl = url.includes('http') ? url : `http://${url}`;
  return (
    <Link href={formatUrl} target="_blank" rel="noopener noreferrer">
      <SocialIcon type={linkType} />
    </Link>
  );
};
