import { Route, Routes } from 'react-router-dom';
import { ROUTES } from 'routes';

import { RequireAuth } from 'app/decorators';
import { NotFoundPanel } from 'components/molecules/NotFoundPanel';
import { DashboardSidebar } from 'components/organisms/DashboardSidebar';
import { BaseSideBarLayout } from 'components/templates/BaseSideBar';

import MyCollectionPage from './collection/page';
import MyMembershipsPage from './memberships/page';
import MySettingsPage from './settings/page';
import MySpotifySettingsPage from './settings/spotify/page';

export const MyRoutes = () => {
  return (
    <BaseSideBarLayout sidebar={<DashboardSidebar />} navbarProps={{ isDashboard: true }}>
      <RequireAuth>
        <Routes>
          <Route path={ROUTES.you.collection.relative} element={<MyCollectionPage />} />
          <Route path={ROUTES.you.memberships.relative} element={<MyMembershipsPage />} />
          <Route path={ROUTES.you.settings.relative} element={<MySettingsPage />} />
          <Route path={ROUTES.you.settings.spotify.relative} element={<MySpotifySettingsPage />} />
          <Route path="*" element={<NotFoundPanel />} />
        </Routes>
      </RequireAuth>
    </BaseSideBarLayout>
  );
};
