'use client';

import { Stack } from '@chakra-ui/react';

const BaseLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack minH="full" w="full" spacing="0">
      {children}
    </Stack>
  );
};

export default BaseLayout;
