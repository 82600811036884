import { ObservableQuery, gql, useQuery } from '@apollo/client';
import { createContext } from 'react';

import ErrorPage from 'app/error';
import { CommunityPanel } from 'components/molecules/CommunityPanel';
import { CommunityHeader } from 'components/organisms/CommunityHeader';
import { CommunityJoinForm } from 'components/organisms/CommunityJoinForm';
import { CommunityJoinedConfirmation } from 'components/organisms/CommunityJoinedConfirmation';

import {
  CommunityLayoutQuery_community,
  CommunityLayoutQuery,
  CommunityLayoutQueryVariables,
} from './__graphql__/CommunityLayoutQuery';

export const QUERY = gql`
  query CommunityLayoutQuery($slug: String!) {
    community(slug: $slug) {
      id
      slug
      background(variant: cover)
      tile: background(variant: tile)
      isWhiteLabel
      currentMembership {
        user {
          id
        }
        role
      }
      offerings {
        id
      }
      ...CommunityJoinForm_community
      ...CommunityJoinedConfirmation_community
      ...CommunityHeader_community
      ...CommunityPanel_community
    }
  }
  ${CommunityJoinForm.fragments.community}
  ${CommunityJoinedConfirmation.fragments.community}
  ${CommunityHeader.fragments.community}
  ${CommunityPanel.fragments.community}
`;

export interface CommunityContextType {
  community?: CommunityLayoutQuery_community | null;
  refetch?: ObservableQuery['refetch'];
  communityLoading: boolean;
}

export const CommunityContext = createContext<CommunityContextType>({
  communityLoading: true,
});

export const CommunityProvider = ({
  children,
  communitySlug,
}: {
  children: (value: CommunityContextType) => React.ReactNode;
  communitySlug?: string;
}) => {
  const { data, loading, error, refetch } = useQuery<CommunityLayoutQuery, CommunityLayoutQueryVariables>(QUERY, {
    variables: {
      slug: communitySlug ?? '',
    },
    skip: !communitySlug,
  });

  if (error) {
    return <ErrorPage />;
  }

  const value = {
    community: data?.community,
    communityLoading: loading,
    refetch,
  };

  return <CommunityContext.Provider value={value}>{children(value)}</CommunityContext.Provider>;
};
