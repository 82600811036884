'use client';

import { PlusCircle, SpotifyLogo } from 'phosphor-react';
import { AuthContext } from 'providers/Auth';
import { useContext } from 'react';
import { ROUTES } from 'routes';

import NotFoundPage from 'app/not-found';
import { WrappedSpinner } from 'components/atoms/WrappedSpinner';
import { Sidebar } from 'components/molecules/Sidebar';
import { SidebarLink } from 'components/molecules/SidebarLink';
import { BaseSideBarLayout } from 'components/templates/BaseSideBar';

const AdminLayout = ({ children }: { children: React.ReactNode }) => {
  const { user, userLoading } = useContext(AuthContext);

  if (userLoading) {
    return <WrappedSpinner />;
  }

  if (!user?.isAdmin && !user?.isStaff) {
    return <NotFoundPage />;
  }

  return (
    <BaseSideBarLayout
      sidebar={
        <Sidebar>
          <SidebarLink label="New Community" to={ROUTES.admin.createCommunity.path} icon={PlusCircle} />
          <SidebarLink label="Spotify Pre-Save" to={ROUTES.admin.spotifyPreSave.path} icon={SpotifyLogo} />
        </Sidebar>
      }
    >
      {children}
    </BaseSideBarLayout>
  );
};

export default AdminLayout;
