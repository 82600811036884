'use client';

import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client/react';
import { Box, Container, HStack, Stack, useBreakpointValue } from '@chakra-ui/react';
import { CommunityContext } from 'providers/Community';
import { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { params, ROUTES } from 'routes';

import { CopyShareLinkButton } from 'components/atoms/CopyShareLinkButton';
import { ErrorMessage } from 'components/atoms/ErrorMessage';
import { Link } from 'components/atoms/Link';
import { QuantitySelector } from 'components/atoms/QuantitySelector';
import { CommunityPanel } from 'components/molecules/CommunityPanel';
import { ItemCriteria } from 'components/molecules/ItemCriteria';
import { NotFoundPanel } from 'components/molecules/NotFoundPanel';
import { OfferingCard } from 'components/molecules/OfferingCard';
import { OfferingShareButton } from 'components/molecules/OfferingShareButton';
import { PagePanel } from 'components/molecules/PagePanel';
import { CollectibleBuyButton } from 'components/organisms/CollectibleBuyButton';
import { CollectibleClaimButton } from 'components/organisms/CollectibleClaimButton';
import { ItemDetails } from 'components/organisms/ItemDetails';
import { ItemPageHeader } from 'components/organisms/ItemPageHeader';
import { useIdFromSlug } from 'hooks/useIdFromSlug';
import { useTrackPageView } from 'hooks/useTrackPageView';

import { ItemDetailPageQuery, ItemDetailPageQueryVariables } from './__graphql__/ItemDetailPageQuery';

export const QUERY = gql`
  query ItemDetailPageQuery($id: ID!, $key: String) {
    offering(id: $id) {
      id
      name
      isOwned
      isSecret
      price
      slug
      isOwned
      numOwned
      maxPurchaseQuantity
      community {
        id
        slug
      }
      unlockCriteria {
        id
        isMet
      }
      ...ItemPageHeader_offering
      ...ItemDetails_offering
      ...CollectibleBuyButton_offering
      ...CollectibleClaimButton_offering
      ...OfferingCard_offering
      ...OfferingShareButton_offering
      ...ItemCriteria_offering
    }
    offeringInviteCode(key: $key) {
      maxClaims
      totalClaims
      ...CollectibleBuyButton_offeringInviteCode
      ...CollectibleClaimButton_offeringInviteCode
      ...ItemCriteria_offeringInviteCode
    }
  }
  ${ItemPageHeader.fragments.offering}
  ${ItemDetails.fragments.offering}
  ${OfferingCard.fragments.offering}
  ${OfferingShareButton.fragments.offering}
  ${CollectibleBuyButton.fragments.offering}
  ${CollectibleBuyButton.fragments.offeringInviteCode}
  ${CollectibleClaimButton.fragments.offering}
  ${CollectibleClaimButton.fragments.offeringInviteCode}
  ${ItemCriteria.fragments.offering}
  ${ItemCriteria.fragments.offeringInviteCode}
`;

const Page = () => {
  // url is built like so: "base_url/item/{offeringName}-{offeringId}/{claimKey}"
  const id = useIdFromSlug();
  const { key } = useParams<keyof params>();
  const { community, communityLoading } = useContext(CommunityContext);
  const [quantity, setQuantity] = useState(1);

  const { data, loading, error } = useQuery<ItemDetailPageQuery, ItemDetailPageQueryVariables>(QUERY, {
    variables: { id: id ?? '', key: key ?? '' },
    skip: !id,
  });
  const isDesktop = useBreakpointValue({ base: false, lg: true });

  const token = data?.offering;

  const criteriaIsMet = data?.offering?.unlockCriteria?.every((criteria) => criteria.isMet);

  useTrackPageView(
    'Item Detail',
    {
      id: token?.id,
      community_id: token?.community.id,
      community_slug: token?.community.slug,
      isOwned: token?.isOwned,
      isSecret: token?.isSecret,
      hasKey: !!data?.offeringInviteCode,
    },
    !token,
  );

  if (error) {
    return (
      <PagePanel isNarrow>
        <ErrorMessage />
      </PagePanel>
    );
  }

  if (!loading && !communityLoading && id) {
    if ((key && !data?.offeringInviteCode) || !data?.offering || data.offering.community.id !== community?.id) {
      return <NotFoundPanel />;
    }
  }

  const cardAndOwnership = (
    <>
      <OfferingCard w="full" mx="auto" isLoaded={!!data?.offering} offering={data?.offering} />
      {data?.offering?.isOwned ? (
        <Box py="2">
          You have {data?.offering.numOwned} in <Link to={ROUTES.you.collection.path}>your collection</Link>.
        </Box>
      ) : null}
    </>
  );

  const buttons = (
    <Stack spacing="2">
      {criteriaIsMet && (
        <>
          {data?.offering?.price &&
            data.offering.releasedAt &&
            !(data.offering.isSecret && !data.offeringInviteCode?.key) && (
              <>
                {data.offering.numOwned < data.offering.maxPurchaseQuantity - 1 && (
                  <QuantitySelector
                    label="Purchase quantity"
                    maxQuantity={data.offering.maxPurchaseQuantity - data.offering.numOwned}
                    quantity={quantity}
                    setQuantity={setQuantity}
                  />
                )}
                <CollectibleBuyButton
                  offering={data?.offering}
                  offeringInviteCode={data?.offeringInviteCode}
                  quantity={quantity}
                />
              </>
            )}
          {!data?.offering?.price && !data?.offering?.isOwned && (
            <CollectibleClaimButton offering={data?.offering} offeringInviteCode={data?.offeringInviteCode} />
          )}
          {data?.offering?.isOwned && (
            <HStack>
              <OfferingShareButton flexGrow="1" offering={data?.offering} />
              <CopyShareLinkButton flexGrow="1" />
            </HStack>
          )}
        </>
      )}
    </Stack>
  );

  return (
    <>
      <Helmet>{data?.offering?.name && <title>{data?.offering?.name} on Temple</title>}</Helmet>
      <PagePanel w="full">
        <HStack justifyContent="center" alignItems="flex-start" spacing="8">
          {isDesktop && (
            <Stack
              spacing="4"
              textAlign="center"
              alignItems={{ base: undefined, lg: 'center' }}
              flexGrow={{ base: undefined, lg: '0.4' }}
              minW={{ base: '12rem', md: '20rem' }}
            >
              {cardAndOwnership}
            </Stack>
          )}
          <Stack
            as={Container}
            alignItems={{ base: undefined, sm: 'center' }}
            variant="text"
            flexGrow="1"
            spacing={isDesktop ? 10 : 8}
            py={{ base: '4', lg: '0' }}
          >
            <ItemPageHeader offering={data?.offering} textAlign="center" />
            {!isDesktop && (
              <Stack
                textAlign="center"
                alignItems={{ base: undefined, lg: 'center' }}
                spacing="4"
                flexGrow={{ base: undefined, lg: '0.4' }}
                minW={{ base: '12rem', md: '20rem' }}
              >
                {cardAndOwnership}
                {buttons}
                <ItemCriteria offering={data?.offering} offeringInviteCode={data?.offeringInviteCode} />
              </Stack>
            )}
            <Stack as={Container} variant="form" p="0 !important" spacing="4">
              <ItemDetails offering={data?.offering} />
              {isDesktop && buttons}
              {isDesktop && <ItemCriteria offering={data?.offering} offeringInviteCode={data?.offeringInviteCode} />}
            </Stack>
          </Stack>
        </HStack>
      </PagePanel>
      {!communityLoading && !community?.currentMembership && <CommunityPanel mt="auto" community={community} />}
    </>
  );
};

export default Page;
