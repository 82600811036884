import { FormControl, FormLabel, Theme, useTheme, FormErrorMessage, useToken } from '@chakra-ui/react';
import { useField } from 'formik';
import ReactSelect, { StylesConfig, GroupBase } from 'react-select';

interface Option {
  label: string;
  value: string;
}
export interface SelectProps {
  id?: string;
  name: string;
  placeholder?: string;
  label?: string;
  options: Array<{ value: string; label: string }>;
  customStyles?: StylesConfig<Option, false, GroupBase<any>>;
  defaultValue?: string;
  isDisabled?: boolean;
  isSearchable?: boolean;
  isRequired?: boolean;
  isMulti?: boolean;
}

export const Select = ({ id, name, placeholder, options, label, isRequired, isMulti, ...props }: SelectProps) => {
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;

  // Adapt to chakra-ui
  const theme = useTheme<Theme>();
  const [bodyText, bodyBg, subtleBg, borderColor] = useToken('semanticTokens', [
    'colors.chakra-body-text',
    'colors.chakra-body-bg',
    'colors.chakra-subtle-bg',
    'colors.chakra-border-color',
  ]);

  const customStyles: StylesConfig<
    {
      label: string;
      value: string;
    },
    false,
    GroupBase<any>
  > = {
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      padding: '1px 0',
      color: bodyText,
      backgroundColor: bodyBg,
      borderColor: borderColor,
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: subtleBg,
    }),
    container: (provided, state) => ({
      ...provided,
    }),
    input: (provided, state) => ({
      ...provided,
      color: bodyText,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      marginLeft: 8,
      color: bodyText,
    }),
    multiValue: (provided, state) => ({
      ...provided,
      color: bodyText,
      backgroundColor: bodyBg,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: theme.colors.gray[500],
      marginLeft: 8,
      fontWeight: theme.fontWeights.normal,
      fontFamily: theme.fonts.body,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,
      display: 'none',
    }),
  };
  const getValue = (options: Option[]) => {
    if (isMulti) {
      return options ? options.filter((option) => field?.value?.indexOf(option.value) >= 0) : [];
    } else {
      return options ? options.find((option) => option.value === field.value) : '';
    }
  };
  return (
    <FormControl isRequired={isRequired} isInvalid={meta.touched && !!meta.error}>
      {label && (
        <FormLabel htmlFor={id} fontSize="sm" fontWeight="bold">
          {label}
        </FormLabel>
      )}
      <ReactSelect
        id={id}
        placeholder={placeholder}
        styles={customStyles}
        theme={(defaultTheme) => ({
          ...defaultTheme,
          borderRadius: 5,
          // colors: {
          //   ...defaultTheme.colors,
          //   neutral0: bodyText,
          //   primary25: theme.colors.gray[500],
          // },
        })}
        {...field}
        {...props}
        //@ts-ignore
        isMulti={isMulti}
        options={options}
        value={getValue(options) as any}
        onChange={(selectedOption) => {
          if (!isMulti) setValue(selectedOption.value);
          else {
            const selection: string[] = selectedOption ? selectedOption.map((item: any) => item.value) : [];
            setValue(selection);
          }
        }}
      />
      {meta.touched && meta.error ? <FormErrorMessage>{meta.error}</FormErrorMessage> : null}
    </FormControl>
  );
};
