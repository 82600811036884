import { Box } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from 'routes';

import { AdminCommunityForm } from 'components/forms/AdminCommunityForm';
import { AdminSpotifyPreSaveForm } from 'components/forms/AdminSpotifyPreSaveForm';

import AdminLayout from './layout';

export const AdminRoutes = () => {
  return (
    <AdminLayout>
      <Routes>
        <Route path={ROUTES.admin.createCommunity.relative} element={<AdminCommunityForm />} />
        <Route path={ROUTES.admin.spotifyPreSave.relative} element={<AdminSpotifyPreSaveForm />} />
        <Route path={ROUTES.error.path} element={<Box></Box>} />
      </Routes>
    </AdminLayout>
  );
};
