import {
  Container,
  HStack,
  Spacer,
  Flex,
  ContainerProps,
  Avatar,
  Box,
  Icon,
  useBreakpointValue,
  useDisclosure,
  Stack,
  Button,
  useColorMode,
  Text,
} from '@chakra-ui/react';
import { CaretDown, List, Moon, SignOut, Sun, Ticket, X } from 'phosphor-react';
import { AuthContext } from 'providers/Auth';
import { CommunityContext } from 'providers/Community';
import { useContext, MouseEvent } from 'react';
import { Helmet } from 'react-helmet';
import { ROUTES } from 'routes';

import { ImageOrPlaceholder } from 'components/atoms/ImageOrPlaceholder';
import { Link, LinkBox, RouterLink } from 'components/atoms/Link';
import { Logo } from 'components/atoms/Logo';
import { CommunityLogoNav } from 'components/molecules/CommunityLogo';
import { SidebarLink } from 'components/molecules/SidebarLink';
import { UserDropdown } from 'components/organisms/Navbar/UserDropdown';

import { DashboardSidebar } from '../DashboardSidebar';

export interface NavbarProps extends ContainerProps {
  isDashboard?: boolean;
  isFullWidth?: boolean;
  loading?: boolean;
  dropdownChildren?: React.ReactNode;
}

export const Navbar = ({
  isDashboard = false,
  isFullWidth = true,
  loading = false,
  dropdownChildren,
  children,
  ...rest
}: NavbarProps) => {
  const dropdownHoldsSidebar = useBreakpointValue({ base: true, lg: false });
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { logout, user, userLoading, logoutLoading, currentCommunityId, setCurrentCommunityId } =
    useContext(AuthContext);
  const { community } = useContext(CommunityContext);
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <>
      <Helmet>
        {
          <link
            rel="icon"
            href={(community?.isWhiteLabel ? community?.logo : null) || '/favicon.png'}
            type="image/png"
          />
        }
      </Helmet>
      <Box position="relative" zIndex="20" w="full">
        <Container variant={isFullWidth ? 'wide' : undefined} py={4} {...rest}>
          <Flex py="1" alignItems="center">
            {community?.isWhiteLabel ? (
              <RouterLink to={ROUTES.community.base.set({ communitySlug: community.slug })}>
                <ImageOrPlaceholder
                  src={community.logoMd}
                  h="3em"
                  w="3em"
                  borderRadius="lg"
                  iconProps={{ boxSize: 6 }}
                  icon={Ticket}
                />
              </RouterLink>
            ) : (
              <Link
                to={user ? ROUTES.dashboard.base.path : '/'}
                padding="3"
                bg="primary-inverse"
                color="primary-inverse-fg"
                lineHeight="0"
                borderRadius="md"
              >
                <Logo boxSize="6" />
              </Link>
            )}

            <Spacer />
            {children}
            {!loading && !userLoading && !logoutLoading && (
              <>
                {user ? (
                  <>
                    <HStack spacing="3">
                      {isDashboard && !!user?.managed.length && (
                        <Button
                          as={Link}
                          to={ROUTES.dashboard.community.itemsNew.set({
                            communitySlug: user.managed[0].community.slug,
                          })}
                          variant="primary"
                        >
                          New Drop
                        </Button>
                      )}
                      <UserDropdown
                        user={user}
                        onClick={(e: MouseEvent<HTMLButtonElement>) => {
                          if (dropdownHoldsSidebar) {
                            e.preventDefault();
                            onToggle();
                          }
                        }}
                      >
                        <HStack spacing="3">
                          <Avatar size="md" src={user?.avatar || ''} />
                          <Icon
                            as={dropdownHoldsSidebar ? (isOpen ? X : List) : CaretDown}
                            ml="-2px"
                            boxSize={dropdownHoldsSidebar ? '8' : undefined}
                          />
                        </HStack>
                      </UserDropdown>
                    </HStack>
                  </>
                ) : (
                  <>{/* <Link to={ROUTES.auth.login.set({ isWhiteLabel, communitySlug: slug })}>Login</Link> */}</>
                )}
              </>
            )}
          </Flex>
        </Container>
        {/* // For small breakpoints Values */}
        {isOpen && dropdownHoldsSidebar && (
          <Box bg="chakra-subtle-bg" w="full" position="absolute">
            <Container px={{ base: '2', md: '4' }} py="6">
              <Flex>
                <Stack spacing="2" w="50%" onClick={onClose}>
                  {user && (
                    <>
                      <DashboardSidebar />
                      <SidebarLink
                        onClick={(e) => {
                          e.preventDefault();
                          toggleColorMode();
                        }}
                        label={`Toggle ${colorMode === 'light' ? 'Dark' : 'Light'} Mode`}
                        to={ROUTES.auth.login.path}
                        icon={colorMode === 'light' ? Moon : Sun}
                      />
                      <SidebarLink
                        onClick={(e) => {
                          e.preventDefault();
                          logout();
                        }}
                        label="Log out"
                        to={ROUTES.auth.login.path}
                        icon={SignOut}
                      />
                    </>
                  )}
                </Stack>
                <Stack w="50%">
                  {!!user?.managed.length && (
                    <Text fontWeight="600" color="gray.500" mb={4}>
                      Your communities
                    </Text>
                  )}
                  {!!user?.managed.length &&
                    user.managed.map((community) => (
                      <LinkBox
                        key={community.community.id}
                        onClick={() => {
                          setCurrentCommunityId(community.community.id);
                          onClose();
                        }}
                        to={ROUTES.dashboard.community.overview.set({ communitySlug: community.community.slug })}
                      >
                        <Flex alignItems="center" mb={4}>
                          <CommunityLogoNav logo={community.community.logo} />
                          <Text
                            ml={2}
                            fontWeight="bold"
                            fontSize="lg"
                            color={currentCommunityId === community.community.id ? 'gray.500' : ''}
                          >
                            {community.community.displayName}
                          </Text>
                        </Flex>
                      </LinkBox>
                    ))}
                </Stack>
              </Flex>
            </Container>
          </Box>
        )}
      </Box>
    </>
  );
};
