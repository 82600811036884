import { Stack, StackProps } from '@chakra-ui/react';

export const Sidebar = (props: StackProps) => (
  <Stack
    spacing="0"
    alignItems="start"
    wordBreak="break-all"
    w={{ base: '220px', lg: '260px', xl: '300px' }}
    ml={{ base: undefined, lg: '-16px' }}
    mr={{ base: undefined, xl: '2' }}
    {...props}
  />
);
