import { gql } from '@apollo/client';
import { Box, BoxProps, Container, Flex, Stack, useBreakpointValue } from '@chakra-ui/react';
import { ROUTES } from 'routes';

import { LinkBox } from 'components/atoms/Link';
import { CommunityHeaderControls } from 'components/organisms/CommunityHeaderControls';

import { CommunityLogo } from '../CommunityLogo';
import { CommunityNameAndBio } from '../CommunityNameAndBio';
import { CommunityPanel_community } from './__graphql__/CommunityPanel_community';

interface CommunityPanelProps extends BoxProps {
  community?: CommunityPanel_community | null;
  withSignUp?: boolean;
}

export const CommunityPanel = ({ community, withSignUp, ...rest }: CommunityPanelProps) => {
  const alignMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box w="full" bg="lineGray" color="offBlack" py={8} px={{ xl: 8 }} {...rest}>
      <Container
        as={Stack}
        spacing={{ base: 4, lg: 10, xl: 20 }}
        direction={{ base: 'column', lg: 'row' }}
        alignItems="center"
        py={{ base: 0, xl: 10 }}
      >
        <Stack
          spacing={{ base: 4, lg: 10, xl: 20 }}
          flexGrow={1}
          direction={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'center', md: 'center' }}
          textAlign={{ base: 'center', md: 'left' }}
          w="full"
        >
          <LinkBox to={ROUTES.community.base.set({ communitySlug: community?.slug })}>
            <CommunityLogo
              community={community}
              flexShrink={0}
              w={{ base: '24', lg: '32' }}
              h={{ base: '24', lg: '32' }}
            />
          </LinkBox>
          <Stack direction={{ base: 'column', md: 'row' }} w="full" spacing={4} alignItems="center">
            <CommunityNameAndBio
              community={community}
              pt="1"
              welcomeExplainer
              textAlign={alignMobile ? 'center' : undefined}
              hideLinkInBio
            />
            <Flex mx={{ base: 'auto !important', md: '0 !important' }}>
              <CommunityHeaderControls community={community} withGoogle showCounts={false} />
            </Flex>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

CommunityPanel.fragments = {
  community: gql`
    fragment CommunityPanel_community on Community {
      id
      logoCard: logo(variant: card)
      displayName
      slug
      ...CommunityHeaderControls_community
      ...CommunityNameAndBio_community
      ...CommunityLogo_community
    }
    ${CommunityHeaderControls.fragments.community}
    ${CommunityNameAndBio.fragments.community}
    ${CommunityLogo.fragments.community}
  `,
};
